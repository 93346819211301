// Search layout
.NotifiStatisticSearch {
  padding: 24px 0;
}

.NotifiStatisticSearchTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #212529;
  padding-bottom: 8px;
}

.NotifiStatisticSearchSelect {
  .SelectWrapper {
    width: 100%;
    padding-right: 8px;
  }

  .ButtonPrimaryWrapper .ButtonPrimary {
    padding: 0 56px !important;
  }
}

.NotifiStatisticCurrentHallSearch {
  .InputWrapper {
    width: 100%;
    padding-right: 8px;
  }
  .ButtonPrimaryWrapper .ButtonPrimary {
    padding: 0 56px !important;
  }
}

// Table layout
.NotifiStatisticTableColumn {
  width: 25%;
  word-spacing: normal !important;
}

.NotificationsStatisticTableRow:hover {
  cursor: auto !important;
}

.NotificationsStatisticTableItem {
  margin-left: 32px !important;
}

.NotificationsStatisticTableItem,
.NotificationsStatisticTableLink {
  margin-top: 10px;
  margin-bottom: 10px;
}

.NotificationsStatisticTableLink {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #228BE6;
}

.NotificationsStatisticTableLink:hover {
  cursor: pointer;
  opacity: 0.7;
}
