.MainPageContentWrapper_nominals {
  width: 100% !important;
}

.NominalsContentWrapper {
  margin-top: 24px;
  padding-bottom: 54px;
  max-width: 1400px;
}

.NominalsTableTime {
  color: @gray-600;
}

.NominalsTableDate {
  color: @shark-500;
}

.NominalsTableCommentWrapper {
  max-width: 400px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.NominalsTableCommentContainer {
  display: flex;
  flex-direction: row;
}

.NominalsTableCommentWrapperMore {
  text-transform: lowercase;
  color: @blue-700;
  align-self: end;
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
}

.HistoryModalContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.HistoryModal {
  padding: 32px !important;
}

.HistoryModalContainer__header {
  &:extend(.HeadlineSecondText);
}

.HistoryModalContainer__body {
  word-wrap: break-word;
  &:extend(.SubtitleSecondText);
}

.HistoryModalContainer__footer__button {
  width: 96px;
  height: 40px;
}

.ChangeTabTennisContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.ChangeTabTennisContainer_item {
  width: 200px;
  height: 56px;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px @gray-200 solid;

  &:hover {
    cursor: pointer;
    border: 1px @lemon-500 solid;
    outline: 1px solid @lemon-500;
  }
}

.ChangeTabTennisContainer_title {
    &:extend(.BodySecondText);
}

.ChangeTabTennisContainer_item__active {
  border: 1px @lemon-500 solid;
  outline: 1px solid @lemon-500;
}

.ChangeTabTennisContainer_tooltip {
  width: 100%;
  height: fit-content;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  gap: 6px;
  border-radius: 8px;
  background: @blue-10;
  margin: 16px 0;
  padding: 12px;
}

.ChangeTabTennisContainer_tooltip_text {
  &:extend(.TextDefault);
  white-space: pre-line;
  color: @blue-600 !important;
  b {
    font-weight: 700 !important;
  }
}

.NominalsTablesWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.ChangeTabColWrapper {
  display: flex;
  flex-direction: column;
  height: 96px;
  margin-left: 20px;
}

.NominalsBetTitle {
  margin-left: 20px;
}

.ChangeTabColWrapper_TitleImageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-top: 16px;

  svg {
    width: 20px;
    height: 20px;
  }
}

.ChangeTabColWrapper_TitleImageContainer_Text {
  &:extend(.TextDefaultBold);
}

.ChangeTabColWrapper_TitleImageContainer_Text-secondary {
  margin-top: 20px;
  color: @gray-600 !important;
  &:extend(.TextDefault);
}

.ChangeTabColNominalChange_Wrapper {
  display: flex;
  flex-direction: column;
  gap: 14px
}

.InputWrapper  input.ant-input.ChangeTabColNominalChange_Input {
  min-width: 80px !important;
  max-width: 80px !important;
  height: 32px !important;
}

.ChangeTabColNominalChange_Title {
  &:extend(.TextDefault);
}

.ChangeTabColNominalChange_Title_MaxLimit,
.NominalsTemplateCard_Cell_SubTitle_Value_Max,
.NominalsTemplateCard_Cell_SubTitle_Currency_Max  {
  color: @orange-600 !important;
  &:extend(.TextDefaultBold);
}

.ChangeTab_Nominals-mock-title {
  margin-top: 16px;
  padding-bottom: 16px !important;
  .TitleDescription .TitleText span {
    font-size: 16px !important;
    &:extend(.BodySecondText);
  }

  .TitleTooltip {
    margin-left: 4px;
    .TooltipComponentTargetWrapper svg {
      width: 16px;
      height: 16px;
    }
  }
}


.NominalsBetTable {
  .ant-table-thead tr th {
    background-color: @orange-10 !important;
  }
}

.NominalsBetTableWrapper .WithoutPaginationTableComponentWrapper {
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 0 !important;
  }
  .ant-table-content {
    border-radius: 0 !important;
    border: 0 !important;
  }
  .ant-table-thead {
    border-radius: 0 !important;
  }
  &:extend(.PaginationTableComponentWrapper);
  .ant-spin-nested-loading {
    .ant-spin-container {
      .ant-table {
        border-radius: 0 !important;
      }
    }
  }
}

.NominalsBetTableWrapper {
  width: 100%;
}

.NominalsTemplateCardWrapper {
  width: 692px;
  height: 132px;
  background-color: @white-100;
  border-radius: 12px;
  border: 1px solid @gray-200;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.NominalsTemplateCardWrapper_Modal {
  padding: 0;
  border: none;
  border-radius: unset;
  border-bottom: 1px solid @gray-200;
  height: 93px;
}

.NominalsTemplateCardWrapper_MinMax {
  height: 280px;
}

.NominalsTemplateCardWrapper_Modal_MinMax {
  height: 237px;
}

.NominalsTemplateCardContainer {
  padding-bottom: 54px;
}

.NominalsTemplateCard_TitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.NominalsTemplateCard_LeftTitle, .NominalsTemplateCard_RightTitle {
  display: flex;
  flex-direction: row;
  gap: 16px;

  .CheckboxWrapper {
    align-items: center;
  }
}

.NominalsTemplateCard_Title {
  &:extend(.BodySecondText);
}

.NominalsTemplateCard_RightTitle {
  &:extend(.TextDefault);
}

.NominalsTemplateCard_NominalsRow {
  display: flex;
  justify-content: space-between;
}

.NominalsTemplateCard_Cell {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.NominalsTemplateCard_Cell_Title,
.NominalsTemplateCard_Cell_SubTitle_Currency,
.NominalsTemplateCard_NumbersTable_MinMaxHead,
.NominalsTemplateCard_NumbersTable_Cell_Currency {
  color: @gray-600 !important;
  &:extend(.CaptionText);
}

.NominalsTemplateCard_Cell_SubTitle_Container {
  display: flex;
  flex-direction: row;
  gap: 4px;

}

.NominalsTemplateCard_Cell_SubTitle_Value {
  &:extend(.TextDefaultBold);
}

.NominalsTemplateCard_Cell_SubTitle_Currency_Max {
  font-weight: 400 !important;
}

.NominalsTemplateCardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.NominalsSendButton {
  margin-top: 16px;
}

.NominalsTemplateCard_NumbersTable {
  table-layout: fixed;
  //border-collapse: separate;
  //border-spacing: 0 8px;
}

.NominalsTemplateCard_NumbersTable_Cell_Currency_Wrapper {
  display: flex;
  gap: 4px;
  align-items: center;
}

.NominalsTemplateCard_NumbersTable_MinMaxHead {
  white-space: nowrap;
}

.NominalsTemplateCard_NumbersTable_Numbers {
  background-color: @gray-10;
}

.NominalsModalContainer {
  //width: 739px; //TODO fix mb
  min-width: 739px;
  height: fit-content;
  //height: 634px;
  //min-height: 634px;
  height: fit-content;
  background: @white-100;
  padding: 46px 48px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 16px;
}

.NominalsModalSuccessContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;

  button {
    width: 240px;
  }
}

.NominalsModalSuccessTitle {
  white-space: pre;
  &:extend(.HeadlineFirstText);
  text-align: center;
}

.NominalsTemplateCardWrapper_Active {
  border-color: var(--lemon-main, #FCE000);
  outline: 1px solid var(--lemon-main, #FCE000);
}

.ChangeTabWrapper {
  .ant-table-row .ant-table-cell {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .ErrorInputWrapper .ErrorText {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    line-height: 1.5;
  }
}

.NominalsColumns_GameCell {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.NominalsResultContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.NominalsContentWrapper {
  .ModalContent {
    max-height: 90vh;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

@media (max-width: 768px) {

  .ChangeTabColWrapper {
    margin-left: 0 !important;
  }
  .ChangeTabWrapper {
    > *:not(.ChangeTabTennisContainer) {
      margin-left: 16px;
      margin-right: 16px;
    }
  }

  .ChangeTabTennisContainer {
    margin-left: 16px;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .ChangeTabTennisContainer_item {
    //display: inline-block;
    margin-right: 10px;
    min-width: 136px;
    width: 136px;
    min-height: 48px;
    height: 48px;
  }

  .ChangeTabTennisContainer_tooltip {
    max-width: 92%;
    border: 1px solid @blue-600;
  }

  .NominalsTemplateCard_NominalsRow {
    flex-wrap: wrap !important;
    gap: 10px !important;
    justify-content: initial !important;
  }

  .NominalsTemplateCard_TitleContainer {
    flex-direction: column;
    align-items: start;
    gap: 8px;
  }

  .NominalsTemplateCardWrapper {
    height: 100%;
    gap: 12px;
    padding: 16px;
  }

  .NominalsModalContainer {
    min-width: 100% !important;
    padding: 24px 16px;

    .HeadlineFirstText {
      font-size: 20px !important;
      line-height: normal !important;
    }
  }

  .NominalsTemplateCardWrapper_Modal {
    width: 100% !important;
    border-radius: 16px;
    background: @gray-10;
    padding: 16px;

    .MobileTableCardRowItemTitle {
      padding-right: 8px;
      background: @gray-10;
      z-index: 9;
    }

    .NominalsTemplateCard_Cell_SubTitle_Container {
      padding-left: 8px;
      background: @gray-10;
      z-index: 9;
    }
  }

  .NominalsResultContainer {
    gap: 4px;
  }

  .ModalContent::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .GridCenter div.ModalWrapper {
    padding-left: 16px;
    padding-right: 16px;
    width: 100% !important;
  }
}

.MobileTableCardRow {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.MobileTableCardRowItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  align-items: center;

  .InputWrapper {
    max-width: 80px !important;
    width: 80px !important;
    min-width: 80px !important;
  }
}

.MobileTableCardRowItem__WithDots:before {
  content: '';
  position: absolute;
  top: 63%;
  left: 0;
  right: 0;
  height: 2px;
  background: repeating-linear-gradient(90deg, @gray-400, @gray-400 2px, transparent 2px, transparent 4px);
  transform: translateY(-50%);
  margin: 0 10px;
  z-index: 8;
}



.MobileTableCardContainer {
  padding: 16px;
  border-radius: 16px;
  border: 1px solid @gray-200;
  background: @white-100;

  .MobileTableCardRowItemTitle {
    padding-right: 8px;
    &:extend(.CaptionText);
  }

  .ChangeTabColWrapper_TitleImageContainer_Text-secondary {
    &:extend(.CaptionText);
  }

  .ChangeTabColNominalChange_Wrapper,
  .MobileTableCardRowItemTitle {
    z-index: 9;
    background: @white-100;
  }

  .ChangeTabColNominalChange_Wrapper {
    flex-direction: row !important;
    align-items: center;
    padding-left: 8px;
  }
}

.MobileTableCardRowItem_TitleRow {
  height: 20px;

  .ChangeTabColWrapper {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

  }
}

.MobileTableCardsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.MobileTableCardLimitsToggleContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-top: 8px;

  &:hover {
    cursor: pointer;
  }

  svg {
    margin-top: 3px;
  }

  .MobileTableCardLimitsToggleContainer_Text {
    color: @blue-600 !important;
    &:extend(.TextDefaultBold);
  }
}

.MobileTableCardLimitsToggleContainer_shown {
  svg {
    transform: rotate(180deg);
  }
}

.MobileTableCardLimitsSwitchContainer {
  display: flex;
  width: 100%;
  max-width: 311px;
  min-width: 311px;
  padding: 2px;
  justify-content: center;
  align-items: flex-start;
  gap: 1px;
  border-radius: 5px;
  background: @white-800;
  margin-top: 8px; //TODO mb use gap

  .MobileTableCardLimitsSwitchItem {
    display: flex;
    height: 28px;
    padding: 6px 8px;
    justify-content: center;
    align-items: center;
    border: 0.5px solid transparent;
    width: 153px;
    &:extend(.CaptionTextBold);
  }

  .MobileTableCardLimitsSwitchItem_active {
    border-radius: 4px;
    border: 0.5px solid @gray-200;
    background: var(--white-100, #FFF);
  }
}

.MobileTableCardRowMinMax {
  display: none;
}

.MobileTableCardRowMinMax__visible {
  display: flex;
}

.NominalsTemplateCardsContainer {
  .ant-carousel {
    width: 100%;
    height: 100%;
    padding-bottom: 15px;

    .NominalsTemplateCardWrapper {
      max-width: 100%;
    }

    .slick-dots li {
      background: @gray-400;
      width: 8px !important;
      height: 8px !important;
      border-radius: 50% !important;

      button {
        background: @gray-400;
        width: 8px !important;
        height: 8px !important;
        border-radius: 50% !important;
      }

      .slick-active {
        background: @shark-500 !important;
        button {
          background: @shark-500 !important;
        }
      }

    }

    .slick-dots-bottom {
      bottom: -15px !important;
    }
  }
}

.NominalsTemplateCard_NominalsRow_MinMaxLimitsTitle_Mobile {
  margin-bottom: -4px;
  color: @gray-600 !important;
  &:extend(.StatusText);
}

.NominalStatus {
  width: 112px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  &:extend(.StatusText);
}

.NominalStatus_processing {
  color: @orange-600;
  background: @orange-10;
}

.NominalStatus_completed {
  color: @green-700;
  background: @green-10;
}

.NominalStatus_canceled {
  color: @red-600;
  background: @red-10;
}

.NominalsContentWrapper {
  .ModalContent::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.NominalsTemplateCard_NumbersTable_Numbers .NominalsTemplateCard_NumbersTable_Cell {
  padding-top: 8px;
  padding-bottom: 8px;
  &:extend(.TableHeader);
}

.NominalsTemplateCard_NumbersTable_Cell_Value {
  &:extend(.TextDefaultBold);
}

.NominalsTemplateCard_NumbersTable_Cell_Currency {
  font-size: 14px !important;
  &:extend(.HeadlineFirstText);
}


.NominalsTemplateCard_NumbersTable {
  border-spacing: 0 8px;
  position: relative;
}

.NominalsTemplateCard_NumbersTable tbody {
  border-bottom: 8px solid transparent;
}

.NominalsTemplateCard_NumbersTable_Numbers {
  position: relative;
}

.NominalsTemplateCard_NumbersTable_Numbers::before,
.NominalsTemplateCard_NumbersTable_Numbers::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 24px;
  height: 32px;
  background-color: @gray-10;
}

.NominalsTemplateCard_NumbersTable_Numbers::before {
  left: -24px;
}

.NominalsTemplateCard_NumbersTable_Numbers::after {
  right: -24px;
}

.ChangeTabWrapper .Overlay {
  z-index: 9;
}

.NominalsTemplateCardWrapper_Modal .ChangeTabColWrapper_TitleImageContainer {
  margin-top: 0;
}

.NominalsModal {
  .ModalCloseWrapper {
    position: absolute;
    right: 0.5em;
    top: 0.5em;
    svg {
      width: 28px;
      height: 28px;
    }
  }
}

.NominalsTemplateCard_RightTitle .ant-checkbox-disabled .ant-checkbox-inner {
  background-color: @gray-200 !important;
  border-color: @gray-200 !important;
}