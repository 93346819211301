.VisitsContentPeriodWrapper {
  padding: 0 8px 24px 8px;
}

.VisitCalendarShow {
  display: block;
}

.VisitCalendarHidden {
  display: none;
}

.VisitsPageTitle {
  grid-gap: 18px;
  margin-bottom: 8px;
  padding: 0;
  margin-top: 10px;
}

.VisitsPageTitle .VisitsPageTitleInner {
  .HeadlineFirstText;
  display: flex;

  .TooltipComponentTargetWrapper {

    svg {
      position: relative;
      top: 4px;
      margin-left: 10px;
    }
  }
}

.VisitsBlock {
  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  border-radius: 8px;
  padding-top: 24px;
  padding-bottom: 24px;
  position: relative;
  margin-bottom: 16px;
}

.VisitsBlockSecondary {
  padding: 24px;
}

.VisitsContentPeriodWrapper {
  padding: 0;
  margin-bottom: 16px;

  .ContentPeriodDescription {
    line-height: 120%;
    color: #868E96;
  }

  .ContentPeriod {
    color: #212529;
  }
}

.VisitsRegSelection {
  display: flex;
  position: absolute;
  top: 32px;
  right: 17px;

  .SwitchComponentLabel {
    .SubtitleSecondText;
    margin-left: 10px;
  }
}

.VisitsRegSelectionTooltip {
  margin-left: 2px;

  svg {
    width: 17px;
    height: 17px;
    position: relative;
    top: 3px;
  }
}

.VisitsStatisticTypesWrapper {
  max-width: 692px;
  margin: 0 auto;
  justify-content: center;
  padding-bottom: 8px;
  padding-top: 0;
}

.VisitsStatisticTypesItem {
  min-width: 220px;
  margin: 8px;
  padding: 9px;
  border-radius: 8px;
  transition: background-color 0.2s ease-in, border 0.2s ease-in;

  .TypeItemTitle {
    .SubtitleFirstText;
    font-weight: 500;
    line-height: 22px;
  }
}

.VisitsBlockContainer {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  justify-content: space-between;

  .VisitsBlock {
    flex: 0 0 calc(50% - 8px);
    max-width: calc(50% - 8px);

    svg{
      max-width: 100%;
    }
  }
}

.VisitsBlockTitle,
.VisitsBlockTitle span {
  .HeadlineThirdText;
  margin-bottom: 8px;
  display: flex;

  svg {
    width: 20px;
    height: 20px;
    margin-left: 2px;
    position: relative;
    top: 3px;
  }
}

.VisitsBlockTitleWrapper {
  padding-right: 40px;
}

.VisitsCalendarTrigger {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 32px;
  height: 32px;

  & > svg {
    cursor: pointer;
  }
}

.VisitCalendarShow {
  min-width: 151px;
  position: absolute;
  top: 41px;
  right: 0;
  z-index: 2;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 8px 14px 14px;

  .RadioGroupOption {
    line-height: 16px;
    height: auto;
    padding-bottom: 4px;
  }
}

.VisitsCalendarTitle {
  .BodySecondText;
  font-weight: 700;
}

.VisitWeekDaysWrapper {
  text-align: center;

  .VisitWeekDays.ant-radio-group {
    display: inline-flex;
    .BodySecondText;
    font-weight: 700;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #868E96;
    background: #F4F4F4;
    border-radius: 8px;
    padding: 2px;

    .ant-radio-button-wrapper {
      border: none;
      background: none;
      border: 1px solid transparent;
      transition: background 0.2s ease-in, border-color 0.2s ease-in, color 0.2s ease-in;
      color: #868E96;
      min-width: 56px;
      height: 28px;
      line-height: 28px;
      position: relative;
      padding: 0 10px;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;


      &:before {
        display: none;
      }

      &.ant-radio-button-wrapper-checked {
        background: #FFFFFF;
        border-color: #E9ECEF;
        border-radius: 8px;
        color: #212529;
      }

      &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within,
      &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-visible {
        box-shadow: none;
        outline: none;
      }
    }

    .ant-radio-button-wrapper.PeaksHoursMostVisited {
      &:after {
        content: "";
        height: 11px;
        width: 11px;
        background-image: url(/Components/icons/favorite.svg);
        background-size: contain;
        margin-left: 5px;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }

}

.RegStatTitle {
  .HeadlineSecondText;
  text-align: center;
}

.RegStatSubTitle {
  .SubtitleFirstText;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  color: #495057;
}

.regStat_legendColor {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.regStat_legendColorNew {
  background: #FFA94D;
}

.regStat_legendColorUnique {
  background: #748FFC;
}

.regStat_legendName {
  .TableHeaderText;
}

.regStat_legendBox {
  display: flex;
  justify-content: center;
  gap: 32px;
}

.VisitsBlock {
  .highcharts-text-outline {
    display: none;
  }
}

.regStat_legendInfo {
  display: flex;
  align-items: center;
  gap: 14px;
}

.regStatLabel {
  fill: #FFFFFF;
  .BodySecondText;
  font-size: 18px;
}

.VisitRegTooltipWrapper {
  height: 44px;
  width: 68px;
  gap: 4px;
}

.VisitRegPointUp {
  .SubtitleFirstText;
  line-height: 18px;
  display: block;
  color: #FFDD2D !important;
  text-align: center;
}

.VisitRegPointDown {
  .OverlineText;
  color: #D1D1D6 !important;
  text-align: center;
}

.VisitLabel {
  .TextDefault;
  font-weight: 500;
  line-height: 17px;
  color: #868E96;
}

.VisitPeaksLabel {
  .CaptionText;
  line-height: 14px;
  color: #868E96;
}

.VisitsPageContentWrapper {

  .VisitsDashboardsWrapper {
    background-color: #ffffff;
    padding: 12px 24px;
    border: 1px solid #DEE2E6;
    border-radius: 16px;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 1680px) {

  .VisitsPageContentWrapper {
    .ComponentDashboardItem {
      padding: 16px 8px 16px 16px;

      .DashboardItemIconWrapper {
        svg, img {
          width: 42px;
        }
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .VisitsPageTitle {
    align-items: center;

    .VisitsPageTitleInner {
      .HeadlineSecondText;

      .TooltipComponentTargetWrapper {
        svg {
          width: 20px;
          height: 20px;
          margin-left: 6px;
          top: 3px;
        }
      }
    }
  }

  .VisitsStatisticTypesItem {
    min-width: 180px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 0;

    .TypeItemTitle {
      font-size: 13px;
      line-height: 18px;
    }
  }

  .VisitsStatisticTypesWrapper{
    padding-bottom: 0;
  }

  .TypeItemMarkerWrapper{
    margin-top: 2px;
  }

  .VisitWeekDaysWrapper .VisitWeekDays.ant-radio-group .ant-radio-button-wrapper {
    min-width: 44px;
  }

  .VisitWeekDaysWrapper .VisitWeekDays.ant-radio-group .ant-radio-button-wrapper.PeaksHoursMostVisited:after {
    margin-left: 2px;
  }
}

@media screen and (max-width: 1320px){
  .VisitsBlockContainer {
    display: block;

    .VisitsBlock{
      max-width: unset;

      svg{
        max-width: unset;
      }
    }
  }
}

@media screen and (max-width: 920px) {
  .VisitsRegSelectionTooltip {
    .TooltipComponentTargetWrapper {
      svg {
        top: 1px;
      }
    }
  }

  .VisitLabelYAxis {
    font-size: 11px !important;
    font-weight: 400 !important;;
    line-height: 16px !important;;
  }

  .VisitsPageContentWrapper {

    .MainPageContentWrapper& {
      background: #ffffff !important;
    }

    .VisitsDashboardsWrapper {
      background-color: unset;
      border: none;
      padding-left: 16px;
      padding-right: 16px;
    }

    .ButtonIconWrapper .ant-btn.ButtonIcon_excel {
      border: none;
      background: transparent url(/Components/icons/excel_small.svg) no-repeat center center;
      background-size: contain;
      padding: 0;
      width: 26px;
      height: 32px;

      svg, span {
        display: none;
      }
    }
  }

  .VisitsPageTitle {
    padding-left: 16px;
    padding-right: 16px;
    justify-content: space-between;
    margin-bottom: 20px;

    .VisitsPageTitleInner .TooltipComponentTargetWrapper svg {
      width: 24px;
      height: 24px;
      margin-left: 5px;
    }
  }

  .VisitsContentPeriodWrapper {
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 14px;

    .ContentPeriodDescription {
      font-size: 12px;
      line-height: 14px;
    }
  }

  .VisitsRegSelection {
    right: auto;
    left: 16px;
  }

  .VisitsStatisticTypesWrapper {
    max-width: unset;
    width: auto;
    padding-left: 0;
    margin-left: 16px;
    margin-right: 16px;
    padding-bottom: 0;
  }

  .VisitsStatisticTypesItem {
    margin: 0;
    width: calc(100% / 3);
    min-width: unset;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 0;
    border-color: rgb(222, 226, 230) !important;


    &:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-right: none !important;
    }

    &:last-of-type {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-left: none !important;
    }

    .TypeItemTitle {
      font-size: 12px;
      line-height: 16px;
      white-space: nowrap;
    }
  }

  .TypeItemMarkerWrapper {
    width: 8px;
    height: 8px;
    margin-top: 4px;
    margin-right: 4px;
  }

  .VisitsRegSelection .SwitchComponentLabel {
    .TextDefault;
    margin-left: 6px;
  }

  .VisitsBlock {
    border: none;
    padding: 5px 0 0;
    margin-bottom: 34px;

    .CashboxChartsWrapper {
      padding-left: 0;
    }
  }

  .VisitsBlockContainer {

    .VisitsBlock {

      &:first-of-type{
        margin-bottom: 50px;
      }

      .VisitsBlockTitle {
        padding-left: 16px;
        padding-right: 16px;
        font-size: 16px;
      }
    }
  }

  .VisitWeekDaysWrapper {
    margin-top: 25px;
  }

  .VisitWeekDaysWrapper .VisitWeekDays.ant-radio-group {
    display: flex;
    margin-left: 16px;
    margin-right: 16px;
    padding: 6px 11px;
  }

  .VisitWeekDaysWrapper .VisitWeekDays.ant-radio-group .ant-radio-button-wrapper {
    flex: 1 1 calc(100% / 7);
  }

  .VisitsBlockTitle svg {
    width: 20px;
    height: 20px;
    top: 4px;
  }

  .VisitsBlockTitle,
  .VisitsBlockTitle span{
    font-size: 16px;
  }

}

.mostVisitedHoursWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  .CaptionText;

  svg {
    margin-right: 5px;
    position: relative;
    top: -1px;
  }
}

.mostVisitedHoursTime {
  font-size: 14px;
  font-weight: 600;
  margin-right: 5px;
}

.mostVisitedHoursText {
  color: #868E96;
}

.VisitsDashboardsHours {
  display: flex;
  align-items: flex-end;
  .TableHeaderText;
  font-size: 13px;

  & span{
    .TableHeaderText;
    font-size: 13px;
  }

  & > svg {
    margin-right: 6px;
    position: relative;
    top: 1px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background-color: unset;
  }

  .ant-select.VisitTimeShiftSelect.ant-select-single.ant-select-show-arrow.ant-select-focused.ant-select-open,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      border: none;
      box-shadow: none;
    }

  }

  .ant-select-arrow {
    display: none;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding: 2px 4px;
    .BodySecondText;
    line-height: 17px;
    color: #228BE6;
  }

  .VisitsDashboardsCity {
    padding-top: 2px;

    .BodySecondText;
    line-height: 14px;
    color: #228BE6;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 17px;
  }
}

.VisitTimeShiftSelect.ant-select{
  display: inline;
  line-height: 17px;
}

.ant-select-dropdown.VisitTimeShiftSelect_dropdown {
  min-width: 120px !important;
  width: 120px !important;
  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;

  .ant-select-item.ant-select-item-option {
    padding: 9px 16px;

    &.ant-select-item-option-selected,
    ant-select-item-option-active {
      background: #F1F3F5 !important;

      .ant-select-item-option-content {
        font-weight: 700;
      }
    }

    .ant-select-item-option-content {
      .TableHeaderText;
      color: #495057;
    }
  }

  //.rc-virtual-list-holder{
  //  max-height: 168px !important;
  //}
}

.VisitChartPeaks_EmptyDataIcon {
  text-align: center;
  padding-top: 30px;
}

.RegStatChartWrapper {
  padding-top: 0px;
}

.VisitAndRegChartsWrapper {
  padding-left: 0px;
}

.EmptyDataPeaksTitle {
  .HeadlineSecondText;
  text-align: center;
}

.EmptyDataPeaksText {
  .TextDefault;
  text-align: center;
}

.VisitLabelYAxis {
  .BodyFirstText;
  font-weight: 500;
  line-height: 19px;
  color: #868E96;
}

.VisitsPageContentWrapper .CahsboxChartsTooltipWrapper {
  &>span{
    min-width: 78px;
    text-align: center;

    span, b{
      text-align: center;
    }

    span:first-of-type,
    br{
      display: none;
    }
  }
}
