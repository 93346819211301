@import "./Comments/styles";
@import "./Freebets/styles";

.GuestHistoryCardWrapper {
  gap: 32px;
}

.GuestHistoryCardInfo {
  padding: 24px;
  width: calc(100% - 324px);

  border: 1px solid #DEE2E6;
  border-radius: 16px;
  background: #FFFFFF;
}

.GuestHistoryCardInfoHeader {
  gap: 16px;
}

.GuestHistoryCardInfoHeaderTitle {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #212529;
}

.GuestHistoryCardInfoDescription {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;

  padding-top: 8px;
  color: #868E96;
}

.GuestHistoryStatus {
  display: flex;
  padding: 6px 18px;
  border-radius: 4px;

  span {
    padding-top: 2.5px;
    padding-left: 4px;
  }
}

.GuestHistoryCardInfoDescriptionWalletStatus {
  width: 8px;
  height: 8px;

  margin-right: 5px;
  margin-top: 6px;

  border-radius: 50%;
}

.GuestHistoryCardInfoDescriptionWalletStatusTooltip {
  padding: 0 !important;

  .ant-popover-content {
    margin-left: -4px !important;

    .ant-popover-arrow {
      display: none !important;
    }
  }
}

.GuestHistoryCardInfoDescriptionActiveWallet {
  background: #40C057;
}

.GuestHistoryCardInfoDescriptionInactiveWallet {
  background: #E03131;
}

.GuestHistoryActive {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;

  padding: 6px 12px;
  background: #EBFBEE;
  border-radius: 4px
}

.GuestHistoryCardDate {
  background: #FFFFFF;
  border: 1px solid #DEE2E6;
  border-radius: 16px;
}

.GuestHistoryCardDateData {
  padding: 24px 18px 17px 25px;
}

.GuestHistoryCardDateStick {
  margin-right: 24px;
}

.GuestHistoryCardDateDataTitleWrapper {
  padding-left: 12px;
}

.GuestHistoryCardDateTitle {
  padding-bottom: 12px;

  position: relative;
}

.GuestHistoryCardDateTitleTooltip {
  position: absolute;

  left: 200px;
  top: 0;

  .TooltipComponentTargetWrapper {
    height: 24px !important;
  }
}

.GuestHistoryCardDateDataTitle, .GuestHistoryCardDateTitle {
  color: #212529;
}

.CalculateBetweenTextDate {
  padding-left: 4px;
}

.GuestHistoryCardDateDataDescription {
  color: #868E96;
}

.GuestHistoryCardDateDataGrey {
  display: flex;
  flex-direction: column;
  gap: 8px;

  margin-top: 15px;
  margin-bottom: 18px;
  padding: 10px 12px;
  background: #F1F3F5;
  border-radius: 8px;
}

// MOBILE

@media screen and (max-width: 920px) {
  .GuestHistoryCardInfo {
    width: calc(100vw - 32px) !important;
    margin-left: 16px;
    margin-right: 16px !important;
    padding: 20px 14px !important;
  }

  .GuestHistoryCardDate {
    margin-left: 16px;
    width: calc(100vw - 32px);
  }

  .GuestHistoryCardDateStick {
    margin-left: auto;
  }

  .GuestHistoryCardDateData {
    width: 100%;
  }

  .GuestHistoryCardInfoHeaderTitle {
    font-size: 20px !important;
    line-height: 25px !important;
  }
}