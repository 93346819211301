.InsurancesTable {

  table{
    width: 1398px !important;
    min-width: 1398px !important;
  }

  .ant-table-tbody .ant-table-row:hover {
    cursor: default;
  }
  .ant-table-column-sort {
    background-color: #ffffff;
  }

  .ant-table-column-has-sorters .ant-table-column-sorters,
  .ant-table-thead > tr > th {
    background-color: #E3FAFC !important;
    min-width: 124px;
  }

  .ant-table-tbody .ant-table-row > td:first-child,
  .ant-table-container table > thead > tr th:first-child{
    padding-left: 32px !important;
  }

  td.ant-table-cell{
    vertical-align: top;

    &:first-child{
      font-weight: 700;
    }

    &:nth-last-child(2){
      vertical-align: middle;
    }
  }
}

.InsurancesDateTimeCell {
  display: flex;

  .InsurancesTime{
    margin-left: 10px;
    color: #868E96;
  }
}

.InsurancesMaxPayout{
  font-weight: 700;
}

.InsurancesMore {
  color: #1C7ED6;
  cursor: pointer;
}

.InsurancesDetailWrapper {
  padding: 40px 24px;
}

.InsurancesDetailTable {
  margin-top: 16px;
}

.InsurancesDetailTable .TableComponentWrapper {
  border: 1px solid #E9ECEF;
  border-radius: 8px;
}

.InsuranceStatusFinished,
.InsuranceStatusPending,
.InsuranceStatusCanceled{
  .StatusText;
  width: 112px;
  height: 27px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.InsuranceStatusFinished {
  color: #495057 !important;
  background-color: #F1F3F5;
}

.InsuranceStatusCanceled {
  color: #FA5252 !important;
  background-color: #FFF5F5;
}

.InsuranceStatusPending {
  color: #37B24D !important;
  background-color: #EBFBEE;
}

.InsurancesDetailWrapper {
  .InsuranceDatesDescription {
    .ContentPeriodDescription {
      display: none;
    }
  }
}
.InsurancesGamesList{
  display: flex;
  flex-wrap: wrap;
  gap: 10px 13px;
}

.InsurancesGamesItem,
.InsurancesMore{
  display: flex;
  align-items: center;
}

.InsurancesGamesItem{
  height: 24px;

  img {
    margin-right: 6px;
    width: 24px;
  }
}

div.InsurancesWinnersButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 143px;
  height: 32px;
  border: 1px solid #DEE2E6;
  border-radius: 8px;
  cursor: pointer;

  svg {
    margin-right: 8px;
  }

  span {
    .ButtonTextSecond;
  }
}

div.InsurancesWinnersButton:hover {
  border: 1px solid #CED4DA;
  background: #FFF;
}

.InsurancesWinnersEmpty{
  .ButtonTextSecond;
  color: #868E96;
}

.InsurancesCellInner{
  display: flex;
  align-items: center;
  min-height: 40px;
}

.InsurancesDetailWrapper {

  span.HeadlineSecondText {
    .HeadlineSecondText;
  }

  .ant-table-container table > thead > tr:first-child th:first-child{
    border-top-left-radius: 8px;
    border-left: 1px solid #E9ECEF;
  }

  .ant-table-container table > thead > tr:first-child th:nth-last-child(2){
    border-top-right-radius: 8px;
  }

  .ant-table-container table > thead > tr:first-child th{
    border-top: 1px solid #E9ECEF;
  }

  .ant-table-tbody .ant-table-row > td:first-child{
    border-left: 1px solid #E9ECEF;
  }

  .ant-table-tbody .ant-table-row > td:last-child{
    border-right: 1px solid #E9ECEF;
  }

  .ant-table-header{
    border-top-right-radius: 8px;
    border-right: 1px solid #E9ECEF;
  }
}

.InsurancesDetailSubtitle{
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .InsuranceStatusFinished,
  .InsuranceStatusCanceled,
  .InsuranceStatusPending{
    width: auto;
    background: none;
    .TextDefault;
    height: 30px;
    display: flex;
    align-items: center;
  }

  .ContentPeriodWrapper{
    padding-top: 0;
  }

  .ContentPeriod{
    padding-left: 0;
    margin-top: 0;
  }
}

.InsurancesDetailText,
.InsurancesDetailText .ContentPeriodMain,
.InsurancesDetailText .ContentPeriodSeparatorHideTime,
.InsurancesDetailSeparator{
  .TextDefault;
  color: #868E96;
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 0;
  white-space: nowrap;
}

.InsurancesDetailSeparator{
  padding: 0 10px;
}

.InsurancesDetailTable {
  .ant-table-tbody .ant-table-row:hover {
    cursor: default;
  }

  tr th.ant-table-cell{
    white-space: nowrap !important;
  }

  table {
    td:first-child,
    thead th:first-child {
      padding-left: 24px !important;
    }
  }
}

.ContentPeriodWrapper{
  margin-left: 0 !important;
}

@media screen and (max-width: 920px){

  .InsurancesTitleWrapper {
    .ContentPeriodWrapper {
      flex-direction: row !important;
    }
  }

  .InsurancesDetailHeader {
    padding-left: 16px;
  }

  .InsurancesDetailWrapper{
    padding: 70px 5px 40px;

  }

  div.TitleText{
    margin-left: 0 !important;
  }

  .ContentPeriodDescription{
    padding-left: 0 !important;
  }

  .ModalRightDetailContent .InsurancesDetailTable {
    table{
      min-width: 350px;
      table-layout: auto !important;

      col:last-child{
        width: 1px !important;
      }

      tr th.ant-table-cell{
        white-space: pre !important;

        &:last-child{
          padding: 0 !important;
        }
      }

      td{
        white-space: nowrap;
      }
    }
  }

  .InsurancesTable {

    table{
      width: auto !important;
      min-width: unset !important;
    }

    .InsurancesGamesList{
      min-width: 360px;
    }

    .InsurancesGamesList:empty{
      min-width: unset;
    }
  }

  .InsurancesDetailSeparator{
    padding: 0 5px;
  }

  .ModalRightDetail .ModalRightDetailClose svg{
    top: 40px !important;
  }

  .InsurancesDetailSubtitle {
    .ContentPeriodMain {
      color: #868E96 !important;
    }
  }
}
