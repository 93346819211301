.HistoryCustomFilter_Range {
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: space-between;
}

.HistoryBetCell {
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
  max-width: 250px;
}

.HistoryBetCell > div {
  flex: 0 0 calc(10% - 4px);
  position: relative;
}

.HistoryBetCell_NumberGreen {
  color: @green-700;
}

.HistoryBetsDateTimeCell {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.HistoryBetsDateTimeCellWrapper {
  display: flex;
  flex-direction: column;
}

.HistoryBetsDateTimeCellDate {
  &:extend(.TextDefault);
}

.HistoryBetsDateTimeCellDate_Time {
  color: @gray-600 !important;
}

.HistoryBetsDetailContentContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .DetailTableComponentWrapper {
    margin-bottom: 8px;
  }
}

.HistoryBetRed {
  color: @red-700 !important;
}

.HistoryBetsCustomFilter .ant-input-prefix {
  color: @gray-500 !important;
}

.HistoryBetsDevelopment {
  color: @gray-600 !important;
  &:extend(.TextOne);
}

.HistoryErrContainer {
  &:extend(.RaceErrContainer);
  .HistoryErrSubContainer {
    max-width: 100%;
    width: 800px;
    height: 580px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 32px;
    div {
      white-space: pre-line;
      text-align: center;
    }

    .SubtitleSecondText {
      color: @gray-600 !important;
    }
  }
}

.HistoryBetsMore {
  white-space: nowrap;
  color: @blue-700;
}

.HistoryBetCell > div:not(:last-child)::after {
  content: ',';
}

.HistoryBetsPeriod {
  display: flex !important;
  align-items: center !important;
  .ContentPeriod {
    padding-left: 0;
  }
}

@media screen and (max-width: 910px) {
  div.HistoryBetsPeriod {
    flex-direction: row !important;
  }
}

.HistoryCustomFilter_RangeWin {
  padding-top: 8px;
}

.HistoryLiveCell {
  color: @gray-600 !important;
  &:extend(.TextOne);
}

.HistoryLiveCellWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.RedCircleHistory {
  width: 4px;
  height: 4px;
  background-color: @red;
  border-radius: 50%;
  box-shadow: 0 0 0 2px @red-200;
}