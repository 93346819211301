.RadioWrapper {

  label {
    display: flex;
  }

  .ant-radio-inner {
    width: 20px;
    height: 20px;
    border-color: #AAB4B5;
    background: #fff;

    &:after {
      top: 9px;
      left: 9px;
    }
  }

  .ant-radio-checked .ant-radio-inner::after {
    background-color: #0d0d0d;
  }

  .ant-radio-checked .ant-radio-inner {
    background-color: #F8E800;
    border-color: #F8E800;
  }

  .ant-radio-disabled .ant-radio-inner {
    border-color: rgba(217, 217, 217, 0.5) !important;
    background-color: #f6f6fb !important;
    opacity: 0.6;
  }

  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #F8E800;
  }
}

.RadioGroupOption {
  padding-bottom: 8px;
  height: 34px;
}
