.CashboxTableInsideContent {
  padding: 16px 0 16px 36px;
  background-color: #fff !important;
}

.CashboxTableInsideItemTitle {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #2B2D33;
  padding-bottom: 10px;


  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 8px;
}

.CashboxTableInsideItemTitle span {
  padding-top: 1px;
}

.ReportInsideHallInfo {
  margin-top: -1px;
}

.ReportInsideHallInfo:hover {
  svg path {
    fill: #808185;
  }
}

.CashboxTableInsideItemTitle svg:hover {
  cursor: pointer;
}

.CashboxInsideRow {
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  width: calc(100% - 32px);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #2C2C2E !important;
}

.CashboxInsideRowTotal {
  background-color: #F8F9FA;
  font-weight: bold !important;
}

.CashboxInsideRowTotal > .CashboxTableItem > div {
  margin-top: 2px;
}

.CashboxTableInsideItemDescription {
  padding-left: 8px;
  padding-top: 4px;
}

.CashboxTableInsideWithBorder {
  margin-top: 10px;
  margin-bottom: 18px;
}

.InfoPopoverWrapper .ant-popover-content .ant-popover-inner{
  border-radius: 8px !important;
}

.CashboxInsidePopoverInfo {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  padding: 0 4px;
  color: #fff;
}

.CashboxTableItemValue {
  padding-top: 5px;
}

.CashboxTableItemNegativeValue {
  color: #FF3B30 !important;
}

// MOBILE!!

@media screen and (max-width: 920px) {
  .CashboxMobileInsideWrapper {
    padding: 16px;
  }

  .CashboxTableInsideContent {
    padding: 16px 0 16px 50px !important;
    background-color: white !important;
  }

  .CashboxInsideRow {
   padding: 8px 16px 8px 0;
  }
}

.CashboxMobileHallTable {
  border: 1px solid #E9EAEA;
  border-radius: 8px;
  margin: 8px;
}

.CashboxMobileHallTableHeader {
  padding: 12px 24px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  span {
    font-family: Gilroy, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    color: #2B2D33;
    padding-left: 8px;
    padding-top: 2px;
  }
}

.CashboxMobileHallTableItem {
  padding: 16px;
  .CashboxMobileHallTableItemDescription {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #808185;
  }
  .CashboxMobileHallTableItemValue {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #2B2D33;
  }
}

.CashboxTableItem {
  min-width: 135px;
}

.CashboxTableItem:first-child {
  max-width: 224px;

}

.CashboxTableItem:first-child {
  width: calc(18% - 10px);
}

.CashboxTableItem:nth-child(2) {
  width: calc(27% + 30px);
}

.CashboxTableItem:nth-child(3) {
  width: calc(27% + 25px);
}

@media screen and (min-width: 1680px) {
  .CashboxTableItem:first-child {
    width: calc(18% - 5px);
  }
}

