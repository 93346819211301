@green-900: #2B8A3E;
@green-800: #2F9E44;
@green-700: #37B24D;
@green-600: #40C057;
@green-500: #51CF66;
@green-400: #69DB7C;
@green-300: #8CE99A;
@green-200: #B2F2BB;
@green-100: #D3F9D8;
@green-10: #EBFBEE;

// Color
.Green900Color {
  color: @green-900 !important;
}

.Green800Color {
  color: @green-800 !important;
}

.Green700Color {
  color: @green-700 !important;
}

.Green600Color {
  color: @green-600 !important;
}

.Green500Color {
  color: @green-500 !important;
}

.Green400Color {
  color: @green-400 !important;
}

.Green300Color {
  color: @green-300 !important;
}

.Green200Color {
  color: @green-200 !important;
}

.Green100Color {
  color: @green-100 !important;
}

.Green10Color {
  color: @green-10 !important;
}

// BackgroundColor
.Green900Background {
  background-color: @green-900 !important;
}

.Green800Background {
  background-color: @green-800 !important;
}

.Green700Background {
  background-color: @green-700 !important;
}

.Green600Background {
  background-color: @green-600 !important;
}

.Green500Background {
  background-color: @green-500 !important;
}

.Green400Background {
  background-color: @green-400 !important;
}

.Green300Background {
  background-color: @green-300 !important;
}

.Green200Background {
  background-color: @green-200 !important;
}

.Green100Background {
  background-color: @green-100 !important;
}

.Green10Background {
  background-color: @green-10 !important;
}

// BorderColor
.Green900Border{
  border-color: @green-900 !important;
}

.Green800Border{
  border-color: @green-800 !important;
}

.Green700Border{
  border-color: @green-700 !important;
}

.Green600Border{
  border-color: @green-600 !important;
}

.Green500Border{
  border-color: @green-500 !important;
}

.Green400Border{
  border-color: @green-400 !important;
}

.Green300Border{
  border-color: @green-300 !important;
}

.Green200Border{
  border-color: @green-200 !important;
}

.Green100Border{
  border-color: @green-100 !important;
}

.Green10Border{
  border-color: @green-10 !important;
}
