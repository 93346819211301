.GuestMetricTableTitle {
  margin-top: 32px;
  margin-bottom: 16px;

  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: #212529;

}

.GuestMetricTableWrapper {
  width: 100%;
  border: 1px solid #CED4DA;
  border-radius: 8px;
}

.GuestMetricTableRow {
  display: flex;
  flex-direction: row;

  width: 100%;
}

.GuestMetricTableItem {
  display: flex;
  flex-direction: column;

  width: 25%;
  padding: 22px 16px;

  border-right: 1px solid #CED4DA;
}

.GuestMetricTableRowFirst > .GuestMetricTableItem {
  border-bottom: 1px solid #CED4DA;
}

.GuestMetricTableItemValue {
  color: #212529;
  .TooltipComponentTargetWrapper {
    padding-left: 8px;
  }
}

.GuestMetricTableItemDescription {
  color: @gray-600;

  padding-top: 2px;
}

.GuestMetricTableLast {
  border-right: none !important;
}

.GuestMetricTableItemLast {
  border-right: none !important;
}

@media screen and (max-width: 920px) {
  .GuestMetricTableItem {
    width: 100%;

    border-right: none !important;
  }

  .GuestMetricTableRow > .GuestMetricTableItem {
    border-bottom: 1px solid #CED4DA;
  }

  .GuestMetricTableRow {
    flex-direction: column;
  }

  .GuestMetricTableItemLastMobile {
    border-bottom: none !important;
  }
}

.GuestMetricTableItemMaxCashback {
  padding-right: 4px;
}