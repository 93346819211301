.ButtonMiniExcel {
  align-items: center;
  padding: 10px 16px 10px 12px;
  gap: 8px;
  background: #E9FAC8;
  border-radius: 8px;
  width: fit-content;
}

.ButtonMiniExcelOnlyIcon {
  background: none !important;
}

.ButtonMiniExcel:hover {
  cursor: pointer;
  opacity: 0.8;
}

.ButtonMiniExcelDisabled:hover {
  cursor: not-allowed;
}