@lemon-900: #8A7500;
@lemon-800: #AA9200;
@lemon-700: #C9AE00;
@lemon-600: #E4C800;
@lemon-500: #FCE000;
@lemon-400: #FFED37;
@lemon-300: #FFF450;
@lemon-200: #FFF450;
@lemon-100: #FFFB9F;
@lemon-10: #FFFFBB;

// Color
.Lemon900Color {
  color: @lemon-900 !important;
}

.Lemon800Color {
  color: @lemon-800 !important;
}

.Lemon700Color {
  color: @lemon-700 !important;
}

.Lemon600Color {
  color: @lemon-600 !important;
}

.Lemon500Color {
  color: @lemon-500 !important;
}

.Lemon400Color {
  color: @lemon-400 !important;
}

.Lemon300Color {
  color: @lemon-300 !important;
}

.Lemon200Color {
  color: @lemon-200 !important;
}

.Lemon100Color {
  color: @lemon-100 !important;
}

.Lemon10Color {
  color: @lemon-10 !important;
}

// BackgroundColor
.Lemon900Background {
  background-color: @lemon-900 !important;
}

.Lemon800Background {
  background-color: @lemon-800 !important;
}

.Lemon700Background {
  background-color: @lemon-700 !important;
}

.Lemon600Background {
  background-color: @lemon-600 !important;
}

.Lemon500Background {
  background-color: @lemon-500 !important;
}

.Lemon400Background {
  background-color: @lemon-400 !important;
}

.Lemon300Background {
  background-color: @lemon-300 !important;
}

.Lemon200Background {
  background-color: @lemon-200 !important;
}

.Lemon100Background {
  background-color: @lemon-100 !important;
}

.Lemon10Background {
  background-color: @lemon-10 !important;
}

// BorderColor
.Lemon900Border{
  border-color: @lemon-900 !important;
}

.Lemon800Border{
  border-color: @lemon-800 !important;
}

.Lemon700Border{
  border-color: @lemon-700 !important;
}

.Lemon600Border{
  border-color: @lemon-600 !important;
}

.Lemon500Border{
  border-color: @lemon-500 !important;
}

.Lemon400Border{
  border-color: @lemon-400 !important;
}

.Lemon300Border{
  border-color: @lemon-300 !important;
}

.Lemon200Border{
  border-color: @lemon-200 !important;
}

.Lemon100Border{
  border-color: @lemon-100 !important;
}

.Lemon10Border{
  border-color: @lemon-10 !important;
}
