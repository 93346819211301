.GuestsHistoryActiveDayLabel {
  position: relative;
}

.GuestsHistoryActiveDayLabel {
  position: absolute;
  bottom: -2px;
  left: calc(50% - 2px);
  z-index: 2;

  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: @green-600;
}