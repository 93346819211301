@import "./Components/styles";

.PaymentTableFiles:hover {
  span {
    color: @blue-700 !important;
  }

  svg path {
    fill: @blue-700 !important;
  }
  cursor: pointer;
}

.PaymentStatusTableColumn {
  width: 120px;
  border-radius: @pd-4;

  span {
    &:extend(.PaddingLeft12);
    &:extend(.PaddingTop6);
  }
}

.PaymentStatusTableComment {
  &:extend(.PaddingLeft6);
  &:extend(.PaddingTop6);
  &:extend(.PaddingRight6);

  border-top-right-radius: @pd-4;
  border-bottom-right-radius: @pd-4;
  cursor: pointer;
}

.PaymentsStatus {
  &-paid {
    background-color: @green-10;

    span {
      color: @green-600 !important
    }

    .PaymentStatusTableComment {
      background-color: @green-100;

      svg {
        path {
          fill: @green-500 !important
        }
        circle {
          stroke: @green-500 !important
        }
      }
    }
  }

  &-canceled {
    background-color: @red-10;

    span {
      color: @red-600 !important
    }

    .PaymentStatusTableComment {
      background-color: @red-100;

      svg {
        path {
          fill: @red-500 !important
        }
        circle {
          stroke: @red-500 !important
        }
      }
    }

  }

  &-failed {
    background-color: @pink-10;

    span {
      color: @pink-600 !important
    }

    .PaymentStatusTableComment {
      background-color: @pink-100;

      svg {
        path {
          fill: @pink-500 !important
        }
        circle {
          stroke: @pink-500 !important
        }
      }
    }
  }

  &-accepted {
    background-color: @orange-10;

    span {
      color: @orange-600 !important
    }

    .PaymentStatusTableComment {
      background-color: @orange-100;

      svg {
        path {
          fill: @orange-500 !important
        }
        circle {
          stroke: @orange-500 !important
        }
      }
    }
  }

  &-new {
    background-color: @gray-100;

    span {
      color: @gray-700 !important
    }

    .PaymentStatusTableComment {
      background-color: @gray-200;

      svg {
        path {
          fill: @gray-600 !important
        }
        circle {
          stroke: @gray-600 !important
        }
      }
    }
  }
}

.PaymentTableEditItem {
  svg path {
    fill: @gray-600 !important;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
}

.PaymentsTableCommentWrapper {
  max-width: 100px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.PaymentsTableCommentWrapper_overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

.PaymentsTableCommentContainer {
  display: flex;
  flex-direction: row;
}

.PaymentsTableCommentWrapperMore {
  text-transform: lowercase;
  color: @blue-700;
  align-self: end;
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
}

.PaymentsPopover_hidden {
  display: none;
}