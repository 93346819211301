@import "styles/colors/cyan";

.HallCard {
  display: flex;
  flex-direction: column;
  max-width: 684px;
  min-width: 552px;
  width: 100%;
  border-radius: 8px;
  min-height: 256px;
  height: 100%;
  background-color: #FFFFFF;

  &__Header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: @cyan-10;
    height: 56px;
    display: flex;
    gap: 7px;
    align-items: center;
    padding: 0 24px 0 24px;
    min-height: 56px;
  }

  &__Content {
    padding: 16px 16px 24px 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

@media screen and (min-width: 921px) and (max-width: 1440px) {
  .HallCard {
    min-width: 500px;
    max-width: 540px;
  }
}

@media screen and (max-width: 920px) {
  .HallCard {
    min-width: 343px;
    max-width: 540px;
  }
}