.ChartsWrapper {
  background-color: #fff;
  padding-left: 16px;
}

@media screen and (max-width: 920px) {
  .highcharts-scrolling::-webkit-scrollbar {
    display: none !important;
  }
}

.highcharts-credits {
  display: none !important;
}
