.PayrollModalWrapper {
  padding: @pd-16 @pd-16 @pd-10 @pd-16 ;
}

.PayrollModalBottom {
  border-top: 1px solid @gray-300;
  margin-bottom: -24px;
}

.UploadComponentFormError, .UploadComponentFormWrapper {
  width: 58px;
}

.UploadComponentFormError > .ant-form-item-has-error > .ant-form-item-row > .ant-form-item-control > div > .ant-form-item-explain >.ant-form-item-explain-error {
  position: absolute;
  width: 360px;
}

.UploadComponentFormWrapper > .ant-form-item-has-error > .ant-form-item-row > .ant-form-item-control > div > .ant-form-item-explain >.ant-form-item-explain-error {
  position: absolute;
  width: 360px;
  display: none !important;
}

.UploadComponentFormError > .ant-form-item-has-error > .ant-form-item-row > .ant-form-item-control >
.ant-form-item-control-input > .ant-form-item-control-input-content > span >
.ant-upload > span > .UploadAttachmentsButton > button {
  border-color: @red-600 !important;
}

.PayrollModalFormFirstRow > .ant-form-item {
  width: 100%;
}

.PayrollModalFormSecondRow > .ant-form-item {
  width: 100%;
}

@media screen and (max-width: 920px){
  .PayrollModalWrapper {
    padding: 0;
    margin-top: -16px;
  }

  .PayrollModalFormFirstRow > .ant-form-item {
    width: 100%;
  }

  .PayrollModalFormSecondRow > .ant-form-item {
    width: 100%;
  }

  .PayrollModalContentWrapper {
    max-height: calc(100vh - 200px);
    overflow-y: auto;

    margin-right: -16px;
    padding-right: 10px;
  }

  .PayrollModalBottom > .ButtonPrimaryWrapper > button {
    width: 100%;
  }
}

.PayrollModalCloseApprove {
  padding-top: 13px !important;
}

.PayrollModalClose {
  position: absolute;
  right: 24px;
  top: 24px;
}