@black-0: rgb(0,0,0);
@shark500: #212529;
@black-1: rgb(29,31,33);
@black-2: rgb(82,84,97);
@black-3: #757681;
@white: #fff;
@grey-bg: #F5F6F8;
@grey-2: #DEDFE1;
@grey-3: #EEEEEF;
@grey-4: #868E96;
@grey-5: #808185;
@action-info: #2D8AF1;
@action-danger: #FF7454;
@red: #FA5252;
@action-warning: #FFA132;
@light-primary: rgb(222,245,235);
@light-info: #E0EDFD;
@light-warning: #FFF1E0;
@light-danger: #FFEAE5;
@main: #FCE000;
@main-dark: #F5D400;
@main-light: #B9E9D5;
@main-lighter: @light-primary;

@primary: @main;
@secondary: @grey-3;
@border-radius: 2px;
@height-base: 56px;
@height-lg: 64px;
@height-sm: 48px;

// import color palette
@import "styles/colors/styles";

// import paddings
@import "styles/padding/styles";

// import size classes
@import "styles/size/styles";

@font-face {
  font-family: 'Gilroy';
  font-weight: 500;
  src: local('Gilroy'), url(./fonts/Gilroy/Gilroy-Medium.otf) format('truetype');
}

@font-face {
  font-family: 'Gilroy';
  font-weight: 600;
  src: local('Gilroy'), url(./fonts/Gilroy/Gilroy-Semibold.otf) format('truetype');
}

@font-face {
  font-family: 'Gilroy';
  font-weight: 700;
  src: local('Gilroy'), url(./fonts/Gilroy/Gilroy-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'Gilroy';
  font-weight: 900;
  src: local('Gilroy'), url(./fonts/Gilroy/Gilroy-Extrabold.otf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 400;
  src: local('Lato'), url(./fonts/Lato/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 500;
  src: local('Lato'), url(./fonts/Lato/Lato-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 600;
  src: local('Lato'), url(./fonts/Lato/Lato-Semibold.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 700;
  src: local('Lato'), url(./fonts/Lato/Lato-Bold.ttf) format('truetype');
}

body {
  margin: 0 !important;
  font-family: Lato, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  min-width: 375px !important;
  color: @black-1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  h1, h2, h3, h4, h5, h6 {
    font-family: Gilroy, sans-serif;
  }

  h1,
  .Title-h1 {

    font-size: 30px;
    line-height: 1.25;
    font-weight: 700;
    margin-bottom: 0;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 16px;
  }

  img {
    display: block;
    max-width: 100%;
  }
  span {
    font-family: Lato, sans-serif;
  }

  input {
    font-family: Lato, sans-serif !important;
    font-weight: 500;

    &::placeholder {
     color: #ADB5BD !important;
    }
  }
}

::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(@white);
  border-radius: 20px;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
  background-color: rgba(@white);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #D1D1D6;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background-color: rgba(@white);
}

iframe {
  display: none !important;
}

.HeadlineFirstText {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: @shark500;
}

.HeadlineSecondText {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: @shark500;
}

.HeadlineThirdText {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: @shark500;
}

.TextOne {
  font-family: 'Lato' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.TextOneBold {
  &:extend(.TextOne);

  font-weight: 700 !important;
}

.TextTwo {
  font-family: Lato !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}

.TextTwoBold {
  &:extend(.TextTwo);

  font-weight: 700 !important;
}

.SubtitleFirstText {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: @shark500;
}

.SubtitleSecondText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: @shark500;
}

.BodyFirstText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: @shark500;
}

.BodySecondText {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: @shark500;
}

.ChartDecodingText {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.154px;
  color: #212529;
}

.TextDefault {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: @shark500;
}

.TextDefaultBold {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.3px;
  color: @shark500;
}

.Text1 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: @shark500;
}

.TextDefaultBold {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: @shark500;
}

.TableHeaderText {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: @shark500;
}

.StatusText {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.TableHeader, .TableHeader > span {
  font-family: 'Gilroy' !important;
  font-style: normal !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  letter-spacing: 0 !important;
  word-spacing: normal !important;
  white-space: pre-line !important;
}

.CaptionText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: @shark500;
}

.CaptionTextBold {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: @shark500;
}

.OverlineText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: @shark500;
}

.Overline {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: @shark500;
}

.ButtonTextFirst {
  font-family: 'Gilroy', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

.ButtonTextSecond {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: @shark500;
}

.TextLabelText {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #ADB5BD;
}


.Rectangle {
  height: 1px;
  background: #E5E5EA;
  width: 100%;
}

.Uppercase {
  text-transform: uppercase;
}

.HideScroll::-webkit-scrollbar {
  display: none !important;
}

.CapitalizeText {
  text-transform: capitalize;
}

.LinkText {
  &:extend(.Blue600Color);
}

.HoverItem:hover {
  cursor: pointer;
  opacity: 0.8;
}

.TextAlignCenter {
  text-align: center;
}

.TextUnderline {
  text-decoration: underline;
}

.RelativePosition {
  position: relative;
}

.HideBlock {
  display: none !important;
}

.ButtonSecondary {
  background-color: @gray-100 !important;
  border: transparent !important;
}

.DisplayNoneElement {
  display: none !important;
}