@import "./ManageContent/styles";

.SuperwinHistoryContentEmpty {
  padding-top: 10%;

  .EmptyTableLabel {
    width: 400px;
  }
}

@media screen and (max-width: 920px) {
  .SuperwinTitleExcelButton {
    position: absolute;
    right: 0;
    top: 52px;
  }
}