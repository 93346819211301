.CounterComponentWrapper {
  margin-top: 10px;
  padding: 4px;
  border-radius: 18px;
  background-color: #FF2D55;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
  height: 22px;
}
