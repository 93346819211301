.ComponentDashboardItemSkeleton {
  background-color: #F4F4F4;
  .ant-skeleton-content {
    width: 224px !important;
    height: 84px !important;
  }
}

.DashboardItemTitleContainer {
  display: flex;
  flex-direction: column;
}

.DashboardItemDetailsButton {
  margin-top: 20px;
  display: flex;
  padding: 3px 12px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid @gray-400;
  width: 89px;
  height: 24px;
  &:extend(.StatusText);
}