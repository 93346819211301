.DatePickerWrapper,
.RangePickerWrapper {
  .ant-picker {
    border-radius: 8px;
    height: 44px;
    width: 100%;
    padding: 10px 16px !important;

    &:hover {
      border: 1px solid #212529 !important;
    }

    &-suffix {
      margin-left: auto;
      color: #808889;
    }
  }
}

.DatePickerWrapper .ant-picker-focused,
.DatePickerWrapper .ant-picker-focused.ant-picker:hover,
.RangePickerWrapper .ant-picker-focused.ant-picker:hover,
.RangePickerWrapper .ant-picker-focused {
  border: 1px solid #212529 !important;
  box-shadow: none !important;
}

.RangePickerWrapper {
  .ant-picker .ant-picker-disabled:hover {
    border-color: #e6e6e6 !important;
  }

  .ant-picker-range .ant-picker-active-bar {
    background: #212529;
  }
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #0d0d0d !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #F8E800;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  border-color: @main !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #F8E800 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #F8E800 !important;
}

.ant-picker-header button {
  font-weight: bold !important;
}

.ant-picker-panel-container {
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.05) !important;
}

.ant-picker-today-btn {
  color: #0d0d0d !important;
}

.ant-picker-header-view button:hover,
.ant-picker-today-btn:active,
.ant-picker-today-btn:hover {
  color: #1066f2 !important;
}

.RangePickerWrapper .ant-picker-disabled {
  opacity: 0.5;
}

.ant-picker-dropdown {
  z-index: 9999 !important;
}

.ant-picker-dropdown {
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: rgba(248, 232, 0, 0.35) !important;
  }
}

.ErrorPickerWrapper .ant-picker,
.ErrorRangePickerWrapper .ant-picker-range {
  border-color: #e80012 !important;
}

.ErrorText {
  font-size: 10px;
  color: #e80012;
  margin-top: 2px;
  margin-bottom: -16px;
}

.ant-picker-input > input {
  color: #1d1f21;
}

.ant-picker-range {
  display: flex !important;
  flex-direction: row !important;
}

.ant-picker-range > .ant-picker-input {
  display: inline-block !important;
  max-width: 80px;
}

.ant-picker-range > .ant-picker-input input {
  text-align: center !important;
}

// Time picker component

.TimePickerComponentWrapper .ant-picker {
  width: 100%;
  border: 1px solid #D1D1D6;
  box-sizing: border-box;
  border-radius: 8px;
  height: 44px;
}

.TimePickerComponentWrapper .ant-picker-focused,
.TimePickerComponentWrapper .ant-picker-focused .ant-picker:hover,
.TimePickerComponentWrapper .ant-picker-focused .ant-picker:hover,
.TimePickerComponentWrapper .ant-picker-focused,
.TimePickerComponentWrapper .ant-picker:hover{
  border-color: #212529 !important;
  box-shadow: none !important;
}

.TimePickerComponentPopupWrapper .ant-btn-primary {
  background-color: @main !important;
  color: #0d0d0d !important;
}

.DatePickerSeparatorWrapper {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

@media screen and (max-width: 920px){
  .ant-picker-panels {
    flex-direction: column !important;
  }
}

.RangePickerMiddleWrapper > .ant-picker {
  height: 40px !important;
}

.DatePickerWrapperGreyBackground {
  .ant-picker {
    background-color: #f8f9fa !important;
  }
}