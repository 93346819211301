.DateTimeDate {
  padding-left: 0;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
}

.DateTimeTime {
  opacity: 0.5;
  padding-left: 8px;
}