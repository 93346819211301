.QiwiModalWrapper {
  max-height: 648px;
  overflow-y: auto;

  padding: 16px 32px 16px 16px;
  margin-right: -18px;
}

@media screen and (max-width: 920px) {
  .QiwiModalWrapper {
    max-height: calc(100vh - 120px);
    padding: 0 8px 0 0 !important;
  }

  .QiwiModalHallsItemRowSelect {
    width: 100%;
  }
}

.QiwiModalFieldsRow > div,
.QiwiModalHallsItemRow,
.QiwiModalHallsItemRow > div,
.QiwiModalHallsItemRow > div > .ant-form-item {
  width: 100%
}

.QiwiModalHallsItemWrapper {
  padding: 16px 24px 0 24px;
  border-radius: 8px;
  background: @white-800;
}

.ant-form-item-has-error .ant-row .ant-col .ant-form-item-control-input .ant-form-item-control-input-content > .QiwiModalHallsItemSumCommission {
  display: none !important;
  background-color: #2f54eb !important;
}

.ant-form-item-has-error .ant-row .ant-col .ant-form-item-control-input .ant-form-item-control-input-content .InputMaskWrapper > .ant-input-affix-wrapper {
  border-color: #ff4d4f;
}