.PaymentModalWrapper {
    padding: 0 @pd-16 @pd-16 @pd-16;
}

.PaymentModalBottom {
    border-top: 1px solid @gray-300;
    margin-bottom: -24px;
}

.UploadComponentFormError, .UploadComponentFormWrapper {
    width: 58px;
}

.UploadComponentFormError > .ant-form-item-has-error > .ant-form-item-row > .ant-form-item-control > div > .ant-form-item-explain > .ant-form-item-explain-error {
    position: absolute;
    width: 360px;
}

.UploadComponentFormWrapper > .ant-form-item-has-error > .ant-form-item-row > .ant-form-item-control > div > .ant-form-item-explain > .ant-form-item-explain-error {
    position: absolute;
    width: 360px;
    display: none !important;
}

.UploadComponentFormError > .ant-form-item-has-error > .ant-form-item-row > .ant-form-item-control >
.ant-form-item-control-input > .ant-form-item-control-input-content > span >
.ant-upload > span > .UploadAttachmentsButton > button {
    border-color: @red-600 !important;
}

.PaymentModalFormFirstRow > .ant-form-item {
    width: 50%;
}

.PaymentModalFormSecondRow > .ant-form-item {
    width: 100%;
}

@media screen and (max-height: 799px), screen and (max-width: 920px) {
    .PaymentModalWrapper {
        padding: 0;
        margin-top: -16px;
    }

    .PaymentModalFormFirstRow > .ant-form-item {
        width: 100%;
    }

    .PaymentModalFormSecondRow > .ant-form-item {
        width: 100%;
    }

    .PaymentModalContentWrapper {
        max-height: calc(100vh - 200px);
        overflow-y: auto;

        margin-right: -16px;
        padding-right: 10px;
    }

    .PaymentModalBottom > .ButtonPrimaryWrapper > button {
        width: 100%;
    }
}

.PaymentModalRangePicker .RangePickerWrapper .ant-picker {
    min-height: 48px;
    max-height: 48px;
}

.PaymentDivider {
    margin: 8px 0
}

.PaymentSpace {
    padding: 0 8px 4px;
}

