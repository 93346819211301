@import "src/_main";
.TableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table {
  border-radius: 8px !important;
}

.ant-table-wrapper {
  overflow-x: auto;
}

.ant-table-column-has-sorters .ant-table-column-sorters,
.ant-table-thead > tr > th {
  background-color: #E6F6F8 !important;
  padding: 12px !important;
}

.ant-table-column-has-sorters .ant-table-column-sorters {
  padding: 0 !important;
}

thead tr .ActiveSortTableColumn .ant-table-column-sorters > span:first-child {
  color: #15AABF !important;
}

.TableColumn {
  padding: 12px !important;
  word-spacing: normal !important
}

.ant-table-column-sorter-inner .active svg {
  path {
    fill: #15AABF !important;
  }
}

.ant-table-column-sorter-inner svg:not(.active) svg {
  path {
    fill: #B4CDCF !important;
  }
}

.ant-table-column-has-sorters .ant-table-column-sorters > span:first-child,
.ant-table-thead > tr > th {
  &:extend(.TableHeader);
}

.ant-table-thead > tr > th {
  word-spacing: 999px;
  height: 64px;
}

.TableHeaderWithoutSpacing {
  word-spacing: 0 !important;
  white-space: nowrap;
}

.ant-table-content {
  border-top: 1px solid #E9EAEA !important;
  border-right: 1px solid #E9EAEA !important;
  border-left: 1px solid #E9EAEA !important;
  border-radius: 8px;
}

.ant-table-thead > tr > th:first-child {
  padding-left: 44px !important;
}

.ant-table-cell::before {
  display: none !important;
}

.ant-table-tbody .ant-table-row > td {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #2B2D33;
  padding: 12px;
}

.ant-table-tbody .ant-table-row:hover {
  cursor: pointer;
}

.TableComponentTotalRow > td {
  font-weight: bold !important;
}

.ant-table-expanded-row, .ant-table-row-selected,
.ant-table-expanded-row td, .ant-table-row-selected td {
  background-color: #fff !important;
}

.ant-table-expanded-row > td {
  padding: 0 !important;
  overflow-x: auto;
}

.ant-table-expanded-row-fixed {
  margin: 0 !important;
  width: 100% !important;
}

.ant-table-expanded-row > td .ant-table-expanded-row-fixed {
  padding: 0 !important;
  min-width: 1394px;
}
.TableItemNegativeValue {
  color: #FF3B30 !important;
}

.ant-table-body::-webkit-scrollbar {
  display: none !important;
}

@media screen and (max-width: 920px) {
  .TableColumnWrapper, .ReportTableContentWrapper {
    width: calc(100vw - 10px);
  }
  .ant-table-row-expand-icon-cell, .ant-table-cell-fix-left {
    width: 20px !important;
    padding: 0 !important;
  }
  .ant-table-content::-webkit-scrollbar {
    display: none !important;
  }
}

.OrangeHeaderTable .TableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container
.ant-table-header table thead tr > th {
  background-color: #FFF4E6 !important;
}

.OrangeHeaderTable .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container
.ant-table-header table thead tr > th {
  background-color: #FFF4E6 !important;
}

.TableComponentTopScrollWrapper {
  overflow-x: scroll;
  height: 10px;
}

// Search in header

.TableSearchInput {
  width: 205px;
}

.TableSearchSelect {
  width: 205px;
}

.TableSearchClearIcon {
  margin-left: 2px;
}

.TableSearchClearIcon:hover, .TableSearchTitle:hover {
  cursor: pointer;
}

.TableSearchClearIcon {
  margin-left: 6px;
}

.TableSearchTitleActive {
  color: #15AABF;
}

.TableBoldValue {
  font-weight: 700 !important;
}

.TableColumnNormalSpacing {
  word-spacing: normal !important;
}

.TableHeaderHelpWrapper {
  padding-left: 4px;
}

.TableSearchSelect {
  .ant-select-clear {
    right: 35px;
  }
}

.TableItemMaxWidth {
  width: max-content !important;
  padding-right: 8px;
}