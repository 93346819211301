@import "./HallPickerModal/styles";

@media screen and (min-width: 921px) and (max-width: 1440px) {
  .HallPickerWrapper {
    margin: 32px 0 !important;
  }
}

.HallPickerWrapper {
  background: #3A3A3C;
  border-radius: 44px;
  padding: 12px 16px 10px 16px;
  margin: 32px;
  display: inline-flex !important;

  span {
    font-family: Gilroy, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    padding: 0 8px;
  }
}

.HallPickerWrapper:hover {
  cursor: pointer;
  opacity: 0.9;
}

.HallPickerMoreWrapper {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 14px !important;
  text-align: right;
  color: #FFFFFF;
  opacity: 0.75;
  padding-top: 4px;
}

.HallPickerPoint {
  background: #FEE600;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-top: 5px;
}

.HallPickerArrowWrapper {
  margin-left: 40px;
}
