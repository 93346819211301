.ModalWrapper, .ModalFormWrapper {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
}

.ModalWrapper {
  max-height: 600px;
}

.ModalFormTitle {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #212529;
}

.ModalFormWrapper {
  max-height: calc(100vh - 64px);
}

@media screen and (max-width: 920px){
  .ModalWrapper, .ModalFormWrapper {
    left: 8px !important;
    width: calc(100vw - 16px) !important;
  }
  .ModalContent, .ModalFormContent {
    width: calc(100vw - 16px) !important;
  }
  .ModalMarginWrapper {
    width: calc(100vw - 32px) !important;
    left: 16px !important;
  }

  .ModalMarginWrapper .ModalContent {
    width: calc(100vw - 32px) !important;
  }
}

.ModalContent, .ModalFormContent {
  background: #fff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 96px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
}

.ModalCloseWrapper {
  margin: 14px;
}

.ModalCloseWrapper:hover {
  cursor: pointer;
}

.Overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 8;
  top: 0;
  left: 0;
}

.OverlayFilterTable {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0);
  z-index: 8;
  top: 0;
  left: 0;
}

.ModalFormContent {
  padding: 24px;
  width: 100%;
}

.ModalTitleWrapper {
  justify-content: space-between;

  .ModalFormClose:hover {
    cursor: pointer;
  }
}

.ModalRightDetail {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9;

  max-width: 100vw;
  height: 100vh;

  transition: width 500ms ease;

  background: #FFFFFF;
  border-radius: 16px 0px 0px 16px;

  .ModalRightDetailClose {
    position: relative;

    svg {
      position: absolute;

      right: 24px;
      top: 24px;

      width: 24px;
      height: 24px;

      path {
        fill: @shark500;
      }
    }

    svg:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}

.ModalFormBigClose {
  position: absolute;
  right: 24px;
  top: 24px;
}

@media screen and (max-width: 920px){
  .ModalRightDetail{
    width: 100% !important;
  }
  .ModalRightDetailContent{
    overflow-x: auto;

    table{
      min-width: 840px;
    }
  }
}