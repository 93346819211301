
.MailingContentTitleRight {
  margin-left: auto;
}

.MailingContentTitleWrapper {
  padding-bottom: 12px !important;
}

.MailingContentTabs {
  width: 100%;
}

.MailingContentTabsItem {
  margin-right: 24px;
  margin-bottom: 11px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #868E96;
}

.MailingContentTabsInactiveItem:hover {
  cursor: pointer;
  color: #212529;
}

.MailingContentTabsItemActive {
  color: #212529;
}

.MailingContentTabsActiveRestriction {
  margin-right: 24px;
  background: #FCE000;
  height: 3px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.MailingContentTabsRestriction {
  width: 100%;
  height: 1px;
  background: #DEE2E6;
}