.SportboomTableMobileWrapper {
  &:extend(.MarginLeft8);
  &:extend(.MarginRight8);
}

.SportboomTableMobileItem {
  &:extend(.White100Background);

  border: 1px solid @gray-400;
  border-radius: 8px;
}

.SportboomTableMobileItemPending {
  .SportboomTableMobileItem {
    &:extend(.Gray100Background);
  }
}

.SportboomTableMobileListPagination {
  &:extend(.White100Background);

  margin: 8px -8px -32px -8px;
  padding-bottom: 112px;
}

.SportboomTableMobileItemBetNumber {
  &:extend(.BodySecondText);
  &:extend(.LinkText);
  &:extend(.HoverItem);
  &:extend(.PaddingTop4);
}

.SportboomTableMobileItemBetStart {
  &:extend(.CaptionText);
  &:extend(.Gray600Color);
  &:extend(.PaddingTop6);

  margin-left: auto;
}

.SportboomTableMobileItemArrow {
  border-radius: 8px;
  padding: 0 9px;
  height: 32px;

  margin-left: auto;

  svg {
    margin-top: 12px;

    path {
      fill: @gray-700;
    }
  }
  transition: transform 500ms ease;
}

.SportboomTableMobileItemArrowClose {
  &:extend(.Gray100Background);

  transform: rotate(0deg);
}

.SportboomTableMobileItemArrowOpen {
  transform: rotate(180deg);

  &:extend(.Gray400Background);
}

.SportboomTableMobileItemDetailStatus {
  width: 8px;
  height: 8px;
  border-radius: 50%;

  &:extend(.FlexColumn);
  &:extend(.Gap16);
  &:extend(.MarginTop6);
}

.SportboomTableMobileItemMain {
  padding: 14px 14px 12px 14px;
  border-top-left-radius: 4px;

  margin-left: 4px;
}

.SportboomTableMobileItemMainActiveDetailClose {
  border-bottom-left-radius: 4px;
}

.SportboomTableMobileItemDetail {
  &:extend(.Gray200Background);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  padding: 12px 14px 14px 18px;
}

.SportboomTableMobileItemMainActive {
  border-left: 4px solid @lemon-500;

  margin-left: 0 !important;
}

.SportboomTableMobileItemDetailActive {
  border-left: 4px solid @gray-400;

  padding-left: 14px !important;
}