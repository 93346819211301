.UploadWrapper {

  .ant-upload-list {
    display: flex;
    flex-wrap: wrap;
    //gap: 12px;

    .ant-upload {
      margin-bottom: 0 !important;
    }

    .ant-upload-select-picture-card {
      width: 156px !important;
      height: 100px !important;
      background: none !important;
      border: none !important;
      border-radius: 8px;
    }

    .ant-upload-list-picture-card-container {
        width: 156px;
        height: 100px;
        margin: 8px !important;
    }
  }
}

.UploadsNewHideWrapper {
  .ant-upload-list {
    .ant-upload-select-picture-card {
      display: none !important;
    }
  }
}

.UploadViewLargeMode .ant-upload-list .ant-upload-select-picture-card {
  width: 100% !important;
}

.UploadViewMode {
  .ant-upload-list .ant-upload-select-picture-card {
    display: none;
  }
}

.ant-upload-select-picture-card {
  margin: 8px !important;
}

.UploadsNew {
  height: 100px;
  width: 156px;
  background: #F1F3F5;
  border: 1px dashed #868E96;
  border-radius: 8px;

  display: grid;
  place-items: center;

  padding: 24px 0;
}

.UploadsNewHide {
  display: none !important;
}

.UploadsNew:hover {
  cursor: pointer;
}

.UploadsNewText {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  align-items: center;
  text-align: center;

  color: #212529;

  padding-top: 6px;
}

.UploadsNewSmall {
  width: 156px;
}

.UploadsNewLarge {
  width: 100%;
}


.UploadAttachmentsImage {
  position: relative;

  .ant-image, img {
    border-radius: 8px;
    width: 156px;
    height: 100px;
    object-fit: none;
  }
}

.UploadAttachmentsImage:hover {
  cursor: pointer;
}

.UploadLoadingProgress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  .ant-progress-inner {
    width: 32px !important;
    height: 32px !important;

    .ant-progress-circle {
      background: rgba(33, 37, 41, 0.6);
    }
  }
}

.UploadLoadingOverlayWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(33, 37, 41, 0.4);
  border-radius: 8px;
}

.UploadImageBadge {
  position: absolute;
}

.UploadImageBadge:hover {
  cursor: pointer;
  opacity: 0.8;
}

.UploadImageBadgeDelete {
  top: 4px;
  left: 4px;
}

.UploadImageBadgeChange {
  top: 35px;
  left: 4px;
}


.UploaderVideo {
  width: 156px;
  height: 100px;
  border-radius: 8px;
  background-color: #000;
}

.UploadAttachChanged {
  font-family: Gilroy;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: left;
  position: absolute;
  background: #FCE000;
  border-radius: 8px 0px;
  padding: 2px 8px;
  top: 0;
}