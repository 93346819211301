.SportboomTableDetailWrapper {
  margin: 56px 24px;

  max-height: calc(100vh - 80px);
  overflow-y: auto;
}

.SportboomTableDetail {
  gap: 16px;

  div {
    margin-top: 8px;
  }
}

.SportboomTableDetailInfo {
  display: flex;
  flex-direction: row;

  &:extend(.Gap16);
}

@media screen and (max-width: 920px) {

  .SportboomTableDetailInfo {
    flex-direction: column;
  }

  .SportboomCompareWrapper {
    bottom: 48px;
    left: calc(50vw - 160px) !important;
  }

  .SportboomTableDetailMobileTabs {
    margin-bottom: @pd-8;

    .TabsComponentWrapper {
      margin-left: 0 !important;

      .TabsComponentItemsWrapper > div .TabsComponentItem {
        padding-bottom: 8px !important;
      }
    }
  }

  .SportboomTableDetailMobileDelete {
    button {
      width: 100%;
    }
  }

}
