.AccessCardsItem {
  border-radius: 8px;
  border: 1px solid @gray-200;
  background: @white-100;

  width: 289px;
  padding: 16px;
}

.AccessCardsItemFullName {
  text-overflow: ellipsis;
  max-height: 40px;
  overflow: hidden;
  display: flex;
  column-gap: 8px;
  line-height: normal;
}

.AccessCardsItemLogin {
  word-break: break-word;
}

.AccessCardsItemHalls {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.AccessCardsItemStatus {
  height: 28px;
  border-radius: 4px;
  padding: 6px 12px;
  margin-top: 2px;

  &_active {
    background: @green-10;
    color: @green-700;
  }
  &_paused {
    background: @red-10;
    color: @red-700;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &_resuming {
    background: @orange-10;
    color: @orange-700;
  }
}

.AccessCardsItemStatusTooltip {
  height: 28px;
  background: @red-100;

  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.AccessCardsItemButton {
  padding: 8px;
  background: @gray-100;
  border-radius: 4px;

  height: 36px;
  width: 36px;
}

.AccessCardsItemButtonDelete {
  svg path {
    fill: @shark-800;
  }
}

.AccessCardsItemButton:hover {
  opacity: 0.8;
  cursor: pointer;
}

@media screen and (max-width: 920px){
  .AccessCardsItem {
    width: calc(100vw - 32px);
    margin-left: 16px;
  }
}

.RemoveAccessButtons {
  .ButtonNotifiGray button {
    width: 108px;
    padding: 0 !important;
  }
  .ButtonNotifiRed button {
    width: 140px;
    padding: 0 !important;
  }
}

.AccessEmptyCarsList {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translateY(-50%);
}

.AccessCardsTooltipIconWrapper{
  position: relative;
  top: 2px;
}