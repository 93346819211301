@orange-900: #D9480F;
@orange-800: #E8590C;
@orange-700: #F76707;
@orange-600: #FD7E14;
@orange-500: #FF922B;
@orange-400: #FFA94D;
@orange-300: #FFC078;
@orange-200: #FFD8A8;
@orange-100: #FFE8CC;
@orange-10: #FFF4E6;

// Color
.Orange900Color {
  color: @orange-900 !important;
}

.Orange800Color {
  color: @orange-800 !important;
}

.Orange700Color {
  color: @orange-700 !important;
}

.Orange600Color {
  color: @orange-600 !important;
}

.Orange500Color {
  color: @orange-500 !important;
}

.Orange400Color {
  color: @orange-400 !important;
}

.Orange300Color {
  color: @orange-300 !important;
}

.Orange200Color {
  color: @orange-200 !important;
}

.Orange100Color {
  color: @orange-100 !important;
}

.Orange10Color {
  color: @orange-10 !important;
}

// BackgroundColor
.Orange900Background {
  background-color: @orange-900 !important;
}

.Orange800Background {
  background-color: @orange-800 !important;
}

.Orange700Background {
  background-color: @orange-700 !important;
}

.Orange600Background {
  background-color: @orange-600 !important;
}

.Orange500Background {
  background-color: @orange-500 !important;
}

.Orange400Background {
  background-color: @orange-400 !important;
}

.Orange300Background {
  background-color: @orange-300 !important;
}

.Orange200Background {
  background-color: @orange-200 !important;
}

.Orange100Background {
  background-color: @orange-100 !important;
}

.Orange10Background {
  background-color: @orange-10 !important;
}

// BorderColor
.Orange900Border{
  border-color: @orange-900 !important;
}

.Orange800Border{
  border-color: @orange-800 !important;
}

.Orange700Border{
  border-color: @orange-700 !important;
}

.Orange600Border{
  border-color: @orange-600 !important;
}

.Orange500Border{
  border-color: @orange-500 !important;
}

.Orange400Border{
  border-color: @orange-400 !important;
}

.Orange300Border{
  border-color: @orange-300 !important;
}

.Orange200Border{
  border-color: @orange-200 !important;
}

.Orange100Border{
  border-color: @orange-100 !important;
}

.Orange10Border{
  border-color: @orange-10 !important;
}
