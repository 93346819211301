@import "ProposalModal/styles";
@import 'ProposalMobileCard/styles';

.ProposalStatus {
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  height: 24px;
  width: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProposalStatusPending {
  background: #F1F3F5;
}

.ProposalStatusCanceled {
  color: #FA5252;
  background: #FFF5F5;
}

.ProposalStatusSuccess {
  color: #40C057;
  background: #EBFBEE;
}

.PopoverGridTools {
  display: flex;
  flex-direction: row;
}

.ProposalIcon:nth-child(2n) {
  margin-left: 15px;
}

.ProposalIcon {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    color: #ADB5BD;
  }
}

.ProposalIcon:hover {
  opacity: 0.25;
}

.ProposalIconText {
  color: #ADB5BD;
  size: 14px;
  margin-left: 3px;
}

.ProposalIconDetails {
  margin-left: auto;
}

.ProposalIconDetails:hover {
  cursor: pointer;
  opacity: 0.25;
}

.ProposalPopoverButton {
  display: flex;
  align-items: center;
  padding: 15px 15px 15px 10px;
  border-radius: 12px;

  .ProposalPopoverButtonText {
    margin-left: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.ProposalPopoverButton:hover {
  background-color: #E8E8E8FF;
  cursor: pointer;
}


.ant-popover-inner-content {
    padding: 0 !important;
}

.ant-popover-inner {
 border-radius: 12px !important;
}

.ProposalTextColumn {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


@media screen and (max-width: 1600px) {
  .ProposalNewButtonTitleContainer {
    display: inline-flex !important;
    margin-top: 10px
  }
}

.ProposalNewButtonTitleContainer {
  margin-left: auto;
}

.SupportProposalAdditionalCell {
  word-spacing: 0 !important;
  word-wrap: unset !important;
}

.ProposalTable .ProposalTableCellHeader, .SupportProposalAdditionalCell {
  color: #212529 !important;
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.ProposalNullishZendeskIdIcon {
  width: 16px;
  height: 16px;
}

.ProposalNullishZendeskIdMask {
  color: #CED4DA;
}

.ProposalNullishZendeskIdWrapper {
  display: flex;
  align-items: center;
}

.ProposalNullishZendeskIdWrapper .TooltipComponentTargetWrapper {
  width: 16px;
  height: 16px;
  margin-left: 3px;
}

.ProposalTextColumn .ant-typography mark {
  background-color: #fef9cc;
}
