@pd-2: 2px;
@pd-3: 3px;
@pd-4: 4px;
@pd-6: 6px;
@pd-8: 8px;
@pd-10: 10px;
@pd-12: 12px;
@pd-14: 14px;
@pd-16: 16px;
@pd-18: 18px;
@pd-20: 20px;
@pd-24: 24px;
@pd-32: 32px;
@pd-40: 40px;
@pd-64: 64px;

// auto

.MarginAuto {
  margin: auto;
}

.MarginLeftAuto {
  margin-left: auto;
}

.MarginRightAuto {
  margin-right: auto;
}

.MarginTopAuto {
  margin-top: auto;
}

.MarginBottomAuto {
  margin-bottom: auto;
}

// @pd-2

.Padding2 {
  padding: @pd-2;
}

.PaddingLeft2 {
  padding-left: @pd-2;
}

.PaddingRight2 {
  padding-right: @pd-2;
}

.PaddingTop2 {
  padding-top: @pd-2;
}

.PaddingBottom2 {
  padding-bottom: @pd-2;
}


.Margin2 {
  margin: @pd-2;
}

.MarginLeft2 {
  margin-left: @pd-2;
}

.MarginRight2 {
  margin-right: @pd-2;
}

.MarginTop2 {
  margin-top: @pd-2;
}

.MarginBottom2 {
  margin-bottom: @pd-2;
}

.Gap2 {
  gap: @pd-2
}

// @pd-4

.Padding4 {
  padding: @pd-4;
}

.PaddingLeft4 {
  padding-left: @pd-4;
}

.PaddingRight4 {
  padding-right: @pd-4;
}

.PaddingTop4 {
  padding-top: @pd-4;
}

.PaddingBottom4 {
  padding-bottom: @pd-4;
}


.Margin4 {
  margin: @pd-4;
}

.MarginLeft4 {
  margin-left: @pd-4;
}

.MarginRight4 {
  margin-right: @pd-4;
}

.MarginTop4 {
  margin-top: @pd-4;
}

.MarginBottom4 {
  margin-bottom: @pd-4;
}

.Gap4 {
  gap: @pd-4
}

// @pd-6

.Padding6 {
  padding: @pd-6;
}

.PaddingLeft6 {
  padding-left: @pd-6;
}

.PaddingRight6 {
  padding-right: @pd-6;
}

.PaddingTop6 {
  padding-top: @pd-6;
}

.PaddingBottom6 {
  padding-bottom: @pd-6;
}


.Margin6 {
  margin: @pd-6;
}

.MarginLeft6 {
  margin-left: @pd-6;
}

.MarginRight6 {
  margin-right: @pd-6;
}

.MarginTop6 {
  margin-top: @pd-6;
}

.MarginBottom6 {
  margin-bottom: @pd-6;
}

.Gap6 {
  gap: @pd-6
}

// @pd-8

.Padding8 {
  padding: @pd-8;
}

.PaddingLeft8 {
  padding-left: @pd-8;
}

.PaddingRight8 {
  padding-right: @pd-8;
}

.PaddingTop8 {
  padding-top: @pd-8;
}

.PaddingBottom8 {
  padding-bottom: @pd-8;
}


.Margin8 {
  margin: @pd-8;
}

.MarginLeft8 {
  margin-left: @pd-8;
}

.MarginRight8 {
  margin-right: @pd-8;
}

.MarginTop8 {
  margin-top: @pd-8;
}

.MarginBottom8 {
  margin-bottom: @pd-8;
}

.Gap8 {
  gap: @pd-8
}

// @pd-10

.Padding10 {
  padding: @pd-10;
}

.PaddingLeft10 {
  padding-left: @pd-10;
}

.PaddingRight10 {
  padding-right: @pd-10;
}

.PaddingTop10 {
  padding-top: @pd-10;
}

.PaddingBottom10 {
  padding-bottom: @pd-10;
}


.Margin10 {
  margin: @pd-10;
}

.MarginLeft10 {
  margin-left: @pd-10;
}

.MarginRight10 {
  margin-right: @pd-10;
}

.MarginTop10 {
  margin-top: @pd-10;
}

.MarginBottom10 {
  margin-bottom: @pd-10;
}

.Gap10 {
  gap: @pd-10
}

// @pd-12

.Padding12 {
  padding: @pd-12;
}

.PaddingLeft12 {
  padding-left: @pd-12;
}

.PaddingRight12 {
  padding-right: @pd-12;
}

.PaddingTop12 {
  padding-top: @pd-12;
}

.PaddingBottom12 {
  padding-bottom: @pd-12;
}


.Margin12 {
  margin: @pd-12;
}

.MarginLeft12 {
  margin-left: @pd-12;
}

.MarginRight12 {
  margin-right: @pd-12;
}

.MarginTop12 {
  margin-top: @pd-12;
}

.MarginBottom12 {
  margin-bottom: @pd-12;
}

.Gap12 {
  gap: @pd-12
}


// @pd-14

.Padding14 {
  padding: @pd-14;
}

.PaddingLeft14 {
  padding-left: @pd-14;
}

.PaddingRight14 {
  padding-right: @pd-14;
}

.PaddingTop14 {
  padding-top: @pd-14;
}

.PaddingBottom14 {
  padding-bottom: @pd-14;
}


.Margin14 {
  margin: @pd-14;
}

.MarginLeft14 {
  margin-left: @pd-14;
}

.MarginRight14 {
  margin-right: @pd-14;
}

.MarginTop14 {
  margin-top: @pd-14;
}

.MarginBottom14 {
  margin-bottom: @pd-14;
}

.Gap14 {
  gap: @pd-14
}


// @pd-16

.Padding16 {
  padding: @pd-16;
}

.PaddingLeft16 {
  padding-left: @pd-16;
}

.PaddingRight16 {
  padding-right: @pd-16;
}

.PaddingTop16 {
  padding-top: @pd-16;
}

.PaddingBottom16 {
  padding-bottom: @pd-16;
}


.Margin16 {
  margin: @pd-16;
}

.MarginLeft16 {
  margin-left: @pd-16;
}

.MarginRight16 {
  margin-right: @pd-16;
}

.MarginTop16 {
  margin-top: @pd-16;
}

.MarginBottom16 {
  margin-bottom: @pd-16;
}

.Gap16 {
  gap: @pd-16
}

// @pd-18

.Padding18 {
  padding: @pd-18;
}

.PaddingLeft18 {
  padding-left: @pd-18;
}

.PaddingRight18 {
  padding-right: @pd-18;
}

.PaddingTop18 {
  padding-top: @pd-18;
}

.PaddingBottom18 {
  padding-bottom: @pd-18;
}


.Margin18 {
  margin: @pd-18;
}

.MarginLeft18 {
  margin-left: @pd-18;
}

.MarginRight18 {
  margin-right: @pd-18;
}

.MarginTop18 {
  margin-top: @pd-18;
}

.MarginBottom18 {
  margin-bottom: @pd-18;
}

.Gap18 {
  gap: @pd-18
}

// @pd-20

.Padding20 {
  padding: @pd-20;
}

.PaddingLeft20 {
  padding-left: @pd-20;
}

.PaddingRight20 {
  padding-right: @pd-20;
}

.PaddingTop20 {
  padding-top: @pd-20;
}

.PaddingBottom20 {
  padding-bottom: @pd-20;
}


.Margin20 {
  margin: @pd-20;
}

.MarginLeft20 {
  margin-left: @pd-20;
}

.MarginRight20 {
  margin-right: @pd-20;
}

.MarginTop20 {
  margin-top: @pd-20;
}

.MarginBottom20 {
  margin-bottom: @pd-20;
}

.Gap20 {
  gap: @pd-20
}

// @pd-24

.Padding24 {
  padding: @pd-24;
}

.PaddingLeft24 {
  padding-left: @pd-24;
}

.PaddingRight24 {
  padding-right: @pd-24;
}

.PaddingTop24 {
  padding-top: @pd-24;
}

.PaddingBottom24 {
  padding-bottom: @pd-24;
}


.Margin24 {
  margin: @pd-24;
}

.MarginLeft24 {
  margin-left: @pd-24;
}

.MarginRight24 {
  margin-right: @pd-24;
}

.MarginTop24 {
  margin-top: @pd-24;
}

.MarginBottom24 {
  margin-bottom: @pd-24;
}

.Gap24 {
  gap: @pd-24
}

// @pd-32

.Padding32 {
  padding: @pd-32;
}

.PaddingLeft32 {
  padding-left: @pd-32;
}

.PaddingRight32 {
  padding-right: @pd-32;
}

.PaddingTop32 {
  padding-top: @pd-32;
}

.PaddingBottom32 {
  padding-bottom: @pd-32;
}


.Margin32 {
  margin: @pd-32;
}

.MarginLeft32 {
  margin-left: @pd-32;
}

.MarginRight32 {
  margin-right: @pd-32;
}

.MarginTop32 {
  margin-top: @pd-32;
}

.MarginBottom32 {
  margin-bottom: @pd-32;
}

.Gap32 {
  gap: @pd-32
}

// @pd-40

.Padding40 {
  padding: @pd-40;
}

.PaddingLeft40 {
  padding-left: @pd-40;
}

.PaddingRight40 {
  padding-right: @pd-40;
}

.PaddingTop40 {
  padding-top: @pd-40;
}

.PaddingBottom40 {
  padding-bottom: @pd-40;
}


.Margin40 {
  margin: @pd-40;
}

.MarginLeft40 {
  margin-left: @pd-40;
}

.MarginRight40 {
  margin-right: @pd-40;
}

.MarginTop40 {
  margin-top: @pd-40;
}

.MarginBottom40 {
  margin-bottom: @pd-40;
}

.Gap40 {
  gap: @pd-40
}


// @pd-64

.Padding64 {
  padding: @pd-64;
}

.PaddingLeft64 {
  padding-left: @pd-64;
}

.PaddingRight64 {
  padding-right: @pd-64;
}

.PaddingTop64 {
  padding-top: @pd-64;
}

.PaddingBottom64 {
  padding-bottom: @pd-64;
}


.Margin64 {
  margin: @pd-64;
}

.MarginLeft64 {
  margin-left: @pd-64;
}

.MarginRight64 {
  margin-right: @pd-64;
}

.MarginTop64 {
  margin-top: @pd-64;
}

.MarginBottom64 {
  margin-bottom: @pd-64;
}

.Gap64 {
  gap: @pd-64
}