.MainFilterWrapper {
  position: fixed;
  top: 0;
  width: 320px;
  background: #FFFFFF;
  z-index: 5;
  transition: right 500ms ease;
  border-left: 1px solid #E5E5EA;
}

.MainFilterWrapperUnActive {
  right: -298px;
  box-sizing: border-box;
  box-shadow: -4px 0px 16px rgba(0, 0, 0, 0.08);
}

@media screen and (min-width: 921px) and (max-width: 1680px) {
  .MainFilterWrapperActive {
    box-shadow: -4px 0px 16px rgba(0, 0, 0, 0.08) !important;
  }
}

.MainFilterWrapperActive {
  right: 0;
}

.MainFilterHeader {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  justify-content: space-between;
  padding: 24px 32px 0 32px;
}

@media screen and (min-width: 921px) {
  .MainFilterMobileClose {
    display: none !important;
  }

  .MainFilterWrapper {
    height: 100vh;
  }

  .MainFilterContent {
    overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - 169px);
    margin-right: 4px;
  }
}

.MainFilterContent {
  padding: 16px 28px 32px 32px;
  display: flex;
  flex-direction: column;
}

.MainFilterContentLabel {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding-bottom: 12px;
}

.MainFilterGmtWrapper {
  padding: 12px 0 30px 0;
}

.MainFilterLocationLabel, .MainFilterGmtLabel {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.MainFilterGmtLabel {
  opacity: 0.5;
  //padding-left: 8px;
}

.MainFilterGmtHourWrapper {
  padding-top: 12px;
}

.MainFilterGmtHour {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #212529;
  padding-left: 8px;
}

.MainFilterGmtHourMoscow {
  opacity: 0.5;
}

.MainFilterApplyWrapper {
  border-top: 1px solid #E5E5EA;
  padding: 32px;
  margin-top: auto;
}

@media screen and (max-width: 920px) {
  .MainFilterWrapper {
    display: none;
  }
  .MainFilterApplyWrapper {
    border-bottom: 1px solid #E5E5EA;
    margin-bottom: 120px;
  }
  .MainFilterMobileClose {
    position: absolute;
    right: 16px;
  }

  .MainFilterWrapperActive::-webkit-scrollbar, .MainFilterMobile::-webkit-scrollbar {
    display: none !important;
  }
}

.MainFilterApplyWrapper button {
  min-width: 256px !important;
  width: 100%;
}

MainFilterMobile
.MainFilterApplyWrapper .ButtonPrimaryWrapper button{
  min-width: 208px;
}

