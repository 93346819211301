@import "./Filter/styles";
@import "./EditModal/styles";

.MainPageTitleHelp {
  padding-left: 10px;
  padding-top: 4px;
}

.MainPageTitleHelp:hover {
  cursor: pointer;
  opacity: 0.8;
}

.ChangePhoneTableWrapper {
  margin-top: 22px;
}

.ChangePhoneStatusWrapper {
  padding: 8px 10px;
  border-radius: 8px;
  text-align: center;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.ChangePhoneFirstTh {
  padding-left: 42px !important;
}

.ChangePhoneStatusNew {
  color: #212529;
  width: 64px;
}

.ChangePhoneStatusApproved {
  color: #495057;
  width: 90px;
}

.ChangePhoneStatusReject {
  color: #E03131;
  width: 94px;
}

.NotifiChangePhoneTableColumn {
  word-spacing: normal !important;
}

.NotifiChangePhoneTableColumn:before {
  display: none !important;
}

.NotifiChangePhoneTableColumnTime {
  opacity: 0.6;
  padding-left: 8px;
}

.TableComponentChangePhoneActive {

}

.RowTableComponentChangePhoneDisabledRow:hover, .RowTableComponentChangePhoneDisabledRow {
  cursor: default !important;
}