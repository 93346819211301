.LoginWrapper {
  width: 100vw;
  height: 100vh;
  background-color: #212529;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LoginHeader {
  width: 100%;
  background-color: #2C2C2E;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.LoginFormWrapper {
  border-radius: 16px;
  background-color: #fff;
  //padding: 46px;
  width: 100%;
  max-width: 485px;
}

.LoginFormLogoLabel {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17.5px;
  line-height: 22px;
  color: #E9ECEF;
  padding-bottom: 38px;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
}

@media screen and (min-height: 800px) {
  .LoginFormLogoLabel {
    margin-top: calc(50vh - 400px);
  }
}

@media screen and (max-height: 799px) {
  .LoginFormLogoLabel {
    margin-top: 20px;
  }
}

.LoginFormLogoLabel svg {
  margin-left: 24px;
  margin-bottom: 20px;
}

.LoginFormLogoLabel div span {
  padding-left: 8px;
  padding-top: 4px;
}

.LoginFormLabel {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 22px;
  color: #212529;
  padding-top: 60px;
  padding-bottom: 32px;
}

.LoginFormFieldWrapper {
  display: grid;
  place-items: center;
}

.LoginFormField {
  width: 320px;
  padding-top: 8px;
}

.ErrorMessage {
  display: grid;
  place-items: center;
  color: #ff0025;
  padding: 5px 0;
}

.LoginFormButtons {
  display: grid;
  place-items: center;
  padding-bottom: 69px;
}

.LoginFormEnter {
  width: 240px;
  height: 48px !important;
  color: #0d0d0d !important;
}

.LoginFormEnter:hover {
  background-color: #ebdb00 !important;
  border-color: #ebdb00 !important;
}

