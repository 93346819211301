

.MiddleHeight {
  .ant-btn {
    height: 40px !important;
  }
}

.ButtonPrimary span {
  font-family: Gilroy, sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #212529;
}

.ButtonDefaultWrapper,
.ButtonPrimaryWrapper {
  .ant-btn {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    padding-left: 32px;
    padding-right: 32px;
    border-radius: 8px;

    span {
      font-weight: 600;
      font-size: 14px;
      color: #1D1F21;
    }

    &:disabled {
      border: none;
      background: @gray-500 !important;
      cursor: not-allowed;

      span {
        color: @shark-500 !important;
      }
    }
  }
}

.ButtonDefaultWrapper {
  .ant-btn {
    background: #fff;
    border-color: #CED4DA;

    &:not([disabled]) {
      &:hover,
      &:focus,
      &:active {
        border-color: #666;
      }
    }

    &.ButtonDefaultDisabled,
    &[disabled] {
      &,
      &:hover,
      &:focus,
      &:active {
        background: #FAFAF9 !important;
        border-color: #AEAEB2 !important;
        cursor: not-allowed;
      }
    }
  }
}

.ant-btn-primary,
.ButtonPrimaryWrapper {
  .ant-btn {
    background: #F8E800;
    border-color: #F8E800;

    &:hover,
    &:focus,
    &:active {
      border-color: #F5D400;
      background: #F5D400;
    }

    &:not([disabled]) {
      &:hover,
      &:focus,
      &:active {
        border-color: #F5D400;
        background: #F5D400;
      }
    }

    &.ButtonPrimaryDisabled,
    &[disabled] {
      &,
      &:hover,
      &:focus,
      &:active {
        background: #F8E800;
        border-color: #F8E800;
        opacity: 0.48;
        cursor: not-allowed;
      }
    }
  }
}

.ButtonIconOuter{
  position: relative;
}

.ButtonIconWrapper {
  position: relative;

  svg{
    position: absolute;
    z-index: 1;
    top: 9px;
    left: 10px;
    width: 20px;
    height: 20px;
  }

  .ant-btn {
    background: #fff;
    border: 1px solid #f8e800;
    text-shadow: none;
    border-radius: 8px;

    font-family: Gilroy, sans-serif;
    font-weight: 600;
    height: 40px;
    padding-left: 36px;
    display: block;

    &:not([disabled]) {
      &:hover,
      &:focus,
      &:active {
        border: 1px solid #FFDD2D;
        box-shadow: 0 0 0 1px #f8e800;
        color: #000;
      }
    }

    &.ButtonIconDisabled,
    &.ButtonFadedDisabled,
    &[disabled] {
      &,
      &:hover,
      &:focus,
      &:active {
        background: #FAFAF9;
        border: none;
        opacity: 0.48;
        cursor: not-allowed;
      }
    }

    &.ButtonIcon_excel{
      border: none;
      background: no-repeat 2px center #E9FAC8;
      box-shadow: none;
      padding: 4px 16px 4px 36px;
      min-width: 119px;

      &:not([disabled]):hover, &:not([disabled]):focus, &:not([disabled]):active{
        background-color: #D8F5A2;
        border: none;
        box-shadow: none;
        color: #000;
      }

      span{
        .TableHeaderText;
        font-weight: 500;
      }

    }

    &.ButtonIcon_basic {
      border: 1px solid grey;
      background: no-repeat 2px center white;
      box-shadow: none;
      padding: 4px 16px 4px 36px;

      &:not([disabled]):hover, &:not([disabled]):focus, &:not([disabled]):active{
        background-color: white;
        border: 1px solid grey;
        box-shadow: 0 0 0 1px grey;
        color: #000;
      }
    }
  }
}


.ButtonFadedWrapper {
  .ant-btn {
    font-family: Gilroy, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #1C1C1E;
    background: #FAFAF9;
    border: 1px solid #E5E5EA !important;
    box-shadow: none !important;
    text-shadow: none !important;
    border-radius: 8px;
    height: 48px;
    padding: 16px;

    &:not([disabled]) {
      &:focus,
      &:active {
        color: #1C1C1E;
        background: #FAFAF9;
        border: 1px solid #E5E5EA !important;
      }
      &:hover {
        color: #1C1C1E;
        opacity: 0.8;
        background: #E5E5EA;
      }

    }

    &.ButtonFadedDisabled,
    &[disabled] {
      &,
      &:hover,
      &:focus,
      &:active {
        color: #1C1C1E;
        background: #FAFAF9;
        border: 1px solid #E5E5EA !important;
        opacity: 0.48;
        cursor: not-allowed;
      }
    }
  }
}

.ButtonGreenWrapper {
  .ant-btn {
    background: @green-10;
    border: none !important;
    border-radius: 8px;
    box-shadow: none !important;
    text-shadow: none !important;
    height: 40px;

    svg path {
      fill: @green-700;
    }
    span {
      padding-left: 8px;
      padding-top: 4px;
      color: @green-700 !important;
      font-family: Gilroy, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
    }

    &:not([disabled]) {
      &:focus,
      &:active {
        color: @green-600;
      }
      &:hover {
        color: @green-700;
        opacity: 0.9 !important;
        background: @green-10;
      }

    }

    &.ButtonGreenDisabled,
    &[disabled] {
      &,
      &:hover,
      &:focus,
      &:active {
        background: @green-10;
        border: none;
        opacity: 0.48;
        cursor: not-allowed;
      }
    }
  }
}

.ButtonNotifiWrapper, .ButtonNotifiBlue {
  .ant-btn {
    border: 1px solid #CED4DA !important;
    border-radius: 8px;
    box-shadow: none !important;
    text-shadow: none !important;
    height: 48px;
    padding: 4px 16px;

    div span {
      &:extend(.ButtonTextSecond);
      padding: 5px 12px 0 12px;
    }

    &:not([disabled]) {
      &:focus,
      &:active {
        color: #868E96;
        background: #F8F9FA;
      }
      &:hover {
        border-color: #868E96 !important;
      }

    }

    &.ButtonGreenDisabled,
    &[disabled] {
      &,
      &:hover,
      &:focus,
      &:active {
        background: #F8F9FA;
        opacity: 0.48;
        cursor: not-allowed;
      }
    }
  }
}

.ButtonNotifiActive {
  .ant-btn {
    border: 1px solid #FCE000 !important;
  }
}

.ButtonPadding {
  padding: 0 48px;
}

.ButtonNotifiBlue {
  color: #2f54eb !important;

  .ant-btn {
    &:extend(.ButtonPadding);

    div span {
      font-family: Gilroy, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #228BE6;
    }

  }
}

.ButtonNotifiGreen {
  .ant-btn {
    color: #fff !important;
    background-color: #51CF66 !important;
    &:extend(.ButtonPadding);
    border: none !important;

    div span {
      font-family: Gilroy, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #fff !important;
    }

  }
}

.ButtonNotifiRedBorder {
  .ant-btn {
    color: #FA5252 !important;
    background-color: #fff !important;
    &:extend(.ButtonPadding);
    border: 1px solid #FA5252 !important;

    div span {
      font-family: Gilroy, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #FA5252 !important;
    }
  }
}


.ButtonNotifiRedBorder {
  .ant-btn {

    &:not([disabled]) {
      &:hover {
        opacity: 0.8 !important;
        border-color: #FA5252 !important;
      }

    }
  }
}

.ButtonNotifiRed {
  .ant-btn {
    color: #fff !important;
    background-color: #FA5252 !important;
    &:extend(.ButtonPadding);
    border: none !important;

    div span {
      font-family: Gilroy, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #fff !important;
    }
  }
}

.ButtonNotifiGray {
  .ant-btn {
    &:extend(.Shark500Color);
    &:extend(.Gray10Background);
    &:extend(.Gray400Border);
    &:extend(.ButtonPadding);

    div span {
      &:extend(.ChartDecodingText);
      &:extend(.Shark500Color);
    }
  }
}

.ButtonNotifiRed:hover {
  opacity: 0.8;
}

.MobileBackButtonWrapper {

  svg {
    margin-top: 1px;
  }

  .MobileBackButtonSpan {
    padding-left: 4px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #228BE6;
  }
}

.ButtonOutlinedWrapper {
  .ant-btn {
    border: 1px solid #228BE6 !important;
    border-radius: 8px;
    box-shadow: none !important;
    text-shadow: none !important;
    height: 48px;
    padding: 11px 20px;
    background: transparent;
    span {
      color: #228BE6 !important;
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
      font-family: 'Gilroy', sans-serif;
    }
    div span {
      &:extend(.ButtonTextSecond);
      padding: 5px 12px 0 12px;

    }

    &:not([disabled]) {
      &:focus,
      &:active {
        color: #868E96;
        background: #F8F9FA;
      }
      &:hover {
        border-color: #868E96 !important;
      }

    }

    &.ButtonGreenDisabled,
    &[disabled] {
      &,
      &:hover,
      &:focus,
      &:active {
        background: #F8F9FA;
        opacity: 0.48;
        cursor: not-allowed;
      }
    }
  }
}