.NotificationsFilterWrapper {
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.NotificationsFilterField {
  width: calc(25% - 20px);
  max-width: 335px;
  margin-right: 20px;
  margin-bottom: 16px;
}

.NotificationsFilterFieldDescription {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #343A40;
  padding-bottom: 4px;
}

.FilterButtonNotifiWrapper {
  margin-top: 22px;
  margin-right: 16px;
}

.NotificationsFilterWrapperMobile, .FilterButtonNotifiMobileWrapper {
  display: none !important;
}


@media screen and (max-width: 920px) {
  .NotificationsFilterWrapper {
    flex-direction: column !important;
    position: fixed;
    background: #fff;
    top: 0;
    z-index: 1;
    padding-left: 16px;
    padding-right: 16px;
    min-height: 100vh;
  }

  .NotificationsFilterField {
    width: calc(100vw - 32px) !important;
    max-width: none !important;
    margin-right: 0 !important;
  }

  .NotificationsFilterWrapperMobile {
    display: inherit !important;
    padding-bottom: 32px;
    padding-top: 16px;
  }

  .NotificationsFilterWrapperMobileTitle {
    font-family: Gilroy, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
  }

  .NotificationsFilterWrapperMobileClose {
    margin-left: auto;
  }

  .FilterButtonNotifiWrapper {
    display: none;
  }

  .FilterButtonNotifiMobileWrapper,
  .FilterButtonNotifiMobileWrapper > .FilterButtonNotifiWrapper {
    display: inherit !important;
  }

  .FilterButtonNotifiMobileWrapper {
    padding-bottom: 120px;
  }
}
