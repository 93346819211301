@import "src/_main";
.CheckboxWrapper,
.HallPickerFilterCheckboxGroupWrapper,
.MainFilterCheckboxGroupWrapper,
.GamesListFilterCheckboxGroupWrapper {
  display: inline-flex;
}

.CheckboxWrapper,
.HallPickerFilterCheckboxGroupWrapper,
.SideFilterCheckBoxGroupWrapper,
.MainFilterCheckboxGroupWrapper,
.GamesListFilterCheckboxGroupWrapper,
.ant-table-cell {
  .ant-checkbox-checked .ant-checkbox-inner {
    background: transparent url(/Components/CheckBox/check.svg) no-repeat 70% 50%;

    &:after {
      display: none !important;
    }
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    background: none;
    border-color: #C7C7CC;
    border-radius: 4px;

    &:after {
      display: none;
      width: 7px;
      height: 11px;
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #F8E800;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #F8E800;
    border-color: #F8E800;
  }

  .ant-checkbox-checked::after {
    border-color: #F8E800;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after,
  .ant-checkbox-checked:hover .ant-checkbox-inner:after {
    border-right-color: #1D1F21;
    border-bottom-color: #1D1F21;
  }

  .ant-checkbox-wrapper-disabled,
  .ant-checkbox-wrapper-disabled + .CheckboxText {
    opacity: 0.5;
  }
}

.CheckboxText {
  font-size: 15px;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 1px !important;
  background-color: #C7C7CC !important;
}

thead tr th.ant-table-cell,
.ant-table-column-has-sorters .ant-table-column-sorters > span:first-child,
.CashboxTableHeaderItem {
    &:extend(.TableHeader);
}

