@import "./Charts/styles";
@import "./Types/styles";

.CashboxStatisticsTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #8E8E93;
  padding-left: 12px;
}
