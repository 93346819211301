.OfficialSmsTitleWrapper {
  .TitleTooltip {
    top: 2px
  }
}

.OfficialSmsTable {
  .ant-table-tbody .ant-table-row:hover {
    cursor: default;
  }
  .ant-table-column-sort {
    background-color: #ffffff;
  }

  .ant-table-column-has-sorters .ant-table-column-sorters,
  .ant-table-thead > tr > th {
    background-color: #E3FAFC !important;
    min-width: 124px;
  }

  .ant-table-tbody .ant-table-row > td:first-child,
  .ant-table-container table > thead > tr th:first-child{
    padding-left: 32px !important;
  }
}