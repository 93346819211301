.ant-btn {
    .DropdownButtonIcon {
        transition: .3s;
    
        &.dropdown {
            transform: rotate(180deg);
        }
    }
}

