.GuidebookRightPanelWrapper {
  padding: 24px 0;
}

.GuidebookRightPanelWrapperHidden {
  display: none;
}

.GuidebookRightPanelTitle {
  padding: 0 32px 20px 32px;
}

.GuidebookRightPanelTabs {
  gap: 24px;

  padding: 0 32px;
  border-bottom: 1px solid #DEE2E6;
}

.GuidebookRightPanelTab {
  span {
    padding-bottom: 10px;
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
  }
  &:hover {
    cursor: pointer;
  }
}

.GuidebookRightPanelCommentHeaderPrimaryText,
.GuidebookRightPanelCommentHeaderSecondaryText {
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.GuidebookRightPanelNotActiveTab {
  color: #868E96;
  padding-bottom: 13px !important;
}

.GuidebookRightPanelNotActiveTab:hover {
  cursor: pointer;
  color: @shark500;
}

.GuidebookRightPanelActiveTab {
  background: #FCE000;
  height: 3px;
  width: 100%;

  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.GuidebookRightPanelMessages {
  display: flex;
  flex-direction: column;
  //gap: 10px;
  overflow-y: scroll;
  height: 87vh;
}

.GuidebookRightPanelComment {
  padding: 24px 24px 16px 24px;
  border-bottom: 1px solid #DEE2E6;
}

.GuidebookRightPanelComment::before {
  height: 6px;
  width: 6px;
  background-color: @red;
  border-radius: 50%;
  display: block;
  position: relative;
  content: "";
  top: 14px;
  left: -13px;
}

.GuidebookRightPanelComment:hover {
  cursor: pointer;
  opacity: 0.7;
}

.GuidebookRightPanelCommentHeader {
  display: flex;
}

.GuidebookRightPanelCommentHeaderSecondaryText {
  color: @grey-4;
  margin-left: 3px;
}

.GuidebookRightPanelCommentHeaderDateTime {
  display: flex;
  color: @grey-4;
  gap: 6px;
  margin-top: 6px;
}

.GuidebookRightPanelEmptyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  margin-top: 105px;
}

.GuidebookRightPanelEmptyText {
  text-align: center;
  white-space: pre-line;
}

.GuidebookRightPanelTabWithBadge {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.GuidebookRightPanelTabBadge {
  padding: 2px 4px 3px;
  width: 22px;
  height: 19px;
  background: #FA5252;
  border-radius: 18px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}