@import "./Filter/styles";
@import "./Content/styles";


.MainPageTitleWithExel {
  gap: 24px;
}

.CashboxExcelButton {
  margin-left: 24px;
}

.GamesReport_Table_Column .TitleTooltip {
  top: 0 !important;
  margin-left: 4px !important;
}