.ProposalModalWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 10;
  min-height: 1080px;
}

.ProposalModalSubTitleWrapperSupportMessage {
  white-space: pre-line;
}

@media screen and (min-height: 1081px) {
  .ProposalModalWrapper {
    min-height: 100vh;
    height: 100%;
  }
}

.ProposalModal {
  width: 1064px;
  z-index: 10;
  background-color: white;
  margin: 72px 0;
  border: 1px solid #E9ECEF;
  border-radius: 16px;
  padding: 32px 40px 40px 40px
}

@media screen and (min-width: 921px) and (max-width: 1440px) {
  .ProposalModal {
    max-width: calc(100vw - 320px);
    margin-left: 32px !important;
  }
}

@media screen and (min-width: 1441px) {
  .ProposalModal {
    max-width: calc(100vw - 320px);
    margin-left: calc(50vw - 160px) !important;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 920px) {
  .ProposalModal {
    width: 100% !important;
    height: calc(100vh - 72px);
    overflow-y: auto;
    padding: 24px 16px !important;
  }
}

.ProposalModal::-webkit-scrollbar {
  display: none !important;
}

.ProposalModalTitleClose {
  margin-left: auto;
  margin-right: -25px;
  margin-top: -10px;
}

.ProposalModalTitleClose:hover {
  cursor: pointer;

  svg path {
    fill: black;
  }
}

.ProposalModalTitleWrapper .ProposalModalTextTitleText{
    font-family: Gilroy, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: #212529;
    margin-right: 12px;
    display: flex;
}

.ProposalModalSubTitleWrapper {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
  margin-top: 24px;
}

.ProposalModalButtonsGroup {
  padding-top: 18px;
  display: flex;

  .ButtonIconWrapper {
    margin-right: 16px !important;
  }

  .ButtonPrimaryWrapper button {
    width: 200px;
  }

  .ProposalModalAttachmentButton {
    margin-left: 16px;
    display: flex;
  }

  .ProposalModalAttachmentIcon {
    align-self: center;
  }

  .ant-form-item-explain-error {
    margin-top: 14px;
    margin-left: 17px;
  }
}

.ProposalModalButtonsGroupTopLine {
  border-top: 1px solid #DEE2E6;
  padding-top: 32px;
}

.ProposalModalTitleAndStatus {
  display: flex;
  align-items: center;
}

.ProposalModalSupportCard {
  .ProposalModalSubTitleWrapper {
    margin-top: 0;
  }
  background: #F8F9FA;
  border-radius: 12px;
  padding: 16px 16px 16px 16px;
  margin-top: 24px;
}

.ProposalModalFilesWrapper {
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ProposalModalCloseIcon {
  margin-top: 30px;
  margin-right: 26px;
}

.SupportProposalModal .ant-modal-content {
  border-radius: 16px;
  border: 1px solid #E9ECEF;
}

.SupportProposalModal .ant-modal-body {
  padding: 46px 56px;
}

.SupportProposalModalMobile .ant-modal-body {
  padding: 58px 17px 19px 17px;
}

.SupportProposalModal .ant-form-item-label, .ProposalModalSubTitleWrapper {
  padding: 0 0 6px !important;
}

.SupportProposalModal .ant-form-item {
  margin-bottom: 0 !important;
}

.ProposalModalPhoneInput {
  max-width: 246px !important;
  min-width: 246px !important;
  width: 246px !important;


  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.ProposalModalTitleViewMode {
  border-bottom: 1px solid #DEE2E6;
  padding-bottom: 18px;
}

.ProposalFormViewMode  {
  .ant-form-item-label {
    padding-bottom: 0 !important;
  }

  .ant-form-item-control-input {
    min-height: 14px !important;
  }
}

.ProposalModalContentWrapper {
  margin-bottom: 24px;
}

.ProposalModalTitleAndStatus .ProposalNullishZendeskIdWrapper {
  align-items: unset !important;
}

.ProposalModalTextTitleMain {
  margin-right: 6px;
}

.ProposalModalTitleAndStatus .TooltipComponentTargetWrapper {
  margin-left: 10px !important;
  width: 24px;
  height: 24px;
    svg {
      width: 24px;
      height: 24px;
    }
}

.SupportProposalSuccess {
  height: 588px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column
}

.SupportProposalSuccessText {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: #212529;
  white-space: pre-line;
  margin-bottom: 20px;
}

.ProposalModalButtonCancel {
  width: 145px;
  svg {
    width: 15px !important;
    height: 15px !important;
    left: 28px !important;
    top: 10px !important;
  }
}

.ProposalModalButtonDuplicate {
  width: 197px;
  svg {
    left: 40px !important;
  }
}

.ProposalModalContentMobileViewBack {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #228BE6;
  margin-bottom: 19px;
  margin-top: 19px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;
  }
}