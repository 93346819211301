@teal-900: #087F5B;
@teal-800: #099268;
@teal-700: #0CA678;
@teal-600: #12B886;
@teal-500: #20C997;
@teal-400: #38D9A9;
@teal-300: #63E6BE;
@teal-200: #96F2D7;
@teal-100: #C3FAE8;
@teal-10: #E6FCF5;

// Color
.Teal900Color {
  color: @teal-900 !important;
}

.Teal800Color {
  color: @teal-800 !important;
}

.Teal700Color {
  color: @teal-700 !important;
}

.Teal600Color {
  color: @teal-600 !important;
}

.Teal500Color {
  color: @teal-500 !important;
}

.Teal400Color {
  color: @teal-400 !important;
}

.Teal300Color {
  color: @teal-300 !important;
}

.Teal200Color {
  color: @teal-200 !important;
}

.Teal100Color {
  color: @teal-100 !important;
}

.Teal10Color {
  color: @teal-10 !important;
}

// BackgroundColor
.Teal900Background {
  background-color: @teal-900 !important;
}

.Teal800Background {
  background-color: @teal-800 !important;
}

.Teal700Background {
  background-color: @teal-700 !important;
}

.Teal600Background {
  background-color: @teal-600 !important;
}

.Teal500Background {
  background-color: @teal-500 !important;
}

.Teal400Background {
  background-color: @teal-400 !important;
}

.Teal300Background {
  background-color: @teal-300 !important;
}

.Teal200Background {
  background-color: @teal-200 !important;
}

.Teal100Background {
  background-color: @teal-100 !important;
}

.Teal10Background {
  background-color: @teal-10 !important;
}

// BorderColor
.Teal900Border{
  border-color: @teal-900 !important;
}

.Teal800Border{
  border-color: @teal-800 !important;
}

.Teal700Border{
  border-color: @teal-700 !important;
}

.Teal600Border{
  border-color: @teal-600 !important;
}

.Teal500Border{
  border-color: @teal-500 !important;
}

.Teal400Border{
  border-color: @teal-400 !important;
}

.Teal300Border{
  border-color: @teal-300 !important;
}

.Teal200Border{
  border-color: @teal-200 !important;
}

.Teal100Border{
  border-color: @teal-100 !important;
}

.Teal10Border{
  border-color: @teal-10 !important;
}
