@violet-900: #5F3DC4;
@violet-800: #6741D9;
@violet-700: #7048E8;
@violet-600: #7950F2;
@violet-500: #845EF7;
@violet-400: #9775FA;
@violet-300: #B197FC;
@violet-200: #D0BFFF;
@violet-100: #E5DBFF;
@violet-10: #F3F0FF;

// Color
.Violet900Color {
  color: @violet-900 !important;
}

.Violet800Color {
  color: @violet-800 !important;
}

.Violet700Color {
  color: @violet-700 !important;
}

.Violet600Color {
  color: @violet-600 !important;
}

.Violet500Color {
  color: @violet-500 !important;
}

.Violet400Color {
  color: @violet-400 !important;
}

.Violet300Color {
  color: @violet-300 !important;
}

.Violet200Color {
  color: @violet-200 !important;
}

.Violet100Color {
  color: @violet-100 !important;
}

.Violet10Color {
  color: @violet-10 !important;
}

// BackgroundColor
.Violet900Background {
  background-color: @violet-900 !important;
}

.Violet800Background {
  background-color: @violet-800 !important;
}

.Violet700Background {
  background-color: @violet-700 !important;
}

.Violet600Background {
  background-color: @violet-600 !important;
}

.Violet500Background {
  background-color: @violet-500 !important;
}

.Violet400Background {
  background-color: @violet-400 !important;
}

.Violet300Background {
  background-color: @violet-300 !important;
}

.Violet200Background {
  background-color: @violet-200 !important;
}

.Violet100Background {
  background-color: @violet-100 !important;
}

.Violet10Background {
  background-color: @violet-10 !important;
}

// BorderColor
.Violet900Border{
  border-color: @violet-900 !important;
}

.Violet800Border{
  border-color: @violet-800 !important;
}

.Violet700Border{
  border-color: @violet-700 !important;
}

.Violet600Border{
  border-color: @violet-600 !important;
}

.Violet500Border{
  border-color: @violet-500 !important;
}

.Violet400Border{
  border-color: @violet-400 !important;
}

.Violet300Border{
  border-color: @violet-300 !important;
}

.Violet200Border{
  border-color: @violet-200 !important;
}

.Violet100Border{
  border-color: @violet-100 !important;
}

.Violet10Border{
  border-color: @violet-10 !important;
}
