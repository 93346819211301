@import "./ConfirmModal/styles";

.NotifyStatusTableWrapper {
  max-height: calc(100vh - 355px);
  height: calc(100vh - 355px);

  margin-bottom: 32px;
  overflow-y: auto;
}

.NotifyStatusTableStatus {
  width: 88px;
  height: 27px;
  border-radius: @pd-4;
}

.NotifyStatusTableEmail {
  max-width: 194px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow-x: hidden;
}

.NotifyStatusTableButtons {
  border-top: 1px solid @gray-100;

  .ButtonNotifiWrapper button {
    &:extend(.Gray100Background);
  }
}

.NotifyStatusTableButtonsSvgItem div {
  color: @shark-500 !important;
}

.NotifyStatusTableButtonsSvgItem > span {
  padding-top: 3px !important;
}

.NotifyStatusTableButtonsSvgItem > span, .NotifyStatusTableButtonsAddItem > span {
  font-weight: 600 !important;
}

.NotifyStatusTableButtonsItem:hover {
  button .NotifyStatusTableButtonsSvgItem div {
    color: @shark-500 !important;
  }
}

@media screen and (max-width: 920px) {
  .NotifyStatusTableContent {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }

  .NotifyStatusTableContent::-webkit-scrollbar {
    display: none !important;
  }
}