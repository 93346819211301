.GuestHistoryCashboxTableTitle {
  padding-bottom: 4px;
}


.GuestHistoryCashboxTableDescriptionWrapper {
  display: flex;
  flex-direction: row;
}

.GuestHistoryCashboxTableDescription {
  color: #868E96 !important;
  padding-left: 4px;
  padding-right: 16px;
}

.GuestHistoryCashboxTableHeader {
  padding-bottom: 22px;
}

.GuestHistoryCashboxTableType {
  padding-left: 8px;
}

@media screen and (max-width: 920px) {
  .GuestHistoryCashboxTableDescriptionWrapper {
    flex-direction: column;
  }
}