.NewHallContent  {

  .EditorTextArea,
  .EditorWrapperView {
    min-height: fit-content !important;
    max-height: fit-content !important;
  }

  .EditorWrapperView {
    background: #FFFFFF;
  }

  .EditorWrapperRoot {
    padding-bottom: 30px;

    p {
      margin-bottom: 0 !important;
    }

    ul, ol {
      margin-top: 1em !important;
      padding-inline-start: 40px !important;
    }

    h1, h2, h3 {
      margin-bottom: 0.5em  !important;
    }

    p:empty {
      margin-bottom: 1em !important;
    }
  }

  .public-DraftStyleDefault-block {
    margin: 0 !important;
  }

  .EditorToolbar {
    position: sticky !important;
    bottom: 0 !important;
    left: 0 !important;
    top: 0 !important;
    width: 580px;
  }

  .EditorTextArea {
    padding: 14px 16px 14px 16px !important
  }

  .ButtonIconWrapper {
    button {
      border: 1px solid #CED4DA !important;
      display: flex;
      align-items: center;
      width: 40px;
    }

    button:hover {
      border: 1px solid #CED4DA !important;
      box-shadow: 0 0 0 1px #CED4DA !important;
    }
  }

  .NewHallContent_ButtonsGroup {
    display: flex;

    .ButtonPrimaryWrapper {
      margin-right: 10px;
    }

    .ButtonIconWrapper {

      button {
        height: 48px;
        width: 120px;
      }

      svg {
        height: 15px;
        width: 15px;
        margin-top: 6px;
        margin-left: 6px;
      }
    }
  }
}


