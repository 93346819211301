@import "./Inside/styles";

@media screen and(max-width: 920px) {
  .ReportTableContentWrapper {
    margin: 0 12px !important;
  }
}

.ReportTableContentWrapper {
  margin: 0 0;
  border-radius: 8px;
  border-bottom: 1px solid #E9EAEA;
}

.ReportTableFixedHeaderItem {
  width: 156px !important;
  position: fixed !important;
  height: 63px;
  z-index: 5;
  background-color: #E6F6F8 !important;
}

.ReportTableFixedHeaderItem span {
  padding-left: 25px;
}

.ReportTableFixedItem > div {
  padding-top: 18px;
}

.ReportTableFixedItem {
  width: 156px !important;
  height: 53px !important;
  position: fixed !important;
  margin-top: -18px;
  z-index: 5;
  background-color: #fff !important;
}

.ReportTableFixedItemShadow {
  box-shadow: 5px 0 5px -5px rgba(43, 45, 51, 0.12);
}

.ReportTableFixedItem span {
  padding-left: 40px;
}

.ReportTableHeader {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ededf0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  word-spacing: 100vw;
  background-color: #E6F6F8 !important;
  height: 64px;
  margin: auto !important;
}

.ConsolidatedReportTableItem, .ConsolidatedReportTableBodyItem {
  width: 10%;
  padding: 0 12px;
  float: left;
}

.ReportTableLastItem {
  font-weight: bold !important;
}

.ReportTableHeaderItem {
  min-width: 148px;
  position: relative;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #2B2D33;
  padding: 0 12px;
  float: left;
}

.ReportTableHeaderItem span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.ReportTableRowWrapper {
  border-bottom: 1px solid #ededf0;
}

.ReportTableRow, .ReportTableLastRow {
  display: flex;
  flex-direction: row;
  padding: 18px 0;
  width: 100%;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #2C2C2E;
  background-color: #fff !important;
}

.ReportTableLastRow {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ReportTableActiveRow {
  font-family: Lato, sans-serif;
  color: #000000 !important;
}

.ReportTableRow:hover {
  cursor: pointer;
  background: #F8F8F8 !important;

  .ReportTableFixedItem {
    background: #F8F8F8 !important;
  }
}

.ReportTableRowDisabled:hover {
  cursor: no-drop !important;
}
