.PromotionWrapper {

  .ComponentDashboardItem {
    margin-top: 0px;
    margin-bottom: 24px;
  }
}

.PromotionWrapperInActive{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 150px);
}

@media screen and (max-width: 920px){
  .PromotionWrapper{
    .TitleWrapper{
      padding-bottom: 16px !important;
    }

    .ComponentDashboardWrapper{
      padding: 0 16px;
      gap: 16px;
    }

    .ComponentDashboardItem{
      margin-bottom: 16px;
    }

    .PaginationTableComponentWrapper {
      .ant-table-content {
        table {
          background: #ffffff;
          border-top: 1px solid #E9EAEA;
          border-radius: 8px;
        }
      }
    }

    .PaginationTableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content{
      border-right: none !important;
      border-top: none !important;
    }
  }
}

.BetBonusEmptyDataWrapper{
  min-height: calc(100vh - 250px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.BetBonusFilter {
  .ant-picker-range-separator {
    padding: 0px 4px;
  }

  .ant-picker-range > .ant-picker-input {
    max-width: 95px;
  }

  .RangePickerWrapper{
    .ant-picker{
      padding: 10px !important;
    }
  }
}