.SmsContentTabs {
  width: 100%;
}

.SmsContentTitleRight {
  margin-left: auto;
}

.SmsContentTitleWrapper {
  .TitleWrapper {
    padding-bottom: 16px;
  }
}

.SmsContentTabsItem {
  .ButtonTextSecond;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 13px;
  color: #868E96;
}

.SmsContentTabsItemActive {
  color: #212529;
}

.SmsContentTabsActiveRestriction {
  margin-right: 20px;
  background: #FCE000;
  height: 3px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.SmsContentTabsRestriction {
  width: 100%;
  height: 1px;
  background: #DEE2E6;
}