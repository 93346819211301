.GuestHistoryBetsChartHeader {
  display: flex;
  gap: 8px;

  .SelectWrapper {
    width: 230px;
  }
}

.GuestHistoryBetsBySumWrapper {
  padding-top: 27px;
  margin-left: -12px;
}

@media screen and (max-width: 920px) {
  .GuestHistoryBetsChartHeader {
    flex-direction: column;
  }
}