.StatisticTypesWrapper {
  background-color: #fff;
  padding-top: 18px;
  padding-bottom: 42px;
  padding-left: 16px;
}

.StatisticTypesItem {
  padding: 18px;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 12px;
  min-width: 272px;
  width: 30%
}

.StatisticTypesItemHovered:hover {
  cursor: pointer;
  background-color: #F2F2F7;
}

.TypeItemMarkerWrapper {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-top: 5px;
  margin-right: 8px;
}

.TypeItemTitle {
  font-weight: bold;
  margin-right: 12px;
}
