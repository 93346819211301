@lime-900: #5C940D;
@lime-800: #66A80F;
@lime-700: #74B816;
@lime-600: #82C91E;
@lime-500: #94D82D;
@lime-400: #A9E34B;
@lime-300: #C0EB75;
@lime-200: #D8F5A2;
@lime-100: #E9FAC8;
@lime-10: #F4FCE3;

// Color
.Lime900Color {
  color: @lime-900 !important;
}

.Lime800Color {
  color: @lime-800 !important;
}

.Lime700Color {
  color: @lime-700 !important;
}

.Lime600Color {
  color: @lime-600 !important;
}

.Lime500Color {
  color: @lime-500 !important;
}

.Lime400Color {
  color: @lime-400 !important;
}

.Lime300Color {
  color: @lime-300 !important;
}

.Lime200Color {
  color: @lime-200 !important;
}

.Lime100Color {
  color: @lime-100 !important;
}

.Lime10Color {
  color: @lime-10 !important;
}

// BackgroundColor
.Lime900Background {
  background-color: @lime-900 !important;
}

.Lime800Background {
  background-color: @lime-800 !important;
}

.Lime700Background {
  background-color: @lime-700 !important;
}

.Lime600Background {
  background-color: @lime-600 !important;
}

.Lime500Background {
  background-color: @lime-500 !important;
}

.Lime400Background {
  background-color: @lime-400 !important;
}

.Lime300Background {
  background-color: @lime-300 !important;
}

.Lime200Background {
  background-color: @lime-200 !important;
}

.Lime100Background {
  background-color: @lime-100 !important;
}

.Lime10Background {
  background-color: @lime-10 !important;
}

// BorderColor
.Lime900Border{
  border-color: @lime-900 !important;
}

.Lime800Border{
  border-color: @lime-800 !important;
}

.Lime700Border{
  border-color: @lime-700 !important;
}

.Lime600Border{
  border-color: @lime-600 !important;
}

.Lime500Border{
  border-color: @lime-500 !important;
}

.Lime400Border{
  border-color: @lime-400 !important;
}

.Lime300Border{
  border-color: @lime-300 !important;
}

.Lime200Border{
  border-color: @lime-200 !important;
}

.Lime100Border{
  border-color: @lime-100 !important;
}

.Lime10Border{
  border-color: @lime-10 !important;
}
