 .GuestHistoryCommentWrapper {
   margin-top: 21px;
   margin-bottom: 12px;

   padding: 5px 8px 5px 4px;
   border: 1px solid #FFF;
   cursor: pointer;
 }

 .GuestHistoryCommentEditWrapper {
   border: 1px solid #CED4DA;
   border-radius: 4px;
 }

 .GuestHistoryCommentCrossWrapper {
   display: none;
   margin-left: auto;

   padding-top: 6px;
 }

 .GuestHistoryCommentEditWrapper > .GuestHistoryCommentCrossWrapper {
   display: inherit !important;
   cursor: pointer;
 }

 .GuestHistoryCommentActiveWrapper .GuestHistoryCommentText {
  color: #339AF0 !important;
 }

 .GuestHistoryCommentActiveWrapper .GuestHistoryCommentIcon path {
   fill: #339AF0;
 }

 .GuestHistoryCommentText {
   font-family: 'Gilroy', sans-serif;
   font-style: normal;
   font-weight: 700;
   font-size: 20px;
   line-height: 25px;
   letter-spacing: 0.2px;
   color: #DEE2E6;

   max-width: calc(100vw - 770px);
   overflow-x: hidden;
   padding-top: 4px;
   padding-left: 14px;
 }

 // MODAL

 .GuestHistoryCommentChangeModal {
   padding: 46px 56px;
 }

 .GuestHistoryCommentChangeModalTitle {
   font-family: 'Gilroy', sans-serif;
   font-style: normal;
   font-weight: 700;
   font-size: 24px;
   line-height: 24px;
   color: #212529;
 }

 .GuestHistoryCommentChangeModalLabel {
   font-family: 'Gilroy', sans-serif;
   font-style: normal;
   font-weight: 700;
   font-size: 16px;
   line-height: 24px;
   color: #212529;

   padding-top: 24px;
   padding-bottom: 6px;
 }

 .GuestHistoryCommentChangeModalDataRow {
   padding-bottom: 12px;
 }

 .GuestHistoryCommentChangeModalDataItem {
   font-family: 'Lato', sans-serif;
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   letter-spacing: 0.2px;
   color: #212529;

   max-width: 310px;
   overflow-x: hidden;
   padding-left: 11px;
 }

 .GuestHistoryCommentChangeModalDataItemDate {
   font-family: 'Lato', sans-serif;
   font-style: normal;
   font-weight: 400;
   font-size: 11px;
   line-height: 18px;
   letter-spacing: 0.15px;
   color: #868E96;

   padding-left: 32px;
 }

 .GuestHistoryCommentChangeModalButtons {
   padding-top: 19px;
   gap: 16px;

   .ButtonPrimaryWrapper button {
     width: 213px;
   }
 }

 .GuestHistoryCommentChangeModalMobileBack {
   display: none;
 }

 .GuestHistoryCommentEmptyHistory {
   padding-bottom: 126px;
 }


 // MOBILE

 @media screen and (max-width: 920px) {
   .GuestHistoryCommentText, .GuestHistoryCommentChangeModalDataItem {
     max-width: calc(100vw - 130px);
     overflow-x: hidden;
   }

   .GuestHistoryCommentChangeModal {
     padding: 18px;
     width: 100vw;
   }

   .GuestHistoryCommentEmptyHistory {
     margin-bottom: auto;
   }

   .GuestHistoryCommentChangeModalMobileBack {
     display: flex;
     width: fit-content;
     padding-bottom: 20px;

     svg path {
       fill: #228BE6;
     }

     span {
       font-family: 'Gilroy', sans-serif;
       font-style: normal;
       font-weight: 400;
       font-size: 16px;
       line-height: 19px;
       color: #228BE6;

       padding-left: 6px;
       padding-top: 2px;
     }
   }
 }
