.MailingFilterContentStatus {
  margin-top: 12px;
  padding-bottom: 24px;

  .CheckboxWrapper:first-child {
    margin-bottom: 10px;
  }
}

.MailingFilterContentSearch {
  padding-top: 8px;
}