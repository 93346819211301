.GuestHistoryBetsByTimeWrapper {
  overflow-x: auto;
  padding-bottom: 24px;
}

@media screen and (max-width: 920px) {
  .GuestHistoryBetsByTimeWrapper::-webkit-scrollbar {
    display: none !important
  }
}

.GuestHistoryBetsByTimeGameTitle {
  padding-right: 34px;
  text-align: left;
  min-width: 162px;

  padding-top: 15px;
}

.GuestHistoryBetsByTimeGameHourLabel {
  width: 44px;
}

.GuestHistoryBetsByTimeGameTableItemLabelPosition {
  margin-left: -18px;
}

.GuestHistoryBetsByTimeGameHourSumLabel {
  width: 94px;
  margin-left: 14px !important;
}

.GuestHistoryBetsByTimeGameHourSumLabel,
.GuestHistoryBetsByTimeGameHourLabel,
.GuestHistoryBetsByTimeGameTitle {
  color: #868E96;
}

.GuestHistoryBetsByTimeGameTableItemLabel, .GuestHistoryBetsByTimeGameTableItem {
  height: 45px;
  margin: 1.5px 3px 1.5px 0;
  width: 37px;
}

.GuestHistoryBetsByTimeGameTableItem {
  border-left: 1px dashed #E9ECEF;
}

.GuestHistoryBetsByTimeGameTableItemBet {
  background: #8CE99A;
}

.GuestHistoryBetsByTimeGameTableItemWin {
  background: #FF6B6B;
}

// tooltip

.GuestHistoryBetsByTimeItemTooltip {
  width: 376px;
  padding: 12px 13px 0 13px;
}

.GuestHistoryBetsByTimeItemTooltipHeader {
  color: #FCE000
}

.GuestHistoryBetsByTimeItemTooltipItem {
  width: 164px;
}

.GuestHistoryBetsByTimeItemTooltipRow {
  gap: 16px;
  padding-bottom: 12px;
}

.GuestHistoryBetsByTimeItemTooltipDescription {
  color: #868E96;
}

.GuestHistoryBetsByTimeItemTooltipValue {
  color: #FFF;
}