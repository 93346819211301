.ManageListMobileHallPicker {
  margin: 0 @pd-16;
}

.ManageListMobileHallItem {
  border-radius: @pd-8;
  border: 1px solid @gray-200;
  background: @white-100;

  padding: @pd-12 @pd-16;
}

.ManageListMobileHallItemActive {
  border: 2px solid @lemon-500 !important;
}

.ManageListMobileHallItem:hover {
  cursor: pointer;
  opacity: 0.8;
}

.ManageListMobileItemWrapper {
  margin: 0 @pd-16;

  border-radius: @pd-8;
  border: 1px solid @gray-200;
  background: @white-100;
}

.ManageListMobileItemTitle {
  padding: @pd-14;

  border-top-left-radius: @pd-8;
  border-top-right-radius: @pd-8;
}

.ManageListMobileItemTitleVip {
  background: @orange-10 !important;
}

.ManageListMobileItemTitleMain {
  background: @cyan-10 !important;
}

.ManageListMobileItemRow {
  border-top: 1px solid @gray-200;

}

.ManageListMobileItemRowItem {
  width: 50%;
  padding: @pd-16 @pd-16 @pd-12 @pd-16;
}

.ManageListMobileItemRowFirst {
  border-right: 1px solid @gray-200;
}