@import "./Filter/styles";
@import "./Current/styles";

.MessagesRouteList {
  background: #fff;
  border: 1px solid #E9ECEF;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
}

.MessagesPageTitle {
  padding-left: 0 !important;
}

.MessagesHeaderTabs {
  padding-top: 16px;
}

.NotificationsHeaderTabsItem span {
  min-width: max-content;
}

@media screen and (max-width: 920px) {
  .MessagesPageTitle {
    padding-left: 16px !important;
  }

  .MessagesHeaderTabs {
    padding-top: 0;
    margin-right: 0 !important;
  }

  .MessagesRouteListItemRow {
    max-width: none !important;
  }

  .MessagesRouteIconWrapper {
    margin-left: 8px;
  }

  .MessagesRouteListItemDataMobile {
    max-width: calc(100vw - 160px);
  }

}

.MessagesRouteListItem {
  padding: 16px 18px;
  width: 100%;
  border-bottom: 1px solid #E9ECEF;
}

.MessagesRouteIconWrapper {
  padding-top: 19px;
  padding-bottom: 8px;
  padding-left: 8px;
}

.MessagesRouteListTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #495057;
  padding-left: 8px;
}

.MessagesRouteListFirstItem {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.MessagesRouteListLastItem {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom: none !important;
}

.MessagesRouteListItem:hover {
  background: #F8F9FA;
  cursor: pointer;
}

@media screen and (min-width: 921px) {
  .MessagesRouteListItem > div > .UnPinnedIconWrapper {
    visibility: hidden;
  }

  .MessagesRouteListItem:hover > div > .UnPinnedIconWrapper {
    visibility: inherit !important;
  }
}

.MessagesRouteListPendingItem {
  background: #F8F9FA !important;
  cursor: not-allowed !important;
}

.MessagesRouteListUnreadItem {
  padding-left: 40px !important;
}

.MessagesRouteListItemReadCircle {
  background: #FCE000;
  width: 8px !important;
  height: 8px !important;
  margin-right: 16px;
  margin-top: 6px;
  border-radius: 8px;
}

.MessagesRouteListItemTitle {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #212529;
  width: max-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important; /* number of lines to show */
  line-clamp: 1 !important;
  -webkit-box-orient: vertical;
}

.MessagesRouteListItemText {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  padding-left: 8px;
  color: #495057;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important; /* number of lines to show */
  line-clamp: 1 !important;
  -webkit-box-orient: vertical;
}

.MessagesRouteListItemRow {
  max-width: calc(100vw - 432px);
}

@media screen and (min-width: 921px) and (max-width: 1440px) {
  .MessagesRouteListItemRow {
    max-width: calc(100vw - 368px);
  }
}

.MessagesRouteListItemRight {
  margin-left: auto;
  margin-top: auto;
}

.MessagesRouteListItemAttach {
  margin-top: -2px;
  padding: 0 24px;
}

.MessagesRouteListItemAttach svg path {
  fill: #868E96;
}

.MessagesRouteListItemDate {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #868E96;
}

.MessagesRouteListItemHallsTitle, .MessagesRouteListItemHalls {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 20px;
}

.MessagesRouteListItemHallsTitle {
  color: #1098AD;
  padding-right: 8px;
  padding-left: 24px;
  font-weight: bold;
}

.MessagesRouteListItemHalls {
  font-weight: normal;
  color: #212529;
  padding-right: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important; /* number of lines to show */
  line-clamp: 1 !important;
  -webkit-box-orient: vertical;
}



.MessagesRouteListItemText > span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important; /* number of lines to show */
  line-clamp: 1 !important;
  -webkit-box-orient: vertical;
  width: 100%;

  h1, h2, h3, h4, h5 {
    font-family: Lato, sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #212529;
  }
}

.MessagesRouteListPinWrapper {
  padding-top: 2px;
  padding-right: 8px;
}
