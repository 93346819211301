@red-900: #C92A2A;
@red-800: #E03131;
@red-700: #F03E3E;
@red-600: #FA5252;
@red-500: #FF6B6B;
@red-400: #FF8787;
@red-300: #FFA8A8;
@red-200: #FFC9C9;
@red-100: #FFE3E3;
@red-10: #FFF5F5;

// Color
.Red900Color {
  color: @red-900 !important;
}

.Red800Color {
  color: @red-800 !important;
}

.Red700Color {
  color: @red-700 !important;
}

.Red600Color {
  color: @red-600 !important;
}

.Red500Color {
  color: @red-500 !important;
}

.Red400Color {
  color: @red-400 !important;
}

.Red300Color {
  color: @red-300 !important;
}

.Red200Color {
  color: @red-200 !important;
}

.Red100Color {
  color: @red-100 !important;
}

.Red10Color {
  color: @red-10 !important;
}

// BackgroundColor
.Red900Background {
  background-color: @red-900 !important;
}

.Red800Background {
  background-color: @red-800 !important;
}

.Red700Background {
  background-color: @red-700 !important;
}

.Red600Background {
  background-color: @red-600 !important;
}

.Red500Background {
  background-color: @red-500 !important;
}

.Red400Background {
  background-color: @red-400 !important;
}

.Red300Background {
  background-color: @red-300 !important;
}

.Red200Background {
  background-color: @red-200 !important;
}

.Red100Background {
  background-color: @red-100 !important;
}

.Red10Background {
  background-color: @red-10 !important;
}

// BorderColor
.Red900Border{
  border-color: @red-900 !important;
}

.Red800Border{
  border-color: @red-800 !important;
}

.Red700Border{
  border-color: @red-700 !important;
}

.Red600Border{
  border-color: @red-600 !important;
}

.Red500Border{
  border-color: @red-500 !important;
}

.Red400Border{
  border-color: @red-400 !important;
}

.Red300Border{
  border-color: @red-300 !important;
}

.Red200Border{
  border-color: @red-200 !important;
}

.Red100Border{
  border-color: @red-100 !important;
}

.Red10Border{
  border-color: @red-10 !important;
}
