@import "./AddEditPenaltyModal/styles";
@import "./AdminPenaltyCard/styles";
@import "./ViewPenaltyModal/styles";
@import "./EditCancelRejectFinalModal/styles";

.ModalDisabledFormItem {
  .ant-form-item-label label div {
    color: #a6a8aa !important;
  }

  .InputWrapper input  {
    color: #b2b4b8 !important;
    background-color: #fdfdfd !important;
    border-color: #f1f3f5 !important;
  }

  .SelectWrapper .ant-select .ant-select-selector {
    border: 1px solid #f1f3f5 !important;
    background: #fdfdfd !important;

    .ant-select-selection-item {
      color: #b2b4b8 !important;
    }
  }

  .SelectWrapper {
    .SelectSuffixIcon path {
      fill: #b2b4b8 !important;
    }
  }
}

.AdminPenaltySubtitleRangePeriod {
  .ContentPeriodDescription {
    text-transform: unset !important;
  }
}


@media screen and (max-width: 1600px) {
 .PenaltyTitleWrapper .TitleText {
    margin-left: 0 !important;
  }
}

.AdminPenaltyCardsWrapper {
  min-width: 815px;
}