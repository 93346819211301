@import "styles/colors/gray";

.RegistrationExcelButton {
  margin-left: 24px;
}
.RegistrationReportTitle {
  padding-bottom: 0 !important;

  div .ContentPeriodTime {
    color: #808185 !important;
  }
}

.RegistrationEmptyContainer {
  padding-top: 5%;
  .RegistrationEmptySubContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 570px;
    width: 100%;
    height: 580px;
    gap: 32px;

    div {
      white-space: pre-line;
      text-align: center;
    }

    .SubtitleSecondText {
      color: @gray-600 !important;
    }
    .RegistrationEmptyContainerTextMain {
      font-family: 'Gilroy', sans-serif;
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
    }
  }
}
.RegistrationTable {
  display: flex;
  flex-direction: column;
  .ant-table-tbody {
    background-color: #ffffff;
  }

  &__Loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 250px);
  }
}

@media (max-width: 767px)  {
  .RegistrationEmptyContainer {
    .RegistrationEmptySubContainer {
      .RegistrationEmptyContainerTextMain {
        font-size: 20px;
        line-height: 25px;
      }
    }
  }
  .RegistrationExcelButton {
    margin-top: 10px;
    margin-left: 0 !important;
  }

  .RegistrationTable {
    padding-left: 16px;
  }
}

.EmptyDataWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 250px);
}

