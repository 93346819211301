.EditCancelRejectFinalModalButtonsGroup {
  .ButtonDefaultWrapper {
    margin-left: 16px;
  }
  display: flex;
  align-items: center;
}

.EditCancelRejectFinalModalButton__red {
  span {
    color: white !important;
  }

  background-color: #FA5252 !important;
  border-color: #FA5252 !important;
}