.NotifiEmptyTableWrapper {
  display: grid;
  place-items: center;
}

.NotifiEmptyTableTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #212529;
  padding-top: 40px;
}

.NotifiEmptyTableText {
  max-width: 350px;
  padding-top: 8px;
  text-align: center;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: #212529;
}
