@import "./Table/styles";
@import "./Modal/styles";
@import "./Cancel/styles";

.QiwiOrderNewButton {
  .ButtonPrimaryWrapper button {
    height: 40px !important;
  }
}

@media screen and (min-width: 921px) {
  .QiwiOrderNewButton {
    position: absolute;
    right: 0;
  }
}

@media screen and (max-width: 920px) {
  .QiwiOrderNewButton {
    margin: 0 0 16px 26px;
  }
}