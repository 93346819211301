.CalendarPopupOverlayWrapper .ant-popover-content .ant-popover-inner {
  border: 1px solid #E9ECEF;
  border-radius: 8px;
}

.CalendarPopupWrapper {
  border: none;
}

.CalendarPopupWrapper .ant-picker-panel {
  border-top: none !important;
  padding-top: 32px;
}

.CalendarPopupOverlayWrapper .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
  padding: 32px !important;
}

.CalendarPopupWrapper .ant-picker-panel .ant-picker-date-panel
.ant-picker-body .ant-picker-content thead tr > th {
  text-transform: uppercase;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #868E96;
}


.CalendarPopupWrapper .ant-picker-panel .ant-picker-date-panel
.ant-picker-body .ant-picker-content tbody tr > td {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  height: 40px;
  width: 40px;
}
.CalendarPopupWrapper .ant-picker-panel .ant-picker-date-panel
.ant-picker-body .ant-picker-content tbody tr .ant-picker-cell-selected {
  background: #FCE000;
  border-radius: 8px;
  border: none !important;
}

.CalendarPopupWrapper .ant-picker-panel .ant-picker-date-panel
.ant-picker-body .ant-picker-content tbody tr .ant-picker-cell-today
.ant-picker-calendar-date-today::before {
  border: none !important;
}

.CalendarPopupWrapper .ant-picker-panel .ant-picker-date-panel
.ant-picker-body .ant-picker-content tbody tr .ant-picker-cell-disabled {
  background: #f5f5f5;
}

.CalendarPopupWrapper .ant-picker-panel .ant-picker-date-panel
.ant-picker-body .ant-picker-content tbody tr .ant-picker-cell-selected
.ant-picker-cell-inner {
  background: #FCE000 !important;
}
// Header

.CalendarPopupHeader {
  justify-content: space-between;
}

.CalendarPopupHeaderArrow {
  width: 32px;
}

.CalendarPopupHeaderArrow:hover {
  cursor: pointer;
  opacity: 0.6;
}

.CalendarPopupHeaderArrowDisabled svg{
  path {
    fill: #CED4DA !important;
  }
}
.CalendarPopupHeaderTitle {
  text-transform: capitalize;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  //height: 32px;
  color: #212529;
  place-items: center;
}

// Pickers

.CalendarPopupPickers {
  padding-bottom: 24px;
  padding-top: 16px;
}

.CalendarPopupPickersLabel {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #868E96;
}

.CalendarPopupDatePicker {
  border-bottom: 1px solid #CED4DA !important;
}

.CalendarPopupPickersTime, .CalendarPopupDatePicker > .ant-input {
  padding-left: 0 !important;
}

.CalendarPopupPickersTime .ant-picker-input input {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
}

.CalendarPopupPickersTimeDropdown {
  top: 516px !important;
}

.CalendarPopupPickersTimeDropdown .ant-picker-panel-container {
  border-radius: 8px;
}

.ant-picker-panel .ant-picker-time-panel .ant-picker-content > .ant-picker-time-panel-column > li {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.ant-picker-panel .ant-picker-time-panel
.ant-picker-content > .ant-picker-time-panel-column > .ant-picker-time-panel-cell-selected
.ant-picker-time-panel-cell-inner {
  background: #FCE000 !important;
}

.CalendarPopupPickersTimeDropdown .ant-picker-panel-container
.ant-picker-panel .ant-picker-footer {
  display: none !important;
}
// Buttons

.CalendarPopupButtons, .CalendarPopupPickers {
  justify-content: space-between;
}
.CalendarPopupPickers > div,
.CalendarPopupButtons > div > button {
  width: 124px;
}