.CashboxChartsWrapper {
  background-color: #fff;
  padding-top: 50px;
  padding-left: 16px;
}

.highcharts-credits {
  display: none !important;
}

.CahsboxChartsTooltipWrapper {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.154px;
  color: #FFDD2D !important;
  background-color: #2D8AF1 !important;
}

.tooltip1 {
  background-color: #2D8AF1 !important;
}
