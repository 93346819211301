.FromToDatesItem {
  .DatePickerWrapper {
    width: 268px;
  }
}

//.FromToDatesItemTimePicker

.ant-picker-ok > button.ant-btn {
  height: 48px;
  border-radius: 8px;
  border: none !important;
}

.ant-picker-ok > button.ant-btn > span {
  font-size: 0;
}

.ant-picker-ok > button.ant-btn > span:after {
  content: "Выбрать";
  font-size: 16px; /* original font size */

  &:extend(.ButtonTextFirst);
}

// For filter position
@media screen and (max-height: 710px) {
  .PeriodTableFilterHeader {
    .TableHeaderModal .ModalWrapper {
      top: 24px !important;
    }
  }
}