.ant-notification {
  width: 488px !important;
  margin-right: 32px !important;
}

.NotificationToastWrapper {
  width: 488px !important;
  padding: 24px !important;
  border: 1px solid #DEE2E6 !important;
  box-shadow: 0px 4px 8px rgba(33, 37, 41, 0.16) !important;
  border-radius: 8px !important;
}

.NotificationToastWrapper {
  cursor: pointer;
}

.NotificationToastWrapper .ant-notification-notice-content
.ant-notification-notice-with-icon > div {
  margin-left: 70px !important;
}

.NotificationToastTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  color: #212529;
}

.NotificationToastTextField {
  max-width: 360px !important;
  max-height: 65px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.NotificationToastTheme {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #212529;
}

.NotificationToastText {
  padding-left: 4px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #212529;
}
