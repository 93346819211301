.TooltipComponentTargetWrapper:hover {
  cursor: pointer;
}

.TooltipComponentWrapper .ant-popover-content .ant-popover-inner {
  border-radius: 8px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #2C2C2E;
  background-color: #343A40;

  .ant-popover-inner-content {
    padding: 8px 11px !important;
    max-width: 320px;
    color: #fff;
  }
}

.InfoTooltipWrapper > .ant-tooltip-content > .ant-tooltip-inner {
  border-radius: 4px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #FFFFFF;
}

.TooltipComponentWrapper{
  .ant-popover-arrow-content::before{
    background: #343A40;
  }
}

.TooltipComponentModal {
  padding-top: 0 !important;

  .ant-popover-content {
    .ant-popover-arrow {
      display: none !important;
    }
    .ant-popover-inner {
      &:extend(.White100Background);
      .ant-popover-inner-content {
        border-radius: 16px !important;
        max-width: 100% !important;
      }
    }
  }
}

.ant-popover-placement-left .ant-popover-content .ant-popover-arrow {
 right: 4px !important;
}