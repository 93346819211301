@import "./MobileList/styles";

.SuperwinListHeader {
  background-color: @cyan-10 !important;
  padding-top: @pd-20 !important;
}

.SuperwinListNominalHeader {
  font-weight: 400 !important;
}

.SuperwinListFirstColumn {
  width: 20% !important;
  min-width: 20% !important;
}

.IncreaseModalLimit {
  &:extend(.Blue10Background);

  border-radius: @pd-8;
  padding: @pd-12 @pd-16;
}

.IncreaseModalLimitText {
  text-align: center;
  &:extend(.Blue600Color);
}

.SuperwinListFirstColumn {
  border-top-left-radius: @pd-8;
  border-bottom-left-radius: @pd-8;
  border-top: 1px solid @gray-200;
  border-left: 1px solid @gray-200;
  border-bottom: 1px solid @gray-200;

  .SuperwinListHeader {
    border-top-left-radius: @pd-8;
  }
  .SuperwinListVipRow {
    border-bottom-left-radius: @pd-8;
  }
}

.SuperwinListFirstColumn > div {
  padding-left: @pd-32 !important;
}

.SuperwinListColumn {
  border-top: 1px solid @gray-200;
  border-bottom: 1px solid @gray-200;
  min-width: fit-content;
  width: 100%;
}

.SuperwinListRow {
  overflow-x: scroll;
}

.SuperwinListRow::-webkit-scrollbar {
  width: 10px; /* Adjust to your preference */
  background: transparent; /* This makes it transparent */
}

.SuperwinListRow::-webkit-scrollbar-track {
  background: transparent !important;
}




.SuperwinListFirstColumn .SuperwinListItem:nth-child(2),
.SuperwinListColumn .SuperwinListItem:nth-child(2) {
  border-bottom: 1px solid @gray-200;
}

.SuperwinListlastColumn {
  border-top-right-radius: @pd-8;
  border-bottom-right-radius: @pd-8;

  border-right: 1px solid @gray-200;

  .SuperwinListHeader {
    border-top-right-radius: @pd-8;
  }
  .SuperwinListItem:last-child {
    border-bottom-right-radius: @pd-8;
  }
}

.SuperwinListItem {
  padding: 14px 8px;
  height: 56px;
  background-color: @white-100;

  .SuperwinListMainRow {
    &:extend(.GridCenter);
    &:extend(.TextOne);
  }
}

.SuperwinListMainRowIcon:hover {
  cursor: pointer;
  opacity: 0.8;
}

// CRUD

.IncreaseModalWrapper {
  width: 500px;

  padding: @pd-32 @pd-24;

  position: relative;
}

.IncreaseModalTitleClose {
  position: absolute;
  right: @pd-16;
  top: @pd-16;
}

.SuperwinIncreaseSliderWrapper .SliderComponentWrapper .SliderComponentValueWrapper {
  display: none;
}

.SuperwinIncreaseSliderWrapper {
  padding-top: @pd-8;
}

.IncreaseModalAlertArea {
  padding: @pd-12 @pd-16;

  border-radius: 8px;
  background: @white-800;
}

.IncreaseModalApply .ButtonPrimaryWrapper button {
  width: 100%;
}

.IncreaseModalTable {
  border-radius: @pd-8;
  border: 1px solid @gray-400;

  .IncreaseModalTableFirstRow {
    border-bottom: 1px solid @gray-400;
  }
  .IncreaseModalTableFirstRow > div {
    width: 50%;
  }
}

.IncreaseModalTableFirstRow .IncreaseModalTableItem:first-child {
  border-right: 1px solid @gray-400;
}

.IncreaseModalTableItem {
  padding: @pd-8 @pd-16;

  .Overline {
    color: @gray-600;
  }
}

// Success CRUD

.SuperwinSuccessModalWrapper {
  padding: @pd-32;

  .ButtonPrimaryWrapper button {
    width: 100%;
  }
}

.SuperwinSuccessModalDescription {
  width: 372px;
}

@media screen and (max-width: 920px) {
  .IncreaseModalWrapper {
    width: calc(100vw - 32px);
  }
  .SuperwinSuccessModalDescription {
    width: auto !important;
  }
  .SuperwinSuccessModalWrapper {
    padding: @pd-24 @pd-16 ;
  }

  .IncreaseModalAlertArea > div {
    max-width: calc(100vw - 115px);
  }
}