@import "Card/styles";
@import "RightPanel/styles";
@import "Chat/styles";

.GuidebookHeaderMobile {
  flex-direction: column !important;
  padding: 10px 16px 0 16px !important;
}

.GuidebookTitle {
  padding: 0 12px 20px 0;
}

.ComponentByStatusWrapper {
  padding: 8px 16px 7px 11px;
  border-radius: 4px;

  svg {
    margin-right: 4px;
  }
  span {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding-top: 4px;
  }
}

.ComponentByStatusSectionWrapper {
  padding: 5px 0 4px 0;
  border-radius: 4px;
  width: 120px;
  text-align: center;

  span {
    width: 100%;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding-top: 4px;
  }
}

.GuidebookTitleDateDescription {
  padding-top: 12px;
  padding-left: 16px;

  span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #868E96;
  }
}

.GuidebookTitleDateDescriptionMobile {
  padding-top: 0 !important;
  padding-left: 0 !important;
}

.GuidebookTitleTimeDescription {
  padding-left: 8px;
}

.GuidebookTitleArchive {
  margin-left: auto;
}

.GuidebookTitleArchive .ButtonDefaultWrapper button {
  height: 40px !important;
  padding: 0 12px !important;
}

.GuidebookTitleArchiveText {
  span {
    padding-left: 8px;
  }
}

.GuidebookAttachModalContainerTop {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.GuideBookAttachModal  {
  //width: 400px;
  //height: 400px;
  position: relative;
  .ant-modal-content {
    border-radius: 16px;
  }

  .ant-modal-body {
    .ant-image {
      border-radius: 16px;
    }

   padding: 0;
  }

}

.GuidebookCommentTitle {
  display: flex;
  margin-right: 16px;
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.GuidebookCommentTitleIndex {
  color: @grey-4;
  margin-left: 3px;
}

.GuidebookPhotoDateTime {
  display: flex;
  color: @grey-4;
  gap: 6px;
  margin-top: 6px;
}

.GuidebookAttachModalContainerRoot {
  padding: 32px 26px;
  display: flex;
  justify-content: space-between;
}

.GuidebookAttachModalImageComment {
  padding: 8px 5px;
}

.GuidebookImageWithCommentContainer {
  position: relative;
  display: flex;
  width: 100%;
  height: 720px;
  max-width: 1080px;
  max-height: 720px;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;

  .ant-image-img {
    display: block !important;
    max-width:1080px !important;
    max-height:720px !important;
    width: 100% !important;
    height: 100% !important;
  }
}

.GuidebookImageWithCommentContainerClickable {
  position: relative;
}

.GuidebookImageWithCommentContainerClickableVideo {
  width: 100%;
  height: 100%;
  video {
    width: 100%;
    height: 100%;
  }
}

.GuidebookAttachModalImageCommentPoint {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  background: #FA5252;
  border: 4px solid #FFFFFF;
}

.GuidebookAttachModalImageCommentWrapper {
  position: absolute;
}


.GuidebookAttachModalImageCommentTooltip .ant-popover-content .ant-popover-inner {
  border-radius: 8px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #2C2C2E;
  background-color: white !important;

  .ant-popover-inner-content {
    padding: 0 !important;
    max-width: 500px !important;
    color: black !important;
  }
}

.GuidebookAttachModalImageCommentTooltip {
  .ant-popover-arrow-content::before{
    background: white !important;
  }
}

.GuidebookCommentIconContainer {
  border-radius: 8px;
  background: #FFFFFF;
  border: 1px solid #CED4DA;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.5;
  }
}

.GuidebookCommentIconContainer_Active {
  background: #FCE000;
}

.GuidebookCommentIconContainer:hover {
  cursor: pointer;
}

.GuidebookAttachModalImageComment textarea {
  border-style: none;
  border-color: Transparent;
  overflow: auto;
  resize: none;
}

.GuidebookAttachModalSendIconContainer {
  background: #FCE000;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.GuidebookAttachModalSendIconContainer:hover {
  cursor: pointer;
}

.GuidebookAttachModalAddCommentContainer {
  display: flex;
  align-items: center;
}


.GuidebookModalImage_Active:hover {
  cursor: url(/Containers/Guidebook/icons/cursor_comment.svg), auto;
}

.GuidebookModalImage {
  //width: 100% !important;
  //height: 100% !important;
  margin-top: 6px;
}

div.GuidebookModalSelect {
  width: fit-content !important;
  height: 24px !important;
  //margin-left: 16px;
  border-radius: 4px !important;
    .ant-select {
      height: 24px !important;
      width: fit-content !important;
      border-radius: 4px !important;

      .ant-select-selector {
        height: 24px !important;
        width: fit-content !important;
        border-radius: 4px !important;

        .ant-select-selection-search {
          height: 24px !important;
          width: fit-content !important;
          border-radius: 4px !important;

          input {
            height: 24px !important;
            width: fit-content !important;
            border-radius: 4px !important;
          }
        }
      }
    }

  .ant-select-selector {
    .ant-select-selection-item {
       font-family: Gilroy !important;
      font-size: 12px !important;
      font-weight: 500 !important;
      line-height: 15px !important;
      letter-spacing: 0px !important;
      text-align: left !important;
    }
  }

  .ant-select-selector {
    &:not(.ant-select-disabled):hover {
      border-color: #D7D7D7 !important;
    }
  }
  div.ant-select-open {
    &:not(.ant-select-disabled) div.ant-select-selector {
      &,
      &:hover {
        border-color: #D7D7D7 !important;
      }
    }
  }
}

div.GuidebookModalSelect_consideration {
  .ant-select-open:not(.ant-select-disabled) div.ant-select-selector {
     span.ant-select-selection-item {
       color: #FD7E14 !important;
     }
    background: #FFF4E6 !important;
  }

  .ant-select-open .ant-select-disabled div.ant-select-selector {
    span.ant-select-selection-item {
      color: #FD7E14 !important;
    }
    background: #FFF4E6 !important;
  }

  .ant-select div.ant-select-selector {
    span.ant-select-selection-item {
      color: #FD7E14 !important;
    }
    background: #FFF4E6 !important;
  }

  .ant-select-arrow svg path {
    fill: #FD7E14 !important
  }
  .ant-select-selector {
    &:not(.ant-select-disabled):hover {
      .ant-select-selection-item {
        color: #FD7E14 !important;
      }
    }
  }
}

div.GuidebookModalSelect_rejected {
  .ant-select-open:not(.ant-select-disabled) div.ant-select-selector {
    span.ant-select-selection-item {
      color: #FA5252 !important;
    }
    background: #FFF5F5 !important;
  }

  .ant-select-open .ant-select-disabled div.ant-select-selector {
    span.ant-select-selection-item {
      color: #FA5252 !important;
    }
    background: #FFF5F5 !important;
  }

  .ant-select div.ant-select-selector {
    span.ant-select-selection-item {
      color: #FA5252 !important;
    }
    background: #FFF5F5 !important;
  }

  .ant-select-arrow svg path {
    fill: #FA5252 !important
  }
  .ant-select-selector {
    &:not(.ant-select-disabled):hover {
      .ant-select-selection-item {
        color: #FA5252 !important;
      }
    }
  }
}

div.GuidebookModalSelect_approved {
  .ant-select-open:not(.ant-select-disabled) div.ant-select-selector {
    span.ant-select-selection-item {
      color: #40C057 !important;
    }
    background: #e1fce5 !important;
  }

  .ant-select-open .ant-select-disabled div.ant-select-selector {
    span.ant-select-selection-item {
      color: #40C057 !important;
    }
    background: #e1fce5 !important;
  }

  .ant-select div.ant-select-selector {
    span.ant-select-selection-item {
      color: #40C057 !important;
    }
    background: #e1fce5 !important;
  }

  .ant-select-arrow svg path {
    fill: #40C057 !important
  }
  .ant-select-selector {
    &:not(.ant-select-disabled):hover {
      .ant-select-selection-item {
        color: #40C057 !important;
      }
    }
  }
}


.GuidebookModalSelectDropDown_consideration {
   .ant-select-selector:not(.ant-select-disabled):hover .ant-select-selection-item {
      color: #FD7E14 !important;
  }
  .ant-select-item-option-content {
    color: #FD7E14;
  }
}

.GuidebookModalSelectDropDown_rejected .ant-select-item-option-content {
  color: #FA5252;
}

.GuidebookModalSelectDropDown_approved .ant-select-item-option-content {
  color: #40C057;
}

.GuidebookModalSelectDropDown .ant-select-item-option-content {
  font-family: 'Gilroy' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 15px !important;
}

.GuidebookModalSelectDropDown {
  padding: 8px 12px !important;
}

.GuidebookCommentIconContainer svg path {
  fill: black;
}

.GuidebookCommentsIconContainer {
  display: flex;
  gap: 12px;
}

.GuidebookAttachModalImageCommentSubWrapper {
  display: flex;
  flex-direction: row;
}

.GuidebookDetailsIcon {
  min-width: 14px;

  &:hover {
    cursor: pointer;
    path {
      fill-opacity: 0.4;
    }
  }
}

.StatusDateDefaultWrapper {
  display: flex;
  flex-direction: row;
}

.GuideBookSwitchPhotoWrapper {
  display: flex;
  .SwitchComponentLabel {
    display: none;
  }
  .SwitchComponentWrapper {
    margin: 0px 6px;
  }
}

.GuideBookSwitchPhotoText {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: right;
}

.GuideBookSwitchPhotoTextInactive {
  color: #868E96;
}

.GuideBookFilterLarge {
  .MainFilterWrapper {
  width: 500px;
  }

  .MainPageFilterButtonWrapperActive {
    right: 485px;
    transition: none !important;
  }

  .MainPageFilterButtonWrapperUnActive {
    transition: all 300ms ease;
  }
}

.GuideBookFilter {
  .MainPageFilterButtonWrapperActive {
    transition: none !important;
  }
}


