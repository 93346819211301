@import "HallsCheck/styles";

.ant-btn-link.CopyButton {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
}

.NotifiCrudModalWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 8;
  min-height: 1080px;
}

@media screen and (min-height: 1081px) {
  .NotifiCrudModalWrapper {
    min-height: 100vh;
    height: 100%;
  }
}

.NotifiCrudModal, .NotifiCrudModalSuccessWrapper {
  width: 1064px;
  z-index: 10;
  background-color: white;
  margin: 72px 0;
  border: 1px solid #E9ECEF;
  border-radius: 16px;
  padding: 32px 40px 40px 40px
}

.NotifiCrudModalSuccessWrapper {
  display: grid;
  place-items: center;
}

.NotifiCrudModalSuccessWrapper > div {
  margin: auto;
  place-items: center;
}

@media screen and (max-width: 920px) {
  .NotifiCrudModalSuccessWrapper {
    position: fixed;
    top: 72px;
    width: 100vw;
    height: calc(100vh - 72px);
  }
}

.NotifiCrudModalSuccessTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #212529;
}

.NotifiCrudModalSuccessImg {
  padding: 66px 0 61px 0;
}

.NotifiCrudModalSuccessButton {
  padding-bottom: 80px;
}

//
@media screen and (min-width: 921px) and (max-width: 1440px) {
  .NotifiCrudModal, .NotifiCrudModalSuccessWrapper {
    max-width: calc(100vw - 320px);
    margin-left: 32px !important;
  }
}

@media screen and (min-width: 1441px) {
  .NotifiCrudModal, .NotifiCrudModalSuccessWrapper {
    max-width: calc(100vw - 320px);
    margin-left: calc(50vw - 160px) !important;
    transform: translateX(-50%);
  }
}


.NotifiCrudModalTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #212529;
}

.NotifiCrudModalClose {
  margin-left: auto;
  margin-right: -16px;
  margin-top: 10px;
}

.NotifiCrudModalClose:hover {
  cursor: pointer;

  svg path {
    fill: black;
  }
}

.NotifiCrudModalBodyHeader > div {
  width: 100%;
}

.NotifiCrudModalBodyHeader {
  padding-top: 35px;
  padding-bottom: 5px;
}

.NotifiCrudModalBodyContentRow, .NotifiCrudModalBodyContentButtonsRow {
  padding-top: 24px;
}

.NotifiCrudModalCheckbox {
  padding-right: 18px;
}

.NotifiCrudModalButtons {
  margin-right: 18px;
}

.CalendarPopupSendAfterText {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #212529 !important;
  text-decoration: none !important;
  text-align: left;
  padding-left: 12px;
}

.NotifiCrudModalFiles {
  padding: 26px 0 8px 0;
  border-bottom: 1px solid #DEE2E6;
}

.NotifiCrudModalBodyLocationFields {
  display: flex;
  flex-direction: row;
}

// File

.FileErrorToastWrapper {
  width: 488px !important;
  padding: 24px !important;
  border: 1px solid #DEE2E6 !important;
  box-shadow: 0px 4px 8px rgba(33, 37, 41, 0.16) !important;
  border-radius: 8px !important;
  border-left: 8px solid #FA5252 !important;
}

.FileErrorToastWrapper .ant-notification-notice-content
.ant-notification-notice-with-icon > div {
  margin-left: 70px !important;
}

.FileErrorToastTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  color: #212529;
}

.FileErrorToastTextField {
  max-width: 315px !important;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #495057;
}

.NotifiCrudModalMobileSaveButton {
  display: none;
}

@media screen and (max-width: 920px) {
  .NotifiCrudModal {
    width: 100% !important;
    height: calc(100vh - 72px);
    overflow-y: auto;
    padding: 24px 16px !important;
  }

  .NotifiCrudModalTitle {
    font-size: 26px;
    line-height: 32px;
  }

  .NotifiCrudModalClose {
    margin-right: 1px;
  }

  .NotifiCrudModal::-webkit-scrollbar, .NotifiCrudModalSaveButton {
    display: none !important;
  }

  .NotifiCrudModalBodyLocationFields {
    flex-direction: column !important;
  }

  .NotifiCrudModalBodyLocationItem {
    padding-bottom: 16px !important;
  }

  .NotifiCrudModalBodyContentRow {
    flex-wrap: wrap;
  }

  .NotifiCrudModalMobileSaveButton {
    display: inherit !important;

    .ButtonPrimaryWrapper button {
      padding: 12px !important;
    }
  }
}
.Flex {
  display: flex;
  align-items: center;

  &.SpaceBetween {
    justify-content: space-between;
  }
}