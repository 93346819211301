@import "./Inside/styles";

.GamesReportTableWrapper {
  min-width: 1194px;
}

.GamesReportTableItem, .GamesReportTableBodyItem {
  width: 15%;
  padding: 0 12px;
  float: left;
}
