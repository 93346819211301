@import "./Chart/styles";
@import "./Table/styles";

.GuestHistoryBetsWrapper {
  position: relative;
  padding: 32px 0;
}

.GuestHistoryBetsDataSwitcher {
  position: absolute;
  top: 32px;
  right: 0;
}
