.PaginationWrapper {
  display: grid;
  place-items: center;
  padding-top: 24px;
}

.TablePaginationWrapper div,
.PaginationWrapper div {
  place-items: center;
}


.TablePaginationWrapper div .ant-pagination .ant-pagination-next,
.TablePaginationWrapper div .ant-pagination .ant-pagination-prev,
.PaginationWrapper div .ant-pagination .ant-pagination-next,
.PaginationWrapper div .ant-pagination .ant-pagination-prev {
  display: none;
}

.PaginationWrapperButtons {
  padding-top: 8px;
  margin: 0 8px;
}

.TablePaginationWrapper div .ant-pagination .ant-pagination-item,
.PaginationWrapper div .ant-pagination .ant-pagination-item {
  height: 32px !important;
  min-width: 32px !important;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #212529 !important;
  padding-top: 6px;
  background: none;
  border: none;
  margin: 0 2px !important;
}

.ant-pagination-item-active {
  padding-top: 6px !important;
}
.TablePaginationWrapper div .ant-pagination .ant-pagination-item-active,
.PaginationWrapper div .ant-pagination .ant-pagination-item-active {
  //width: 32px !important;
  min-width: 32px !important;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #FCE000;
  box-sizing: border-box;
  border-radius: 4px;
  color: #212529 !important;
}

.TablePaginationWrapper div .ant-pagination .ant-pagination-item-active a,
.TablePaginationWrapper div .ant-pagination .ant-pagination-item-active:hover a,
.PaginationWrapper div .ant-pagination .ant-pagination-item-active a,
.PaginationWrapper div .ant-pagination .ant-pagination-item-active:hover a {
  color: #212529 !important;
}

.PaginationWrapper div .ant-pagination .ant-pagination-item:hover a {
  color: #212529 !important;
  opacity: 0.5;
}

.PaginationWrapperButtons:hover {
  cursor: pointer;
  opacity: 0.5;
}


// Table Pagination
.TablePaginationWrapper {
  background-color: #fff;
  padding: 12px;

  margin: 0 12px;
}

.TablePaginationLeftShadow::before {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.12);
  border-left: 1px solid #D1D1D6 !important;
}

.TablePaginationRightShadow {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.12);
  border-right: 1px solid #D1D1D6 !important;
}

.TablePaginationAllShadow {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.12), inset -10px 0 8px -8px rgba(0, 0, 0, 0.12);
  border-right: 1px solid #D1D1D6 !important;
  border-left: 1px solid #D1D1D6 !important;
}

.TablePaginationShadowWrapper {
  background-color: #fff;

  border-bottom: 1px solid #E9EAEA;
  border-left: 1px solid #E9EAEA;
  border-right: 1px solid #E9EAEA;

  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.TablePaginationPageSizeWrapper {
  margin-left: auto;

  span {
    padding-right: 8px;
  }
}


.TablePaginationWrapper div .ant-pagination .ant-pagination-item:hover,
.TablePaginationWrapper div .ant-pagination .ant-pagination-jump-next:hover,
.TablePaginationWrapper div .ant-pagination .ant-pagination-jump-prev:hover {
  cursor: pointer;
  background: #F1F3F5;
  border-radius: 4px;

  a {
    color: #212529 !important;
  }
}

.TablePaginationJump {
  line-height: inherit;
}

.TablePaginationWrapperButtons {
  padding: 8px 8px 0 8px;
}

.TablePaginationWrapperButtons:hover {
  cursor: pointer;
  background: #F1F3F5;
  border-radius: 4px;
}

@media screen and (max-width: 920px) {
  .TablePaginationWrapper {
    display: grid !important;
    place-items: center !important;

    .TablePaginationPageSizeWrapper {
      padding-top: 16px;
      padding-right: 16px;
      margin-left: 0 !important;
    }
  }
}