.TableCard,
.TableCardCollapsible{
  margin-bottom: 8px;
  width: 342px;
  max-width: 100%;
  border: 1px solid #E9ECEF;
  border-radius: 8px;
  background: #FFFFFF;
  .BodyFirstText;
}

.TableCard{
  padding: 16px 16px 0;
}

.TableCard,
.TableCardCollapsibleInner{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.TableCardRow{
  flex: 0 0 calc(50% - 12px);
  margin-bottom: 16px;
}

.TableCardRowFullWidth{
  flex: 0 0 100%;
}

.TableCardsContainer{
  padding: 58px 16px 16px;
  max-height: 100vh;
  overflow-y: auto;
}

.TableCardKey{
  .OverlineText;
  color: #868E96;
  margin-bottom: 2px;
}

.TableCardCollapsible{
  .ant-collapse,
  .ant-collapse-content,
  .ant-collapse > .ant-collapse-item{
    background: transparent;
    border: none;
  }

  .ant-collapse-header{
    padding: 18px 16px;
    border-bottom: 1px solid #E9ECEF;
  }

  .ant-collapse-content-box{
    border-bottom: 1px solid #E9ECEF;
  }

  .ant-collapse-arrow{
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background: #F1F3F5;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
}

.TableCardCollapsibleHeader {
  position: relative;
  padding-left: 8px;
}