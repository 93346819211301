.TableFilterSortCheckBoxWrapper {
  width: 132px;

  span {
    margin-top: -2px;
    color: @shark-500 !important;
  }
}

.TableFilterSortCheckBoxWrapper:hover {
  cursor: pointer;
}