

.QiwiStatusTableColumn {
  width: 80px;
  border-radius: @pd-4;

  span {
    &:extend(.PaddingLeft12);
    &:extend(.PaddingTop6);
  }
}

.QiwiStatusTableComment {
  &:extend(.PaddingLeft6);
  &:extend(.PaddingTop6);
  &:extend(.PaddingRight6);

  border-top-right-radius: @pd-4;
  border-bottom-right-radius: @pd-4;
  cursor: default !important;
}

.OrderQiwiTableComment {
  height: 24px;
}

.QiwiStatus {
  &-pending {
    background-color: @orange-10;

    span {
      color: @orange-600 !important
    }

    .QiwiStatusTableComment {
      background-color: @orange-100;

      svg {
        path {
          fill: @orange-500;
        }
        circle {
          stroke: @orange-500;
        }
      }
    }

    .QiwiStatusTableComment:hover {

      svg {
        path {
          fill: @orange-600;
        }
        circle {
          stroke: @orange-600;
        }
      }
    }
  }

  &-approved {
    background-color: @green-10;

    span {
      color: @green-600 !important
    }

    .QiwiStatusTableComment {
      background-color: @green-100;

      svg {
        path {
          fill: @green-500 !important
        }
        circle {
          stroke: @green-500 !important
        }
      }
    }
  }

  &-rejected {
    background-color: @red-10;

    span {
      color: @red-600 !important
    }

    .QiwiStatusTableComment {
      background-color: @red-100;

      svg {
        path {
          fill: @red-500 !important
        }
        circle {
          stroke: @red-500 !important
        }
      }
    }

  }

  &-canceled {
    background-color: @orange-10;

    span {
      color: @orange-600 !important
    }

    .QiwiStatusTableComment {
      background-color: @orange-100;

      svg {
        path {
          fill: @orange-500 !important
        }
        circle {
          stroke: @orange-500 !important
        }
      }
    }
  }

  &-new {
    background-color: @gray-100;

    span {
      color: @gray-700 !important
    }

    .QiwiStatusTableComment {
      background-color: @gray-200;

      svg {
        path {
          fill: @gray-600 !important
        }
        circle {
          stroke: @gray-600 !important
        }
      }
    }
  }
}