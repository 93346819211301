.NotActiveFilterWrapper {
  display: grid;
  place-items: center;
  padding-top: 5%;
}

.NotActiveFilterContent {
  max-width: 504px;
}

.NotActiveFilterTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  color: #000000;
  max-width: 800px;
  padding-bottom: 26px;
}

.NotActiveFilterDescription {
  font-family: Lato, sans-serif ;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  color: #8E8E93;
  max-width: 470px;
  padding-top: 52px;
}

.NotActiveFilterDescription .TextDefault{
  .TextTwo;
  color: #808185;
}

@media screen and (max-width: 920px){
  .NotActiveFilterWrapper {
    width: 100vw !important;
  }

  .NotActiveFilterTitle {
    width: calc(100vw - 64px);
    font-size: 18px !important;
  }

  .NotActiveFilterDescription {
    font-size: 14px;
    width: calc(100vw - 64px);
  }

  .NotActiveFilterIconWrapper {
    svg {
      width: calc(100vw - 135px);
      height: calc(100vw - 135px);
    }
  }
}
