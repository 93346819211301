.NotifiCrudModalDeletedOverlay {
  z-index: 18 !important;
}

.NotifiDeleteModalWrapper {
  position: fixed;
  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  border-radius: 16px;
  z-index: 19 !important;
  top: calc(50% - 120px);
  left: calc(50% - 80px);
  width: 510px;
  padding: 32px;
}

.NotifiDeleteModalTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 16px;
  color: #212529;
  padding-left: 7px;
}

.NotifiDeleteModalText {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
  width: 360px;
  padding-top: 20px;
  padding-bottom: 32px;
  padding-left: 7px;
}

.NotifiDeleteModalButtons {
  padding-left: 186px;
}

.NotifiDeleteModalButtonsFirst {
  padding-right: 8px;
}

.NotifiDeleteModalSuccessWrapper {
  position: fixed;
  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  border-radius: 16px;
  z-index: 19 !important;
  top: calc(50% - 120px);
  left: calc(50% - 255px);
  width: 510px;
  padding: 32px;
}

.NotifiDeleteModalSuccessTitle {
  width: 100%;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #212529;
  padding-bottom: 24px;
}

.NotifiDeleteModalSuccessIcon {
  margin: 0 auto;
  padding-bottom: 6px;
}

@media screen and (max-width: 920px) {
  .NotifiDeleteModalWrapper {
    left: 8px !important;
    width: calc(100vw - 16px);
  }

  .NotifiDeleteModalButtons {
    padding-left: 0 !important;
  }
}