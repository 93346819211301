.MessagesRouteFilterContent {
  padding-top: 24px;
}
.MainFilterReset {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #228BE6;
  margin-left: auto;
  padding-top: 3px;
}

.MainFilterReset:hover {
  cursor: pointer;
  opacity: 0.7;
}