@media screen and (min-width: 921px) {
  .SportsBroadcast__ModalWrapper {
    padding: 0 @pd-16;
  }

  .SportsBroadcast__ModalWrapper_FormItem_Button > .ant-form-item {
    margin-bottom: 16px !important;
  }
}

.SportsBroadcast__ModalWrapper_FormItem .ant-form-item {
  margin-bottom: 18px !important;
}

@media screen and (max-width: 920px) {
  .SportsBroadcast__ModalWrapper {
    margin-top: -24px;

    .HeadlineFirstText {
      padding-bottom: 14px !important;
    }
  }

  .SportsBroadcast__ModalWrapper_FormItem > .ant-form-item {
    margin-bottom: 14px !important;
  }

  .SportsBroadcast__ModalWrapper_FormItem_Button > .ant-form-item {
    margin-bottom: 0 !important;
  }
}