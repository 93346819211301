.GuestHistoryBetsTableWrapper {
  padding-top: 16px;
}

.GuestHistoryBetsTableHeader {
  gap: 68px;
}

.GuestHistoryBetsTableHeaderDescription {
  gap: 16px;
}

@media screen and (max-width: 920px){
  .GuestHistoryBetsTableHeaderDescription {
    flex-direction: column !important;
    gap: 8px !important;
  }

  .GuestHistoryBetsTableHeader {
    padding-top: 8px;
    flex-direction: column !important;
    gap: 8px !important;
  }
}

.GuestHistoryBetsTableHeaderDescriptionItem {
  color: #868E96;

  svg {
    margin-top: 2px;
    margin-right: 4px;
  }
}

