.ChatSendMessageContainer {
  display: flex;
  flex-direction: column;
  padding: 17px 12px;
  gap: 10px;
  border-top: 1px solid #DEE2E6;

  .InputWrapper {
    width: 100% !important;
    textarea {
      border: none !important;
    }
  }


  .ChatQuoteWrapperContainer {
    margin-left: 43px;
    display: flex;
    flex-direction: row;
    .ChatQuoteWrapper {
      width: 100%;
    }
    .ChatQuoteCancelIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;

      path {
        fill: #475058;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}


.ChatWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 87vh;
}

.ChatAttachIcon:hover,
.ChatSendIconActive:hover {
  cursor: pointer;
}

.ChatSendIcon {
  opacity: 0.5;
}

.ChatSendIconActive {
  opacity: 1;
}

.MessagesWrapper {
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 23px;
}

.ChatIcon {
  width: 40px;
  height: 40px;
  min-height: 40px;
  min-width: 40px;
  background-color: green;
  border-radius: 50%;
  //display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 10px;
  letter-spacing: 1px;
  text-align: center;
  color: #FFFFFF;
}

.ChatRoleDateTextContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ChatMessageContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.RoleDateWrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.ChatRole {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.ChatDate {
  font-family: Lato;
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  color: #868E96;
}

.ChatInput {
  width: 100%;
  overflow: visible !important;
}


.ChatTextWrapper {
  background: #F4F4F4;
  border-radius: 0px 16px 16px 16px;
  padding: 16px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;

}

.ChatMessageContainerRight {
  align-self: flex-end;
  .ChatTextWrapper {
    border-radius: 16px 0px 16px 16px;
  }

  .RoleDateWrapper {
    justify-content: end;
  }
}

.ChatQuoteWrapper {
  border-left: 4px solid #FCE000;
  padding-left: 10px;
}

.ChatTextWrapperQuote {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.ChatTextWrapperRole {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.ChatReplyIconContainer {
  //transition: all 200ms ease-out 100ms;
  visibility: hidden;
  width: 32px;
  height: 32px;
  background: #F4F4F4;
  border: 1px solid #FFFFFF;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -17px;
  right: 10px;
  svg {
    height: 14px;
    width: 16px;
  }
  &:hover {
    cursor: pointer;
  }
}

.ChatTextContainer {
  position: relative;
  &:hover {
    .ChatReplyIconContainer {
      visibility: visible;
    }
  }
}

.ChatSendMessageContainerChildren {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}







