.NewDashboard {
  padding: 16px 8px;
  margin-left: -8px;

  overflow: auto;

  &_ItemWithMore {
    padding: 16px 16px 14px 16px !important;
  }

  &_Item {
    position: relative;

    min-width: 224px;

    padding: 16px;
    border-radius: 16px;

    &_Title {
      height: 40px;
    }

    &_InfoWrapper {
      position: absolute;
      right: 0;
      top: 0;

      padding: 8px 8px 2px 8px;
      border-radius: 0 16px;
      background: rgba(255, 255, 255, 0.50);

      .TooltipComponentTargetWrapper {
        svg path {
          fill: #868E96;
        }
      }
    }

    &_Loading {
      position: relative;
    }

    &_Loading .ant-skeleton .ant-skeleton-button {
      width: 224px;
      height: 132px;

      padding: 16px;
      border-radius: 16px;
      background-image: linear-gradient(99deg, @gray-200 10.65%, rgba(233, 236, 239, 0.20) 51.69%, @gray-200 89.6%);
    }
  }
}

.Dashboard_Item_Loading_Wrapper {
  position: absolute;
  top: 0;

  width: 224px;
  height: 132px;

  padding: 16px
}


.Dashboard_Item_Loading_Icon {
  width: 40px;
  height: 40px;

  background: @gray-300 !important;
  border-radius: 8px;
}

.Dashboard_Item_Loading_Label {
  width: 140px;
  height: 14px;

  border-radius: 4px;
  background: @gray-300;
}

.Dashboard_Item_Loading_Description {
  width: 192px;
  height: 29px;

  border-radius: 4px;
  background: @gray-300;
}

.Dashboard_Item_Loading_Value {

}

.NewDashboard::-webkit-scrollbar {
  display: none !important;
}

.NewDashboard_Item_ValueSkeleton, .NewDashboard_Item_Value {
  width: 100% !important;
  height: 28px;
}

.NewDashboard_Item_Title {
  letter-spacing: 0 !important;
  word-spacing: normal !important;
  white-space: pre-line !important;
}

.NewDashboard_Item_Clickable:hover {
  cursor: pointer;
  transform: scale(1.05);
  transform-origin: center;
}

.NewDashboard_Item_MoreButton {
  border-radius: 8px;
  border: 1px solid @gray-500;
  padding: 7px 14px;
  margin-top: -1.5px;
}

.NewDashboard_Item_MoreButton:hover {
  cursor: pointer;
}

// Old Styles

.ComponentDashboardWrapper {
  display: flex;
  flex-direction: row;
  max-width: 1400px;
  overflow-x: auto;
  gap: 24px;
}

.ComponentDashboardWrapper::-webkit-scrollbar {
  display: none !important;
}

.ComponentDashboardItem {
  margin-top: 12px;
  margin-bottom: 12px;
  max-height: fit-content !important;
}

@media screen and (min-width: 921px) {
  .ComponentDashboardItemClickable:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  .ComponentDashboardItem {
    width: 224px;
    min-height: 112px;
    min-width: 224px;
    padding: 11px 9px 16px 14px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
  }

  .DashboardItemLabelPosition {
    display: flex;
    flex-direction: row;
  }
}

@media screen and (max-width: 920px) {

  .ComponentDashboardItem {
    padding: 16px 9px 16px 16px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    min-width: 205px;
    min-height: 112px;
  }

  .ComponentDashboardWrapper {
    padding: 0 5px;
  }

  .DashboardItemLabelPosition {
    display: flex;
    flex-direction: row !important;
  }
}

.DashboardItemCurrencyContainer {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 1680px) {
  .ComponentDashboardItem {
    width: 224px;
    min-height: 112px;
  }

  span.DashboardItemLabelValue, span.DashboardItemLabelCurrency {
    font-size: 24px;
    line-height: 30px;
  }

  .DashboardItemLabel {
    font-size: 14px !important;
    line-height: 120% !important;
  }

  .DashboardItemIcon {
    width: 48px !important;
  }
}

.DashboardItemLabel {
  max-width: 142px;
  padding: 0 20px 0 4px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  align-items: center;
  letter-spacing: 0.2px;
  color: #2B2D33;
}

.DashboardItemLabel__Date {
  color: @gray-600;
}

.DashboardItemLabelTight {
  max-width: 110px !important;
}

span.DashboardItemLabelValue, span.DashboardItemLabelCurrency {
  padding-left: 4px;
  font-family: Gilroy, sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #000000;
}

.DashboardItemLabelValueDecimal {
  font-family: Gilroy, sans-serif !important;
  font-style: normal;
  letter-spacing: -0.2px;
  color: #000000;
  padding-top: 16px;
  font-weight: bold;
  font-size: 18px !important;
  line-height: 24px !important;
}

.DashboardItemDescription {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.4px;
  color: #8E8E93;
}

.DashboardItemInfoWrapper {
  width: 16px;
  margin-left: auto;
  position: relative;
  align-self: flex-start;

  .TooltipComponentTargetWrapper{
    position: relative;
    top: -2px;
    right: 1px;
  }
}

.DashboardItemLabelContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 14px;
}

.DashboardItemIconWrapper{
  width: 40px;
  min-width: 40px;
}

.DashboardItemIcon {
  width: 100%;
}

.DashboardItemMoreButton {
  padding: 3px 12px;

  margin: 2px 0 2px 16px;

  border: 1px solid #CED4DA;
  border-radius: 4px;
}

.DashboardItemMoreButton:hover, .ComponentDashboardItemClickable:hover {
  opacity: 0.8;
  cursor: pointer;
}