@yellow-900: #E67700;
@yellow-800: #F08C00;
@yellow-700: #F59F00;
@yellow-600: #FAB005;
@yellow-500: #FCC419;
@yellow-400: #FFD43B;
@yellow-300: #FFE066;
@yellow-200: #FFEC99;
@yellow-100: #FFF3BF;
@yellow-10: #FFF9DB;

// Color
.Yellow900Color {
  color: @yellow-900 !important;
}

.Yellow800Color {
  color: @yellow-800 !important;
}

.Yellow700Color {
  color: @yellow-700 !important;
}

.Yellow600Color {
  color: @yellow-600 !important;
}

.Yellow500Color {
  color: @yellow-500 !important;
}

.Yellow400Color {
  color: @yellow-400 !important;
}

.Yellow300Color {
  color: @yellow-300 !important;
}

.Yellow200Color {
  color: @yellow-200 !important;
}

.Yellow100Color {
  color: @yellow-100 !important;
}

.Yellow10Color {
  color: @yellow-10 !important;
}

// BackgroundColor
.Yellow900Background {
  background-color: @yellow-900 !important;
}

.Yellow800Background {
  background-color: @yellow-800 !important;
}

.Yellow700Background {
  background-color: @yellow-700 !important;
}

.Yellow600Background {
  background-color: @yellow-600 !important;
}

.Yellow500Background {
  background-color: @yellow-500 !important;
}

.Yellow400Background {
  background-color: @yellow-400 !important;
}

.Yellow300Background {
  background-color: @yellow-300 !important;
}

.Yellow200Background {
  background-color: @yellow-200 !important;
}

.Yellow100Background {
  background-color: @yellow-100 !important;
}

.Yellow10Background {
  background-color: @yellow-10 !important;
}

// BorderColor
.Yellow900Border{
  border-color: @yellow-900 !important;
}

.Yellow800Border{
  border-color: @yellow-800 !important;
}

.Yellow700Border{
  border-color: @yellow-700 !important;
}

.Yellow600Border{
  border-color: @yellow-600 !important;
}

.Yellow500Border{
  border-color: @yellow-500 !important;
}

.Yellow400Border{
  border-color: @yellow-400 !important;
}

.Yellow300Border{
  border-color: @yellow-300 !important;
}

.Yellow200Border{
  border-color: @yellow-200 !important;
}

.Yellow100Border{
  border-color: @yellow-100 !important;
}

.Yellow10Border{
  border-color: @yellow-10 !important;
}
