@cyan-900: #0B7285;
@cyan-800: #0C8599;
@cyan-700: #1098AD;
@cyan-600: #15AABF;
@cyan-500: #22B8CF;
@cyan-400: #3BC9DB;
@cyan-300: #66D9E8;
@cyan-200: #99E9F2;
@cyan-100: #C5F6FA;
@cyan-10: #E3FAFC;

// Color
.Cyan900Color {
  color: @cyan-900 !important;
}

.Cyan800Color {
  color: @cyan-800 !important;
}

.Cyan700Color {
  color: @cyan-700 !important;
}

.Cyan600Color {
  color: @cyan-600 !important;
}

.Cyan500Color {
  color: @cyan-500 !important;
}

.Cyan400Color {
  color: @cyan-400 !important;
}

.Cyan300Color {
  color: @cyan-300 !important;
}

.Cyan200Color {
  color: @cyan-200 !important;
}

.Cyan100Color {
  color: @cyan-100 !important;
}

.Cyan10Color {
  color: @cyan-10 !important;
}

// BackgroundColor
.Cyan900Background {
  background-color: @cyan-900 !important;
}

.Cyan800Background {
  background-color: @cyan-800 !important;
}

.Cyan700Background {
  background-color: @cyan-700 !important;
}

.Cyan600Background {
  background-color: @cyan-600 !important;
}

.Cyan500Background {
  background-color: @cyan-500 !important;
}

.Cyan400Background {
  background-color: @cyan-400 !important;
}

.Cyan300Background {
  background-color: @cyan-300 !important;
}

.Cyan200Background {
  background-color: @cyan-200 !important;
}

.Cyan100Background {
  background-color: @cyan-100 !important;
}

.Cyan10Background {
  background-color: @cyan-10 !important;
}

// BorderColor
.Cyan900Border{
  border-color: @cyan-900 !important;
}

.Cyan800Border{
  border-color: @cyan-800 !important;
}

.Cyan700Border{
  border-color: @cyan-700 !important;
}

.Cyan600Border{
  border-color: @cyan-600 !important;
}

.Cyan500Border{
  border-color: @cyan-500 !important;
}

.Cyan400Border{
  border-color: @cyan-400 !important;
}

.Cyan300Border{
  border-color: @cyan-300 !important;
}

.Cyan200Border{
  border-color: @cyan-200 !important;
}

.Cyan100Border{
  border-color: @cyan-100 !important;
}

.Cyan10Border{
  border-color: @cyan-10 !important;
}
