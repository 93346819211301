@import './Components/StatusesCheckBoxFilter/styles';

.SideFilterComponentWrapper {
  display: flex;
  flex-direction: column;
  background: #fff;
}

.SideFilterContentLabel {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
}

.SideFilterField {
  padding-bottom: 16px;

  .SideFilterContentLabel {
    padding-bottom: 8px;
  }
}

.SideFilterReset {
  font-family: Gilroy, sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #228BE6;
  padding-top: 3px;
  margin-left: 10px;
}

.SideFilterReset:hover {
  cursor: pointer;
  opacity: 0.7;
}