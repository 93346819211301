@import "./Table/styles";
@import "./UserCrud/styles";

.StatusNotifyModal > .ModalRightDetail > .ModalRightDetailClose {
  top: 12px;
}

.StatusNotifyHallPicker {
  max-width: 760px;
  overflow-x: auto;

  &:extend(.PaddingTop24);
  &:extend(.MarginLeft24);
  &:extend(.PaddingBottom12);
  &:extend(.PaddingMargin24);
}

.StatusNotifyHallPickerPending {
  .StatusNotifyHallPickerItem {
    background-color: @gray-100 !important;
    cursor: auto !important;
  }
}

.StatusNotifyHallPickerItem {
  border-radius: @pd-8;
  border: 1px solid @gray-200;

  padding: @pd-12 @pd-16;
}

.StatusNotifyHallPickerItem:hover {
  cursor: pointer;
  border-color: @lemon-500;

}

.StatusNotifyHallPickerItemActive {
  border-color: @lemon-500 !important;
}


@media screen and (max-width: 920px) {
  .StatusNotifyHallPicker::-webkit-scrollbar {
    display: none !important;
  }
}