.TopThreePlaceWrapper {
  margin-left: 16px;
  width: 24px;
  height: 24px;
  text-align: center;
  border-radius: @pd-4;
  padding: @pd-4 0 ;
}

.TopTreeGameImg {
  height: 24px;
  width: 24px;
}