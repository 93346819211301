@indigo-900: #364FC7;
@indigo-800: #3B5BDB;
@indigo-700: #4263EB;
@indigo-600: #4C6EF5;
@indigo-500: #5C7CFA;
@indigo-400: #748FFC;
@indigo-300: #91A7FF;
@indigo-200: #BAC8FF;
@indigo-100: #DBE4FF;
@indigo-10: #EDF2FF;

// Color
.Indigo900Color {
  color: @indigo-900 !important;
}

.Indigo800Color {
  color: @indigo-800 !important;
}

.Indigo700Color {
  color: @indigo-700 !important;
}

.Indigo600Color {
  color: @indigo-600 !important;
}

.Indigo500Color {
  color: @indigo-500 !important;
}

.Indigo400Color {
  color: @indigo-400 !important;
}

.Indigo300Color {
  color: @indigo-300 !important;
}

.Indigo200Color {
  color: @indigo-200 !important;
}

.Indigo100Color {
  color: @indigo-100 !important;
}

.Indigo10Color {
  color: @indigo-10 !important;
}

// BackgroundColor
.Indigo900Background {
  background-color: @indigo-900 !important;
}

.Indigo800Background {
  background-color: @indigo-800 !important;
}

.Indigo700Background {
  background-color: @indigo-700 !important;
}

.Indigo600Background {
  background-color: @indigo-600 !important;
}

.Indigo500Background {
  background-color: @indigo-500 !important;
}

.Indigo400Background {
  background-color: @indigo-400 !important;
}

.Indigo300Background {
  background-color: @indigo-300 !important;
}

.Indigo200Background {
  background-color: @indigo-200 !important;
}

.Indigo100Background {
  background-color: @indigo-100 !important;
}

.Indigo10Background {
  background-color: @indigo-10 !important;
}

// BorderColor
.Indigo900Border{
  border-color: @indigo-900 !important;
}

.Indigo800Border{
  border-color: @indigo-800 !important;
}

.Indigo700Border{
  border-color: @indigo-700 !important;
}

.Indigo600Border{
  border-color: @indigo-600 !important;
}

.Indigo500Border{
  border-color: @indigo-500 !important;
}

.Indigo400Border{
  border-color: @indigo-400 !important;
}

.Indigo300Border{
  border-color: @indigo-300 !important;
}

.Indigo200Border{
  border-color: @indigo-200 !important;
}

.Indigo100Border{
  border-color: @indigo-100 !important;
}

.Indigo10Border{
  border-color: @indigo-10 !important;
}
