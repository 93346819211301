.ConsolidatedInsideWrapper {
  padding-top: 12px;
  margin-right: 32px;
  background-color: #fff;
  overflow-x: auto;
}

.ReportTableInsideWrapper {
  background-color: #fff;
  padding: 0 60px 24px 60px;
}

.ReportTableInsideContent {
  margin-left: 32px;
  margin-top: 16px;
  box-sizing: border-box;
  display: inline-flex;
}

.ConsolidatedInsideTableBonus {
  border-top: 1px solid #E9EAEA;
  border-left: 1px solid #E9EAEA;
  border-bottom: 1px solid #E9EAEA;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.ConsolidatedInsideTableMoney {
  border-top: 1px solid #E9EAEA;
  border-bottom: 1px solid #E9EAEA;
}

.ConsolidatedInsideTableFreebet {
  border-top: 1px solid #E9EAEA;
  border-right: 1px solid #E9EAEA;
  border-bottom: 1px solid #E9EAEA;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ConsolidatedInsideTableHeader {
  padding: 16px 0;

  svg {
    margin-top: 3px;
  }

  span {
    padding-left: 4px;
  }
}


.ConsolidatedInsideHallTitle {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #2B2D33;
  margin: 12px 32px;
}

.ReportInsideHallInfo {
  margin-left: 10px;
}

.ReportInsideHallInfo:hover {
  cursor: pointer;
}

.ConsolidatedInsideTableItem {
  min-width: 210px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #2B2D33;
  padding: 16px 24px;
}

.ConsolidatedInsideTableItemHeader {
  background: #F8F8F8 !important;
  border-bottom: 1px solid #E9EAEA;
}
