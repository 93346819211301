@media screen and (max-width: 920px) {
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px !important;
  }
}

.InputWrapper > .ant-input,
.InputWrapper > .ant-input-number,
.InputWrapper > .ant-input-affix-wrapper,
.InputWrapper .ant-input-number-input {
  height: 48px !important;
  border-radius: 8px !important;

  &::placeholder {
    font-family: Lato, sans-serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #ADB5BD !important;
  }
}

.InputMiddleWrapper > .ant-input-affix-wrapper {
  height: 40px !important;
  padding: 10px 12px !important;
}

.InputWrapper > .ant-input-affix-wrapper {
  padding: 12px !important;
}


.InputWrapper > .ant-input-affix-wrapper > .ant-input {

  &::placeholder {
    font-family: Lato, sans-serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: #ADB5BD !important;
  }
}

.InputWrapper > .ant-input:hover,
.InputWrapper > .ant-input-affix-wrapper:hover,
.InputWrapper > .ant-input-number:hover {
  border-color: #212529 !important;
}

.InputWrapper > .ant-input:focus,
.InputWrapper > .ant-input-focused,
.InputWrapper > .ant-input-affix-wrapper-focused,
.InputWrapper > .ant-input:focus,
.InputWrapper > .ant-input-number-focused,
textarea.ant-input:focus {
  box-shadow: 0 0 0 0 #212529 !important;
  border-color: #212529 !important;
}

.InputWrapper {
  .ant-input-disabled {
    color: #ADB5BD !important;
  }

  .ant-input-prefix {
    margin-right: 10px;
  }
}

.ViewInputWrapper .ant-input-affix-wrapper-disabled,
.ViewInputWrapper .ant-input-disabled {
  color: #1D1F21 !important;
  background-color: white !important;
}

.InputWrapper {
  .ant-input-number-handler-up {
    border-top-right-radius: 4px;
  }

  .ant-input-number-handler-down {
    border-bottom-right-radius: 4px;
  }

  .ant-input-number {
    &-handler {
      &-wrap {
        border-radius: 0 4px 4px 0;
      }

      &:hover {
        background: #f8e800;

        .anticon {
          color: #1D1F21;
        }
      }
    }
  }
}

.InputLabel {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #868E96;
  padding-bottom: 6px;
}

.ErrorInputWrapper .ErrorText {
  font-size: 10px;
  color: #e80012;
  margin-top: 2px;
  margin-bottom: -16px;
}
.ErrorInputWrapper .ant-input,
.ErrorInputWrapper .ant-input-affix-wrapper {
  border-color: #e80012 !important;
}

.InputWrapperGreyBackground .ant-input,
.InputWrapperGreyBackground .ant-input-affix-wrapper,
.InputWrapperGreyBackground .ant-input-number{
  background-color: #f8f9fa !important;
}

.InputWrapperControlsHidden {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}

.InputNumberClearWrapper {
  position: relative;

  .InputNumberClear {
    width: 100%;

    .ant-input-number-handler-wrap {
      display: none !important;
    }
  }

  .InputNumberClearSuffix {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}

.InputNumberClearWrapper .InputNumberClear
.ant-input-number-input-wrap .ant-input-number-input {
  width: calc(100% - 32px) !important;
}

.ErrorsContainer {
  display: flex;
  flex-direction: column;
}
