@gray-900: #212529;
@gray-800: #343A40;
@gray-700: #495057;
@gray-600: #868E96;
@gray-500: #ADB5BD;
@gray-400: #CED4DA;
@gray-300: #DEE2E6;
@gray-200: #E9ECEF;
@gray-100: #F1F3F5;
@gray-10: #F8F9FA;

// Color
.Gray900Color {
  color: @gray-900 !important;
}

.Gray800Color {
  color: @gray-800 !important;
}

.Gray700Color {
  color: @gray-700 !important;
}

.Gray600Color {
  color: @gray-600 !important;
}

.Gray500Color {
  color: @gray-500 !important;
}

.Gray400Color {
  color: @gray-400 !important;
}

.Gray300Color {
  color: @gray-300 !important;
}

.Gray200Color {
  color: @gray-200 !important;
}

.Gray100Color {
  color: @gray-100 !important;
}

.Gray10Color {
  color: @gray-10 !important;
}

// BackgroundColor
.Gray900Background {
  background-color: @gray-900 !important;
}

.Gray800Background {
  background-color: @gray-800 !important;
}

.Gray700Background {
  background-color: @gray-700 !important;
}

.Gray600Background {
  background-color: @gray-600 !important;
}

.Gray500Background {
  background-color: @gray-500 !important;
}

.Gray400Background {
  background-color: @gray-400 !important;
}

.Gray300Background {
  background-color: @gray-300 !important;
}

.Gray200Background {
  background-color: @gray-200 !important;
}

.Gray100Background {
  background-color: @gray-100 !important;
}

.Gray10Background {
  background-color: @gray-10 !important;
}

// BorderColor
.Gray900Border{
  border-color: @gray-900 !important;
}

.Gray800Border{
  border-color: @gray-800 !important;
}

.Gray700Border{
  border-color: @gray-700 !important;
}

.Gray600Border{
  border-color: @gray-600 !important;
}

.Gray500Border{
  border-color: @gray-500 !important;
}

.Gray400Border{
  border-color: @gray-400 !important;
}

.Gray300Border{
  border-color: @gray-300 !important;
}

.Gray200Border{
  border-color: @gray-200 !important;
}

.Gray100Border{
  border-color: @gray-100 !important;
}

.Gray10Border{
  border-color: @gray-10 !important;
}
