@import "./Metrics/styles";
@import "./Cashbox/styles";
@import "./Bets/styles";

.GuestHistoryReportsWrapper {
  background: #FFFFFF;
  border: 1px solid #DEE2E6;
  border-radius: 16px;

  padding: 32px 24px;
  margin-top: 32px;
}

.ChartTooltip {
  padding: 20px 24px;
  background-color: #434345
  !important; margin: -10px
  !important; border-radius: 10px
}

.ChartTooltipItem {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-bottom: 12px
}