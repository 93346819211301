.BalanceTableComment {
  max-width: 318px;
}

.BalanceTableCommentModal {
  width: 544px;
  background: #FFFFFF;
  border-radius: 16px;
}

.BalanceTableCommentModalTitle {
  padding: 26px 32px;
  border-bottom: 1px solid #E5E5EA;
}

.BalanceTableCommentModalBody {
  padding: 12px 32px;
  height: 240px;
  max-height: 240px;
  overflow-y: auto;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.2px;
  color: #3A3A3C;
}

.BalanceTableCommentModalClose {
  padding: 26px 32px;
  border-top: 1px solid #E5E5EA;

  .ButtonPrimaryWrapper {
    margin-left: auto;
    width: 256px;

    button {
      width: 256px;
    }
  }
}

.BalanceTableCommentMore {
  color: #228be6;
  padding-left: 2px;
}
