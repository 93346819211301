@import "./Games/styles";

.GameFilterComponentWrapper {
  max-height: calc(100vh - 112px);
  overflow: hidden;
  overflow-y: auto;
}

.GameFilterContentWrapper {
  padding-top: 32px;
}

.MainFilterCheckboxGroup {
  margin-top: -8px;
  padding-bottom: 24px;
}

.MainFilterCheckBoxGroupItem {
  padding: 8px 0;
}
