.MainModalWrapper {
  position: fixed;
  top: 0;
  z-index: 20;
  transition: transform 500ms ease, width 500ms ease;
  box-sizing: border-box;
  right: 0;
  transform: translateX(0);
  width: 613px;
}

.MainModalWrapperWide {
  width: calc(100vw - 360px);
  max-width: calc(100vw - 360px);
}

.MainModalWrapperUnActive {
  transform: translateX(100%);
}

.MainModalWrapperActive {
  box-shadow: -8px 0px 16px rgba(0, 0, 0, 0.08);
}

@media screen and (max-width: 1440px){
  .MainModalWrapperWide {
    width: calc(100vw - 280px);
    max-width: calc(100vw - 280px);
  }
}

@media screen and (min-width: 921px) {
  .MainModalWrapper {
    height: 100vh;
    overflow-y: auto;
  }
}

.ContactsEdit {
  font-size: 16px;
  background: #FFFFFF;
  border-left: 1px solid #E5E5EA;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.ContactsEdit_top {
  padding: 40px 23px 23px;
}

.ContactsEdit_actionsModalHidden {
  display: none;
}

.ContactsEdit_actionsModalActive {
  display: block;
}

.ContactsEdit {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
}

.ContactsEdit_header {
  font-family: 'Gilroy';
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #212529;
  margin-bottom: 37px;
}

.ContactsEdit_closeIcon {
  position: absolute;
  top: 37px;
  right: 23px;
  cursor: pointer;
}

.ContactsEdit_header_arrBack {
  padding-left: 77px;

  .ContactsEdit_returnIcon {
    position: absolute;
    top: 36px;
    left: 24px;
  }
}

.ContactsEdit_returnIcon {
  width: 48px;
  height: 48px;
  border: 1px solid #CED4DA;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ContactsEdit .CheckboxText,
.ContactsEdit_fieldname,
.CheckboxWrapper span {
  font-family: 'Gilroy';
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #212529;
}

.ContactsEdit_fieldname {
  margin-top: 24px;
  margin-bottom: 6px;
}

.MainModalWrapper .ContactsEdit_position
.InputWrapper > textarea.ant-input,
.InputWrapper > textarea.ant-input-number,
.InputWrapper > textarea.ant-input-affix-wrapper,
.InputWrapper textarea.ant-input-number-input {
  min-height: 79px !important;
}


.MainModalWrapper {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .InputWrapper > .ant-input,
  .InputWrapper > .ant-input-number,
  .InputWrapper > .ant-input-affix-wrapper,
  .InputWrapper .ant-input-number-input {
    border-color: #CED4DA;
    background-color: #F8F9FA;
    color: #212529 !important;
    font-size: 16px;
  }

  .InputWrapper > textarea.ant-input,
  .InputWrapper > textarea.ant-input-number,
  .InputWrapper > textarea.ant-input-affix-wrapper,
  .InputWrapper textarea.ant-input-number-input {
    min-height: 150px !important;
  }
}

div .ContactsEdit_AnchorCheckbox {
  display: flex;
  margin-top: 24px;

  .ContactsEdit_fieldname {
    margin-top: 0;
  }
}

.ContactsEdit_btns {
  display: flex;
  padding: 20px 23px 32px;
  border-top: 1px solid #F1F3F5;

  & > div {
    margin-right: 16px;
  }

  .ant-btn.ContactsBtn,
  .ant-btn.ant-btn-primary.ContactsBtn {
    width: 200px;
    height: 48px;
    font-size: 16px;

    span {
      font-size: 16px;
    }
  }

  .ant-btn-primary .ant-btn,
  .ButtonPrimaryWrapper .ant-btn {
    background: #FCE000;

    &:hover,
    &:focus,
    &:active {
      background: #FCDA00;
    }
  }

  .ant-btn.ant-btn-default.ContactsBtn {
    background: #F8F9FA;
    border: 1px solid #CED4DA;

    &:hover,
    &:focus,
    &:active {
      background: #F8F9FA;
      border: 1px solid #495057;
      box-shadow: none;
    }
  }

  .ButtonIconWrapper .ant-btn {
    padding-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;


    svg {
      position: static;
      margin-right: 10px;
    }
  }
}


.ant-select-dropdown .ContactSectionModal_dropdown {
  .ant-select-item-option {
    font-size: 16px;
    line-height: 22px;
  }

  .ButtonDefaultWrapper {
    margin: 12px 0;

    .ant-btn {
      background: none;
      border: none;
      display: block;
      color: #228BE6;
      height: auto;
      width: 100%;
      text-align: left;
      padding: 0 20px;
      font-size: 16px;

      span {
        color: #228BE6;
        font-size: 16px;
      }

      &:hover span {
        color: #1D1F21;
      }
    }
  }
}

.ContactsEdit_titleInputWrapper {
  position: relative;
}

.ContactsEdit_titleInputCancel {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }
}

.ContactsEdit_inputsColumnWrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 24px;
}

.ContactsEdit_inputsColumn {
  flex: 0 0 calc(50% - 18px);
  max-width: calc(50% - 18px);

  .InputWrapper textarea {
    max-width: 566px;
  }
}

.ContactsEdit_table {
  margin-top: 32px;

  .ant-table-column-has-sorters .ant-table-column-sorters,
  .ant-table-thead > tr > th {
    background: #FFF4E6 !important;
  }

  .ant-table-thead > tr > th:first-child,
  .ant-table-tbody .ant-table-row > td:first-child
  {
    padding-left: 16px !important;
  }

  .ant-table-thead > tr > th:last-child,
  .ant-table-tbody .ant-table-row > td:last-child
  {
    padding-right: 16px !important;
  }
  .ant-table-content table{

    .ant-table-thead > tr > th:first-child{
      border-top-left-radius: 8px;
    }

    .ant-table-thead > tr > th:last-child{
      border-top-right-radius: 8px;
    }

    .ant-table-tbody > tr:last-child > td:first-child{
      border-bottom-left-radius: 8px;
    }

    .ant-table-tbody > tr:last-child > td:last-child{
      border-bottom-right-radius: 8px;
    }
  }

  .TableComponentWrapper {
    overflow-x: unset;

    .ant-table {
      min-width: 1390px;
    }
  }

  .ant-table-thead > tr > th {
    word-spacing: unset;
    height: 56px;
    font-family: 'Gilroy';
    font-weight: 600 !important;
    font-size: 14px;
    line-height: 17px;
    padding: 0 6px !important;
  }

  .ant-table-tbody .ant-table-row > td {
    height: 56px;
    padding: 0 6px !important;
  }

  .ant-table-tbody > tr > td {
    border-bottom-color: #E9ECEF;
    position: relative;

  }

  .ant-table-container table > thead th:last-child,
  .ant-table-container table .ant-table-tbody .ant-table-row td:last-child {
    text-align: right;
  }
}

.ContactsEdit_actionsModal {
  position: absolute;
  right: 0;
  top: 19px;
  z-index: 5;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 96px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 14px 18px;
}

.ContactsEdit_actionsModal_trigger {
  position: relative;
}

.ContactsEdit_actionsModal_line {
  display: flex;
  align-items: center;

  & + .ContactsEdit_actionsModal_line {
    margin-top: 25px;
  }

  svg {
    height: 16px;
    width: 16px;
    margin-right: 10px;
  }
}

.ContactsEdit_hours {
  margin-top: 6px;

  .SelectHoursWrapper {
    justify-content: space-between;

    .HourSelector {
      width: auto !important;
      flex: 0 0 calc(50% - 18px);
    }
  }
}

.ContactsTableInner {
  white-space: nowrap;
  width: 8.5vw;
  min-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ContactsTableInner_narrow {
  min-width: 113px;
  width: 6.4vw;
}

@media screen and (max-width: 1920px) {
  .ContactsEdit_table .TableComponentWrapper {
    overflow-x: auto;
    padding-bottom: 80px;
  }
}

.ContactsPageSectionEdit_icon {
  display: inline-block;
  border: 1px solid transparent;
  cursor: pointer;
  transition: border-color .25s ease;
  border-radius: 12px;
  position: relative;

  &:after{
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: -5px;
    right: -7px;
    border-radius: 50%;
  }

  &.ContactsIcon_selected,
  &:hover{
    border-color: #212529;
  }

  &.ContactsIcon_selected:after{
    background-image: url("/Components/icons/success.svg");
    background-size: contain;
    background-color: #fff;
  }
}

