.zeroPadding() {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.RaceTableColumnMainTitle {
  text-align: left;
}

.RaceDateTimeCell {
  display: flex;
  flex-direction: column;

  .FlexRow {
    align-items: center;
  }
}

.RaceGameCell_Container {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.RaceGameCell_Container_Element_Text {
  white-space: nowrap;
}

.RaceExpandedTableCell {
  padding: 12px 0;

  .RaceWinnersEmpty {
    margin-left: 20px;
    min-width: 145px;
    max-width: 145px;
  }
}

.RaceGamesTable {
  margin-top: 24px;
}

.RaceModalDetailWrapper {
  display: flex;
  flex-direction: column;
}

.RaceTableModal {
  margin-top: 20px;
}

.RaceStatus {
  padding: @pd-6 @pd-12;
  border-radius: 4px;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:extend(.StatusText);
}

.RaceStatus__cancelled {
  color: @red-600;
  background-color: @red-10;
}

.RaceStatus__pending {
  color: @green-600;
  background-color: @green-10;
}

.RaceStatus__failed, .RaceStatus__finished, .RaceStatus__waiting {
  color: @gray-700;
  background-color: @gray-100;
}

.RaceCellId {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  .TooltipComponentTargetWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.RaceExpandedTableCell__Text {
  color: @gray-700 !important;
  &:extend(.Text1);
}

.RaceErrContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  svg {
    margin-bottom: 24px;
  }
  .SubtitleSecondText {
    white-space: pre-wrap;
    text-align: center;
  }
}

.RaceErrContainerTextMain {
  margin-bottom: 8px;
  &:extend(.HeadlineSecondText);
}

.RaceExpandedTableCell__Winners {
  justify-content: end;
}

.RaceExpandedTableRow {
  align-items: center;
  margin-left: 50px;
}

.RaceExpandedTableCell_LocalContainer {
  max-width: 114px !important;
  min-width: 114px;
}

.RaceExpandedTableCell_StatusContainer {
  max-width: 323px !important;
  min-width: 323px;
}

.RaceExpandedTableCell_Empty {
  max-width: 603px !important;
  min-width: 603px;
}

.RaceDetailWrapper {
  padding: @pd-32;
}

.RaceGamesList{
  display: flex;
  flex-wrap: wrap;
  gap: 10px 13px;
}

.RaceGamesItem,
.RaceMore{
  display: flex;
  align-items: center;
}

.RaceGamesItem{
  height: 24px;

  img {
    margin-right: 6px;
    width: 24px;
  }
}

.RaceCellInner{
  display: flex;
  align-items: center;
  min-height: 40px;
}

div.RaceWinnersButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 143px;
  height: 32px;
  border: 1px solid @gray-300;
  border-radius: 8px;
  cursor: pointer;

  svg {
    margin-right: 8px;
  }

  span {
    &:extend(.ButtonTextSecond);
  }

  &:hover {
    border: 1px solid @gray-400;
    background: @white-100;
  }
}

.RaceWinnersEmpty{
  .ButtonTextSecond;
  color: @gray-600;
}

.RaceMore {
  color: @blue-700;
  cursor: pointer;
}

.RaceDetailSubtitle{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 5px;
  &:extend(.Text1);


  .ContentPeriodWrapper{
    padding-top: 0;
  }

  .ContentPeriod{
    padding-left: 0;
    margin-top: 0;
  }

  .ContentPeriodMain {
    color: @gray-600 !important;
  }
}

.RaceDetailText,
.RaceDetailText .ContentPeriodMain,
.RaceDetailText .ContentPeriodSeparatorHideTime,
.RaceDetailSeparator{
  .TextDefault;
  color: @gray-600;
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 0;
  white-space: nowrap;
}

.RaceDetailText .ContentPeriodSeparatorHideTime {
  margin: 0 5px;
}

.RaceModalEmpty {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;
  flex-direction: column;
  margin-top: 20%;

  svg {
    width: 200px;
    height: 200px;
  }

  .RaceModalEmptyText {
    text-align: center;
    color: @gray-700 !important;
    &:extend(.CaptionText);
  }
}

.RaceGamesTable__InfoTab {
  .ant-table-row, .ant-table-thead {
    .ant-table-cell:nth-child(2) {
      padding-left: 0 !important;
    }
  }
}

.RaceGamesTable__HistoryTab {
  .ant-table-thead > tr > th {
    height: 30px !important;
  }

  .ant-table-thead tr:nth-child(1) .ant-table-cell:nth-child(1),
  .ant-table-row .ant-table-cell:nth-child(1) {
        padding-left: 32px !important;
  }

  .ant-table-thead tr:nth-child(2) .ant-table-cell {
    .zeroPadding();
  }

  .ant-table-thead tr:nth-child(1) {
    th:nth-child(3), th:nth-child(4) {
      .zeroPadding();
    }
  }
}

.RaceExpanded_WinnerCell {
  padding-left: 4px;
}