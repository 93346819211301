.StaticDashboardModalContainer {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.StaticDashboardHallsContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.StaticDashboardHallsSingleHall {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid @gray-200;
  &:extend(.TableHeaderText);
  &:hover {
    border: 2px solid @main;
    cursor: pointer;
    padding: 11px 15px !important;
  }
}

.StaticDashboardHallsSingleHall_active {
  border: 2px solid @main !important;
  padding: 11px 15px !important;
}

.StaticDashboardHallsSingleHall_number {
  color: @gray-500 !important;
  &:extend(.TableHeaderText);
}

.StaticDashboardHallsSingleHall_ButtonsContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;

  button {
    height: 40px !important;
  }
}

.StaticDashboardHallsSingleHall_CancelButton {
  background-color: @gray-100 !important;
  border: unset !important;
  &:hover {
    background-color: @gray-200 !important;
  }
  width: 97px !important;
}

.StaticDashboardHallsSingleHall_RedirectButton {
  width: 162px !important;
}

.StaticDashboardEmptyTableContainer {
  gap: 8px;
  padding: 24px;
  width: 575px;

  svg {
    width: 160px;
    height: 160px;
  }
}

@media screen and (max-width: 920px) {
  .StaticDashboardModalContainerModal .ModalWrapper {
    left: -4% !important;
  }
  .StaticDashboardEmptyTableContainerChild {
    width: 50% !important;
  }
}

.StaticDashboardEmptyTableContainerChild {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

