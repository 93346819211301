@import "src/_main";

@import "./TableFilters/FromToDates/styles";
@import "./TableFilters/CheckListFilter/styles";
@import "./TableFilters/SortFilter/styles";
@import "./TableFilters/OnlySortFilter/styles";

@import "./TableActions/styles";

@import "TableCards/styles";

.NewTableComponentWrapper .ant-spin-nested-loading .ant-spin-container
.ant-table .ant-table-container .ant-table-content {
  min-height: unset;
}

.WithoutFiltersHeader .ant-spin-nested-loading .ant-spin-container
.ant-table .ant-table-container .ant-table-content {
  min-height: auto !important;
}

.ant-table-wrapper {
  overflow-x: auto;
}

.ant-table-column-has-sorters .ant-table-column-sorters,
.ant-table-thead > tr > th {
  background-color: #E3FAFC !important;
  padding: 12px !important;
}

.ant-table-column-has-sorters .ant-table-column-sorters {
  padding: 0 !important;
}

thead tr .ActiveSortTableColumn .ant-table-column-sorters > span:first-child {
  color: #15AABF !important;
}

.TableColumn {
  padding: 12px !important;
  word-spacing: normal !important
}

.ant-table-column-sorter-inner .active svg {
  path {
    fill: #15AABF !important;
  }
}

.ant-table-column-sorter-inner svg:not(.active) svg {
  path {
    fill: #B4CDCF !important;
  }
}

.ant-table-column-has-sorters .ant-table-column-sorters > span:first-child,
.ant-table-thead > tr > th {
  &:extend(.TableHeader);
}

.ant-table-column-sort {
  background-color: @white-100 !important;
}

.ant-table-cell-row-hover {
  background: #fafafa !important;
}

.TableHeaderFirst {
  padding-left: @pd-16;
}

.ant-table-thead > tr > th {
  word-spacing: 999px;
  height: 56px;
  color: @shark500 !important;
}

.TableHeaderWithoutSpacing {
  word-spacing: 0 !important;
}

.PaginationTableComponentWrapper {
  width: 100%;
}

.PaginationTableComponentWrapper .ant-spin-nested-loading {
  width: 100%;
}

.PaginationTableComponentWrapper .ant-spin-nested-loading .ant-spin-container
.ant-table {
  border-radius: 8px 8px 0 0 !important;
}
.PaginationTableComponentWrapper .ant-spin-nested-loading .ant-spin-container
.ant-table .ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 7px !important;
}

.PaginationTableComponentWrapper .ant-spin-nested-loading .ant-spin-container
.ant-table .ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 7px !important;
}

.PaginationTableComponentWrapper .ant-spin-nested-loading .ant-spin-container
.ant-table .ant-table-container .ant-table-content {
  border-top: 1px solid #E9EAEA;
  border-right: 1px solid #E9EAEA;
  border-left: 1px solid #E9EAEA;

  border-radius: 8px 8px 0 0;
}

.PaginationTableComponentWrapper::-webkit-scrollbar {
  border-right: 1px solid red;
}



.PaginationTableComponentWrapper .ant-spin-nested-loading .ant-spin-container
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.12) !important;
}

.PaginationTableComponentWrapper .ant-spin-nested-loading .ant-spin-container
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.12) !important;
}

.WithoutPaginationTableComponentWrapper {
  &:extend(.PaginationTableComponentWrapper);
  .ant-spin-nested-loading {
    .ant-spin-container {
      .ant-table {
        border-radius: 8px !important;
      }
    }
  }
}

.OrangeHeaderTable .ant-spin-nested-loading .ant-spin-container
.ant-table .ant-table-container .ant-table-content table .ant-table-thead .ant-table-cell {
  &:extend(.Orange10Background);
}

.TableWithSelected .ant-spin-nested-loading .ant-spin-container .ant-table-scroll-horizontal
.ant-table-container .ant-table-content table thead tr .ant-table-selection-column .ant-table-selection {
  margin-left: 8px;
}

.PaginationTableComponentTotalRow > td {
  .Text1;
  background-color: #FFF4E6 !important;
  font-weight: 700 !important;
}

.ant-table-thead > tr > th:first-child {
  padding-left: 44px !important;
}

.ant-table-cell::before {
  display: none !important;
}

.ant-table-tbody .ant-table-row > td {
  .Text1;
  padding: 12px;
  height: 56px;
}

.ant-table-tbody .ant-table-row:hover {
  cursor: pointer;
}

.TableComponentTotalRow > td {
  font-weight: bold !important;
}

.ant-table-expanded-row, .ant-table-row-selected,
.ant-table-expanded-row td, .ant-table-row-selected td {
  background-color: #fff !important;
}

.ant-table-expanded-row > td {
  padding: 0 !important;
  overflow-x: auto;
}

.ant-table-expanded-row-fixed {
  margin: 0 !important;
  width: 100% !important;
}

.ant-table-expanded-row > td .ant-table-expanded-row-fixed {
  padding: 0 !important;
  min-width: 1394px;
}

.ant-table-row:has(.ant-table-row-expand-icon-cell):has(span):has(div):has(.TableRowItemUp) > td {
  border-bottom: 0 !important;
}

.TableItemNegativeValue {
  color: #FF3B30 !important;
}

.ant-table-row-expand-icon-cell, .ant-table-cell-fix-left {
  width: 20px !important;
  max-width: 20px !important;
  padding: 0 !important;
}

.ant-table-body::-webkit-scrollbar {
  display: none !important;
}

@media screen and (max-width: 920px) {
  .TableColumnWrapper, .ReportTableContentWrapper {
    width: calc(100vw - 10px);
  }
  .ant-table-row-expand-icon-cell, .ant-table-cell-fix-left {
    width: 20px !important;
    padding: 0 !important;
  }
  .ant-table-content::-webkit-scrollbar {
    display: none !important;
  }
}

.OrangeHeaderTable .PaginationTableComponentWrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container
.ant-table-header table thead tr > th {
  background-color: #FFF4E6 !important;
}

.TableComponentTopScrollWrapper {
  overflow-x: scroll;
  height: 10px;
}

// Search in header

.TableSearchInput {
  width: 205px;
}

.TableSearchSelect {
  width: 205px;
}

.TableSearchClearIcon {
  margin-left: 2px;
}

.TableSearchClearIcon:hover, .TableSearchTitle:hover {
  cursor: pointer;
}

.TableSearchClearIcon {
  margin-left: 6px;
}

.TableSearchTitleActive {
  color: #15AABF;
}

.TableBoldValue {
  font-weight: 700 !important;
}

.TableColumnNormalSpacing {
  word-spacing: normal !important;
}

.TableHeaderHelpWrapper {
  padding-left: 4px;
}

.TableSearchSelect {
  .ant-select-clear {
    right: 35px;
  }
}

.ReportTableTotalArrowItem {
  svg path {
    fill: #FD7E14;
  }
}

.TableHeaderTitle_Active {

    color: #15AABF !important;

}

.TableHeaderTitle{
  display: flex;
  align-items: center;
  word-spacing: normal !important;
  white-space: pre;
  transition: color .25s ease;

  div&{
    cursor: pointer;

    &:hover{
      color: #15AABF;

      svg path {
        fill: #15AABF;
      }
    }
  }
}

.TableHeaderSortIcon{
  position: relative;
  top: -1px;
  margin-left: 2px;

  path{
    transition: fill .25s ease;
    fill: #ADB5BD;
  }
}

.TableHeaderModal {
  word-spacing: normal;

  .ModalWrapper{
    width: 384px;
    max-width: 384px;
  }

  .ModalContent{
    padding: 32px 24px;
    max-width: 100%;
  }

  .CheckboxWrapper{
    align-items: center;
    margin-bottom: 8px;
  }

  .SelectWrapper{
    margin-bottom: 18px;

    .ant-select-selection-item,
    .ant-select-item.ant-select-item-option{
      .TextLabelText;
    }
  }

  .CheckboxText{
    .SubtitleFirstText;
  }

  .ant-slider{
    margin-bottom: 5px;
  }

  .ButtonPrimary, .ButtonPrimaryDisabled {
    width: 100%;
  }

  .ButtonPrimaryWrapper .ant-btn span{
    .ChartDecodingText;
  }
}

.TableHeaderModalTitle{
  white-space: nowrap;
  .HeadlineSecondText;
  margin-bottom: 15px;
}

.TableHeaderModalSubTitle{
  .SubtitleFirstText;
  margin-bottom: 6px;
}

.TableHeaderModalCloseIcon{
  width: 30px;
  height: 30px;
  position: absolute;
  right: 20px;
  top: 30px;
  cursor: pointer;
}

.TableHeaderModal_inputInner{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  .InputWrapper{
    width: 160px;

    .ant-input{
      .TextLabelText;
      color: #212529;
    }
  }
}

.SliderComponentLegend{
  .OverlineText;
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  color: #868E96;
  margin-bottom: 27px;
}

@media screen and (min-width: 921px){
  .TableHeaderModal .ModalWrapper{
    position: absolute;
    top: 64px;
    left: 0;
    transform: none;
  }

  th:first-child, th:nth-child(2){
    .TableHeaderModal .ModalWrapper{
      left: 10px;
      right: auto;
    }
  }


  .ant-table-thead tr th:nth-last-child(2),
  .ant-table-thead tr th:last-child {
    div .TableHeaderModal .ModalWrapper {
      right: 100px !important;
      left: auto !important;
    }
  }

  th:last-child{
    .TableHeaderModal .ModalWrapper{
      left: auto;
      right: 10px;
    }
  }

  .ant-table-ping-left,
  .ant-table-ping-right{
    .TableHeaderModal .ModalWrapper,
    th:first-child .TableHeaderModal .ModalWrapper,
    th:nth-child(2) .TableHeaderModal .ModalWrapper
    {
      position: fixed;
      left: 256px !important;
      right: 0 !important;
      top: 237px;
      margin: auto;
    }
  }
}

@media screen and (max-width: 1600px) and (min-width: 921px){

  .TableHeaderModal .ModalWrapper{
    left: auto;
    right: 10px;
  }
}

@media screen and (max-width: 920px){
  .TableHeaderModal {
    .ModalWrapper, .ModalFormWrapper {
      right: 0;
      margin: auto;
    }
  }

  .TableHeaderModal .ModalWrapper{
    transform: none;
    top: 192px;
  }
}

@media screen and (max-width: 384px){
  .TableHeaderModal {
    .ModalWrapper{
      max-width: 100%;
    }
    .ModalWrapper, .ModalFormWrapper {
      margin: 0;
    }

    .ModalContent{
      padding: 32px 20px;
    }
  }
}

.WithMinHeight {
  .ant-table-content {
    min-height: 500px;
  }
}

.FilterButtonsWrapper{
  display: flex;
  justify-content: space-between;

  .ButtonFadedWrapper {
    flex: 0 0 119px;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ButtonPrimaryWrapper{
    flex: 0 0 199px;

    &:only-child{
      flex: 0 0 100%;
    }
  }

  button{
    width: 100%;
  }
}

.TableFilterSortWrapper .CheckboxWrapper .ant-checkbox-wrapper {
  height: 28px;
}

// Sort arrow color

.TableColumnSortASC svg path:nth-child(2) {
  fill: @cyan-600 !important;
}

.TableColumnSortDESC svg path:nth-child(1) {
  fill: @cyan-600 !important;
}

// hover for only expandable

.TableComponentWrapper > .ant-spin-nested-loading > .ant-spin-container > .ant-table >
.ant-table-container > .ant-table-content > table > tbody > tr:hover,
.NewTableComponentWrapper > .ant-spin-nested-loading > .ant-spin-container > .ant-table >
.ant-table-container > .ant-table-content > table > tbody > tr:hover {
  cursor: default;
}

.ExpandableTableRow:hover {
  cursor: pointer !important;
}