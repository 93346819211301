.HallPickerPageWrapper {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
}

.HallPickerPageContainer {
  width: 830px;
  margin-left: auto;
  margin-right: auto;
}

.HallPickerPageContent {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.HallPickerPageTitle {
  padding: 84px 0 44px 12px;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #0D0D0D;
}

.HallPickerLogout {
  padding-top: 3px;
}

.HallPickerLogout:hover {
  cursor: pointer;
}

.HallPickerPageTitle span {
  padding-left: 8px;
}

.HallPickerPageSearchWrapper {
  margin-left: auto;
  padding-top: 84px;
  margin-right: 24px;
  width: 250px;
}

.HallPickerPageSearchWrapper .ant-input-affix-wrapper {
  background-color: #fff !important;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #E5E5EA;
  width: 250px;
  padding: 12px !important;
}

.HallPickerPageSearchWrapper input {
  background-color: #fff;
}

.HallPickerPageSearchWrapper > .ant-input-affix-wrapper:hover {
  box-shadow: 0 0 0 1px #f8e800 !important;
  border-color: #f8e800 !important;
}

.HallPickerPageSearchWrapper > .ant-input:focus,
.HallPickerPageSearchWrapper > .ant-input-focused,
.HallPickerPageSearchWrapper > .ant-input-affix-wrapper-focused,
.HallPickerPageSearchWrapper > .ant-input:focus,
.HallPickerPageSearchWrapper > .ant-input-number-focused,
textarea.ant-input:focus {
  box-shadow: 0 0 0 1px #f8e800 !important;
  border-color: #f8e800 !important;
}

.HallPickerPageSearchWrapper #placeholder {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #AAABAD;
  width: 100%;
}

.HallPickerPageItem {
  padding-left: 24px;
  padding-top: 18px;
  width: 208px;
  height: 56px;
  margin: 12px;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 24px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.HallPickerPageItem:hover {
  cursor: pointer;
  background-color: #f4f4f4;
}

.HallPickerPageItemDescription {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #8E8E93;
  padding-top: 2px;
}

.HallPickerPageItemLabel {
  padding-left: 6px;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}

.HallPickerPageDisabledItem {
  background-color: #F8F8F8;
}

.HallPickerPageDisabledItem:hover {
  cursor: not-allowed !important;
}

@media screen and (max-width: 720px) {
  .HallPickerPageWrapper, .HallPickerPageContainer {
    width: 100vw !important;
  }

  .HallPickerPageTitle {
    padding: 32px 16px !important;
  }

  .HallPickerPageSearchWrapper {
    padding: 0 16px 32px 16px !important;
    margin: 0 !important;
  }

  .HallPickerPageItem {
    width: 90px !important;
    padding-left: 16px !important;
  }
}
