@import "./Table/styles";
@import "./Modal/styles";

.PayrollsTitleButtons .ButtonFadedWrapper {
  button {
    padding-top: 10px !important;

    span {
      &:extend(.ButtonTextSecond);
    }
  }
}

.PayrollsTitleButtons > .ButtonDefaultWrapper > button {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.PayrollsTitleButtons > div > .ButtonDefaultWrapper,
.PayrollsTitleButtons > div > .ButtonFadedWrapper,
.PayrollsTitleButtons > .ButtonPrimaryWrapper {
  button {
    height: 40px !important;
  }
}

@media screen and (min-width: 1280px) {
  .PayrollsTitleButtons {
    padding-left: 16px;
  }
}