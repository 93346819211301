.GraySpinComponent .ant-spin span svg {
  path {
    fill: #E5E5EA;
  }
}

.YellowSpinComponent .ant-spin span svg {
  path {
    fill: #F8E800;
  }
}

.UltimatePendingWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(13, 15, 16, 0.56);
  z-index: 9999;
  top: 0;
  left: 0;
}