.CashboxTableHeader {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ededf0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 20px 0;
  width: 100%;
  background-color: #E6F6F8 !important;
}

.CashboxTableArrowItem {
  padding-left: 11px;
  padding-right: 11px;
  //min-width: 40px;
}

.CashboxTableArrowHeaderItem {
  padding-left: 24px;
  padding-right: 11px;
}

.CashboxTableItem:hover {

}

.CashboxTableLastItem {
  font-weight: bold;
}

.CashboxTableRowWrapper {
  border-bottom: 1px solid #ededf0;
}

.CashboxTableRow, .CashboxTableLastRow {
  display: flex;
  flex-direction: row;
  padding: 18px 0;
  width: 100%;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #2C2C2E;
  background-color: #fff !important;
}

.CashboxTableLastRow {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.CashboxTableActiveRow {
  font-family: Lato, sans-serif;
  color: #000000 !important;
}

.TableRowItemUp {
  svg {
    transform: rotate(-180deg);
    transition-duration: 0.2s;
  }
}

.TableRowItemDown {
  svg {
    transform: rotate(0deg);
    transition-duration: 0.2s;
  }
}

.CashboxTableRow:hover {
  cursor: pointer;
  background: #F8F8F8 !important;
}

.CashboxTableRowDisabled:hover {
  cursor: no-drop !important;
}

.ReportTableArrowItem {
  padding-left: 16px;
  width: 20px;
}

.CashboxTableWrapper .ant-spin-nested-loading .ant-spin-container
.ant-table .ant-table-container .ant-table-content table .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed {
  min-width: auto !important;
}
