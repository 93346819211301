.SportboomClearFilterModal {
  padding: 32px 24px;

  width: 384px;
}

.SportboomClearFilterModalList {
  max-height: 400px;
  overflow-y: auto;
}

.SportboomClearFilterCount {
  width: 24px;
  height: 24px;
  place-items: center;
  display: grid;

  background: #FCE000;
  border-radius: 24px;
}

.SportboomFilterClearDescription {
  p {
    margin-bottom: 0 !important;
  }
}

.SportboomClearFilterDisabled {
  .TooltipComponentTargetWrapper {
    cursor: not-allowed;
    pointer-events: none !important;
  }
}