.ContentPeriodDescription {
  &:extend(.SubtitleSecondText);
}

div .ContentPeriodWrapper{
  align-items: baseline;
  padding-top: 4px;
  flex-direction: row !important;

  .CalendarIconDescription {
    position: relative;
    top: 4px;
  }

  .ContentPeriodMain{
    .TextTwo;
  }

  .ContentPeriodTime{
    color: #808185 !important;
  }
}

.ContentPeriod {
  padding-left: 4px;
  margin-top: -2px;
  letter-spacing: 0.1px;
  align-items: baseline;
}

.ContentPeriodMain {
  padding-left: 4px;
  .BodyFirstText;
}

.ContentPeriodTime {
  padding: 0 4px;
  .SubtitleSecondText;
}

.ContentPeriodSeparatorHideTime {
  padding-left: 4px;
}

@media screen and (max-width: 1600px){
  .ContentPeriodWrapper{
    margin-left: 8px;
  }
}

@media screen and (max-width: 920px) {
  .ContentPeriodMain {
    color: @shark-500 !important;
  }
  .ContentPeriodTime {
    color: @shark-800 !important;
  }

  .ContentPeriodDescription {
    &:extend(.TextDefault);
    padding-bottom: @pd-4;
    color: @gray-600 !important;
  }
}

.CalendarIconDescription {
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    rect {
      fill: @white-100 !important;
    }
  }
}