@import "./Table/styles";
@import "./InsideTable/styles";
@import "./Statistics/styles";
@import "./Dashboards/styles";

.CashboxContentTitle {
  padding-bottom: 4px !important;
}

@media screen and (max-width: 920px) {
  .CashboxContentTitle {
    flex-direction: column !important;
  }
}

.CashboxTitleBottomWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 15px;
  padding-top: 0 !important;
}
