.FileItemWrapper {
  width: 240px;
  padding: 8px;
  background: #F8F9FA;
  border-radius: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.FileItemText {
  padding-left: 8px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.408px;
  padding-top: 2px;
}

.FileItemTextName {
  color: #212529;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 160px;
}

.FileItemTextSize{
  padding-top: 3px;
  color: #868E96;
}

.FileItemDelete, .FileItemDownload {
  display: none;
  margin-left: auto;
}

.FileItemDelete {
  margin-top: 12px;
}

.FileItemDelete, .FileItemDownload {
  margin-top: 6px;
}

.FileItemWrapper:hover > .FileItemDelete,
.FileItemWrapper:hover > .FileItemDownload {
  display: inherit !important;
}

.FileItemDelete:hover, .FileItemDownload:hover {
  cursor: pointer;

  svg path {
    fill: black;
  }
}

@media screen and (max-width: 920px) {
  .FileItemWrapper {
    width: 150px;
  }

  .FileItemTextName {
    max-width: 80px;
  }

  .FileItemDownload, .FileItemDelete {
    display: inherit !important;
  }
}

.MinifiedFileComponent {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px 2px 12px;
  border-radius: 16px;
  width: fit-content;
}

.MinifiedFileComponentName {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.4px;
  color: #FFFFFF;
}

.MinifiedFileComponentLink svg {
  height: 10px;
  width: 13px;
  fill: #FFFFFF;
  margin-left: 7px
}

.MinifiedFileComponentJpg {
  background: #BE4BDB;
}

.MinifiedFileComponentPng {
  background: #7950F2;
}

.MinifiedFileComponentPdf {
  background: #E64980;
}

.UploadAttachmentsIcon {
  display: grid;
  place-items: center;
}

.UploadAttachmentsButton {
  margin-bottom: 6px;
}

.UploadAttachmentsButton > button {
  padding: 4px 11px !important;
}