.DeveloperProfileWrapper {
    position: fixed;
    bottom: 16px;
    left: 340px;
}

@media screen and (max-width: 1440px) {
    .DeveloperProfileWrapper {
        left: 320px;
    }
}

.DeveloperComponentIconWrapper {
    background-size: 48px !important;
    width: 48px;
    height: 48px;

    &-disabled {
        opacity: 0.7;
    }

    &-active:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}

.DeveloperLinksHeader {
    width: 100%;
    padding: 16px 32px;
    text-align: center;
    border-bottom: 1px solid #E9EAEA;
    font-family: Gilroy, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
}

.DeveloperLinksWrapper {
    margin: 16px 48px;
}

.DeveloperProfileLinksWrapper {
    display: flex;
    flex-direction: column;
}

.DeveloperLinksItem {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    padding: 8px 16px;
}

.DeveloperLinksItem a {
    text-decoration: none !important;
    color: #212529;
}

.DeveloperLinksItem a:hover {
    text-decoration: none !important;
    color: #212529;
}

.DeveloperLinksItem:hover {
    background: #F2F5F7;
    border-radius: 6px;
}

.DeveloperLinkText {
    font-weight: bold !important;
    font-style: italic;
}
