.FreebetsDetailWrapper {
  padding: 40px 24px;
}

.FreebetsDetailTable {
  margin-top: 16px;
}

.FreebetsDetailTable .TableComponentWrapper {
  border: 1px solid #E9ECEF;
  border-radius: 8px;
}
