.SportsBroadcast__InfoWrapper {
  border-radius: 8px;

  .SportsBroadcast__InfoWrapper__Icon {
    margin-top: 2px;

    svg path {
      fill: @blue-600;
    }
  }
}

@media screen and (max-width: 920px) {
  .SportsBroadcast__InfoWrapper__Icon {
    min-width: 20px;
  }
}