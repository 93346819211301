@import "./components/styles";
@import "src/_main";

.HallCardWrapper {
  gap: 32px;
}

.HallCardRow {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__Title {
    color: #868E96;
  }
  &__Subtitle {
    .BodyFirstText
  }
}

.HallCardPrinterBtn {
  display: flex;
  gap: 8px;
  align-items: center;

  &__Text {
    font-family: 'Gilroy', sans-serif !important;

    line-height: 18px;
  }
}

.HallCardPrinterBtnWrapper {
  margin-left: 16px;
  margin-bottom: 9px;
}

@media screen and (min-width: 921px) and (max-width: 1440px) {
  .HallCardWrapper {
    gap: 16px;
  }
}

@media screen and (max-width: 920px) {
  .HallCard {
    min-height: auto;
  }

  .HallCardRow {
    &__Title {
      .CaptionText !important;
      color: @gray-600 !important;
    }
    &__Subtitle {
      .BodySecondText
    }
  }
  .HallCardWrapper {
    flex-direction: column;
    padding: 0 16px;
    gap: 8px;
  }
}