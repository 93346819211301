.QiwiModalHeader {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #0D0D0D;
  padding: 32px 0 24px 0;
  width: 448px;
}

.QiwiModalTableHeader {
  justify-content: space-between;
  padding: 18px 24px;
  border: 1px solid #E5E5EA;
  margin: 0 32px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.QiwiModalTableHeaderItem {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #2B2D33;
}

.QiwiModalTableRow {
  justify-content: space-between;
  padding: 18px 32px;
  border-bottom: 1px solid #E9EAEA;
  border-left: 1px solid #E9EAEA;
  border-right: 1px solid #E9EAEA;
}

.QiwiModalTableLastRow {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.QiwiModalTableRowItem {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #2B2D33;
}

.QiwiModalWrapper {
  padding-bottom: 16px;
}

.QiwiModalTableWrapper {
  overflow-y: auto;
  max-height: 460px;
}

.QiwiModalTableBody {
  margin: 0 32px 8px 32px;
}
