.TableConfigModalWrapper {
  background: @white-100;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 96px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  padding: @pd-32 @pd-24;

  width: 384px;

  position: absolute;
  z-index: 4;
  top: 0;
  right: 184px;
}

.TableConfigModalClose {
  &:extend(.MarginTop6);
  margin-left: auto;
}

.TableConfigModalClose:hover {
  cursor: pointer;
  opacity: 0.8;
}

.TableConfigModalListWrapper {
  &:extend(.PaddingTop24);
}

.TableConfigModalListTitle {
  justify-content: space-between;
}

.TableConfigModalTemplateWrapper {
  &:extend(.MarginTop16);
  &:extend(.Gap6);
}

.TableConfigModalListItem {
  &:extend(.PaddingRight4);
  &:extend(.Gap8);
}

.TableConfigModalListItemView {
  margin-left: auto;
}

.TableConfigModalListItemView:hover {
  &:extend(.HoverItem);
}

.TableConfigModalList {
  &:extend(.PaddingTop10);
  &:extend(.Gap12);
}

.TableConfigModalApply {
  &:extend(.MarginTop24);
  button {
    width: 100%;
  }
}

.TableConfigModalTemplateAdd {
  margin: @pd-24 @pd-16 @pd-16 @pd-16;
}

.DraggableItem {
  .TableConfigModalListItem {
    border-radius: 2px;
    background: @gray-100 !important;
  }
}

.TableConfigModalTemplateListItem {
  div {
    justify-content: space-between;

    svg path {
      fill: @gray-500;
    }
  }
}

.TableConfigModalTemplateAddForm {
  &:extend(.Margin16);
  &:extend(.Gap12);
}

// remove modal

.SportboomConfigRemoveModal {
  padding: 32px;
  &:extend(.Gap16);

  .SportboomConfigRemoveModalButtons {
    margin-left: auto;
    &:extend(.Gap8);
  }
}

.SportboomConfigRemoveModalButtonsRemove {
  path {
    fill: #fff !important;
  }
}
