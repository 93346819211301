.PenaltyAdminCardHeader {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.2px;
  color: #868E96;
}

.PenaltyAdminCardReasonHeader {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  //display: flex;
  //align-items: center;
  color: #212529;
}

.PenaltyAdminCardDatesWrapper {
  display: flex;
}

//.PenaltyAdminCardReasonAndDates {
//  display: flex;
//}

.PenaltyAdminCardDatesElement {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4px 16px 8px;
  width: 112px;
  height: 65px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px -1px;
  &:not(:last-child) {
    border-right: 1px solid #CED4DA;
  }
}

.PenaltyAdminCard {
  background-color: #FFFFFF;
  padding: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #E9ECEF;
  border-radius: 12px;
}

.PenaltyAdminCardReasonHeader:hover,
.PenaltyAdminCardFooterRight:hover,
.MinifiedFileComponent:hover,
.PenaltyAdminCardHeaderDetails:hover,
.PenaltyAdminCardReasonComment:hover {
  cursor: pointer;
}

.PenaltyAdminCardDatesWrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  width: 445px;
  height: 65px;
  border: 1px solid #CED4DA;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: auto;
}

.PenaltyAdminCardHeader {
  display: flex;
  align-items: center;
}

.PenaltyAdminCardHeaderDate {
  margin-right: 0;
  margin-left: auto;
}

.PenaltyAdminCardHeaderDetails {
  margin-left: 18px;
 }

.PenaltyAdminCardContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.PenaltyAdminCardLeft {
  margin-right: 27px;
}

.PenaltyAdminCardRight {
  min-width: 445px;
}

.PenaltyAdminCardReasonComment {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #3A3A3C;
  margin-top: 4px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important; /* number of lines to show */
  line-clamp: 2 !important;
  -webkit-box-orient: vertical;
  margin-bottom: 4px;
}

.PenaltyAdminCardDatesElementText {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #868E96;
}

.PenaltyAdminCardDatesElementDate {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #212529;
}

.PenaltyAdminCardDatesFromNow {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #212529;
}

.PenaltyAdminCardFiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .MinifiedFileComponent {
    margin-right: 8px;
    margin-top: 4px;
  }
}

.PenaltyAdminCardFooterCard {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.4px;
  color: #212529;
  display: flex;
  justify-content: center;
  padding: 6px 12px 8px;
  gap: 10px;
  width: fit-content;
  height: 24px;
  background: #F1F3F5;
  border-radius: 4px;
}

.PenaltyAdminCardFooterLeft {
  display: flex;
  flex-direction: row;

  .PenaltyAdminCardFooterCard:not(:first-child) {
    margin-left: 8px;
  }
}

.PenaltyAdminCardFooterRight {
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.2px;
    color: #868E96;
  }
}

.PenaltyAdminCardFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.PenaltyAdminCardFooterAppealAttach,
.PenaltyAdminCardFooterAppealComment {
  display: flex;
  align-items: center;
  margin-left: 18.5px;

  svg {
    margin-right: 4px;
  }
}






