.EmptyTableTitle {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: #212529;
  padding-top: 22px;
}

.EmptyTableLabel {
  padding-top: 8px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.2px;
  color: #212529;
  white-space: pre-wrap;
}

.EmptyTablePendingSpin {
  height: 248px;
}

.EmptyTableLineWrapper {

}