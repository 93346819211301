.SwitchComponentWrapper {
  display: flex;
  flex-direction: row;
}

.SwitchComponentWrapper .ant-switch-checked {
  background-color: @main;
}

.SwitchComponentWrapper .ant-switch {
  height: 20px;
  width: 36px;
}

.SwitchComponentWrapper .ant-switch .ant-switch-handle {
  height: 16px;
}

.SwitchComponentLabel {
  padding-left: 8px;
  color: #2C2C2E;
}

.SwitchFilterWrapper {
  width: 100%;
  height: 40px;

  .ant-switch {
    margin-left: auto;
    background-color: #E9ECEF;
  }

  .ant-switch-checked {
    background-color: @main !important;
  }
}

.SwitchFilterComponentLabel {
  font-family: 'Gilroy', sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
}
