.MessagesHeader {
  width: 544px;
  padding: 40px 32px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #000000;

  span {
    padding-top: 3px;
  }
}

.MessageTextWrapper {
  max-height: 260px;
  height: 260px;
  overflow-y: auto;
  padding: 0 32px;
  margin-right: 4px;
  margin-bottom: 24px;
}

.MessageTextWrapper::-webkit-scrollbar-thumb {
  background-color: #D1D1D6;
  border-radius: 20px;
}

.MessagesFooter {
  width: 100%;
  border-top: 1px solid #E5E5EA;
  padding: 16px 16px 16px 32px;
}

.MessageCount {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 20px;
  color: #8E8E93;
  padding-top: 16px;
}

.MessageCountTotal {
  color: #000 !important;
}

.MessagesFooter > .ButtonPrimaryWrapper {
  padding-left: 224px;
}

.MessagesFooterButtons {
  gap: 8px;
  margin-left: auto;
}