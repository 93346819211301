.CashboxFilterComponentWrapper {
  display: flex;
  flex-direction: column;
  background: #fff;
}

.CashboxFilterContentWrapper {
  overflow: hidden;
  overflow-y: auto;
  height: calc(100vh - 169px);
}

.CashboxFilterComponentTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  padding-bottom: 32px;
}

.CashboxFilterApplyButtonWrapper {
  margin-top: auto;
}

.CashboxFilterApplyButton {
  width: 100%;
  height: 48px;
  margin-top: 32px;
}

.CashboxFilterContent {
  padding: 12px 0 30px 0;
}

.FilterContentLabel {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.InfoPanelWithoutShiftTypeSwitcher {
  padding-bottom: 20px;
  margin-left: auto;
}

.InfoPanelWithoutShiftTimeWrapper {
  padding-top: 8px;
}
