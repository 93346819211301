@import "./SmsTabs/styles";

.SmsWrapper {
  background: #fff;
  border-radius: 8px;
  position: relative;
  margin-top: 16px;
  margin-bottom: 16px;

  .TableColumnWrapper{
    width: auto !important;
  }
}

.SmsContentTitleWrapper{
  display: flex;
  max-width: 900px;

  .TitleWrapper {
    width: auto !important;
    margin-right: 8px;
  }
}

.CreateSms_inputs {
  padding-right: 40px;
  max-width: 840px;

  .ant-select,
  .ant-input,
  .ant-picker-input,
  .ant-picker-input input,
  .PeriodRadio .ant-radio-button-wrapper {
    .SubtitleSecondText;
    font-weight: 500;
  }

  .RadioGroupOption {
    height: unset;
    padding-bottom: 16px;

    &:last-of-type {
      padding-bottom: 8px;
    }
  }

  .RadioWrapper label {
    align-items: flex-start;
  }

  .TimePickerComponentWrapper .ant-picker {
    height: 48px;
  }

  .PeriodRadio {
    display: flex;

    .ant-radio-button-wrapper {
      flex: 0 1 95px;
      height: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      border-color: #CED4DA;
      background: #F8F9FA;
      color: #868E96;
      padding-left: 0;
      padding-right: 0;


      &:first-of-type {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:last-of-type {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    .ant-radio-button-wrapper.ant-radio-button-wrapper-checked{
      border-color: #FCE000 !important;
      box-shadow: inset 0 0 0 1px #FCE000;
      background: #ffffff;
      color: #212529;

      &:hover,
      &:focus,
      &:active{
        color: #212529;
      }
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
      background-color: #FCE000;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{
      box-shadow: inset 0 0 0 1px #FCE000;
    }
  }

}

.CreateSms_infoBlock {
  display: flex;
  max-width: 703px;
  background: #E7F5FF;
  border: 1px solid #228BE6;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
  .SubtitleSecondText;
  color: #228BE6;

  svg {
    margin-right: 8px;
  }
}

.CreateSms_step {
  margin-bottom: 24px;
}

 .CreateSms_stepTitle {
  .SubtitleFirstText;
  font-weight: 700;
  display: flex;
  margin-bottom: 13px;

   span{
     .SubtitleFirstText;
   }

  span:first-of-type {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    min-width: 24px;
    height: 24px;
    background: #15AABF;
    border-radius: 4px;
    color: #FFFFFF;
    margin-right: 8px;
  }

  .TooltipComponentTargetWrapper {
    margin-left: 7px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.CreateSms_stepTitle_inactive {
  .CreateSms_stepTitle;

  span:first-of-type {
    background: #868E96;
  }
}

.CreateSms_inputWrapper{
  max-width: 500px;

  .ant-select-clear{
    right: 32px;
  }

  textarea.ant-input{
    padding: 12px 16px;
    resize: none;
  }
  textarea.ant-input.CreateSms_customText{
    height: 98px !important;
  }
}

.CreateSms_inputWrapper_wide{
  width: 800px;
  max-width: 100%;
}

.CreateSms_inputInfo {
  display: block;
  .TextDefault;
  font-style: italic;
  margin-bottom: 24px;
}

span.CreateSms_inputLabel,
label.CreateSms_inputLabel{
  display: block;
  .SubtitleFirstText;
  font-weight: 500;
  line-height: 18px;
  margin: 13px 0 6px;
}

label.CreateSms_inputLabel_withTooltip{
  display: flex;
  align-items: center;

  .TooltipComponentTargetWrapper{
    position: static;
    margin-left: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.CreateSms_templateInput,
.CreateSms_subTemplateInput {
  max-width: 480px;
}

.CreateSms_inputPhonesWrapper {
  margin-top: 18px;

  .CreateSms_error_inputPhoneTag,
  .CreateSms_inputPhoneTag{
    .SubtitleSecondText;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 4px 5px 12px;
    border-radius: 8px;
    border: none;
    margin-bottom: 8px;

    .ant-tag-close-icon{
      width: 16px;
      height: 16px;
      margin-left: 8px;
    }
  }

  .CreateSms_inputPhoneTag{
    background: #F4F4F4;
  }

  .CreateSms_error_inputPhoneTag{
    background: #FFE3E3;
    color: #212529;
  }

  .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
    height: 25px;
  }
}

.CreateSmsWrapper{
  padding: 24px 24px 32px;
  border: 1px solid #e9ecef;
}

.CreateSmsWrapper .CreateSms_inputPhonesWrapper,
.CreateSmsWrapper .CreateSms_inputPhonesWrapper .ant-select,
.CreateSmsWrapper .CreateSms_inputPhonesWrapper .ant-select .ant-select-selector
{
  height: 150px !important;
}

.CreateSmsWrapper .CreateSms_inputPhonesWrapper .ant-select-clear{
  top: 16px;
  right: 16px;
}

.CreateSmsWrapper .CreateSms_inputPhonesWrapper .ant-select .ant-select-selector{
  align-content: flex-start;
}

.CreateSmsWrapper .CreateSms_inputPhonesWrapper .ant-select .ant-select-selector .ant-select-selection-search input,
.CreateSmsWrapper .CreateSms_inputPhonesWrapper .ant-select .ant-select-selector .ant-select-selection-search{
  height: 25px !important;
}

.CreateSmsWrapper .CreateSms_inputPhonesWrapper .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix{
  margin-bottom: 8px;
}

.CreateSms_phoneWrapper {
  padding: 25px 24px 32px;
  flex: 0 0 408px;
  width: 408px;
  height: 845px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  position: sticky;
  top: 24px;
}

.CreateSms_phoneBody {
  background: url("/Components/icons/iphone_empty.svg") top center no-repeat;
  height: 360px;
  position: relative;
}

.CreateSmsWrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
}

.CreateSms_phoneInner {
  position: absolute;
  top: 119px;
  left: 25px;
  right: 16px;
  height: 240px;
  overflow-y: auto;
}

.CreateSms_phoneTime{
  margin-top: 27px;
  text-align: center;
  .OverlineText;
  font-size: 9px;
  color: #909093;
}

.CreateSms_input {
  max-width: 480px;
  position: relative;
  margin-bottom: 5px;

  .TooltipComponentTargetWrapper{
    position: absolute;
    right: -28px;
    top: 38px;

    svg{
      width: 20px;
      height: 20px;
    }
  }

  .TimePickerComponentWrapper .ant-picker{
    border-radius: 8px;
  }
}

.CreateSms_input  .InputWrapper + .TooltipComponentTargetWrapper{
  position: absolute;
  right: -28px;
  top: 51px;
}

.CreateSms_inputShort {

  .CreateSms_inputInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .InputWrapper,
  .DatePickerWrapper {
    max-width: 230px;
    flex: 0 1 calc(50% - 8px);
  }

}

.CreateSms_inputDoubleShort {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .CreateSms_inputDoubleInner {
    width: 230px;
    max-width: calc(50% - 8px);
  }
}

.CreateSms_inputContainer{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 480px;

  .CreateSms_input:not(.CreateSms_inputShort_single){
    max-width: 480px;
    flex: 0 1 480px;
  }
}

.CreateSms_inputShort_single{
  max-width: 230px;
  flex: 0 1 calc(50% - 8px);


  .CreateSms_inputInner{
    display: block;

    .InputWrapper,
    .DatePickerWrapper{
      width: 100%;
      max-width: unset;
    }
  }
}

.ant-select-dropdown .ant-select-item-option.CreateSms_SelectDropDownCustom{
  padding-top: 6px;
  padding-bottom: 6px;
}

.CreateSms_free_text{
  display: inline-flex;
  align-items: center;

  svg{
    margin-right: 4px;
    width: 18px;
    height: 18px;
    position: relative;
    top: 1px;
  }
}

.CreateSms_error {
  color: red;
}

.CreateSms_inputError {
  border-color: red;
}

.ErrorSelectWrapper .ErrorText {
  font-size: 10px;
  color: #e80012;
  margin-top: 2px;
}

.ErrorSelectWrapper .ant-select-selector {
  border-color: #e80012 !important;
}

.CreateSms_divider {
  border: 0;
  border-top: 1px solid #DEE2E6;
  margin-bottom: 24px;
}

.SmsCancelBtn {
  background: #F1F3F5 !important;
  border: none !important;
  border-radius: 16px;
}

.SmsPhoneDropdown {
  display: none !important;
}

.CreateSms_phoneCalc{
  min-height: 204px;
  padding: 1px;

  .GraySpinComponent{
    height: 200px;
  }
}

.CreateSms_phoneCalcRow,
.CreateSms_phoneCalcTotal{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 14px 24px;
}

.CreateSms_phoneCalcRow span,
.CreateSms_phoneCalcTotal span{
  .TableHeaderText;
}

.HistoryTableComment {
  max-width: 200px;
  width: 200px;
}

.SmsHistoryColumnLargeText {
  width: 256px;
}


.HistoryTableCommentModal {
  width: 416px;
}

@media screen and (max-width: 920px) {
  .HistoryTableCommentModal {
    width: auto;
    max-height: 400px;
    overflow-y: auto;
  }
}


.CreateSms_phoneCalcRow{

}

span.CreateSms_phoneCalcRowLabel {
  font-weight: 400;
}

span.CreateSms_phoneCalcRowValue{
  font-weight: 700;
}

.CreateSms_phoneCalcTotal{
  border-top: 1px dashed #E9ECEF;
  padding: 12px 0;

  span{
    font-weight: 700;
  }
}

span.Createms_phoneCalcTotalRowValue{
  .HeadlineThirdText;

  .Createms_RubleSigne{
    margin-left: 5px;
  }
}

.CreateSms_phoneBottomText{
  background: #F4F4F4;
  border-radius: 8px;
  padding: 8px 16px;
  .TextDefault;

  a{
    color: #228BE6;
    font-weight: 700;
    display: block;
    margin-top: 8px;
  }
}

.CreateSms_phoneMessage{
  margin: 5px 25px 15px 15px;
  .TextDefault;
  opacity: 0.9;
  padding: 6px 10px;
  background: #E9E9EB;
  border-radius: 17px;
  position: relative;
  z-index: 1;

  &:after{
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: -2px;
    left: -4px;
    background-image: url(/Components/icons/sms_tail.svg);
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
  }
}

.HistorySmsTableWithHeader{

  .ant-table-thead tr th{
    font-weight: 500 !important;
    word-spacing: normal;
    border-bottom: none;
  }

  .ButtonIconWrapper .ant-btn {
    display: flex;
    align-items: center;
  }
}

.SmsHistoryColumn{

  &:nth-child(6){

    th&{
      background: #EBFBEE !important;
    }
  }

  &:nth-child(7){

    th&{
      background: #EBFBEE !important;
    }
  }

  &:nth-child(8){

    th&{
      background: #EBFBEE !important;
    }
  }

  &:nth-child(9),
  &:nth-child(10){
    th&{
      word-spacing: 999px !important;
    }
  }

  &:nth-child(9),
  &:nth-child(10),
  &:nth-child(11),
  &:nth-child(12)
  {

    th&{
      background: #EBFBEE !important;
    }
  }

  &:nth-child(13){

    th&{
      background: #EBFBEE !important;
    }
  }

  .TableSearchSelect{
    max-width: 100%;
  }
}

.SMSCreateModal{
  .ModalWrapper{
    border-radius: 16px;
    padding: 48px 56px;
    max-height: unset;
    background: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 0 96px rgba(0, 0, 0, 0.16);
  }

  .ModalContent{
    max-height: calc(100vh - 232px);
    overflow-y: auto;
    width: 100%;
    background: none;
    box-shadow: unset;
    border-radius: 0;
  }

  .SMSCreateModalWrapper{
    background: #FFFFFF;
    border-radius: 16px;
  }

  .ModalCloseWrapper {
    position: absolute;
    top: 16px;
    right: 16px;

    svg{
      width: 30px;
      height: 30px;
    }
  }
}

.SMSCreateModalWrapper{
  .SubtitleSecondText;
}

.SMSCreateModal_title{
  .HeadlineFirstText;
  margin-bottom: 8px;
}

.SMSCreateModal_ContentRow{
  padding: 16px 0 10px;
  border-bottom: 1px solid #E9ECEF;
}

.SMSCreateModal_label{
  .TableHeaderText;
  font-weight: 500;
  color: #868E96;
}

.SMSCreateModal_info,
.SMSCreateModal_Price,
.SMSCreateModal_btns{
  display: flex;
}

.SmsModal_value{
  margin-left: 5px;
}

.SMSCreateModal_Price{
  .SubtitleFirstText;
  margin: 56px 0 24px;
  align-items: flex-end;
}

.SMSCreateModal_PriceValue{
  .HeadlineSecondText;
  margin-left: 5px;
}

.SMSCreateModal_btns{
  .ButtonDefaultWrapper{
    margin-left: 16px;

    button{
      padding-left: 27px;
      padding-right: 27px;
    }
  }

  .ButtonPrimaryWrapper{
    button{
      width: 200px;
    }
  }
}

.SMSSuccessModal{
  .ModalContent{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.SMSCreate_SuccessWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -50px;
  height: 849px;
  max-height: calc(100vh - 232px);
  justify-content: center;
}

.SMSCreate_SuccessTitle{
  .HeadlineFirstText;
  text-align: center;
  margin-top: 48px;
}

.SmsDateTimeCell{
  display: flex;

  .SmsTime{
    margin-left: 10px;
    color: #adb5bd;
  }
}

.SmsStatusCell{
  .ButtonTextFirst;
  font-size: 12px;
  font-weight: 500;
  padding: 6px;
  text-align: center;
  border-radius: 4px;
  width: 120px;
  max-width: 120px;
}

.HistorySmsTableBottom{
  display: flex !important;
  width: 100% !important;
  flex-direction: row !important;
  justify-content: space-between;
  padding: 12px 22px;
  .ButtonTextSecond;
  font-weight: 500;

  .PaginationWrapper{
    padding-top: 0;
    font-weight: 500;

    .ant-pagination-item a{
      padding: 0;
    }
  }
}

.HistorySmsTableBottom_Right,
.HistorySmsTable_Show{
  display: flex;
  align-items: center;
}

.HistorySmsTable_Show{
  margin-left: 15px;

  .SelectWrapper{
    margin-left: 15px;
    height: 32px !important;
    max-width: 100% !important;

    .ant-select,
    .ant-select .ant-select-selector{
      height: 32px !important;
    }

    .ant-select-selector{
      background: #F8F9FA !important;
      border: 1px solid #CED4DA !important;
    }
  }
}

.CreateSms_pseudoTooltipWrapper{
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 7px;

  svg{
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: relative;
    z-index: 3;
  }
}

.CreateSms_pseudoTooltip {
  .BodySecondText;
  transform-origin: 50% 0;
  transform: scale(0.9);
  opacity: 0;
  transition: opacity 0.1s ease-in, transform 0.1s ease-in;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  position: absolute;
  top: 41px;
  right: -90px;
  user-select: text;
  pointer-events: none;
  z-index: -10;
}

.CreateSms_pseudoTooltipHelper{
  display: none;
  position: absolute;
  top: 0;
  left: -90px;
  right: -90px;
  height: 200px;
  z-index: 2;
}

.CreateSms_pseudoTooltipWrapper:hover .CreateSms_pseudoTooltipHelper{
  display: block;
}

.CreateSms_pseudoTooltipWrapper:hover .CreateSms_pseudoTooltip {
  opacity: 1;
  transform: scale(1);
  pointer-events: unset;
  z-index: 1030;
}

.CreateSms_pseudoTooltipArrow{
  position: absolute;
  display: block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}

.CreateSms_pseudoTooltipArrowContent{
  box-shadow: 2px 2px 5px rgb(0 0 0 / 6%);
  transform: translateY(11px) rotate(-135deg);
  position: absolute;
  top: 10px;
  left: -7px;
  width: 34px;
  height: 34px;
  background: #343A40;
  border-radius: 2px;
}

.CreateSms_pseudoTooltipInner{
  border-radius: 10px;
  .CaptionText;
  background-color: #343A40;
  padding: 8px 11px;
  width: 200px;
  color: #fff;

  ul{
    list-style-type: "-";
    padding-left: 10px;
    margin-bottom: 0;
  }

  li {
    padding-left: 5px;
  }

  p{
    margin-bottom: 0;
  }
}

div.ButtonPrimaryWrapper .ant-btn.CreateSms_applyButton{
  width: 200px;
  span{
    .ButtonTextFirst;
  }
}

.SmsWrapper .InputWrapper {
   .ant-input-prefix {
    .SubtitleSecondText;
     color: #868E96;
  }
}

.ant-select-dropdown.SMS_statusOptions{
  .ant-select-item{
    font-size: 12px;
    padding-left: 4px;
    padding-right: 4px;
  }
}

.SelectWrapper.SMS_statusSelected{
  font-size: 12px;
  max-width: 140px;
  width: 140px;
}

.InputWrapper .ant-input-suffix{
  .ButtonTextFirst;
  line-height: 22px;
  font-weight: 400;
  color: #868E96;
  position: relative;
  top: 2px;
}


@media screen and (max-width: 1680px) {
  .CreateSms_inputWrapper_wide{
    width: 100%;
  }
}

@media screen and (max-width: 1200px){
  .CreateSmsWrapper{
    display: block;
  }

  .CreateSms_inputs{
    padding-right: 0;
  }

  .CreateSms_phoneWrapper{
    display: none;
  }
}

@media screen and (max-width: 1100px){
  .SmsContentTitleWrapper .TitleDescription .TitleText span{
    .HeadlineSecondText;
  }

  .SmsContentPageTop{
    .TitleText{
      margin-left: 0;
    }
  }

  .HistorySmsTableBottom{
    padding-left: 16px;
    padding-right: 16px;
  }

  .PaginationWrapper div .ant-pagination .ant-pagination-item{
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
}

@media screen and (max-width: 920px){
  .SmsContentPageTop{
    padding-left: 16px;
    padding-right: 16px;
  }

  .SMSCreateModal .ModalWrapper{
    height: auto;
    min-height: 347px;
    width: calc(100vw - 32px) !important;
    left: 16px !important;
  }

  .SMSCreateModal .ModalContent{
    max-height: calc(100vh - 100px);
  }

  .SMSCreate_SuccessWrapper{
    top: auto;
    height: 349px;

    svg{
      height: 187px;
      width: 184px;
    }
  }

  .SMSCreate_SuccessTitle{
    .HeadlineThirdText;
    margin-top: 30px;
  }

  .SMSCreateModal .ModalWrapper{
    padding: 24px 17px;
  }

  .SMSCreateModal .ModalCloseWrapper{
    top: 0;
    right: 0;
  }

  .SMSCreateModal_title{
    .HeadlineThirdText;
  }

  .SMSCreateModalWrapper{
    .TextDefault;
  }

  .SMSCreateModal_label{
    font-size: 12px;
  }

  .SMSCreateModal_ContentRow{
    padding-bottom: 8px;
  }

  .SMSCreateModal_Price{
    margin: 17px 0;
    .ButtonTextSecond;
  }

  .SMSCreateModal_PriceValue{
    .HeadlineThirdText;
    line-height: 16px;
  }

  .HistorySmsTableWithHeader{
    margin-left: 16px;
    margin-right: 16px;
  }

  .CreateSms_pseudoTooltipWrapper:hover .CreateSms_pseudoTooltipHelper{
    display: none;
  }
}

@media screen and (max-width: 600px){

  .SmsContentTitleWrapper{
    display: block;
    padding-bottom: 16px;
  }

  .CreateSms_input .TooltipComponentTargetWrapper{
    right: 0;
    top: 0;
  }

  .HistorySmsTableBottom{
    display: block;

    .PaginationWrapper{
      place-items: start;
      margin-bottom: 16px;
    }
  }

  .SMSCreateModal_btns .ButtonPrimaryWrapper button{
    width: auto;
  }
}

@media screen and (max-width: 500px){
  .CreateSms_pseudoTooltipWrapper{
    position: static;
  }

  .CreateSms_stepTitle_CustomTooltip{
    position: relative;
  }

  .CreateSms_pseudoTooltip{
    right: auto;
    left: calc(100vw - 228px);
  }
}

@media screen and (max-width: 450px){
  .SmsContentTitleWrapper .TitleDescription .TitleText span{
    margin-left: 0 !important;
  }
}

.SmsHistoryHeaderGroupContainer {
  display: inline-flex;
  gap: 5px
}

.ant-table-column-has-sorters .ant-table-column-sorters, .ant-table-thead > tr > th.SmsHistoryHeaderGroupCellOrdered {
  background-color: #C5F6FA !important;
}

.ant-table-column-has-sorters .ant-table-column-sorters, .ant-table-thead > tr > th.SmsHistoryHeaderGroupCellDone {
  background-color: #D3F9D8 !important;
}

.ant-table-column-has-sorters .ant-table-column-sorters, .ant-table-thead > tr > th.SmsHistoryHeaderGroupCell {
  height: 40px;
  max-height: 40px;
}

.SmsHistoryColumnRequestedDate,
.SmsHistoryColumnRequestedStatus {
  width: 165px;
  min-width: 165px;
}

.SmsHistoryColumnRequestedDateDone {
  width: 200px;
  min-width: 200px;
}

.CreateSms_address{
  display: block;
  margin-top: 8px;
  .TextDefault;
  color: var(--Text-Link, #228BE6);
}

.CreateSms_calendarIcon svg{
  position: relative;
  top: 1px;
}

.CreateSms_RangePickerContainer{
  margin-top: 12px;

  .RangePickerWrapper  .ant-picker.ant-picker-range{
    height: 48px;
  }

  .ant-picker-range > .ant-picker-input{
    max-width: 100px;
  }
}