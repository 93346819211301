@import "./components/styles";
@import "src/_main";

.RegistrationReport {
  &__Dashboard {
    background-color: #F3F0FF;
  }
}

.RegistrationTableGuest {
  width: max-content;
}