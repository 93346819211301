.BaseLayoutLeftMenuContent {
  max-height: calc(100vh - 450px);
  overflow-y: auto;
  margin-right: 4px;
}

.BaseLayoutLeftMenuContent::-webkit-scrollbar {
  width: 8px;
  background-color: #1C1C1E;
  border-radius: 20px;
}

.BaseLayoutLeftMenuContent::-webkit-scrollbar:horizontal {
  height: 8px;
  background-color: #8E8E93;
  border-radius: 20px;
}

.BaseLayoutLeftMenuContent::-webkit-scrollbar-thumb {
  background-color: #8E8E93;
  border-radius: 20px;
  margin-right: 4px;
}

.BaseLayoutLeftMenuContent::-webkit-scrollbar-track {
  background-color: #1C1C1E;
}

@media screen and (max-width: 920px){
  .LeftMenuDesktop {
    display: none !important;
  }

  .BaseLayoutLeftMenuContent {
    max-height: 100% !important;
    overflow-y: inherit !important;
  }

  .MenuToggleMobile {
    position: absolute;
    left: 177px;
    top: 8px;
  }

  .MenuToggleMobileClose {
    left: 0 !important;
    top: 0 !important;
    background-color: #1A1B1F;
    border-bottom-right-radius: 8px;
    padding: 8px;
    z-index: 10;
  }

  .BaseLayoutLeftMenuDisappear {
    display: none !important;
  }

  .MenuToggleMobileClose {
    svg path {
      fill: #F8E800;
    }
  }
}

.BaseLayoutLeftMenuLink {
  color: #fff;
}

.BaseLayoutLeftMenuLink:hover {
  color: #F8E800 !important;
}

@media screen and (min-width: 921px){
  .MenuToggleMobile {
    display: none !important;
  }
  .LeftMenuMobile {
    display: none !important;
  }
}
