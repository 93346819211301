.MailingModalTitle {
  padding-top: 16px;
  padding-left: 32px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #212529;
}

.MailingModalContent {
  padding: 36px 24px 14px 24px;
  max-height: calc(100vh - 164px);
  overflow-y: auto;
}

.MailingModalFields {
  justify-content: space-between;
}

.MailingModalLeft, .MailingModalRight {
  width: 100%;
}

.MailingModalLeft {
  margin-right: 34px;
}

.MailingModalRectangle {
  height: 1px;
  width: 100%;
  background: #DEE2E6;
  margin-bottom: 36px;
}

.MailingModalFieldLabelWrapper {
  .ant-form-item {
    margin-bottom: 0 !important;
  }
}

.MailingModalCheckboxFieldWrapper {
  margin-bottom: 0 !important;

  .ant-form-item-control .ant-form-item-explain {
    display: none !important;
  }
}

.ant-form-item-has-error .ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content
.MailingModalCheckboxField .CheckboxWrapper .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
  border-color: @error-color;
}

.MailingModalFieldLabel {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
}

.MailingModalCheckboxField {
  padding-bottom: 8px;
}

.MailingModalSuccess {
  margin: 200px 0;

  span {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    color: #212529;
  }

  svg {
    margin-top: 24px;
  }
}