// full size

.FullSize {
  width: 100%;
  height: 100%;
}

.FullSizeWidth {
  width: 100%;
}

.FullSizeHeight {
  height: 100%;
}