.StatusNotifyUserCrudTitle > .ButtonDefaultWrapper > button {
  padding-left: @pd-12 !important;
  padding-right: @pd-12 !important;

  svg path {
    fill: @shark-500;
  }
}

.StatusNotifyUserCrudContent {
  height: calc(100vh - 176px);
}

.StatusNotifyUserCrudButtons {
  border-top: 1px solid @gray-100 ;
  &:extend(.Padding24);
}
