.SportboomChartTypePickerItem {
  min-width: 220px;
  display: flex;
  padding: 10px 16px;

  border: 1px solid #51CF66;
  border-radius: 8px;
}

.SportboomChartTypePickerRow {
  margin: 0 24px;
  gap: 12px
}

.SportboomChartTypePickerCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;

  margin-right: 8px;
  margin-top: 4px;
}

.SportboomChartTypePickerItemDefault:hover {
  cursor: pointer;
  opacity: 0.8;
}

.SportboomChartWrapper {
  margin-top: 16px;
  padding: 24px;
  min-height: 282px;


  background-color: #fff;
  border-top: 1px solid #DEE2E6;
  border-left: 1px solid #DEE2E6;
  border-right: 1px solid #DEE2E6;

  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.SportboomChartTypePicker {
  padding: 32px 0;
  background-color: #fff;

  border-bottom: 1px solid #DEE2E6;
  border-left: 1px solid #DEE2E6;
  border-right: 1px solid #DEE2E6;

  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  display: grid;
  place-items: center;
}

.SportboomInOutTooltip span {
  width: 376px;
  white-space: normal !important;
}

.SportboomChartSkeletonWrapper {
  width: 100% !important;
  height: 282px !important;
  display: block !important;

  span {
    width: 100% !important;
    height: 282px !important;
  }
}

@media screen and (max-width: 920px) {
  .SportboomInOutTooltip span {
    width: calc(100vw - 112px);
    min-width: 320px;
    white-space: normal !important;
  }


  .SportboomChartTypeMobilePicker {
    width: calc(100vw - 32px);
  }

  .SportboomChartTypePickerItem {
    padding: 0 16px !important;
    height: 48px;

    width: 50% !important;
  }

  .SportboomChartTypeMobilePickerRow {
    .SportboomChartTypePickerItem div span {
      color: @gray-600 !important;
    }

    .SportboomChartTypePickerItem {
      min-width: auto !important;
      width: 50% !important;
    }
  }

  .SportboomChartTypeMobilePickerRow .SportboomChartTypePickerItem:first-child,
  .SportboomChartTypeMobilePickerRow .SportboomChartTypePickerItem:nth-child(3){
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .SportboomChartTypeMobilePickerRow .SportboomChartTypePickerItem:nth-child(2),
  .SportboomChartTypeMobilePickerRow .SportboomChartTypePickerItem:nth-child(4){
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
