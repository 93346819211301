@import "./blue";
@import "./cyan";
@import "./grape";
@import "./gray";
@import "./green";
@import "./indig";
@import "./lemon";
@import "./lime";
@import "./orange";
@import "./pink";
@import "./red";
@import "./teal";
@import "./violet";
@import "./yellow";

// Shark

@shark-800: #212529;
@shark-500: #0D0F10;

.Shark800Color {
  color: @shark-800 !important;
}

.Shark500Color {
  color: @shark-500 !important;
}

.Shark800Background {
  background-color: @shark-800 !important;
}

.Shark500Background {
  background-color: @shark-500 !important;
}

.Shark800Border {
  border-color: @shark-800 !important;
}

.Shark500Border {
  border-color: @shark-500 !important;
}

// White

@white-800: #F4F4F4;
@white-100: #FFFFFF;

.White800Color {
  color: @white-800 !important;
}

.White100Color {
  color: @white-100 !important;
}

.White800Background {
  background-color: @white-800 !important;
}

.White100Background {
  background-color: @white-100 !important;
}

.White800Border {
  border-color: @white-800 !important;
}

.White100Border {
  border-color: @white-100 !important;
}

// Main

@bb-yellow: #F8E800;
@bb-red: #FF0025;

.BBYellowColor {
  color: @bb-yellow !important;
}

.BBRed500Color {
  color: @bb-red !important;
}

.BBYellowBackground {
  background-color: @bb-yellow !important;
}

.BBRedBackground {
  background-color: @bb-red !important;
}

.BBYellowBorder {
  border-color: @bb-yellow !important;
}

.BBRed500Border {
  border-color: @bb-red !important;
}