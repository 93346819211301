// super puper win component
@import "./SuperWin/styles";
// filter component
@import "./Filter/styles";
@import "./DashboardItem/styles";

.MainPageContentWrapper {
  padding: 40px 32px;
  transition: width 500ms ease;
}

.MainPageContentRestrictions {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 920px) {
  .MainPageContentWrapperFilter {
    width: 100vw !important;
  }

  .MainPageFilterButtonWrapper {
    display: none;
  }

  .MainPageContentRestrictions {
    width: 100vw;
  }

  .MainFilterWrapper {
    position: inherit !important;
  }

  .MainPageContentWrapper {
    padding: 56px 0 32px 0 !important;
  }

  .MobileFilterIcon {
    position: absolute;
    top: 8px;
    right: 16px;
    z-index: 10;
  }

  .MainFilterMobile {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 16px 0 0 0;
    background-color: white;
    height: 100vh;
    overflow-y: auto;
    z-index: 20;
    .MainFilterHeader {
      padding: 0 32px;
    }
    .MainFilterContent {
      padding: 16px 32px 32px 32px;
    }
    .MainFilterApplyWrapper {
      border-bottom: 0 !important;
    }
  }
}

@media screen and (min-width: 1681px) {
  .MainPageContentWrapperFilter {
    width: calc(100vw - 640px);
  }

  .MainPageContentWrapperUnFilter {
    width: calc(100vw - 340px);
  }
}

@media screen and (min-width: 1441px) and (max-width: 1680px) {
  .MainPageContentWrapper {
    width: calc(100vw - 330px) !important;
  }
}

@media screen and (min-width: 921px) {
  .MobileFilterIcon {
    display: none !important;
  }
  .MainFilterMobile {
    display: none !important;
  }
  .MainPageContentWrapper, .MainPageContentWrapperUnFilter {
    min-height: calc(100vh - 30px);
  }
}

@media screen and (min-width: 921px) and (max-width: 1440px) {
  .MainPageContentWrapper {
    width: calc(100vw - 278px) !important;
  }
}

.MainPageFilterButtonWrapper {
  position: fixed;
  top: 32px;
  width: 24px;
  height: 24px;
  transition: all 500ms ease;
  transition-property: right, background-color;
  background: #FFFFFF;
  border: 1px solid #E5E5EA;
  border-radius: 50%;
  box-sizing: border-box;
  z-index: 6;

  svg {
    margin: 3px 0 0 5px;
    transition: transform 500ms ease;
  }

  svg path {
    transition: fill 500ms ease;
  }
}

.MainPageFilterButtonWrapper:hover {
  cursor: pointer;
}

.MainPageFilterButtonWrapperActive {
  right: 306px;
  background-color: #fff;

  svg {
    transform: rotate(0deg);
  }
  svg path {
    fill: #8E8E93;
  }
}

.MainPageFilterButtonWrapperUnActive {
  right: 10px;
  background-color: black;
  svg {
    transform: rotate(180deg);
  }
  svg path {
    fill: #fff;
  }
}

.MainPageTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #0D0D0D;
  padding: 0 8px 8px 8px;
}

.MainPageHallsWrapper {
  padding: 0 8px 24px 4px;
  max-height: 120px;
  overflow-y: auto;
  margin-bottom: 12px;
  margin-right: 30px;
}

.MainPageHallsItem {
  background: rgba(255, 221, 45, 0.2);
  border: 1px solid #FEE600;
  box-sizing: border-box;
  border-radius: 40px;
  padding: 5px 24px;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin: 4px;
}

.MainPageFilterButtonWrapper {
  margin-top: -8px;
  padding-right: 8px;
  margin-left: auto;
}

.MainSuperWinContent {
  padding-bottom: 24px;
}

.DashboardItemLabelLink div span:first-child {
  text-decoration-line: underline !important;
}

.DashboardItemLabelLink div span {
  color: #007AFF !important;
}

.DashboardItemLabelLink:hover {
  cursor: pointer;
  opacity: 0.8;
}

.SuperWinsColumns_ZoneCell, .SuperWinsColumns_GameCell, .SuperWinsColumns_CurrencyCell {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}
