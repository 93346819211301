.InfoFilterContent {
  padding: 12px 0 16px 0;
}

.InfoPanelWithoutShiftTypeSwitcher {
  padding-bottom: 20px;
  margin-left: auto;
}

.InfoPanelWithoutShiftTimeWrapper {
  padding-top: 8px;
}

.GmtSelectWrapper .SelectComponent .ant-select-selector .ant-select-selection-item {
  height: 48px;
}

.ant-select-selection-item .GmtSelectActiveValue {
  padding-top: @pd-8;
}

.GmtSelectActiveValue > div {
  line-height: 30px !important;
}