.ContactsPageWrapper {
  margin: 0 auto;
  width: 1440px;
  max-width: 92%;
  .TextDefault;
  color: #000000;

  .ant-collapse {
    border: none;
    padding-left: 16px;
    padding-right: 16px;
    background-color: transparent;
  }

  .ant-collapse-content {
    border-top: none;
    background: transparent;
  }

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding: 24px 0;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    right: 3px;
  }
}

.ContactsPageSection {
  border-radius: 16px;
  border: 1px solid #DEE2E6;
  background: #FFFFFF;
  padding: 33px 0 28px;
  margin-bottom: 32px;
  position: relative;
}

.ContactsPageAnchor {
  position: relative;
  top: -100px;
  height: 1px;
  width: 1px;
  opacity: 0;
}

.ContactsPageSectionNumberWrapper {
  position: absolute;
  left: -40px;
  top: 10px;
  .ButtonTextFirst;

  & > div:nth-of-type(2) {
    text-align: center;
    padding: 13px 0;
  }
}

.ContactsPageSectionNumberUp {
  cursor: pointer;

  svg {
    transform: rotate(90deg);
  }
}

.ContactsPageSectionNumberDown {
  cursor: pointer;

  svg {
    transform: rotate(-90deg);
  }
}

.ContactsPageSectionAllBlocksWrapper {
  display: flex;
  flex-wrap: wrap;

  & > div {
    flex: 0 0 50%;
  }
}

.ContactsPageSectionBlock {
  padding: 20px 24px 8px;
}

.ContactsPageSectionHeader_inner {
  display: flex;
  align-items: center;
  color: #000000;
  position: relative;
  padding: 0 24px 21px;
}

.ContactsPageEditIcon {
  position: absolute;
  top: -5px;
  right: 16px;
  cursor: pointer;
}

.ContactsPageSectionHeader_icon {
  width: 48px;
  height: 48px;
  min-width: 48px;
  margin-right: 12px;

  img {
    display: block;
  }
}


.ContactsPageSectionHeader_title {
  .HeadlineSecondText;
}

.ContactsPageSectionHeader_subtitle {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.2px;
}

.ContactsPageSectionBlock {
  position: relative;

  .ContactsPageEditIcon {
    top: 15px;
  }
}

.ContactsPageSectionBlock_title {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.2px;
  margin-bottom: 12px;
  padding-right: 15px;
}

.ContactsPageSectionBlock_description,
.ContactsPageSectionTotalStaffWrapper {
  margin-bottom: 12px;
}

.ContactsPageSectionCredits_prefix {
  margin-right: 5px;
}

.ContactsPageSectionPhone,
.ContactsPageSectionWhatsApp,
.ContactsPageSectionTelegramm,
.ContactsPageSectionEmail{
  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.ContactsPageSectionStaffItemWrapper {
  display: flex;
  margin-bottom: 16px;

  &>div:first-child{
    margin-right: 32px;
  }

  &>div:nth-child(2){
    margin-right: 8px;
  }



  &.ContactsPageSectionStaffItemWrapperEmptyNamePosition {
    margin-bottom: 0;
  }
}

.ContactsPageSectionStaffItemWrapperEmptyNamePosition + .ContactsPageSectionStaffItemWrapper:not(.ContactsPageSectionStaffItemWrapperEmptyNamePosition) {
  margin-top: 16px;
}

.ContactsPageSectionNameAndPosition {
  flex: 0 0 210px;
}

.ContactsPageSectionCredits {
  flex: 0 0 250px;

  & > div {
    margin-bottom: 4px;
  }
}

.ContactsPageSectionPhoneWithExtWrapper {
  display: flex;
}

.ContactsPageSectionWorkHours {
  flex: 0 0 100px;
}

.ContactsPageSectionStaff_name {
  font-weight: 700;
  letter-spacing: 0.5px;
}

.ContactsPageSection_mapWrapper {
  height: 510px;
  width: 680px;
  width: 50%;
  padding: 20px 24px 20px 20px;
  max-width: 50%;
}

.ContactsPageSectionFooter {
  padding: 24px 24px 0;
}

.ButtonIconWrapper .ant-btn.ContactsPageSectionFooter_addBlockBtn {
  padding: 14px 24px 14px 48px;
  font-family: 'Gilroy';
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #212529;
  background: #F8F9FA;
  border: 1px solid #CED4DA;
  border-radius: 8px;
  height: auto;
  transition: border-color 0.25s ease-in;

  &:not([disabled]):hover,
  &:not([disabled]):focus,
  &:not([disabled]):active {

    border: 1px solid #495057;
    box-shadow: none;
  }

  &.ButtonIconDisabled,
  &.ButtonFadedDisabled,
  &[disabled],
  &.ButtonIconDisabled:hover,
  &.ButtonFadedDisabled:hover,
  &[disabled]:hover,
  &.ButtonIconDisabled:focus,
  &.ButtonFadedDisabled:focus,
  &[disabled]:focus,
  &.ButtonIconDisabled:active,
  &.ButtonFadedDisabled:active,
  &[disabled]:active {
    background: #F8F9FA;
    border: 1px solid #CED4DA;
    color: #212529;
  }

  svg {
    top: 14px;
    left: 17px;
  }

}

.ContactsPageSectionBlock_editing {

  .ContactsPageSectionHeader {
    border-bottom: 1px dashed #DEE2E6;
  }

  .ContactsPageSectionBlock {
    border-bottom: 1px dashed #DEE2E6;

    &:nth-last-of-type(1),
    &:nth-last-of-type(2) {
      border-bottom: none;
    }

    &:nth-of-type(odd) {
      border-right: 1px dashed #DEE2E6;
    }
  }

  .ContactsPageSectionOuter {
    border-bottom: 1px dashed #DEE2E6;
  }
}

svg.ContactsPageSectionIcon_disabled {
  path {
    fill: #CED4DA;
  }
}

.ContactsPageSectionDescription {
  padding-left: 20px;
  width: 50%;
  text-align: justify;
  padding-bottom: 20px
}

.ContactsPageSectionHeader_inner + .ContactsPageSectionHeader_subtitle {
  display: none;
}

.ContactsConfirmWrapper {
  .ant-modal-content {
    border-radius: 8px;
  }

  div.ant-modal-body {
    padding: 39px;
    background: #FFFFFF;
    border: 1px solid #CED4DA;
    border-radius: 8px;
  }

  .anticon-exclamation-circle {
    display: none;
  }

  .ant-modal-confirm-title {
    margin-bottom: 15px;

    .ContactsConfirmDelete_title {
      font-family: 'Gilroy';
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      color: #212529;
    }
  }

  .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
    margin: 0;
  }

  .ant-modal-confirm .ant-modal-confirm-btns {
    margin-top: 34px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .ant-btn {
    height: 48px;
    border-radius: 8px;
    padding: 0 20px;
    min-width: 115px;
    display: inline-flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    span {
      font-family: 'Gilroy';
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      display: inline-flex;
      align-content: center;
      align-items: center;
      justify-content: center;
    }

    &.ant-btn-default {
      border-color: #CED4DA;
      color: #212529;

      &:hover {
        background: #Fff;
        border-color: #495057;
        box-shadow: none;
        color: #212529;
      }
    }

    &.ant-btn-primary {
      border-color: #FA5252;
      background: #FA5252;
      text-shadow: none;
      box-shadow: none;

      &:hover {
        background: #f42626;
        border-color: #f42626;
      }

      svg {
        margin-right: 6px;

        path {
          fill: #fff;
        }
      }
    }
  }
}

@media screen and (min-width: 1751px){

  .ContactsPageSectionHasMap{

    .ContactsPageSectionAllBlocksWrapper{
      display: block;
      position: relative;

      &:after {
        content: "";
        clear: both;
        display: table;
      }
    }

    .ContactsPageSection_mapWrapper{
      float: right;
    }

    .ContactsPageSectionBlock{
      display: inline-block;
      vertical-align: top;
      width: 50%;
    }
  }

  .ContactsPageSectionBlock_editing {

    .ContactsPageSectionHasMap{
      .ContactsPageSectionAllBlocksWrapper{
        &:before{
          content:"";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 50%;
          border-left: 1px dashed #DEE2E6;;
        }
      }

      .ContactsPageSectionBlock{
        border-top: 1px dashed #DEE2E6;
        border-bottom: none;

        &:nth-of-type(2){
          border-top: none;
        }

        &:nth-of-type(odd){
          border-right: none;
        }
      }
    }
  }

}

@media screen and (max-width: 1750px) {

  .ContactsPageSectionAllBlocksWrapper {
    display: block;
  }

  .ContactsPageSection_mapWrapper{
    width: auto;
    max-width: unset;
  }

  .ContactsPageSectionDescription {
    width: auto;
    padding-right: 20px;
  }

  .ContactsPageSectionBlock_editing{
    .ContactsPageSectionBlock:nth-of-type(odd) {
      border-right: none;
    }

    .ContactsPageSectionBlock:nth-last-of-type(2){
      border-bottom: 1px dashed #DEE2E6;
    }
  }
}

@media screen and (max-width: 1024px){
  .ContactsPageSectionStaffItemWrapper {
    display: block;
  }
}

@media screen and (max-width: 920px) {

  .ContactsPageWrapper {
    max-width: unset;
    width: auto;
    padding-left: 0;
    padding-right: 0;
  }

  .ContactsPageSectionStaffItemWrapper {

    &>div:first-child{
      margin-right: 0;
    }

    &>div:nth-child(2){
      margin-right: 0;
    }
  }

  .ContactsPageSection_mapWrapper{
    height: 350px;
  }

  .ContactsPageSection {
    border: none;
    padding: 0;
    border-radius: 0;
    margin-bottom: 0;
  }

  .ContactsPageSection_mapWrapper {
    padding: 0;
  }

  .ContactsPageAnchor {
    top: -140px;
  }

  .ContactsPageSectionHeader_inner {
    padding: 0;
  }

  .ContactsPageSectionHeader_title {
    font-size: 20px;
    line-height: 25px;
  }

  .ContactsPageSectionHeader_icon img {
    width: 48px;
    height: 48px;
  }

  .ContactsPageSectionDescription {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 12px;
  }

  .ContactsPageSectionHeader_subtitle {
    margin-bottom: 12px;
  }

  .ContactsPageSectionBlock {
    padding: 0;
    margin-bottom: 32px;
  }

  .ContactsPageSectionBlock_title {
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 12px;
    padding-right: 0;
  }

  .ContactsPageSectionHeader_inner + .ContactsPageSectionHeader_subtitle {
    display: block;
  }

  .ContactsPageSectionCredits {
    margin-bottom: 8px;
  }

  .ContactsPageSectionCredits > div {
    margin-bottom: 0;
    line-height: 1.4;
  }

  .ContactsPageSectionCredits + .ContactsPageSectionWorkHours {
    position: relative;
    top: -8px;
  }

}

.ContactsPage_addBlockBtnFooter {
  margin-bottom: 60px;
}

.ContactsCollapse {
  padding: 6px 0px;
}