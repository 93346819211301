.TableHeaderCupisDateWidth {
  width: 50px !important;
}

.TableHeaderCupisCurrencyWidth {
  width: 120px !important;
}

.TableHeaderCupisPersentWidth {
  width: 80px !important;
}

.TableHeaderCupisWidth {
  width: 140px !important;
}

.CupisInsideTable {
  border: 1px solid #DEE2E6;
  border-radius: 8px;

  margin-top: 12px;
  width: fit-content;
}

.CupisInsideTitle {
  text-align: center;
  padding: 13px 0;
  height: 48px;
}

.CupisInsideContentWrapper {
  padding: 26px;

  display: flex;
  flex-direction: column;
  gap: 26px;
}

.CupisInsideSubtitle, .CupisInsideTitle {
  border-right: 1px solid #DEE2E6;
  border-bottom: 1px solid #DEE2E6;
}

.CupisInsideSubtitle {
  white-space: pre;
}

.CupisInsideFirstLeft {
  border-top-left-radius: 8px;
}

.CupisInsideNoRightBorder {
  border-right: 0 !important;
}

.ButtonTextSecondCupis {
  font-weight: 500 !important;
  width: 166px;
  height: 48px;

  text-align: center;
  place-items: center;
  display: grid;
}

.CupisInsideValue, .CupisInsideBonusValue {
  text-align: center;
  place-items: center;
  display: grid;

  border-right: 1px solid #DEE2E6;
  padding: 6px 0;
}

.CupisInsideValue, .CupisInsideBonusColumn {
  width: 166px;
}

.CupisInsideTableBonus {
  width: 332px;
}

.CupisInsideTableGuests {
  width: 368px;
}

.CupisInsideSubtitleGuests, .CupisInsideGuestsColumn {
  width: 184px;
}

.CupisInsideTableBonus .CupisInsideTitle,
.CupisInsideSubtitleBonus {
  background: #FFF4E6;
}


.CupisInsideTableGuests .CupisInsideTitle,
.CupisInsideSubtitleGuests {
  background: #E6FCF5;
}

.CupisInsideTableDetail .CupisInsideTitle,
.CupisInsideSubtitleDetail {
  background: #F3F0FF;
}


.CupisInsideLastTop {
  border-top-right-radius: 8px;
}

.CupisInsideLastBottom {
  border-bottom-right-radius: 8px !important;
}

@media screen and (max-width: 920px) {
  .CupisInsideTableBonus, .CupisInsideTableDetail, .CupisInsideTableGuests {
    width: 310px !important;
  }

  .CupisInsideGuestsColumn, .CupisInsideBonusColumn {
    width: 155px;
  }

  .CupisInsideSubtitleDetail {
    width: 103.3px;
  }


  .CupisInsideFirstLeft {
    border-top-right-radius: 8px;
  }


  .CupisInsideLastTop {
    border-top-right-radius: 0 !important;
  }

  .CupisInsideMobileNoRightBorder {
    border-right: 0 !important;
  }


  .CupisInsideLastMobileBottom {
    border-bottom-left-radius: 8px !important;
  }
}

.TablePaginationPageSizeWrapper {
  margin-left: auto;
}