.PhotoPreviewModal {
  &__Content {
    padding: 0 !important;
  }
  &__ImageWrapper {
    background-color: @shark-800;

    border-radius: 16px 16px 0 0;
    overflow: hidden;

    position: relative;

    &__Image {
      margin: 0 auto;

      height: 634px;
      max-height: calc(100% - 96px);
    }

    &__Close {
      position: absolute;

      right: 16px;
      top: 16px;
    }
  }

  &__Data {
    height: 96px;
    padding: 24px 32px;

    &__Download {
      margin-left: auto;

      .ButtonDefaultWrapper button {
        padding: 10px;
        margin-top: 2px;

        height: 40px;

        svg path {
          fill: black;
        }
      }
    }
  }
}

@media screen and (max-width: 920px){
  .PhotoPreviewModal__ImageWrapper__Image {
    height: 250px;
  }
}