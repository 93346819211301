@import "./Cards/styles";
@import "./CRUD/styles";

.AccessModalWrapper {
  min-height: auto !important;
  padding-bottom: 32px !important;
}

.AccessBaseTitle {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #212529;
  white-space: pre-wrap;
}

@media screen and (min-width: 921px) {
  .AccessAddButton {
    margin-left: auto;
  }
}

@media screen and (max-width: 920px) {
  .AccessAddButton {
    margin: 16px 0 0 16px;
  }

  .AccessBaseTitle {
    width: fit-content !important;
    margin-left: 16px;
  }
}