.Blue900Color {
  color: #1864AB !important;
}
.Blue800Color {
  color: #1971C2 !important;
}
.Blue700Color {
  color: #1C7ED6 !important;
}
.Blue600Color,
.LinkText {
  color: #228BE6 !important;
}
.Blue500Color {
  color: #339AF0 !important;
}
.Blue400Color {
  color: #4DABF7 !important;
}
.Blue300Color {
  color: #74C0FC !important;
}
.Blue200Color {
  color: #A5D8FF !important;
}
.Blue100Color {
  color: #D0EBFF !important;
}
.Blue10Color {
  color: #E7F5FF !important;
}
.Blue900Background {
  background-color: #1864AB !important;
}
.Blue800Background {
  background-color: #1971C2 !important;
}
.Blue700Background {
  background-color: #1C7ED6 !important;
}
.Blue600Background {
  background-color: #228BE6 !important;
}
.Blue500Background {
  background-color: #339AF0 !important;
}
.Blue400Background {
  background-color: #4DABF7 !important;
}
.Blue300Background {
  background-color: #74C0FC !important;
}
.Blue200Background {
  background-color: #A5D8FF !important;
}
.Blue100Background {
  background-color: #D0EBFF !important;
}
.Blue10Background {
  background-color: #E7F5FF !important;
}
.Blue900Border {
  border-color: #1864AB !important;
}
.Blue800Border {
  border-color: #1971C2 !important;
}
.Blue700Border {
  border-color: #1C7ED6 !important;
}
.Blue600Border {
  border-color: #228BE6 !important;
}
.Blue500Border {
  border-color: #339AF0 !important;
}
.Blue400Border {
  border-color: #4DABF7 !important;
}
.Blue300Border {
  border-color: #74C0FC !important;
}
.Blue200Border {
  border-color: #A5D8FF !important;
}
.Blue100Border {
  border-color: #D0EBFF !important;
}
.Blue10Border {
  border-color: #E7F5FF !important;
}
.Cyan900Color {
  color: #0B7285 !important;
}
.Cyan800Color {
  color: #0C8599 !important;
}
.Cyan700Color {
  color: #1098AD !important;
}
.Cyan600Color {
  color: #15AABF !important;
}
.Cyan500Color {
  color: #22B8CF !important;
}
.Cyan400Color {
  color: #3BC9DB !important;
}
.Cyan300Color {
  color: #66D9E8 !important;
}
.Cyan200Color {
  color: #99E9F2 !important;
}
.Cyan100Color {
  color: #C5F6FA !important;
}
.Cyan10Color {
  color: #E3FAFC !important;
}
.Cyan900Background {
  background-color: #0B7285 !important;
}
.Cyan800Background {
  background-color: #0C8599 !important;
}
.Cyan700Background {
  background-color: #1098AD !important;
}
.Cyan600Background {
  background-color: #15AABF !important;
}
.Cyan500Background {
  background-color: #22B8CF !important;
}
.Cyan400Background {
  background-color: #3BC9DB !important;
}
.Cyan300Background {
  background-color: #66D9E8 !important;
}
.Cyan200Background {
  background-color: #99E9F2 !important;
}
.Cyan100Background {
  background-color: #C5F6FA !important;
}
.Cyan10Background {
  background-color: #E3FAFC !important;
}
.Cyan900Border {
  border-color: #0B7285 !important;
}
.Cyan800Border {
  border-color: #0C8599 !important;
}
.Cyan700Border {
  border-color: #1098AD !important;
}
.Cyan600Border {
  border-color: #15AABF !important;
}
.Cyan500Border {
  border-color: #22B8CF !important;
}
.Cyan400Border {
  border-color: #3BC9DB !important;
}
.Cyan300Border {
  border-color: #66D9E8 !important;
}
.Cyan200Border {
  border-color: #99E9F2 !important;
}
.Cyan100Border {
  border-color: #C5F6FA !important;
}
.Cyan10Border {
  border-color: #E3FAFC !important;
}
.Grape900Color {
  color: #862E9C !important;
}
.Grape800Color {
  color: #9C36B5 !important;
}
.Grape700Color {
  color: #AE3EC9 !important;
}
.Grape600Color {
  color: #BE4BDB !important;
}
.Grape500Color {
  color: #CC5DE8 !important;
}
.Grape400Color {
  color: #DA77F2 !important;
}
.Grape300Color {
  color: #E599F7 !important;
}
.Grape200Color {
  color: #EEBEFA !important;
}
.Grape100Color {
  color: #F3D9FA !important;
}
.Grape10Color {
  color: #F8F0FC !important;
}
.Grape900Background {
  background-color: #862E9C !important;
}
.Grape800Background {
  background-color: #9C36B5 !important;
}
.Grape700Background {
  background-color: #AE3EC9 !important;
}
.Grape600Background {
  background-color: #BE4BDB !important;
}
.Grape500Background {
  background-color: #CC5DE8 !important;
}
.Grape400Background {
  background-color: #DA77F2 !important;
}
.Grape300Background {
  background-color: #E599F7 !important;
}
.Grape200Background {
  background-color: #EEBEFA !important;
}
.Grape100Background {
  background-color: #F3D9FA !important;
}
.Grape10Background {
  background-color: #F8F0FC !important;
}
.Grape900Border {
  border-color: #862E9C !important;
}
.Grape800Border {
  border-color: #9C36B5 !important;
}
.Grape700Border {
  border-color: #AE3EC9 !important;
}
.Grape600Border {
  border-color: #BE4BDB !important;
}
.Grape500Border {
  border-color: #CC5DE8 !important;
}
.Grape400Border {
  border-color: #DA77F2 !important;
}
.Grape300Border {
  border-color: #E599F7 !important;
}
.Grape200Border {
  border-color: #EEBEFA !important;
}
.Grape100Border {
  border-color: #F3D9FA !important;
}
.Grape10Border {
  border-color: #F8F0FC !important;
}
.Gray900Color {
  color: #212529 !important;
}
.Gray800Color {
  color: #343A40 !important;
}
.Gray700Color {
  color: #495057 !important;
}
.Gray600Color {
  color: #868E96 !important;
}
.Gray500Color {
  color: #ADB5BD !important;
}
.Gray400Color {
  color: #CED4DA !important;
}
.Gray300Color {
  color: #DEE2E6 !important;
}
.Gray200Color {
  color: #E9ECEF !important;
}
.Gray100Color {
  color: #F1F3F5 !important;
}
.Gray10Color {
  color: #F8F9FA !important;
}
.Gray900Background {
  background-color: #212529 !important;
}
.Gray800Background {
  background-color: #343A40 !important;
}
.Gray700Background {
  background-color: #495057 !important;
}
.Gray600Background {
  background-color: #868E96 !important;
}
.Gray500Background {
  background-color: #ADB5BD !important;
}
.Gray400Background {
  background-color: #CED4DA !important;
}
.Gray300Background {
  background-color: #DEE2E6 !important;
}
.Gray200Background {
  background-color: #E9ECEF !important;
}
.Gray100Background {
  background-color: #F1F3F5 !important;
}
.Gray10Background {
  background-color: #F8F9FA !important;
}
.Gray900Border {
  border-color: #212529 !important;
}
.Gray800Border {
  border-color: #343A40 !important;
}
.Gray700Border {
  border-color: #495057 !important;
}
.Gray600Border {
  border-color: #868E96 !important;
}
.Gray500Border {
  border-color: #ADB5BD !important;
}
.Gray400Border {
  border-color: #CED4DA !important;
}
.Gray300Border {
  border-color: #DEE2E6 !important;
}
.Gray200Border {
  border-color: #E9ECEF !important;
}
.Gray100Border {
  border-color: #F1F3F5 !important;
}
.Gray10Border {
  border-color: #F8F9FA !important;
}
.Green900Color {
  color: #2B8A3E !important;
}
.Green800Color {
  color: #2F9E44 !important;
}
.Green700Color {
  color: #37B24D !important;
}
.Green600Color {
  color: #40C057 !important;
}
.Green500Color {
  color: #51CF66 !important;
}
.Green400Color {
  color: #69DB7C !important;
}
.Green300Color {
  color: #8CE99A !important;
}
.Green200Color {
  color: #B2F2BB !important;
}
.Green100Color {
  color: #D3F9D8 !important;
}
.Green10Color {
  color: #EBFBEE !important;
}
.Green900Background {
  background-color: #2B8A3E !important;
}
.Green800Background {
  background-color: #2F9E44 !important;
}
.Green700Background {
  background-color: #37B24D !important;
}
.Green600Background {
  background-color: #40C057 !important;
}
.Green500Background {
  background-color: #51CF66 !important;
}
.Green400Background {
  background-color: #69DB7C !important;
}
.Green300Background {
  background-color: #8CE99A !important;
}
.Green200Background {
  background-color: #B2F2BB !important;
}
.Green100Background {
  background-color: #D3F9D8 !important;
}
.Green10Background {
  background-color: #EBFBEE !important;
}
.Green900Border {
  border-color: #2B8A3E !important;
}
.Green800Border {
  border-color: #2F9E44 !important;
}
.Green700Border {
  border-color: #37B24D !important;
}
.Green600Border {
  border-color: #40C057 !important;
}
.Green500Border {
  border-color: #51CF66 !important;
}
.Green400Border {
  border-color: #69DB7C !important;
}
.Green300Border {
  border-color: #8CE99A !important;
}
.Green200Border {
  border-color: #B2F2BB !important;
}
.Green100Border {
  border-color: #D3F9D8 !important;
}
.Green10Border {
  border-color: #EBFBEE !important;
}
.Indigo900Color {
  color: #364FC7 !important;
}
.Indigo800Color {
  color: #3B5BDB !important;
}
.Indigo700Color {
  color: #4263EB !important;
}
.Indigo600Color {
  color: #4C6EF5 !important;
}
.Indigo500Color {
  color: #5C7CFA !important;
}
.Indigo400Color {
  color: #748FFC !important;
}
.Indigo300Color {
  color: #91A7FF !important;
}
.Indigo200Color {
  color: #BAC8FF !important;
}
.Indigo100Color {
  color: #DBE4FF !important;
}
.Indigo10Color {
  color: #EDF2FF !important;
}
.Indigo900Background {
  background-color: #364FC7 !important;
}
.Indigo800Background {
  background-color: #3B5BDB !important;
}
.Indigo700Background {
  background-color: #4263EB !important;
}
.Indigo600Background {
  background-color: #4C6EF5 !important;
}
.Indigo500Background {
  background-color: #5C7CFA !important;
}
.Indigo400Background {
  background-color: #748FFC !important;
}
.Indigo300Background {
  background-color: #91A7FF !important;
}
.Indigo200Background {
  background-color: #BAC8FF !important;
}
.Indigo100Background {
  background-color: #DBE4FF !important;
}
.Indigo10Background {
  background-color: #EDF2FF !important;
}
.Indigo900Border {
  border-color: #364FC7 !important;
}
.Indigo800Border {
  border-color: #3B5BDB !important;
}
.Indigo700Border {
  border-color: #4263EB !important;
}
.Indigo600Border {
  border-color: #4C6EF5 !important;
}
.Indigo500Border {
  border-color: #5C7CFA !important;
}
.Indigo400Border {
  border-color: #748FFC !important;
}
.Indigo300Border {
  border-color: #91A7FF !important;
}
.Indigo200Border {
  border-color: #BAC8FF !important;
}
.Indigo100Border {
  border-color: #DBE4FF !important;
}
.Indigo10Border {
  border-color: #EDF2FF !important;
}
.Lemon900Color {
  color: #8A7500 !important;
}
.Lemon800Color {
  color: #AA9200 !important;
}
.Lemon700Color {
  color: #C9AE00 !important;
}
.Lemon600Color {
  color: #E4C800 !important;
}
.Lemon500Color {
  color: #FCE000 !important;
}
.Lemon400Color {
  color: #FFED37 !important;
}
.Lemon300Color {
  color: #FFF450 !important;
}
.Lemon200Color {
  color: #FFF450 !important;
}
.Lemon100Color {
  color: #FFFB9F !important;
}
.Lemon10Color {
  color: #FFFFBB !important;
}
.Lemon900Background {
  background-color: #8A7500 !important;
}
.Lemon800Background {
  background-color: #AA9200 !important;
}
.Lemon700Background {
  background-color: #C9AE00 !important;
}
.Lemon600Background {
  background-color: #E4C800 !important;
}
.Lemon500Background {
  background-color: #FCE000 !important;
}
.Lemon400Background {
  background-color: #FFED37 !important;
}
.Lemon300Background {
  background-color: #FFF450 !important;
}
.Lemon200Background {
  background-color: #FFF450 !important;
}
.Lemon100Background {
  background-color: #FFFB9F !important;
}
.Lemon10Background {
  background-color: #FFFFBB !important;
}
.Lemon900Border {
  border-color: #8A7500 !important;
}
.Lemon800Border {
  border-color: #AA9200 !important;
}
.Lemon700Border {
  border-color: #C9AE00 !important;
}
.Lemon600Border {
  border-color: #E4C800 !important;
}
.Lemon500Border {
  border-color: #FCE000 !important;
}
.Lemon400Border {
  border-color: #FFED37 !important;
}
.Lemon300Border {
  border-color: #FFF450 !important;
}
.Lemon200Border {
  border-color: #FFF450 !important;
}
.Lemon100Border {
  border-color: #FFFB9F !important;
}
.Lemon10Border {
  border-color: #FFFFBB !important;
}
.Lime900Color {
  color: #5C940D !important;
}
.Lime800Color {
  color: #66A80F !important;
}
.Lime700Color {
  color: #74B816 !important;
}
.Lime600Color {
  color: #82C91E !important;
}
.Lime500Color {
  color: #94D82D !important;
}
.Lime400Color {
  color: #A9E34B !important;
}
.Lime300Color {
  color: #C0EB75 !important;
}
.Lime200Color {
  color: #D8F5A2 !important;
}
.Lime100Color {
  color: #E9FAC8 !important;
}
.Lime10Color {
  color: #F4FCE3 !important;
}
.Lime900Background {
  background-color: #5C940D !important;
}
.Lime800Background {
  background-color: #66A80F !important;
}
.Lime700Background {
  background-color: #74B816 !important;
}
.Lime600Background {
  background-color: #82C91E !important;
}
.Lime500Background {
  background-color: #94D82D !important;
}
.Lime400Background {
  background-color: #A9E34B !important;
}
.Lime300Background {
  background-color: #C0EB75 !important;
}
.Lime200Background {
  background-color: #D8F5A2 !important;
}
.Lime100Background {
  background-color: #E9FAC8 !important;
}
.Lime10Background {
  background-color: #F4FCE3 !important;
}
.Lime900Border {
  border-color: #5C940D !important;
}
.Lime800Border {
  border-color: #66A80F !important;
}
.Lime700Border {
  border-color: #74B816 !important;
}
.Lime600Border {
  border-color: #82C91E !important;
}
.Lime500Border {
  border-color: #94D82D !important;
}
.Lime400Border {
  border-color: #A9E34B !important;
}
.Lime300Border {
  border-color: #C0EB75 !important;
}
.Lime200Border {
  border-color: #D8F5A2 !important;
}
.Lime100Border {
  border-color: #E9FAC8 !important;
}
.Lime10Border {
  border-color: #F4FCE3 !important;
}
.Orange900Color {
  color: #D9480F !important;
}
.Orange800Color {
  color: #E8590C !important;
}
.Orange700Color {
  color: #F76707 !important;
}
.Orange600Color {
  color: #FD7E14 !important;
}
.Orange500Color {
  color: #FF922B !important;
}
.Orange400Color {
  color: #FFA94D !important;
}
.Orange300Color {
  color: #FFC078 !important;
}
.Orange200Color {
  color: #FFD8A8 !important;
}
.Orange100Color {
  color: #FFE8CC !important;
}
.Orange10Color {
  color: #FFF4E6 !important;
}
.Orange900Background {
  background-color: #D9480F !important;
}
.Orange800Background {
  background-color: #E8590C !important;
}
.Orange700Background {
  background-color: #F76707 !important;
}
.Orange600Background {
  background-color: #FD7E14 !important;
}
.Orange500Background {
  background-color: #FF922B !important;
}
.Orange400Background {
  background-color: #FFA94D !important;
}
.Orange300Background {
  background-color: #FFC078 !important;
}
.Orange200Background {
  background-color: #FFD8A8 !important;
}
.Orange100Background {
  background-color: #FFE8CC !important;
}
.Orange10Background {
  background-color: #FFF4E6 !important;
}
.Orange900Border {
  border-color: #D9480F !important;
}
.Orange800Border {
  border-color: #E8590C !important;
}
.Orange700Border {
  border-color: #F76707 !important;
}
.Orange600Border {
  border-color: #FD7E14 !important;
}
.Orange500Border {
  border-color: #FF922B !important;
}
.Orange400Border {
  border-color: #FFA94D !important;
}
.Orange300Border {
  border-color: #FFC078 !important;
}
.Orange200Border {
  border-color: #FFD8A8 !important;
}
.Orange100Border {
  border-color: #FFE8CC !important;
}
.Orange10Border {
  border-color: #FFF4E6 !important;
}
.Pink900Color {
  color: #A61E4D !important;
}
.Pink800Color {
  color: #C2255C !important;
}
.Pink700Color {
  color: #D6336C !important;
}
.Pink600Color {
  color: #E64980 !important;
}
.Pink500Color {
  color: #F06595 !important;
}
.Pink400Color {
  color: #F783AC !important;
}
.Pink300Color {
  color: #FAA2C1 !important;
}
.Pink200Color {
  color: #FCC2D7 !important;
}
.Pink100Color {
  color: #FFDEEB !important;
}
.Pink10Color {
  color: #FFF0F6 !important;
}
.Pink900Background {
  background-color: #A61E4D !important;
}
.Pink800Background {
  background-color: #C2255C !important;
}
.Pink700Background {
  background-color: #D6336C !important;
}
.Pink600Background {
  background-color: #E64980 !important;
}
.Pink500Background {
  background-color: #F06595 !important;
}
.Pink400Background {
  background-color: #F783AC !important;
}
.Pink300Background {
  background-color: #FAA2C1 !important;
}
.Pink200Background {
  background-color: #FCC2D7 !important;
}
.Pink100Background {
  background-color: #FFDEEB !important;
}
.Pink10Background {
  background-color: #FFF0F6 !important;
}
.Pink900Border {
  border-color: #A61E4D !important;
}
.Pink800Border {
  border-color: #C2255C !important;
}
.Pink700Border {
  border-color: #D6336C !important;
}
.Pink600Border {
  border-color: #E64980 !important;
}
.Pink500Border {
  border-color: #F06595 !important;
}
.Pink400Border {
  border-color: #F783AC !important;
}
.Pink300Border {
  border-color: #FAA2C1 !important;
}
.Pink200Border {
  border-color: #FCC2D7 !important;
}
.Pink100Border {
  border-color: #FFDEEB !important;
}
.Pink10Border {
  border-color: #FFF0F6 !important;
}
.Red900Color {
  color: #C92A2A !important;
}
.Red800Color {
  color: #E03131 !important;
}
.Red700Color {
  color: #F03E3E !important;
}
.Red600Color {
  color: #FA5252 !important;
}
.Red500Color {
  color: #FF6B6B !important;
}
.Red400Color {
  color: #FF8787 !important;
}
.Red300Color {
  color: #FFA8A8 !important;
}
.Red200Color {
  color: #FFC9C9 !important;
}
.Red100Color {
  color: #FFE3E3 !important;
}
.Red10Color {
  color: #FFF5F5 !important;
}
.Red900Background {
  background-color: #C92A2A !important;
}
.Red800Background {
  background-color: #E03131 !important;
}
.Red700Background {
  background-color: #F03E3E !important;
}
.Red600Background {
  background-color: #FA5252 !important;
}
.Red500Background {
  background-color: #FF6B6B !important;
}
.Red400Background {
  background-color: #FF8787 !important;
}
.Red300Background {
  background-color: #FFA8A8 !important;
}
.Red200Background {
  background-color: #FFC9C9 !important;
}
.Red100Background {
  background-color: #FFE3E3 !important;
}
.Red10Background {
  background-color: #FFF5F5 !important;
}
.Red900Border {
  border-color: #C92A2A !important;
}
.Red800Border {
  border-color: #E03131 !important;
}
.Red700Border {
  border-color: #F03E3E !important;
}
.Red600Border {
  border-color: #FA5252 !important;
}
.Red500Border {
  border-color: #FF6B6B !important;
}
.Red400Border {
  border-color: #FF8787 !important;
}
.Red300Border {
  border-color: #FFA8A8 !important;
}
.Red200Border {
  border-color: #FFC9C9 !important;
}
.Red100Border {
  border-color: #FFE3E3 !important;
}
.Red10Border {
  border-color: #FFF5F5 !important;
}
.Teal900Color {
  color: #087F5B !important;
}
.Teal800Color {
  color: #099268 !important;
}
.Teal700Color {
  color: #0CA678 !important;
}
.Teal600Color {
  color: #12B886 !important;
}
.Teal500Color {
  color: #20C997 !important;
}
.Teal400Color {
  color: #38D9A9 !important;
}
.Teal300Color {
  color: #63E6BE !important;
}
.Teal200Color {
  color: #96F2D7 !important;
}
.Teal100Color {
  color: #C3FAE8 !important;
}
.Teal10Color {
  color: #E6FCF5 !important;
}
.Teal900Background {
  background-color: #087F5B !important;
}
.Teal800Background {
  background-color: #099268 !important;
}
.Teal700Background {
  background-color: #0CA678 !important;
}
.Teal600Background {
  background-color: #12B886 !important;
}
.Teal500Background {
  background-color: #20C997 !important;
}
.Teal400Background {
  background-color: #38D9A9 !important;
}
.Teal300Background {
  background-color: #63E6BE !important;
}
.Teal200Background {
  background-color: #96F2D7 !important;
}
.Teal100Background {
  background-color: #C3FAE8 !important;
}
.Teal10Background {
  background-color: #E6FCF5 !important;
}
.Teal900Border {
  border-color: #087F5B !important;
}
.Teal800Border {
  border-color: #099268 !important;
}
.Teal700Border {
  border-color: #0CA678 !important;
}
.Teal600Border {
  border-color: #12B886 !important;
}
.Teal500Border {
  border-color: #20C997 !important;
}
.Teal400Border {
  border-color: #38D9A9 !important;
}
.Teal300Border {
  border-color: #63E6BE !important;
}
.Teal200Border {
  border-color: #96F2D7 !important;
}
.Teal100Border {
  border-color: #C3FAE8 !important;
}
.Teal10Border {
  border-color: #E6FCF5 !important;
}
.Violet900Color {
  color: #5F3DC4 !important;
}
.Violet800Color {
  color: #6741D9 !important;
}
.Violet700Color {
  color: #7048E8 !important;
}
.Violet600Color {
  color: #7950F2 !important;
}
.Violet500Color {
  color: #845EF7 !important;
}
.Violet400Color {
  color: #9775FA !important;
}
.Violet300Color {
  color: #B197FC !important;
}
.Violet200Color {
  color: #D0BFFF !important;
}
.Violet100Color {
  color: #E5DBFF !important;
}
.Violet10Color {
  color: #F3F0FF !important;
}
.Violet900Background {
  background-color: #5F3DC4 !important;
}
.Violet800Background {
  background-color: #6741D9 !important;
}
.Violet700Background {
  background-color: #7048E8 !important;
}
.Violet600Background {
  background-color: #7950F2 !important;
}
.Violet500Background {
  background-color: #845EF7 !important;
}
.Violet400Background {
  background-color: #9775FA !important;
}
.Violet300Background {
  background-color: #B197FC !important;
}
.Violet200Background {
  background-color: #D0BFFF !important;
}
.Violet100Background {
  background-color: #E5DBFF !important;
}
.Violet10Background {
  background-color: #F3F0FF !important;
}
.Violet900Border {
  border-color: #5F3DC4 !important;
}
.Violet800Border {
  border-color: #6741D9 !important;
}
.Violet700Border {
  border-color: #7048E8 !important;
}
.Violet600Border {
  border-color: #7950F2 !important;
}
.Violet500Border {
  border-color: #845EF7 !important;
}
.Violet400Border {
  border-color: #9775FA !important;
}
.Violet300Border {
  border-color: #B197FC !important;
}
.Violet200Border {
  border-color: #D0BFFF !important;
}
.Violet100Border {
  border-color: #E5DBFF !important;
}
.Violet10Border {
  border-color: #F3F0FF !important;
}
.Yellow900Color {
  color: #E67700 !important;
}
.Yellow800Color {
  color: #F08C00 !important;
}
.Yellow700Color {
  color: #F59F00 !important;
}
.Yellow600Color {
  color: #FAB005 !important;
}
.Yellow500Color {
  color: #FCC419 !important;
}
.Yellow400Color {
  color: #FFD43B !important;
}
.Yellow300Color {
  color: #FFE066 !important;
}
.Yellow200Color {
  color: #FFEC99 !important;
}
.Yellow100Color {
  color: #FFF3BF !important;
}
.Yellow10Color {
  color: #FFF9DB !important;
}
.Yellow900Background {
  background-color: #E67700 !important;
}
.Yellow800Background {
  background-color: #F08C00 !important;
}
.Yellow700Background {
  background-color: #F59F00 !important;
}
.Yellow600Background {
  background-color: #FAB005 !important;
}
.Yellow500Background {
  background-color: #FCC419 !important;
}
.Yellow400Background {
  background-color: #FFD43B !important;
}
.Yellow300Background {
  background-color: #FFE066 !important;
}
.Yellow200Background {
  background-color: #FFEC99 !important;
}
.Yellow100Background {
  background-color: #FFF3BF !important;
}
.Yellow10Background {
  background-color: #FFF9DB !important;
}
.Yellow900Border {
  border-color: #E67700 !important;
}
.Yellow800Border {
  border-color: #F08C00 !important;
}
.Yellow700Border {
  border-color: #F59F00 !important;
}
.Yellow600Border {
  border-color: #FAB005 !important;
}
.Yellow500Border {
  border-color: #FCC419 !important;
}
.Yellow400Border {
  border-color: #FFD43B !important;
}
.Yellow300Border {
  border-color: #FFE066 !important;
}
.Yellow200Border {
  border-color: #FFEC99 !important;
}
.Yellow100Border {
  border-color: #FFF3BF !important;
}
.Yellow10Border {
  border-color: #FFF9DB !important;
}
.Shark800Color {
  color: #212529 !important;
}
.Shark500Color {
  color: #0D0F10 !important;
}
.Shark800Background {
  background-color: #212529 !important;
}
.Shark500Background {
  background-color: #0D0F10 !important;
}
.Shark800Border {
  border-color: #212529 !important;
}
.Shark500Border {
  border-color: #0D0F10 !important;
}
.White800Color {
  color: #F4F4F4 !important;
}
.White100Color {
  color: #FFFFFF !important;
}
.White800Background {
  background-color: #F4F4F4 !important;
}
.White100Background {
  background-color: #FFFFFF !important;
}
.White800Border {
  border-color: #F4F4F4 !important;
}
.White100Border {
  border-color: #FFFFFF !important;
}
.BBYellowColor {
  color: #F8E800 !important;
}
.BBRed500Color {
  color: #FF0025 !important;
}
.BBYellowBackground {
  background-color: #F8E800 !important;
}
.BBRedBackground {
  background-color: #FF0025 !important;
}
.BBYellowBorder {
  border-color: #F8E800 !important;
}
.BBRed500Border {
  border-color: #FF0025 !important;
}
.MarginAuto {
  margin: auto;
}
.MarginLeftAuto {
  margin-left: auto;
}
.MarginRightAuto {
  margin-right: auto;
}
.MarginTopAuto {
  margin-top: auto;
}
.MarginBottomAuto {
  margin-bottom: auto;
}
.Padding2 {
  padding: 2px;
}
.PaddingLeft2 {
  padding-left: 2px;
}
.PaddingRight2 {
  padding-right: 2px;
}
.PaddingTop2 {
  padding-top: 2px;
}
.PaddingBottom2 {
  padding-bottom: 2px;
}
.Margin2 {
  margin: 2px;
}
.MarginLeft2 {
  margin-left: 2px;
}
.MarginRight2 {
  margin-right: 2px;
}
.MarginTop2 {
  margin-top: 2px;
}
.MarginBottom2 {
  margin-bottom: 2px;
}
.Gap2 {
  gap: 2px;
}
.Padding4 {
  padding: 4px;
}
.PaddingLeft4 {
  padding-left: 4px;
}
.PaddingRight4 {
  padding-right: 4px;
}
.PaddingTop4 {
  padding-top: 4px;
}
.PaddingBottom4 {
  padding-bottom: 4px;
}
.Margin4 {
  margin: 4px;
}
.MarginLeft4 {
  margin-left: 4px;
}
.MarginRight4 {
  margin-right: 4px;
}
.MarginTop4 {
  margin-top: 4px;
}
.MarginBottom4 {
  margin-bottom: 4px;
}
.Gap4 {
  gap: 4px;
}
.Padding6 {
  padding: 6px;
}
.PaddingLeft6 {
  padding-left: 6px;
}
.PaddingRight6 {
  padding-right: 6px;
}
.PaddingTop6 {
  padding-top: 6px;
}
.PaddingBottom6 {
  padding-bottom: 6px;
}
.Margin6 {
  margin: 6px;
}
.MarginLeft6 {
  margin-left: 6px;
}
.MarginRight6 {
  margin-right: 6px;
}
.MarginTop6 {
  margin-top: 6px;
}
.MarginBottom6 {
  margin-bottom: 6px;
}
.Gap6 {
  gap: 6px;
}
.Padding8 {
  padding: 8px;
}
.PaddingLeft8 {
  padding-left: 8px;
}
.PaddingRight8 {
  padding-right: 8px;
}
.PaddingTop8 {
  padding-top: 8px;
}
.PaddingBottom8 {
  padding-bottom: 8px;
}
.Margin8 {
  margin: 8px;
}
.MarginLeft8 {
  margin-left: 8px;
}
.MarginRight8 {
  margin-right: 8px;
}
.MarginTop8 {
  margin-top: 8px;
}
.MarginBottom8 {
  margin-bottom: 8px;
}
.Gap8 {
  gap: 8px;
}
.Padding10 {
  padding: 10px;
}
.PaddingLeft10 {
  padding-left: 10px;
}
.PaddingRight10 {
  padding-right: 10px;
}
.PaddingTop10 {
  padding-top: 10px;
}
.PaddingBottom10 {
  padding-bottom: 10px;
}
.Margin10 {
  margin: 10px;
}
.MarginLeft10 {
  margin-left: 10px;
}
.MarginRight10 {
  margin-right: 10px;
}
.MarginTop10 {
  margin-top: 10px;
}
.MarginBottom10 {
  margin-bottom: 10px;
}
.Gap10 {
  gap: 10px;
}
.Padding12 {
  padding: 12px;
}
.PaddingLeft12 {
  padding-left: 12px;
}
.PaddingRight12 {
  padding-right: 12px;
}
.PaddingTop12 {
  padding-top: 12px;
}
.PaddingBottom12 {
  padding-bottom: 12px;
}
.Margin12 {
  margin: 12px;
}
.MarginLeft12 {
  margin-left: 12px;
}
.MarginRight12 {
  margin-right: 12px;
}
.MarginTop12 {
  margin-top: 12px;
}
.MarginBottom12 {
  margin-bottom: 12px;
}
.Gap12 {
  gap: 12px;
}
.Padding14 {
  padding: 14px;
}
.PaddingLeft14 {
  padding-left: 14px;
}
.PaddingRight14 {
  padding-right: 14px;
}
.PaddingTop14 {
  padding-top: 14px;
}
.PaddingBottom14 {
  padding-bottom: 14px;
}
.Margin14 {
  margin: 14px;
}
.MarginLeft14 {
  margin-left: 14px;
}
.MarginRight14 {
  margin-right: 14px;
}
.MarginTop14 {
  margin-top: 14px;
}
.MarginBottom14 {
  margin-bottom: 14px;
}
.Gap14 {
  gap: 14px;
}
.Padding16 {
  padding: 16px;
}
.PaddingLeft16 {
  padding-left: 16px;
}
.PaddingRight16 {
  padding-right: 16px;
}
.PaddingTop16 {
  padding-top: 16px;
}
.PaddingBottom16 {
  padding-bottom: 16px;
}
.Margin16 {
  margin: 16px;
}
.MarginLeft16 {
  margin-left: 16px;
}
.MarginRight16 {
  margin-right: 16px;
}
.MarginTop16 {
  margin-top: 16px;
}
.MarginBottom16 {
  margin-bottom: 16px;
}
.Gap16 {
  gap: 16px;
}
.Padding18 {
  padding: 18px;
}
.PaddingLeft18 {
  padding-left: 18px;
}
.PaddingRight18 {
  padding-right: 18px;
}
.PaddingTop18 {
  padding-top: 18px;
}
.PaddingBottom18 {
  padding-bottom: 18px;
}
.Margin18 {
  margin: 18px;
}
.MarginLeft18 {
  margin-left: 18px;
}
.MarginRight18 {
  margin-right: 18px;
}
.MarginTop18 {
  margin-top: 18px;
}
.MarginBottom18 {
  margin-bottom: 18px;
}
.Gap18 {
  gap: 18px;
}
.Padding20 {
  padding: 20px;
}
.PaddingLeft20 {
  padding-left: 20px;
}
.PaddingRight20 {
  padding-right: 20px;
}
.PaddingTop20 {
  padding-top: 20px;
}
.PaddingBottom20 {
  padding-bottom: 20px;
}
.Margin20 {
  margin: 20px;
}
.MarginLeft20 {
  margin-left: 20px;
}
.MarginRight20 {
  margin-right: 20px;
}
.MarginTop20 {
  margin-top: 20px;
}
.MarginBottom20 {
  margin-bottom: 20px;
}
.Gap20 {
  gap: 20px;
}
.Padding24 {
  padding: 24px;
}
.PaddingLeft24 {
  padding-left: 24px;
}
.PaddingRight24 {
  padding-right: 24px;
}
.PaddingTop24 {
  padding-top: 24px;
}
.PaddingBottom24 {
  padding-bottom: 24px;
}
.Margin24 {
  margin: 24px;
}
.MarginLeft24 {
  margin-left: 24px;
}
.MarginRight24 {
  margin-right: 24px;
}
.MarginTop24 {
  margin-top: 24px;
}
.MarginBottom24 {
  margin-bottom: 24px;
}
.Gap24 {
  gap: 24px;
}
.Padding32 {
  padding: 32px;
}
.PaddingLeft32 {
  padding-left: 32px;
}
.PaddingRight32 {
  padding-right: 32px;
}
.PaddingTop32 {
  padding-top: 32px;
}
.PaddingBottom32 {
  padding-bottom: 32px;
}
.Margin32 {
  margin: 32px;
}
.MarginLeft32 {
  margin-left: 32px;
}
.MarginRight32 {
  margin-right: 32px;
}
.MarginTop32 {
  margin-top: 32px;
}
.MarginBottom32 {
  margin-bottom: 32px;
}
.Gap32 {
  gap: 32px;
}
.Padding40 {
  padding: 40px;
}
.PaddingLeft40 {
  padding-left: 40px;
}
.PaddingRight40 {
  padding-right: 40px;
}
.PaddingTop40 {
  padding-top: 40px;
}
.PaddingBottom40 {
  padding-bottom: 40px;
}
.Margin40 {
  margin: 40px;
}
.MarginLeft40 {
  margin-left: 40px;
}
.MarginRight40 {
  margin-right: 40px;
}
.MarginTop40 {
  margin-top: 40px;
}
.MarginBottom40 {
  margin-bottom: 40px;
}
.Gap40 {
  gap: 40px;
}
.Padding64 {
  padding: 64px;
}
.PaddingLeft64 {
  padding-left: 64px;
}
.PaddingRight64 {
  padding-right: 64px;
}
.PaddingTop64 {
  padding-top: 64px;
}
.PaddingBottom64 {
  padding-bottom: 64px;
}
.Margin64 {
  margin: 64px;
}
.MarginLeft64 {
  margin-left: 64px;
}
.MarginRight64 {
  margin-right: 64px;
}
.MarginTop64 {
  margin-top: 64px;
}
.MarginBottom64 {
  margin-bottom: 64px;
}
.Gap64 {
  gap: 64px;
}
.FullSize {
  width: 100%;
  height: 100%;
}
.FullSizeWidth {
  width: 100%;
}
.FullSizeHeight {
  height: 100%;
}
@font-face {
  font-family: 'Gilroy';
  font-weight: 500;
  src: local('Gilroy'), url(./fonts/Gilroy/Gilroy-Medium.otf) format('truetype');
}
@font-face {
  font-family: 'Gilroy';
  font-weight: 600;
  src: local('Gilroy'), url(./fonts/Gilroy/Gilroy-Semibold.otf) format('truetype');
}
@font-face {
  font-family: 'Gilroy';
  font-weight: 700;
  src: local('Gilroy'), url(./fonts/Gilroy/Gilroy-Bold.otf) format('truetype');
}
@font-face {
  font-family: 'Gilroy';
  font-weight: 900;
  src: local('Gilroy'), url(./fonts/Gilroy/Gilroy-Extrabold.otf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 400;
  src: local('Lato'), url(./fonts/Lato/Lato-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 500;
  src: local('Lato'), url(./fonts/Lato/Lato-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 600;
  src: local('Lato'), url(./fonts/Lato/Lato-Semibold.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 700;
  src: local('Lato'), url(./fonts/Lato/Lato-Bold.ttf) format('truetype');
}
body {
  margin: 0 !important;
  font-family: Lato, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  min-width: 375px !important;
  color: #1d1f21;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.App h1,
.App h2,
.App h3,
.App h4,
.App h5,
.App h6 {
  font-family: Gilroy, sans-serif;
}
.App h1,
.App .Title-h1 {
  font-size: 30px;
  line-height: 1.25;
  font-weight: 700;
  margin-bottom: 0;
}
.App h2 {
  font-size: 24px;
}
.App h3 {
  font-size: 20px;
}
.App h4 {
  font-size: 16px;
}
.App img {
  display: block;
  max-width: 100%;
}
.App span {
  font-family: Lato, sans-serif;
}
.App input {
  font-family: Lato, sans-serif !important;
  font-weight: 500;
}
.App input::placeholder {
  color: #ADB5BD !important;
}
::-webkit-scrollbar {
  width: 8px;
  background-color: #ffffff;
  border-radius: 20px;
}
::-webkit-scrollbar:horizontal {
  height: 8px;
  background-color: #ffffff;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: #D1D1D6;
  border-radius: 20px;
}
::-webkit-scrollbar-track {
  background-color: #ffffff;
}
iframe {
  display: none !important;
}
.HeadlineFirstText {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #212529;
}
.HeadlineSecondText {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #212529;
}
.HeadlineThirdText {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: #212529;
}
.TextOne,
.TextOneBold {
  font-family: 'Lato' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.TextOneBold {
  font-weight: 700 !important;
}
.TextTwo,
.TextTwoBold {
  font-family: Lato !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
}
.TextTwoBold {
  font-weight: 700 !important;
}
.SubtitleFirstText {
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #212529;
}
.SubtitleSecondText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: #212529;
}
.BodyFirstText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #212529;
}
.BodySecondText {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #212529;
}
.ChartDecodingText {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.154px;
  color: #212529;
}
.TextDefault {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #212529;
}
.TextDefaultBold {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.3px;
  color: #212529;
}
.Text1,
.Geoservices_Photos__Info_Text {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #212529;
}
.TextDefaultBold {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #212529;
}
.TableHeaderText {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
}
.StatusText {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
.TableHeader,
.TableHeader > span {
  font-family: 'Gilroy' !important;
  font-style: normal !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  letter-spacing: 0 !important;
  word-spacing: normal !important;
  white-space: pre-line !important;
}
.CaptionText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #212529;
}
.CaptionTextBold {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #212529;
}
.OverlineText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #212529;
}
.Overline {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: #212529;
}
.ButtonTextFirst {
  font-family: 'Gilroy', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}
.ButtonTextSecond {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #212529;
}
.TextLabelText {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #ADB5BD;
}
.Rectangle {
  height: 1px;
  background: #E5E5EA;
  width: 100%;
}
.Uppercase {
  text-transform: uppercase;
}
.HideScroll::-webkit-scrollbar {
  display: none !important;
}
.CapitalizeText {
  text-transform: capitalize;
}
.HoverItem:hover {
  cursor: pointer;
  opacity: 0.8;
}
.TextAlignCenter {
  text-align: center;
}
.TextUnderline {
  text-decoration: underline;
}
.RelativePosition {
  position: relative;
}
.HideBlock {
  display: none !important;
}
.ButtonSecondary {
  background-color: #F1F3F5 !important;
  border: transparent !important;
}
.DisplayNoneElement {
  display: none !important;
}
.Geoservices {
  gap: 16px;
}
.Geoservices_InformationWrapper {
  background-color: #E7F5FF;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}
.Geoservices_InformationWrapper__Rules {
  display: flex;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #212529;
  color: #228BE6;
}
.Geoservices_InformationWrapper__Rules span {
  font-weight: 700;
}
.Geoservices_InformationWrapper__Info {
  color: #228BE6;
  font-weight: 700;
}
.Geoservices_InformationWrapper__Button {
  background-color: #F1F3F5 !important;
  border: transparent !important;
}
.Geoservices_InformationWrapper__Button .ant-btn.ant-btn-default {
  background: transparent !important;
}
.Geoservices_Photos {
  padding: 24px;
  background: #FFFFFF;
  border-radius: 8px;
}
.Geoservices_Photos__Info {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}
.Geoservices_Photos__Info_Icon path {
  fill: #868E96;
}
.Geoservices_Photos__Info_Text {
  color: #868E96;
}
.Geoservices_Photos__Info_Text-Valid {
  color: #40C057;
}
.Geoservices_Photos__Upload {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}
.Interior {
  padding: 24px;
  background: #FFFFFF;
  border-radius: 8px;
}
.UploadComponent__Uploaded .ant-upload.ant-upload-select-picture-card {
  border: none;
}
.UploadComponent__Delete {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  position: absolute;
  top: 4px;
  left: 4px;
  background: rgba(33, 37, 41, 0.75);
  cursor: pointer;
}
.UploadComponent__Wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.UploadComponent__Image {
  max-width: 180px !important;
  max-height: 120px;
  display: block;
  width: 100% !important;
  object-fit: cover;
  border-radius: 8px;
}
.UploadComponent .ant-select .ant-select-selector {
  height: 40px;
  border-radius: 8px;
  min-width: 180px;
}
.UploadComponent .ant-select .ant-select-single.ant-select-selector {
  align-items: center;
}
.UploadComponent .ant-upload {
  flex-direction: column;
  min-width: 180px;
  border-radius: 8px;
  min-height: 120px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Gilroy', sans-serif;
  color: #212529;
}
.UploadComponent .ant-upload.ant-upload-select-picture-card {
  margin: 0 !important;
}
.UploadComponent > .ant-upload-select-picture-card {
  margin: 0 !important;
}
.RulesPopup .ant-modal-content {
  width: 800px;
  border-radius: 8px;
}
.RulesPopup .ant-modal-content .ant-modal-body {
  padding: 40px;
}
.RulesPopup .ant-modal-content ul {
  margin-bottom: 0;
  padding-left: 15px;
}
@media screen and (max-width: 920px) {
  .RulesPopup__Wrapper {
    overflow-y: scroll;
    max-height: 78vh;
    padding-left: 2px;
  }
  .RulesPopup ::-webkit-scrollbar {
    width: 4px;
  }
  .RulesPopup ::-webkit-scrollbar-thumb {
    background: #CED4DA;
    border-radius: 8px;
  }
  .RulesPopup ::-webkit-scrollbar-track {
    background: transparent;
  }
  .RulesPopup .ant-modal-content {
    max-width: 100%;
    max-height: 716px;
  }
  .RulesPopup .ant-modal-content .ant-modal-body {
    padding: 24px 16px 24px 14px;
  }
  .Geoservices {
    gap: 8px;
  }
  .Geoservices button {
    margin-left: 16px;
  }
  .Geoservices_Photos {
    padding: 16px;
  }
  .Geoservices_Photos__Upload {
    gap: 15px;
  }
  .Geoservices_Photos__Upload .SelectWrapper {
    margin-bottom: 4px;
  }
  .Geoservices_InformationWrapper {
    flex-direction: column;
    gap: 16px;
  }
  .UploadComponent .ant-upload {
    min-width: 164px;
    min-height: 109px;
  }
  .UploadComponent .ant-select .ant-select-selector {
    width: 164px !important;
    min-width: unset;
  }
}
.Geoservices_ConfirmPopup__Button {
  background-color: #F1F3F5 !important;
  border: none;
}
.Geoservices_ConfirmPopup__Button span {
  font-family: 'Gilroy', sans-serif;
  font-size: 16px !important;
}
.Geoservices_ConfirmPopup__Delete {
  background-color: #FA5252 !important;
}
.Geoservices_ConfirmPopup__Delete span {
  color: #FFFFFF !important;
}
.Geoservices_ConfirmPopup__Footer {
  display: flex;
  justify-content: end;
  gap: 8px;
  margin-top: 8px;
}
.Geoservices_ConfirmPopup .ant-modal-content {
  max-width: 480px;
  border-radius: 16px;
}
.Geoservices_ConfirmPopup .ant-modal-content .ant-modal-body {
  padding: 32px;
}
.Geoservices_ConfirmPopup .ant-modal-content ul {
  margin-bottom: 0;
  padding-left: 15px;
}
