@grape-900: #862E9C;
@grape-800: #9C36B5;
@grape-700: #AE3EC9;
@grape-600: #BE4BDB;
@grape-500: #CC5DE8;
@grape-400: #DA77F2;
@grape-300: #E599F7;
@grape-200: #EEBEFA;
@grape-100: #F3D9FA;
@grape-10: #F8F0FC;

// Color
.Grape900Color {
  color: @grape-900 !important;
}

.Grape800Color {
  color: @grape-800 !important;
}

.Grape700Color {
  color: @grape-700 !important;
}

.Grape600Color {
  color: @grape-600 !important;
}

.Grape500Color {
  color: @grape-500 !important;
}

.Grape400Color {
  color: @grape-400 !important;
}

.Grape300Color {
  color: @grape-300 !important;
}

.Grape200Color {
  color: @grape-200 !important;
}

.Grape100Color {
  color: @grape-100 !important;
}

.Grape10Color {
  color: @grape-10 !important;
}

// BackgroundColor
.Grape900Background {
  background-color: @grape-900 !important;
}

.Grape800Background {
  background-color: @grape-800 !important;
}

.Grape700Background {
  background-color: @grape-700 !important;
}

.Grape600Background {
  background-color: @grape-600 !important;
}

.Grape500Background {
  background-color: @grape-500 !important;
}

.Grape400Background {
  background-color: @grape-400 !important;
}

.Grape300Background {
  background-color: @grape-300 !important;
}

.Grape200Background {
  background-color: @grape-200 !important;
}

.Grape100Background {
  background-color: @grape-100 !important;
}

.Grape10Background {
  background-color: @grape-10 !important;
}

// BorderColor
.Grape900Border{
  border-color: @grape-900 !important;
}

.Grape800Border{
  border-color: @grape-800 !important;
}

.Grape700Border{
  border-color: @grape-700 !important;
}

.Grape600Border{
  border-color: @grape-600 !important;
}

.Grape500Border{
  border-color: @grape-500 !important;
}

.Grape400Border{
  border-color: @grape-400 !important;
}

.Grape300Border{
  border-color: @grape-300 !important;
}

.Grape200Border{
  border-color: @grape-200 !important;
}

.Grape100Border{
  border-color: @grape-100 !important;
}

.Grape10Border{
  border-color: @grape-10 !important;
}
