.EditorWrapper {
  position: relative !important;
}

.EditorWrapperOverlay {
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: #000;
  opacity: 0.1;
  border-radius: 8px;
  z-index: 5;
  margin: 1px;
}

.public-DraftEditorPlaceholder-root, .EditorWrapperOverlayPlaceholder {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #868E96;
}

.EditorWrapperOverlayPlaceholder {
  color: #ADB5BD !important;
}

.EditorWrapperOverlayPlaceholder {
  padding: 28px 16px;
}

.RichEditor-hidePlaceholder .EditorWrapper
.EditorTextArea .DraftEditor-root .public-DraftEditorPlaceholder-root {
  display: none !important;
}

.EditorTextArea {
  background: #FFFFFF !important;
  border: 1px solid #CED4DA;
  border-radius: 8px !important;
  padding: 14px 16px 100px 16px !important;
  min-height: 160px !important;
  max-height: 440px !important;
  overflow-y: auto !important;
}

.EditorErrorTextArea {
  border: 1px solid #e80012;
}

.EditorToolbar {
  background: #FFFFFF !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px !important;
  padding: 8px 8px 2px 8px !important;
  position: absolute !important;
  bottom: 16px !important;
  left: 16px !important;
  z-index: 9 !important;
}

.rdw-option-wrapper > img {
  opacity: 0.4;
}

.rdw-option-wrapper, .rdw-option-wrapper:hover {
  border: none !important;
  box-shadow: none !important;
  cursor: pointer;
}

.rdw-option-active > img,
.rdw-option-wrapper:hover > img {
  opacity: 1;
}

.rdw-option-disabled:hover > img {
  opacity: 0.3 !important;
}

.rdw-option-disabled {
  cursor: default !important;
}

.rdw-link-modal {
  height: 225px !important;
  background: #FFFFFF !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px !important;
}

.rdw-link-modal-label {
  font-family: Gilroy, sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 18px !important;
  color: #868E96 !important;
}

.rdw-link-modal-input {
  height: auto !important;
  border-radius: 8px !important;
  padding: 8px !important;
}

.rdw-link-modal-target-option {
  display: none !important;
}

.rdw-link-modal-buttonsection .rdw-link-modal-btn:first-child {
  background: #F8E800 !important;
  border: none !important;
}

.rdw-link-modal-btn {
  padding: 8px 12px !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  height: auto !important;
  width: auto !important;
  font-family: Gilroy, sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #212529 !important;
}

.rdw-link-modal-btn:hover {
  opacity: 0.7;
}

// dropdown fields
.EditorToolbarItemBlockType {
  width: 150px !important;
}

.EditorToolbarItemFontSize {
  width: 50px !important;
}

.EditorToolbarItemBlockType, .EditorToolbarItemBlockType > ul,
.EditorToolbarItemFontSize, .EditorToolbarItemFontSize > ul {
  padding: 2px 8px !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  //height: auto !important;
  //width: auto !important;
}

.rdw-dropdown-optionwrapper > li {
  text-decoration: none !important;
  color: #868E96 !important;
  background: #fff !important;
}

.rdw-dropdown-optionwrapper > li:hover {
  color: #000 !important;
  background: #fff !important;
}

.EditorToolbarItemBlockType > a, .EditorToolbarItemFontSize > a {
  text-decoration: none !important;
  color: #868E96 !important;
}

.EditorToolbarItemBlockType:hover > a,
.EditorToolbarItemFontSize:hover > a{
  color: #000 !important;
}

.EditorToolbarItemFontSize > ul {
  width: 50px !important;
  margin-left: -10px !important;
  margin-top: 6px !important;
}

.EditorToolbarItemBlockType > ul {
  width: 150px !important;
  margin-left: -10px !important;
  margin-top: 6px !important;
}

.rdw-dropdown-carettoopen, .rdw-dropdown-carettoclose {
  display: none !important;
}
// color picker
.rdw-colorpicker-modal {
  width: 240px !important;
  left: -90px !important;
  height: auto !important;
  padding: 2px 8px !important;
  box-shadow: none !important;
  border-radius: 8px !important;
}

.rdw-colorpicker-modal-style-label {
  font-family: Gilroy, sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 18px !important;
  color: #000 !important;
  border-color: #F8E800 !important;
  padding-top: 8px !important;
}

.EditorWrapperView {
  //background: #f5f5f5;
  border: 1px solid #E9ECEF;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 14px 16px;
  //color: #ADB5BD !important;
  max-height: 440px;
  overflow-y: auto;
  font-family: Lato, sans-serif !important;
}

@media screen and (max-width: 920px) {
  .EditorToolbar {
    overflow-x: auto;
    width: calc(100vw - 120px) !important;
  }

  .EditorToolbar, .EditorToolbar .rdw-inline-wrapper,
  .EditorToolbar .rdw-history-wrapper {
    flex-wrap: nowrap !important;
  }

  .EditorToolbar::-webkit-scrollbar {
    display: none !important;
  }
}

.EditorWrapperRoot {
  position: relative;
}