@import "Dashboard/styles";

.FilterField {
  padding-bottom: 16px;

  .FilterContentLabel {
    padding-bottom: 8px;
  }
}

.FilterContentLabelDisabled {
  color: #d9d9d9 !important;
}

.ReportHeader span {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
}

.GuestInfoReportStatus {
  padding: 5px 11px;
  width: fit-content;
  border-radius: 4px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

.GuestInfoReportDate {
  text-transform: capitalize;
  height: 48px;
  padding: 16px 32px 6px 16px;
}
