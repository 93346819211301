@import "./Content/styles";

.SportBoomCustomFilter {
  padding-top: 24px;
  gap: 8px;
}

@media screen and (max-width: 920px) {
  .SportboomMainTitle {
    margin-left: 0 !important;
  }
}