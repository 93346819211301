.GamesListHeader {
  padding-bottom: 10px;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.GamesListFilterCheckboxAllWrapper {
  display: flex;
}

.GamesListFilterCheckboxGroupWrapper {
  display: flex !important;
}

.GamesListFilterCheckboxAllLabel {
  padding-top: 1px;
}

.GamesListFilterCheckboxGroupWrapper, .GamesListFilterCheckboxAllLabel {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #2C2C2E;
}

.GamesListFilterCheckboxGroupWrapper {
  display: flex;
  flex-direction: column;
}

.GamesListFilterCheckboxGroupWrapper label, .GamesListFilterCheckboxAllWrapper label {
  padding-bottom: 10px;
}

.GamesListFilterCheckboxGroupWrapper label:last-child {
  border-bottom: none !important;
}
