@media screen and (max-width: 920px) {
  .TabsComponentWrapper {
    max-width: calc(100vw - 32px);
    margin-left: 16px;
    overflow-x: auto;
  }

  .TabsComponentWrapper::-webkit-scrollbar {
    display: none !important;
  }
}

.TabsComponentItemsWrapper {
  width: 100%;
  min-width: max-content;
  overflow-x: auto;
  gap: 24px;

  border-bottom: 1px solid #DEE2E6;
}

.TabsComponentItem {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #868E96;

  cursor: pointer;
  padding-bottom: 11px;
}

.TabsComponentItemLine {
  background: #FCE000;
  height: 3px;
  visibility: hidden;

  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.TabsComponentActiveItemLine {
  visibility: visible !important;
}

.TabsComponentActiveItem {
  color: #212529 !important;
  cursor: auto !important;
}

.TabsComponentDisabledItem {
  cursor: not-allowed !important;
  color: #868E96;
}

.TableTimeDescription {
  color: #868E96;
  padding-left: 8px;
}