@blue-900: #1864AB;
@blue-800: #1971C2;
@blue-700: #1C7ED6;
@blue-600: #228BE6;
@blue-500: #339AF0;
@blue-400: #4DABF7;
@blue-300: #74C0FC;
@blue-200: #A5D8FF;
@blue-100: #D0EBFF;
@blue-10: #E7F5FF;

// Color
.Blue900Color {
  color: @blue-900 !important;
}

.Blue800Color {
  color: @blue-800 !important;
}

.Blue700Color {
  color: @blue-700 !important;
}

.Blue600Color {
  color: @blue-600 !important;
}

.Blue500Color {
  color: @blue-500 !important;
}

.Blue400Color {
  color: @blue-400 !important;
}

.Blue300Color {
  color: @blue-300 !important;
}

.Blue200Color {
  color: @blue-200 !important;
}

.Blue100Color {
  color: @blue-100 !important;
}

.Blue10Color {
  color: @blue-10 !important;
}

// BackgroundColor
.Blue900Background {
  background-color: @blue-900 !important;
}

.Blue800Background {
  background-color: @blue-800 !important;
}

.Blue700Background {
  background-color: @blue-700 !important;
}

.Blue600Background {
  background-color: @blue-600 !important;
}

.Blue500Background {
  background-color: @blue-500 !important;
}

.Blue400Background {
  background-color: @blue-400 !important;
}

.Blue300Background {
  background-color: @blue-300 !important;
}

.Blue200Background {
  background-color: @blue-200 !important;
}

.Blue100Background {
  background-color: @blue-100 !important;
}

.Blue10Background {
  background-color: @blue-10 !important;
}

// BorderColor
.Blue900Border{
  border-color: @blue-900 !important;
}

.Blue800Border{
  border-color: @blue-800 !important;
}

.Blue700Border{
  border-color: @blue-700 !important;
}

.Blue600Border{
  border-color: @blue-600 !important;
}

.Blue500Border{
  border-color: @blue-500 !important;
}

.Blue400Border{
  border-color: @blue-400 !important;
}

.Blue300Border{
  border-color: @blue-300 !important;
}

.Blue200Border{
  border-color: @blue-200 !important;
}

.Blue100Border{
  border-color: @blue-100 !important;
}

.Blue10Border{
  border-color: @blue-10 !important;
}
