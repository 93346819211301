@import "./Chart/styles";
@import "./Table/styles";
@import "./TableMobile/styles";

.SportboomDetailWrapper {
    margin-top: 32px;

    .SportboomDetailLabel {
        padding-bottom: 16px;
    }
}

.SportboomContentWrapper {
    margin-top: 16px;
}

.SportboomContentSwitcherWrapper {
    margin-top: 32px;
    &:extend(.Gap32);
    position: relative;

    .SportboomContentSwitcherMargin {
        margin-left: auto;
    }

    .SportboomContentTableConfig {
        border: 1px solid @gray-400;
        border-radius: 8px;
        padding: @pd-12 @pd-16;

        &:extend(.Gap8);
        &:extend(.White100Background);

        span {
            &:extend(.PaddingTop4);
        }

        .SportboomContentTableConfigCount {
            &:extend(.White100Color);
            padding: @pd-3 @pd-6;
            height: 22px;

            background: #15AABF;
            border-radius: 24px;
        }
    }
}

.SportboomContentTableConfig:hover {
    cursor: pointer;
    opacity: 0.8;
}


@media screen and (max-width: 920px) {
    .SportboomDetailWrapper {
        .SportboomDetailLabel {
            padding-left: 16px;
        }
    }

    .SportboomContentSwitcherHide {
        display: none !important;
    }

    .SportboomContentSwitcherWrapper {
        margin-left: 16px;
        margin-right: 16px;
    }
}

// Detail
.DetailTooltipContentWrapper {
    width: 328px;
    padding: 12px;
}

.DetailTooltipContentItem {
    width: 128px;
}

.DetailTooltipContentTitle {
    &:extend(.Lemon600Color);
    &:extend(.HeadlineThirdText);
    &:extend(.PaddingBottom8);

    width: 250px;
}

.SportboomDetailBetSumModal {
    padding: @pd-32 @pd-24;
    width: 500px;

    .ButtonPrimaryWrapper button {
        width: 100%;
    }
}

.SportboomDetailBetSumModalTable {
    border: 1px solid @gray-400;
    border-radius: @pd-8;
}

.SportboomDetailBetSumModalTableItem {
    width: 50%;
    padding: @pd-8 @pd-16;
}

.SportboomDetailBetSumModalTable .SportboomDetailBetSumModalTableItem:first-child {
    border-right: 1px solid @gray-400;
}

@media screen and (max-width: 920px) {
    .SportboomDetailBetSumModal {
        width: 100vw !important;
    }

    .SportboomDetailBetSumModalTable .SportboomDetailBetSumModalTableItem:nth-child(2) {
        .SubtitleSecondText {
            padding-top: 12px;
        }
    }

    div.SportBoomDateTimeCell {
        padding-left: 0 !important;
    }
}

.SportBoomWalletModalButtonsContainer {
    display: flex;
    gap: 8px;


}

.SportBoomWalletModalRiskProfileContainer {
    display: flex;
    gap: 4px;
    align-items: center;

    .TooltipComponentTargetWrapper, svg {
        width: 16px !important;
        height: 16px !important;
    }
}

.SportBoomWalletModalContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
}

.SportBoomWalletModalButtonRoger button {
    background: @gray-100 !important;
    border-color: @gray-100 !important;
}

span.SportBoomWalletModalCellWalletId {
    div {
        color: @shark500 !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 20px !important;
    }

    svg {
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 20px !important;
        path {
            fill: @shark500 !important;
        }
    }

    &:hover {
        opacity: unset !important;
    }
}

.SportboomContentWrapper .NewTableComponentWrapper .ant-spin-nested-loading .ant-spin-container
.ant-table .ant-table-container .ant-table-content {
    min-height: 100% !important;
}

.SportBoomDateTimeCell {
    display: flex;
    flex-direction: column;

    .SportBoomDateTimeCell_time {
        color: @gray-600 !important;
    }

}

.SportBoomWalletModalRiskProfileContainerWithHistory {
    display: flex;
    flex-direction: row;
    gap: 4px;
}


.SportBoomWalletModalRiskProfileContainerWithHistory {
    flex-flow: wrap; //TODO MOBILE
}

.SportBoomWalletModalRiskProfileContainerWithHistoryOld {
    color: @gray-600 !important;
}

.SportBoomWalletModalRiskProfileContainerWithHistoryNew {
    color: @green-700 !important;
}

.SportBoomWalletModalEmptyWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    text-align: center;

    svg {
        width: 160px;
        height: 160px;
    }
}

.SportBoomWalletModalContainer_empty .SportBoomWalletModalButtonsContainer {
    justify-content: center;
}

.SportBoomWalletModalContainer_empty {
    margin-top: 32px !important;
}

.SportBoomWalletModal_mobile .SportBoomWalletModalContainer_empty {
    margin-bottom: 12px !important;
}


.SportBoomWalletModal_mobile {
    padding-left: 16px;
    padding-right: 16px;
}

.SportBoomWalletModalEmptyWrapper_title {
    &:extend(.HeadlineThirdText);
    margin-bottom: 8px;
}

.SportBoomWalletModalEmptyWrapper_subtitle {
    color: @gray-600 !important;
    &:extend(.TextOne);
}

@media screen and (min-width: 921px) {
    .SportBoomWalletModal .ModalFormContent {
        min-width: 677px;
    }
}

.SportBoomWalletModal .ModalFormContent {
    padding: 32px;
}

.SportBoomWalletModal_mobile .ModalFormContent{
    padding: 20px 16px !important;

    .SportBoomWalletModalContainer {
        gap: 16px;
        margin-top: 12px;
    }
}

.SportBoomWalletModal .GraySpinComponent {
    height: 200px;
    padding-top: 32px;
}

.SportBoomWalletModal_empty .ModalFormContent {
    padding-top: 0 !important;
}

.SportBoomWalletModalMobileTable {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 8px;
    border: 1px solid @gray-400;
    min-width: 100%;
}

.SportBoomWalletModalMobileTable th:not(:last-child),
.SportBoomWalletModalMobileTable td:not(:last-child) {
    border-right: 1px solid @gray-400;
}

.SportBoomWalletModalMobileTable>tr:not(:last-child)>th,
.SportBoomWalletModalMobileTable>tbody>tr:not(:last-child)>td,
.SportBoomWalletModalMobileTable>tr:not(:last-child)>td,
.SportBoomWalletModalMobileTable>tr:not(:last-child)>th,
.SportBoomWalletModalMobileTable>tbody:not(:last-child) {
    border-bottom: 1px solid @gray-400;
}

.SportBoomWalletModalMobileTable td {
    padding: 8px 16px;
}

.SportBoomWalletModalMobileTableCellTitle {
    color: @gray-600 !important;
    white-space: nowrap;
    &:extend(.OverlineText);
}

.SportBoomWalletModalMobileTableCell {
    display: flex;
    flex-direction: column;
}

.SportBoomWalletModalMobileTableCellValue {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;

    .SportBoomWalletModalMobileTableCellValueInner {
        white-space: nowrap;
        &:extend(.SubtitleSecondText);
    }

    .TooltipComponentTargetWrapper svg {
        width: 16px !important;
        height: 16px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.TooltipComponentWrapper_SportBoomToolTipWrapper {
    z-index: 9999999999999999999 !important;
}

.SportBoomMobileBack {
    margin-top: 16px;
    margin-bottom: 19.5px;
}

.SportboomContentWrapper_modal-opened .Overlay {
    z-index: 9;
}

.SportBoomWalletModalCellWalletId, .SportBoomDateTimeCell {
    padding-left: 12px !important;
}