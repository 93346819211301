@import "./Config/styles";
@import "./Clear/styles";
@import "./Detail/styles";

.SportboomTableColumnDataWithGap {
    gap: 4px;

    svg {
        margin-top: 4px;
    }
}

// Statuses

.SportboomTableStatus {
    width: 112px;
    height: 24px;
    padding: 6px;

    display: grid;
    place-items: center;

    border-radius: 4px;
    &:extend(.StatusText);
}

.SportboomTableFirstItem {
    padding-left: 16px;
}

.SportboomTableFirstItemMargin {
    margin-left: 16px;
}

.SportboomTableStatusWin {
    &:extend(.SportboomTableStatus);
    &:extend(.Red600Color);
    &:extend(.Red10Background);
}

.SportboomTableStatusWinActive {
    &:extend(.SportboomTableStatus);
    &:extend(.White100Color);
    &:extend(.Red400Background);
}

.SportboomTableStatusLoose {
    &:extend(.SportboomTableStatus);
    &:extend(.Green600Color);
    &:extend(.Green10Background);
}

.SportboomTableStatusLooseActive {
    &:extend(.SportboomTableStatus);
    &:extend(.White100Color);
    &:extend(.Green400Background);
}

.SportboomTableStatusInGame {
    &:extend(.SportboomTableStatus);
    &:extend(.Shark500Color);
    &:extend(.Gray100Background);
}

.SportboomTableStatusInGameActive {
    &:extend(.SportboomTableStatus);
    &:extend(.White100Color);
    &:extend(.Gray500Background);
}

.SportboomTableStatusRedeem {
    &:extend(.SportboomTableStatus);
    &:extend(.Violet600Color);
    &:extend(.Violet10Background);
}

.SportboomTableStatusRedeemActive {
    &:extend(.SportboomTableStatus);
    &:extend(.White100Color);
    &:extend(.Violet400Background);
}

.SportboomTableStatusReturnBet {
    &:extend(.SportboomTableStatus);
    &:extend(.Lemon800Color);
    &:extend(.Lemon10Background);
}

.SportboomTableStatusReturnBetActive {
    &:extend(.SportboomTableStatus);
    &:extend(.White100Color);
    &:extend(.Lemon700Background);
}

.SportboomCompareWrapper {
    position: fixed;
    &:extend(.FlexRow);
    &:extend(.Gap16);

    bottom: 48px;
    left: 50vw;
    z-index: 2;
}

.SportboomTableActiveRow {
    .ant-table-selection-column {
        border-left: 4px solid @lemon-500 !important;
        z-index: 10 !important;

        .ant-checkbox-wrapper {
            &:extend(.MarginLeft2);
        }
    }
}

.SportboomTable .ant-spin-nested-loading .ant-spin-container .ant-table-empty
.ant-table-container .ant-table-content table .ant-table-tbody
.ant-table-placeholder .ant-table-cell .ant-table-expanded-row-fixed {
    overflow: inherit !important;

    div .GridCenter {
        position: sticky;
        width: 370px;
        left: 40%;
    }
}

.SportboomTableRow {
    .ant-table-selection-column {
        z-index: 10 !important;

        .ant-checkbox-wrapper {
            &:extend(.MarginLeft8);
        }
    }
}

.SportboomCompareItem {
    padding: 11px 24px;
    border-radius: 8px;
    cursor: pointer;
}

.SportboomCompareApply {
    &:extend(.SportboomCompareItem);
    &:extend(.Lemon500Background);
}

.SportboomCompareApply:hover {
    &:extend(.Lemon400Background);
}

.SportboomCompareCancel {
    &:extend(.SportboomCompareItem);
    &:extend(.Gray100Background);
}

.SportboomCompareCancel:hover {
    &:extend(.Gray10Background);
}

// Inside

.SportboomTableInsideEmpty {
    &:extend(.Gray200Background);
    display: grid;
    place-items: center;
    padding: @pd-18 0;

    div {
        &:extend(.Gap8);

        svg {
            &:extend(.MarginTop2);
        }
    }
}

.SportboomInsideTableWrapper
.ant-spin-nested-loading
.ant-spin-container
.ant-table
.ant-table-container
.ant-table-content {
    border: 0 !important;
    border-radius: 0 !important;
}

.SportboomInsideTableWrapper .ant-spin-nested-loading .ant-spin-container
.ant-table-empty .ant-table-container .ant-table-content table .ant-table-tbody
.ant-table-placeholder .ant-table-cell {
    background-color: @gray-200 !important;
}

.SportboomInsideTableWrapper {
    border-top: 1px solid @gray-400 !important;

    &.BorderNone {
        border-top: none !important;
    }
}



.SportboomInsideTableRow {
    padding-left: 20px !important;

    &:not(.Detail) {
        .ant-table-cell {
            background: @gray-200 !important;
            margin-bottom: 0 !important;
            border-bottom: 1px solid @gray-400 !important;
        }
    }

    &.BorderNone {
        .ant-table-cell {
            border: none !important
        }
    }
}

.SportboomInsideTableActiveRow td:first-child {
    border-left: 4px solid @gray-400 !important;

    div {
        width: 4px !important;
    }
}


// For sport_type position
@media screen and (max-height: 850px) {
    .SportTypeTableFilterHeader {
        .TableHeaderModal .ModalWrapper {
            top: 24px !important;
        }
    }
}

.SportboomWalletCellWrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    div {
        color: @blue-600 !important;
        &:extend(.TextDefaultBold);
    }

    svg {
        width: 20px;
        height: 20px;

        path {
            fill: @blue-600 !important;
        }
    }

    &:hover {
        opacity: 0.5;
    }
}

.SportBoomSideWalletModalContainer {
    padding: 40px 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.SportBoomMobileTitle {
    margin-bottom: 12px;
    &:extend(.HeadlineSecondText);
}