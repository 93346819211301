@import "./Detail/styles";

.MailingEmptyTableWrapper {
  width: 100%;
  margin-left: calc(25% - 127px);
  margin-top: 105px;
}

.MailingHallContentWrapper {
  gap: 16px;
  width: 100%;
}

@media screen and (min-width: 1884px) {
  .MailingHallContentWrapper {
    .MailingDataItemWrapper {
      max-width: calc((100% - 32px)/3) !important;
    }
  }
  .MailingHallContentOnlyTwoWrapper {
    .MailingDataItemWrapper {
      max-width: calc((100% - 16px)/2) !important;
    }
  }
}

@media screen and (min-width: 1630px) {
  .MailingHallContentWithoutFilterWrapper {
    .MailingDataItemWrapper {
      max-width: calc((100% - 32px)/3) !important;
    }
  }
  .MailingHallContentOnlyTwoWrapper {
    .MailingDataItemWrapper {
      max-width: calc((100% - 16px)/2) !important;
    }
  }
}

@media screen and (max-width: 1113px) {
  .MailingHallContentWithoutFilterWrapper {
    .MailingDataItemWrapper {
      max-width: 100% !important;
    }
  }
}

@media screen and (max-width: 1505px) {
  .MailingHallContentWithFilterWrapper {
    .MailingDataItemWrapper {
      max-width: 100% !important;
    }
  }
}

.MailingDataItemWrapper {
  min-width: 393px;
  width: 100%;
  max-width: calc((100% - 16px)/2);
}

.MailingHallItem {
  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  border-radius: 12px;

  min-width: 393px;
  width: 100%;
  padding: 20px 14px;

  margin-top: 18px;
}

.MailingHallItem:hover {
  cursor: pointer;
  opacity: 0.8;
}

.MailingHallItemLeft {
  float: left;
}

.MailingHallItemField {
  height: 58px;
  padding-bottom: 16px;

  div:first-child {
    padding-bottom: 4px;
  }
}

.MailingHallItemTitle {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #212529;

  height: 32px !important;
}

.MailingHallItemValue {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
}

.MailingHallItemValueInactiveColor {
  opacity: 0.4;
}

.MailingHallItemDescription {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #868E96;
}

.MailingHallItemActive {
  padding: 4px 26px;
  margin-top: 8px;
  background: #EBFBEE;
  border-radius: 4px;

  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  color: #40C057;
}

.MailingHallUserAttentionActiveColor {
  margin-left: 4px;

  path {
    fill: #FA5252
  }
}

.MailingHallUserAttentionInactiveColor {
  margin-left: 4px;

  path {
    fill: #ADB5BD
  }
}

.MailingHallItemInactive {
  margin-top: 8px;
  padding: 4px 26px 6px 26px;
  background: #ADB5BD;
  border-radius: 4px;

  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  color: #fff;
}

// right side

.MailingHallItemDate {
  margin-bottom: 24px;
  text-align: right;
}

.MailingHallAdditional {
  margin-left: 8px;
}

.MailingHallItemRight {
  margin-left: auto;
}

.MailingHallUsersWrapper {
  justify-content: end;
}

.MailingHallUser {
  width: fit-content;
  padding: 8px 12px !important;
  background: #F4F4F4;
  border-radius: 24px;

  span {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.4px;
    color: #212529;
    margin-top: 3px;
    margin-left: 4px;
  }
}

.MailingHallAddUserText {
  max-width: 96px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}

.MailingHallUserText {
  max-width: 174px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}

.MailingHallUserActiveColor {
  background: #F4F4F4;

  span {
    color: #212529;
  }
}

.MailingHallUserEmptyActiveColor {
  background: #FFF5F5;

  span {
    color: #FA5252;
  }
}

.MailingHallUserInactiveColor {
  background: #F4F4F4;
  opacity: 0.4;

  span {
    color: #212529;
  }
}

.MailingHallUserEmpty {
  background: #FFF5F5 !important;

  span {
    color: #FA5252 !important;
  }
}

.MailingHallItemFlags {
  justify-content: end;
  margin-top: 2px;

  div:first-child {
    margin-right: 12px;
  }
}


.MailingHallItemFlagInactiveColor {
  path {
    fill: #212529;
  }
}

.MailingHallItemFlagActiveColor {
  path {
    fill: #40C057;
  }
}


@media screen and (max-width: 450px) {
  .MailingHallItem {
    width: calc(100vw - 32px);
  }
}

@media screen and (max-width: 920px) {
  .MailingEmptyTableWrapper {
    margin-top: 50px;
    width: 100%;
    margin-left: 0;
  }
}