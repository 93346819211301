// cashbox filter
@import "./CashboxFilter/styles";
// cashbox data
@import "./CashboxContent/styles";


.CashboxComponentWrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.CashboxComponentContentWrapper {
  width: 100%;
}
