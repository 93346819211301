@import "Uploader/styles";

.GuidebookCardWrapper {
  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  border-radius: 8px;
  padding: 28px 24px 24px 24px;
  margin-bottom: 16px;
}

.GuidebookCardWrapperMobile {
  border: none !important;
  padding: 18px 16px 16px 18px !important;
}

.GuidebookCardTitleTextStatusWrapper {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.GuidebookCardTitle, .GuidebookCardDescription {
  padding-bottom: 16px;
  gap: 12px;

  .HeadlineThirdText {
    padding-top: 6px;
  }
}

.GuidebookCardDescription {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.GuidebookCardEditToggle {
  margin-left: auto;
}

.GuidebookCardEditToggle .ButtonDefaultWrapper button {
  padding: 10px 12px !important;
}

.GuidebookCardExample {
  padding-bottom: 20px;

  .GuidebookCardExampleLink {
    color: #228BE6 !important;
    padding: 3px 12px 0 4px;
    display: block !important;
    text-align: start !important;
    width: 115px !important;
    height: 20px !important;
  }

  .GuidebookCardExampleTextAndIcon {

    display: flex;

    &:hover {
      cursor: pointer;
    }
  }

  .GuidebookCardExampleDescription {
    padding-top: 3px;
  }
}

.GuidebookCardAttachments {
  gap: 12px;
}

.GuidebookCardAttachmentsImage {
  position: relative;

  .ant-image, img {
    border-radius: 8px;
    width: 156px;
    height: 100px;
  }
}

.GuidebookCardAttachmentsImageEditable {
  border: 1px dashed #FFFFFF;
  position: absolute;
  width: 156px;
  height: 100px;
  top: 0;
}

.GuidebookCardAttachmentsImageStatusBadge {
  position: absolute;
  top: -8px;
  right: -8px;
}