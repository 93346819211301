.ErrorPageWrapper {
  padding-top: 8%;
}

.ErrorPageTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.02em;
  color: #0D0D0D;
  padding-bottom: 15px;
}

.ErrorPageDescription {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #3A3A3C;
  padding-bottom: 32px;
}

.ErrorPageButton {
  padding-top: 80px;
  padding-bottom: 40px;
}

