@import "./Table/styles";
@import "./Chart/styles";

.GuestHistoryCashboxWrapper {
  position: relative;
  padding: 32px 0;
}

.GuestHistoryCashboxSwitcher {
  position: absolute;
  top: 32px;
  right: 0;
}

@media screen and (max-width: 920px) {
  .GuestHistoryCashboxSwitcher {
    right: 0;
  }

  .DataSwitcherButton {
    height: 48px !important;
  }
}