@import "UserPenaltyCard/styles";
@import "./ViewPenaltyModal/styles";
@import "./AppealModal/styles";

.ModalDisabledFormItem {
  .ant-form-item-label label div {
    color: #a6a8aa !important;
  }

  .InputWrapper input  {
    color: #b2b4b8 !important;
    background-color: #fdfdfd !important;
    border-color: #f1f3f5 !important;
  }

  .SelectWrapper .ant-select .ant-select-selector {
    border: 1px solid #f1f3f5 !important;
    background: #fdfdfd !important;

    .ant-select-selection-item {
      color: #b2b4b8 !important;
    }
  }

  .SelectWrapper {
    .SelectSuffixIcon path {
      fill: #b2b4b8 !important;
    }
  }
}

.UserPenaltyCardsWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
}

.UserPenaltyCardsWrapperMobile {
  justify-content: center;
  .PenaltyUserCard {
    width: 100%;
    margin-right: 16px;
    margin-left: 16px;
  }

  .PenaltyUserCardWinReason {
    -webkit-line-clamp: 2;
  }
}

.PenaltyStatus {
  border-radius: 4px;
  font-size: 12px;
  line-height: 12px;
  height: 24px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px 8px 12px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.4px;
}

.PenaltyStatusGrey {
  background: #F1F3F5;
}

.PenaltyStatusRed {
  color: #FA5252;
  background: #FFF5F5;
}

.PenaltyStatusGreen {
  color: #40C057;
  background: #EBFBEE;
}

.PenaltyUserCardComment,
.PenaltyUserCardAttach {
  margin-left: 14px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #ADB5BD;

  svg {
    margin-right: 4px;
  }
}

.PenaltyUserCardAttach svg {
  width: 20px;
  height: 20px;
}
.PenaltyStatusNew {
  width: 102px !important;
}

.PenaltyStatusView {
  width: 102px !important;
}

.PenaltyStatusReqCancel {
  width: 126px !important;
}

.PenaltyStatusReviewed {
  width: 94px !important;
}

.PenaltyStatusCancel {
  width: 102px !important;
}

.PenaltyStatusCons {
  width: 128px !important;
}

.AppealModalPenaltyCheckboxWrapper .ant-checkbox {
  top: 0.4em !important;
}

.AppealModalRulesLink {
  color: #339cf1;
  border-bottom: 1px solid #339cf1;
  width: fit-content;
}

.AppealModalRulesLink:hover {
  cursor: pointer;
}

.UserPenaltyModal {
  margin-bottom: 0px !important;
  margin-top: 6px !important;
}

.AppealModalPenaltyTextElementFirst {
  margin-top: 32px;
}

.AppealModalButtonsGroup {
  padding-top: 32px;
  border-top: 1px solid #DEE2E6;
  display: flex;
  align-items: center;
}

.AppealModalCFormContentWrapper {
  margin-bottom: 24px;
}

.AppealModalSubtitleWrapper {
  padding: 0 !important;
}

.UserPenaltySubtitleRange {
  display: flex;
  align-items: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #545b62;
  margin-top: 3px
}

.UserPenaltySubtitleRangePeriod {
  margin-left: 2px;
  .ContentPeriodDescription {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: #545b62;
    text-transform: lowercase;
  }

  .ContentPeriod {
    padding-left: 0;
    margin-top: 0;
  }

  .ContentPeriod {
    font-weight: 700;
  }
}

.UserPenaltySubtitleRangeMobile {
  .ContentPeriodDescription:first-letter {
    text-transform: capitalize;
  }
}

@media screen and (max-width: 450px) {
  .UserPenaltySubtitleRange {
    margin-left: 16px;
  }
  .PenaltyTitleWrapper .TitleText span {
    margin-left: 16px !important;
  }
}