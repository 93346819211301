.DataSwitcherWrapper {
  display: flex;
  flex-direction: row;
}

.DataSwitcherTitle {
  padding-top: 13px;
  padding-right: 16px;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #3A3A3C;
}

.DataSwitcherButton {
  width: 40px;
  height: 40px !important;
  padding: 0 !important;
  margin: 0 !important;
}

.DataSwitcherButtonDisabled {
  background: #FAFAF9 !important;
  border-color: #AEAEB2 !important;

  svg path {
    fill: #AEAEB2;
  }
}

.DataSwitcherTable {
  border-radius: 5px 0 0 5px !important;
}

.DataSwitcherStatistic {
  border-radius: 0 5px 5px 0 !important;
}

.DataSwitcherActive {
  background: rgba(255, 221, 45, 0.2) !important;
  border: 1px solid #FFDD2D !important;
}

@media screen and (max-width: 920px) {
  .DataSwitcherTitle {
    display: none;
  }

  .DataSwitcherButton {
    height: 48px !important;
  }
}