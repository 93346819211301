// Files modal component
.PaymentFilesModalWrapper .ModalFormContent .ModalTitleWrapper .ModalFormClose {
  margin-top: 4px;
}

.PaymentFilesModalContentWrapper {
  margin-top: 12px;
}

.PaymentFilesItemWrapper {
  &:extend(.Gray10Background);
  &:extend(.Padding8);

  border-radius: @pd-8;
}

.PaymentFilesItemButtons {
  display: none !important;

  .HoverItem a svg path {
    fill: @gray-600 !important;
  }
}

.PaymentFilesItemWrapper:hover {
  background-color: @gray-100 !important;

  .PaymentFilesItemButtons {
    display: inherit !important;
  }
}

.PaymentFilesItemWrapper > .FlexRow > .FlexColumn > .TextDefault {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

// Clear component

.PaymentClearButtonCount {
  width: 20px;
  height: 20px;

  padding-top: 2px;
  margin-top: 2px;

  border-radius: 50%;
  &:extend(.Lemon500Background);
}

.PaymentClearModalButton {
  width: 100% !important;

  span {
    font-weight: 600 !important;
  }
}