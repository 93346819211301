.GuestDashboardWrapper {
  max-width: 1500px;
  overflow-x: auto;
}

@media screen and (min-width: 921px) {
  .GuestDashboardWrapper {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 920px) {
  .GuestDashboardWrapper::-webkit-scrollbar {
    display: none !important;
  }
}

.ReportDashboardItemWrapper {
  margin-top: 19px;
  padding: 19px;
  border-radius: 16px;
  min-width: 230px;
  margin-right: 16px;
  max-width: 284px;
  width: 100%;
}

.ReportDashboardLabel, .StatusPromoteLabel {
  font-family: 'Gilroy', sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #212529;
  margin-left: 12px;
}

.ReportDashboardValue {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #212529;
  padding-top: 16px;
}

.GuestInfoPromoteSpecify {
  border: 1px solid #CED4DA;
  border-radius: 4px;
  width: 100%;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #212529;
  padding: 12px 0;
}

.GuestInfoPromoteSpecify, .GuestInfoPromoteReload {
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.GuestInfoPromoteValue {
  margin-top: 2px;
}

.GuestInfoPromoteReload {
  margin-left: 16px;
}

.GuestInfoPromoteMax {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
  padding-left: 6px;
  padding-top: 3px;
}
