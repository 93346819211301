@pink-900: #A61E4D;
@pink-800: #C2255C;
@pink-700: #D6336C;
@pink-600: #E64980;
@pink-500: #F06595;
@pink-400: #F783AC;
@pink-300: #FAA2C1;
@pink-200: #FCC2D7;
@pink-100: #FFDEEB;
@pink-10: #FFF0F6;

// Color
.Pink900Color {
  color: @pink-900 !important;
}

.Pink800Color {
  color: @pink-800 !important;
}

.Pink700Color {
  color: @pink-700 !important;
}

.Pink600Color {
  color: @pink-600 !important;
}

.Pink500Color {
  color: @pink-500 !important;
}

.Pink400Color {
  color: @pink-400 !important;
}

.Pink300Color {
  color: @pink-300 !important;
}

.Pink200Color {
  color: @pink-200 !important;
}

.Pink100Color {
  color: @pink-100 !important;
}

.Pink10Color {
  color: @pink-10 !important;
}

// BackgroundColor
.Pink900Background {
  background-color: @pink-900 !important;
}

.Pink800Background {
  background-color: @pink-800 !important;
}

.Pink700Background {
  background-color: @pink-700 !important;
}

.Pink600Background {
  background-color: @pink-600 !important;
}

.Pink500Background {
  background-color: @pink-500 !important;
}

.Pink400Background {
  background-color: @pink-400 !important;
}

.Pink300Background {
  background-color: @pink-300 !important;
}

.Pink200Background {
  background-color: @pink-200 !important;
}

.Pink100Background {
  background-color: @pink-100 !important;
}

.Pink10Background {
  background-color: @pink-10 !important;
}

// BorderColor
.Pink900Border{
  border-color: @pink-900 !important;
}

.Pink800Border{
  border-color: @pink-800 !important;
}

.Pink700Border{
  border-color: @pink-700 !important;
}

.Pink600Border{
  border-color: @pink-600 !important;
}

.Pink500Border{
  border-color: @pink-500 !important;
}

.Pink400Border{
  border-color: @pink-400 !important;
}

.Pink300Border{
  border-color: @pink-300 !important;
}

.Pink200Border{
  border-color: @pink-200 !important;
}

.Pink100Border{
  border-color: @pink-100 !important;
}

.Pink10Border{
  border-color: @pink-10 !important;
}
