.DetailTableComponentWrapper {
  background-color: @white;
  border: 1px solid #CED4DA;
  border-radius: 8px;
}

.DetailTableComponentRowItem {
  padding: 22px 16px;
  width: 100%;

  border-right: 1px solid #CED4DA;

  div {
    .TooltipComponentTargetWrapper {
      margin-left: 6px;
      margin-top: 2px;
    }
  }
}

.DetailTableComponentRowSmallWrapper {
  .DetailTableComponentRowItem {
    width: fit-content;
  }
}

.DetailTableComponentRowItemLabel {
  &:extend(.Gray600Color);
}

.DetailTableComponentFirstRow {
  .DetailTableComponentRowItem {
    &:extend(.Gray400Border);
    border-bottom: 1px solid;
  }
}

.DetailTableComponentRowLastItem {
  border-right: none !important;
}

.DetailTableComponentRowSmallWrapper {
  border: 1px solid;
  &:extend(.Gray400Border);
  width: fit-content;
  &:extend(.FlexRow);

  border-radius: 8px;
}

.DetailTableComponentRowSmall {
  padding: 5px 16px;
}

.DetailComponentSmallSkeletonValue {
  height: 18px !important;

  .ant-skeleton-button {
    width: fit-content !important;
  }
}

.DetailComponentSmallSkeletonLabel {
  margin-top: 2px;
  height: 18px !important;

  .ant-skeleton-button {
    width: 94px !important;
  }
}

.DetailComponentSkeletonValue {
  .ant-skeleton-button {
    width: 172px !important;
    height: 25px !important;
  }
}



.DetailComponentSkeletonLabel {
  margin-top: 2px;

  .ant-skeleton-button {
    width: 94px !important;
    height: 20px !important;
  }
}

@media screen and (max-width: 920px) {

  .DetailTableComponentRowSmall {
    min-width: 190px;
  }

  .DetailTableComponentWrapper {
    max-width: calc(100vw - 32px);
    margin-left: 16px;
  }

  .DetailTableComponentRow {
    flex-direction: column !important;
  }

  .DetailTableComponentRowItem {
    width: 100%;
    border-right: none !important;
  }

  .DetailTableComponentRowSmallWrapper {
    .DetailTableComponentRowItem {
      width: 100%;
    }
  }

  .DetailTableComponentRowItem {
    border-bottom: 1px solid #CED4DA;
  }

  .DetailTableComponentLastRow {
    .DetailTableComponentRowLastItem {
      border-bottom:none !important;
    }
  }

  .DetailTableComponentRowSmallWrapper {
    flex-direction: column;
  }
}