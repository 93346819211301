.TitleWrapper {
  width: 100% !important;
}

.TitleDescription {
  width: 100% !important;
}

.TitleText {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #212529;
  white-space: pre-wrap;
}

.TitleDescription .TitleText span {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 24px;
  color: #212529;
  white-space: pre-wrap;
}


.TitleWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 24px;
}

.TitleTooltip {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: 4px;
}

.TitleTooltip:hover {
  cursor: pointer;
  opacity: 0.8;
}

@media screen and (max-width: 1500px) {
  .TitleWrapper div div .TitleText {
    max-width: 400px;
  }
}

@media screen and (max-width: 1800px) {
  .TitleWrapper div div .TitleText {
    max-width: 400px;
  }
}

@media screen and (max-width: 910px) {
  .TitleWrapper {
    display: block !important;
  }

  .TitleText span {
    font-size: 24px;
    max-width: 320px !important;
  }
}

@media screen and (max-width: 920px) {
  .TitleWrapper {
    margin-left: 16px;
    max-width: 100vw;
    width: auto !important;
  }

  .TitleDescription {
    display: flex;
    flex-direction: column;
  }

  .TitleRightAdditional {
    margin-left: 10px !important;
  }

  .TitleDescription .TitleText span {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
  }

  .TitleWrapper {
    padding-bottom: 12px !important;
  }
}

@media screen and (min-width: 921px) {
  .TitleDescription {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media screen and(max-width: 994px) {
  .TitleText {
    width: 100% !important;
    min-width: fit-content !important;
    margin-left: 15px !important;
  }
}

.TitleBottomAdditionalDefault {
  margin-left: 7px;
  padding-top: 5.5px;
}