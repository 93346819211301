.ChangePhoneEditModal, .ChangePhoneEditSuccess {
  position: fixed;
  left: calc(50% - 250px);
  top: calc(50% - 250px);
  z-index: 999;
}

.ChangePhoneEditModalWrapper {
  width: 450px;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 96px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  padding: 32px;
}

.ChangePhoneEditModalClose {
  margin: 10px 14px;
}

.ChangePhoneEditModalClose:hover {
  cursor: pointer;
  opacity: 0.8;
}

.ChangePhoneEditModalWrapperTitle {
  width: 100%;
  text-align: center;
  padding-bottom: 24px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #212529;
}

.ChangePhoneEditModalWrapperTable {
  border: 1px solid #E9ECEF;
  box-sizing: border-box;
  border-radius: 8px;
}

.ChangePhoneEditModalWrapperTableHeader {
  background: #E3FAFC;
}

.ChangePhoneEditModalWrapperTableRowWithBorder, .ChangePhoneEditModalWrapperTableHeader {
  border-bottom: 1px solid #E9ECEF;
}

.ChangePhoneEditModalWrapperTableRow, .ChangePhoneEditModalWrapperTableHeader {
  padding: 18px 32px;
}

.ChangePhoneEditModalWrapperTableLeft {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
  float: left;
  width: 150px;
}


.ChangePhoneEditModalWrapperTableRight {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #212529;
  float: right;
  width: 100%;
}

.ChangePhoneEditModalUserTitle {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
  padding-top: 20px;
  padding-bottom: 10px;
}

.ChangePhoneEditModalUser {
  margin-bottom: 32px;

  .InputWrapper input {
    background: #F8F9FA !important;
  }
}

.ChangePhoneEditModalButtons {
  justify-content: space-between;

  .ButtonNotifiWrapper, .ButtonPrimaryWrapper {
    button {
      width: 184px !important;
    }
  }
}

// Success modal

.ChangePhoneEditSuccess {
  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  box-sizing: border-box;
  border-radius: 16px;
  padding-top: 32px;
  padding-bottom: 38px;
  width: 500px;
  //place-items: center;
}

.ChangePhoneEditSuccessTitle {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #212529;
  padding-bottom: 24px;
}

.ChangePhoneEditSuccessIcon {
  display: grid;
  place-items: center;
}

.ChangePhoneEditModalMobileClose {
  display: none;
}

@media screen and (max-width: 920px) {
  .ChangePhoneEditModalMobileClose {
    margin-left: auto;
    display: inherit;
  }

  .ChangePhoneEditModalClose {
    display: none;
  }

  .ChangePhoneEditModal {
    width: 100vw;
    bottom: 0 !important;
    top: auto !important;
    left: auto !important;
    right: 0 !important;
  }

  .ChangePhoneEditModalWrapper {
    width: 100vw;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .ChangePhoneEditModalButtons {
    flex-direction: column !important;
    //place-items: center;
  }

  .ChangePhoneEditModalButtons {
    .ButtonNotifiWrapper, .ButtonPrimaryWrapper {
      button {
        width: calc(100vw - 64px) !important;
      }
    }
  }

  .ChangePhoneEditModalButtons .ButtonNotifiWrapper {
    margin-bottom: 16px;
  }

  .ChangePhoneEditSuccess {
    bottom: 0;
    top: auto;
    //left: auto;
    //right: 0;
  }
}