@import "./Filters/styles";
@import "./Table/styles";
@import "./CrudModal/styles";
@import "./DeleteModal/styles";
@import "./Statistic/styles";

.NotifiRouteWrapper {
  position: relative;
}

.NotificationMainPageTitle {
  padding-left: 0 !important;
  width: 100%;
}

.NotificationMainPageTitleBack:hover {
  cursor: pointer;
  opacity: 0.7;
}

.NotificationMainPageTitleBack {
  padding-right: 12px;
  padding-top: 2px;
}

.NotificationsHeader {
  width: 100%;
  justify-content: space-between;
}

.NotificationsIconButtons > div > svg {
  margin-right: 8px;
}

.NotificationsIconButtons > div > svg path {
  fill: #868E96;
}

.NotificationsHeaderButtons {
  margin-top: 28px;
  height: 48px;
}

.NotificationsHeaderButtonsItem {
  margin: 0 12px 12px 12px;
}

.NotificationsHeaderTabs {
  margin-top: -12px;
  border-bottom: 1px solid #DEE2E6;
  width: 100%;
}

.NotificationsHeaderTabsItem {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #868E96 !important;
  margin-right: 20px;
  padding-bottom: 15px;
  border-bottom: none !important;
  position: relative;
}

.NotificationsHeaderTabsActiveItem {
  width: 100%;
  height: 2px;
  background: black;
  position: absolute;
}


.NotificationsFilterCounter {
  width: 20px;
  height: 20px;
  padding-top: 2px;
  border-radius: 50%;
  background: #FCE000;
  display: grid;
  place-items: center;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  color: #212529;

}

.NotificationMainPageTitleMobileAdd, .NotificationMainPageTitleMobileDelete,
.NotificationMainPageTitleMobileClearBasket {
  display: none !important;
}

@media screen and (min-width: 921px) {
  .NotificationsHeaderTabsItem:hover {
    cursor: pointer;
    color: #212529;
  }

  .NotificationsHeaderTabsActiveItemText {
    color: #212529 !important;
  }

  .NotificationsHeaderTabsActiveItem {
    height: 3px;
    background: #FCE000 !important;
    top: 28px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
}

@media screen and (max-width: 920px) {
  .NotificationsHeaderButtons {
    display: none !important;
  }

  .NotificationMainPageTitle {
    margin-left: 16px;
    padding-top: 16px;
  }

  .NotificationsHeaderTabs {
    max-height: calc(100vw - 40px) !important;
    margin: 20px 16px 0 0;
    padding: 0 16px;
    overflow-x: auto;
    width: auto !important;
  }

  .NotificationsHeaderTabsActiveItemText {
    color: #212529 !important;
  }

  .NotificationsHeaderTabsActiveItem {
    height: 3px;
    background: #FCE000 !important;
    top: 28px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .NotificationsHeaderTabs::-webkit-scrollbar {
    display: none !important;
  }

  .NotificationMainPageTitleMobileAdd {
    display: inherit !important;
    margin-left: 12px;
    padding: 8px;
    background: #FCE000;
    border-radius: 8px;
  }

  .NotificationMainPageTitleMobileDeleteWrapper {
    margin-left: auto;
  }

  .NotificationMainPageTitleMobileDelete {
    display: inherit !important;
    margin-left: auto;
    margin-right: 8px;
    padding: 8px;
    border: 1px solid #DEE2E6;
    border-radius: 8px;
    height: 40px;
  }

  .NotificationMainPageTitleMobileClearBasket {
    display: inherit !important;
    font-family: Gilroy, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #FA5252;
    padding: 12px 10px;
    border: 1px solid #FF8787;
    box-sizing: border-box;
    border-radius: 8px;
    margin-left: auto;
    margin-right: 8px;
  }

  .NotificationMainPageTitle span {
    font-family: Gilroy, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #212529;
  }
}