.MainSuperWinWrapper {
  margin-top: 20px;
  border-radius: 8px;
}

.MainSuperWinWrapper .ant-collapse {
  border-radius: 8px;
}

.MainSuperWinWrapper .ant-collapse .ant-collapse-item {
  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  border-radius: 8px !important;
  box-sizing: border-box !important;
}

.MainSuperWinWrapper .ant-collapse .ant-collapse-item:hover {
  border: 1px solid #CED4DA;
}

.MainSuperWinWrapper .ant-collapse .ant-collapse-item-active:hover {
  border: 1px solid #E9ECEF !important;
}

.MainSuperWinWrapper .ant-collapse .ant-collapse-item .ant-collapse-header {
  display: grid;
  place-items: center;
}

.ant-collapse-content-active {
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.MainSuperWinWrapper > div > div > .ant-collapse-content {
  border-top: 1px solid #E5E5EA !important;;
  background-color: #fff !important;
}

.MainSuperHeaderWrapper {
  text-align: center;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding: 6px 0;
}

.MainSuperGamesWrapper {
  padding: @pd-8 @pd-16;
  margin-bottom: @pd-24;

  border-radius: @pd-8;
  border: 1px solid @gray-200;
  background: @white-100;
}

.MainSuperGamesLogo {
  .MainSuperGamesLogoImg {
    margin-top: 8px;
  }
}

.MainSuperGamesLogoImg {
  width: 28px;
}

.MainSuperGamesLogo span {
  color: #3A3A3C;
  padding-left: 8px;
  padding-top: 12px;
}

.MainSuperGamesHeader {
  justify-content: space-between;
  padding-right: 20px;
  margin-left: 16px;
}

.MainSuperButtonWrapper {
  padding-top: 5px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 1590px) {
  .MainSuperGamesHeader {
    flex-wrap: wrap;
  }
  .MainSuperButtonWrapper {
    padding-bottom: 24px;
  }
}

.MainSuperButtonLabel {
  svg path {
    fill: #fff
  }
  span {
    padding-left: 8px;
    padding-top: 4px;
    color: #fff !important;
    font-family: Gilroy, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }
}


.MainSuperHeaderHallWrapper {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #8E8E93;
  padding-left: 8px;
}

// super win table

.MainSuperTablesWrapper {
  overflow-x: auto;
  padding-right: 20px;
}

.MainSuperTableWrapper {
  min-width: 954px;

  border-radius: @pd-8;
  border: 1px solid @gray-200;
}

.MainSuperTableHeaderWrapper {
  margin-bottom: 6px;
}

.MainSuperGamesHeader, .MainSuperTableWrapper {
  background: #fff;
}

.MainSuperTableWrapper {
  margin: 0 0 16px 16px;
}

.MainSuperTableRow {
  width: 100%
}

.MainSuperTableHeaderCell {
  background: @cyan-10;
  padding: 20px 13px 12px 0;
  text-align: left;

  height: 54px;
}

.MainSuperTableHeaderCell,
.MainSuperTableMobileTitleMain {
  background: @cyan-10;
}

.MainSuperTableMobileTitleVip,
.MainSuperTableHeaderVip {
  background: @orange-10 !important;
}

.MainSuperTableFirstCell {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding: 18px 32px 18px 0;
  width: 100%;
  text-align: left;

  height: 54px;
}

.MainSuperTableLeftCell {
  text-align: left !important;
  min-width: 176px;
  padding-left: 24px;
}

.MainSuperTableCellBordered {
  border-bottom: 1px solid #E5E5E5;
}

.MainSuperWinSpinWrapper {
  margin: 32px;
}

@media screen and (max-width: 920px) {
  .MainSuperButtonWrapper {
    padding-left: 0 !important;
  }

  .MainSuperTableWrapper {
    display: none;
  }

  .MainSuperTableMobileWrapper {
    border: 1px solid #E9EAEA;
    border-radius: 8px;
    margin-bottom: 16px;
  }

  .MainSuperTableMobileTitle {
    border-top-right-radius: @pd-8;
    border-top-left-radius: @pd-8;
    border-bottom: 1px solid #E5E5E5;
  }

.MainSuperTableMobileHeader {
    background-color: #FBFBFB;
    border-bottom: 1px solid #E9EAEA;
  }

  .MainSuperTableMobileHeaderItem,
  .MainSuperTableMobileHeaderItemLast {
    padding: 12px;
    width: calc((100vw - 40px) / 3);
    display: grid;
    place-items: center;
    text-align: center;
  }

  .MainSuperTableMobileBodyItem,
  .MainSuperTableMobileBodyItemLast {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #2B2D33;
    padding: 12px;
    width: calc((100vw - 40px) / 3);
    display: grid;
    place-items: center;
    text-align: center;
  }

  .MainSuperTableMobileHeaderItem,
  .MainSuperTableMobileBodyItem {
    border-right: 1px solid #E9EAEA;
  }

  .MainSuperTableMobileBodyRow {
    border-bottom: 1px solid #E9EAEA;
  }

  .MainSuperTableMobileBodyRowLast {
    border-bottom-right-radius: @pd-8;
    border-bottom-left-radius: @pd-8;

    border-bottom: none !important;
  }
}
