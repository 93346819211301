@import "./EmptyTable/styles";

.NotificationsTable {
  margin-top: 24px;
  padding-bottom: 24px;
}

.NotificationsTable .TableColumnWrapper .TableComponentTopScrollWrapper {
  display: none !important;
}

@media screen and (min-width: 1441px) {
  .NotificationsTableText {
    max-height: 64px;
  }
}

@media screen and (min-width: 921px) and (max-width: 1440px) {
  .NotificationsTableText {
    //max-width: calc(100vw - 1000px);
    max-height: 72px;
  }
}

@media screen and (max-width: 920px) {
  .NotificationsTable {
    margin: 0 8px !important;
    width: calc(100vw - 40px) !important;
  }

  .NotificationsTableText {
    min-width: 400px;
    max-height: 72px;
  }
}

.NotificationsTableTextRow {
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.NotificationsTableTextRow > span {
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  h1, h2, h3, h4, h5 {
    font-family: Lato, sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #212529;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.NotifiTableDateColumn {
  word-spacing: normal !important;
}

.NotificationsTableTitle {
  padding-right: 2px;
  font-weight: bold !important;
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.NotificationsRowToolsIcon {
  padding-top: 3px;
}

.NotificationsRowTools {
  gap: 12px;
}

.NotificationsRowTools div:hover > svg path {
  fill: black;
}
.NotificationsTableDate {
  margin-top: -10px !important;
}

.NotificationsTableDate div {
  svg {
    margin-right: 12px;
    margin-top: 5px;
  }
  span {
    margin-top: 5px;
  }
}

.NotificationsTable .TableColumnWrapper .TableComponentWrapper
.ant-spin-nested-loading .ant-spin-container
.ant-table .ant-table-container .ant-table-content table {
  .ant-table-thead > tr th {
    font-weight: 600 !important;
  }

  .ant-table-thead > tr th:first-child {
    padding-left: 22px !important;
  }

  .ant-table-tbody .ant-table-row {
    height: 72px !important;
  }

  .ant-table-tbody .ant-table-row td:first-child {
    padding-left: 22px !important;
  }
  .ant-table-thead tr .ant-table-cell .ant-table-selection .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner,
  .ant-table-tbody > tr .ant-table-cell .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
    height: 18px !important;
    width: 18px !important;
  }
}

.NotificationsTable .TableColumnWrapper .TableComponentWrapper div div div {
  overflow-x: auto;
}

.NotificationsTable .TableColumnWrapper .TableComponentWrapper div div div
.ant-table-container .ant-table-content table thead tr th div {
  height: 25px;
  width: 30px;

  label {
    margin-top: 2px;
  }
}

// history

.NotificationsRowToolsAuthorHistoryIcon {
  width: 32px;
  margin-right: 8px;
  margin-top: 2px;
}

.NotificationsRowToolsAuthorHistoryArea {
  top: calc(50% - 166px);
  left: calc(50% - 326px);
  z-index: 9;
  position: fixed;
  width: 704px;

  .HistoryModalClose {
    margin-top: 10px;
  }

  .HistoryModal {
    width: 652px;
    height: auto !important;
    background: #fff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 96px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    margin-right: 14px;
    padding: 32px;
    cursor: auto !important;
    overflow-y: inherit !important;

    .ModalTitle {
      font-family: Gilroy, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 20px;
      width: 100%;
      text-align: center;
      color: #212529;
      padding-bottom: 24px;
    }

    .ModalTable {
      width: 100%;
      border: 1px solid #E9ECEF;
      box-sizing: border-box;
      border-radius: 8px;
      max-height: 332px;
      overflow-y: auto;

      .ModalTableRow {
        border-bottom: 1px solid #E9ECEF;

        .ModalTableStatus {
          width: 170px;

          .ModalTableStatusTitle {
            font-family: Lato, sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            color: #212529;
            padding: 18px 34px 21px 24px;
          }
          .ModalTableStatusTitlePrefix {
            color: grey;
          }
        }

        .ModalTableName {
          font-family: Lato, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #212529;
          padding: 18px 0 21px 41px;
          width: 265px
        }

        .ModalTableDate {
          font-family: Lato, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #495057;
          padding-top: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 920px) {
  .NotificationsRowToolsAuthorHistoryArea {
    width: 100vw;
    top: 72px;
    left: 0;

    .HistoryModalClose {
      position: fixed;
      top: 20px;
      right: 20px;
    }

    .HistoryModal {
      margin: 0 !important;
      padding: 32px 0 !important;

      .ModalTable {
        .ModalTableRow {
          overflow-x: auto;
          min-width: 560px;
        }
      }

      .ModalTable::-webkit-scrollbar {
        display: none !important;
      }
    }
  }
}
