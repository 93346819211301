@import "./ContactsPage/styles";
@import "./EditModals/styles";

.ContactsMainPageWrapper{
  background: linear-gradient(180deg, #FFFFFF 0%, #F8F8F8 29.63%, #F4F4F4 100%);
}

.ContactsPageHeaderMainWrapper {
  min-height: unset;
  margin: 0 auto;
  padding-top: 40px;
  width: 1440px;
  max-width: 92%;
}

.ContactsPageHeader_title {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #212529;
  flex: auto;
}

.ContactsPageHeaderWrapper {
  display: flex;
}

.ContactsEmptyPageInfo_title {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #000000;
  padding-bottom: 44px;
}

.ContactsEmptyPage_description {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #808185;
}

.ant-btn.ant-btn-primary.ContactsPageHeader_editBtn {
  font-family: 'Gilroy';
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.ant-btn.ant-btn-primary.ContactsPageHeader_editBtn:hover:disabled, .ant-btn.ant-btn-primary.ContactsPageHeader_editBtn:disabled {
  background-color: #ADB5BD;
  border-color: #ADB5BD;
}

.ant-btn.ant-btn-primary.ContactsPageHeader_editBtn:disabled {
  background-color: #ADB5BD;
  border-color: #ADB5BD;
}

.ContactsEmptyPageInfoWrapper {
  text-align: center;
  margin-top: 202px;
}

.ant-btn.ant-btn-primary.ContactsBtn {
  font-family: 'Gilroy';
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.ContactsPageHeader_editBtnWrapper .ButtonPrimaryWrapper {
  display: inline-block;
}

.ContactsPageHeader_saveBtn {
  width: 169px;
}

.ContactsPageHeader_cancelBtn {
  background-color: #CED4DA;
  width: 169px;
}

.ContactsPage_addBlockBtn {
  svg {
    margin-right: 9px;
  }

  margin-top: 32px;
  width: 203px;
}

.ContactsEmptyPageInfo_startBtn {
  margin-bottom: 44px;
}

.ContactsPage_addBlockBtnWrapper {
  width: 1440px;
  max-width: 95%;
  margin: 0 auto;
  padding-left: 24px;
}

.ContactsEmptyPageInfoNoAccess_title {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #000000;
  margin-bottom: 8px;
}

.ContactsPageHeader_tabsWrapper .TooltipComponentTargetWrapper {
  display: inline-block;
  margin-right: 24px;
  margin-top: 20px;
  border-bottom: 3px solid transparent;
  padding-bottom: 11px;

  &:hover {
    color: #212529;
    border-bottom-color: #FCE000;
  }
}

.ContactsPageTooltip {
  .ant-popover-arrow-content::before {
    top: -12px;
  }
}

.ContactsPageHeader_tab {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #868E96;
  position: relative;


  .ContactsPageHeader_tab_text {
    white-space: nowrap;
    max-width: 217px;
    overflow: hidden;
    display: block;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 170px;
      top: 0;
      bottom: 0;
      width: 49px;
      background: linear-gradient(270deg, rgba(255, 255, 255, 1) 8.16%, rgba(255, 255, 255, 0.5) 45.73%, rgba(255, 255, 255, 0) 81.63%);
    }
  }
}


.ContactsPageHeader_tab_active {
  color: #212529;
  border-bottom-color: #FCE000;
}

.ContactsOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(13, 15, 16, 0.56);
  z-index: 12;
}

.ContactsPageHeader_tabsWrapper {
  position: sticky;
  top: 0;
  z-index: 5;
  margin-bottom: 32px;
  background-color: #fff;
}

.ContactsPageHeader_tabsInner{
  width: 1440px;
  max-width: 92%;
  margin: 0 auto;
  border-bottom: 1px solid #DEE2E6;
  min-height: 52px;
}

@media screen and (max-width: 920px) {
  .ContactsMainPageWrapper {
    background: #FFFFFF;
    width: 100vw;
  }

  .ContactsPageHeaderMainWrapper {
    margin-top: 18px;
    max-width: unset;
    width: auto;
    padding-left: 16px;
    padding-right: 16px;
  }

  .ContactsPageHeader_tabsWrapper {
    background-color: #FFFFFF;
    z-index: 11;
    border-bottom: none;
  }

  .ContactsPageHeader_tabsInner{
    max-width: unset;
    width: auto;
    padding-left: 16px;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    border-bottom: 1px solid #DEE2E6;
  }

  .ContactsPageHeader_title {
    font-size: 24px;
    line-height: 120%;
  }

  .ContactsPageHeader_tabsWrapper {
    overflow-x: scroll;
    white-space: nowrap;
    margin-bottom: 0;
  }

  .ContactsPageHeader_tabsWrapper::-webkit-scrollbar {
    display: none !important;
  }
}

.ContactsEdit_error {
  color: red;
}

.ContactsPageHeader_tabActive {
  color: #212529;
}