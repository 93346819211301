.MessagesComponentIdBack {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #495057;
}

.MessagesComponentIdBack {
  padding-bottom: 16px;
}

.MessagesComponentIdBack:hover {
  cursor: pointer;
  opacity: 0.7;
}

.MessagesComponentIdBack > div {
  padding-left: 4px;
  padding-top: 2px;
}

.MessagesComponentIdDataTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #212529;
  padding-bottom: 8px;
}

.MessagesComponentIdDataDate {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #868E96;
  padding-bottom: 12px;
}

.MessagesComponentIdDataHallsWrapper {
  padding-bottom: 24px;
}

.MessagesComponentIdDataWrapper {
  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 32px 40px;
}

@media screen and (max-width: 920px) {
  .MessagesComponentIdDataWrapper {
    margin: 0 16px;
  }

  .MessagesComponentIdBack {
    margin-top: 16px;
    margin-left: 16px;
  }
}

.MessagesComponentIdDataFiles {
  margin-top: 32px;
  padding-top: 16px;
  border-top: 1px solid #DEE2E6;
  max-width: 984px;
}

.MessagesComponentIdDownloadAllInfo {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #868E96;
}

.MessagesComponentIdDownloadAllLink {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #228BE6;
  padding-left: 8px;
}

.MessagesComponentIdDownloadAllLink:hover {
  cursor: pointer;
  opacity: 0.7;
}

.MessagesComponentIdDownloadAll {
  padding-bottom: 20px;
}

.MessagesIdRouteListItemHalls {
  font-weight: normal;
  color: #212529;
  overflow: hidden;
  text-overflow: fade !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important; /* number of lines to show */
  line-clamp: 1 !important;
  -webkit-box-orient: vertical;
}

.MessagesIdRouteListItemHallsMore {
  margin-top: auto;

  span {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #228BE6;
    margin-left: 8px;
  }
  svg {
    margin-top: 8px;
    margin-left: 6px;
  }
}


.MessagesIdRouteListItemHallsMoreUp {
  svg {
    transform: rotate(0deg);
    transition-duration: 0.4s;
  }
}
.MessagesIdRouteListItemHallsMoreDown {
  svg {
    transform: rotate(-180deg);
    transition-duration: 0.4s;
  }
}

.MessagesIdRouteListItemHallsMore:hover {
  opacity: 0.7;
  cursor: pointer;
}

.PinIconWrapper {
  padding-left: 4px;
  padding-top: 2px;
}

.SmallPinIconWrapper {
  padding-top: 1px;
  padding-right: 8px;
}

.UnPinnedIconWrapper {
  svg path {
    fill: #CED4DA;
  }
}

.PinnedIconWrapper {
  svg path {
    fill: #FF922B;
  }
}

.UnPinnedIconWrapper:hover {
  svg path {
    fill: #ADB5BD;
  }
}

.PinIconWrapper:hover {
  cursor: pointer;
}