.ReportModalWrapper {
  padding: 40px 21px;
  z-index: 1000;
  background-color: white;
  position: absolute;
  width: 900px;
  overflow: hidden;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 16px 0 0 16px;
}

.NoncashPageTitleHeader {
  white-space: pre-line;
}

@media screen and(max-width: 994px) {
  .ReportModalWrapper {
    width: 100%;
  }

  .TableColumnWrapper {
    width: calc(100vw - 10px);
  }

  .NoncashPageTitle {
    width: 100% !important;
  }
}

.ReportModalWrapper {
  .CloseModalIcon:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  .InfoModalIcon {
    margin-left: 10px;
  }

  .NoncashPageTitle {
    font-size: 24px;
  }

  .CashboxContentPeriod {
    padding-left: 0;
    color: #808185;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
  }

  thead tr .ActiveSortTableColumn .ant-table-column-sorters > span:first-child {
    color: #eea84e !important;
  }

  .ant-table-column-sorter-inner .active svg path {
    fill: #eea84e !important;
  }

  .ant-table-column-has-sorters .ant-table-column-sorters,
  .ant-table-thead > tr > th {
    background-color: #FFF4E6 !important;
    padding: 12px !important;;
  }

  .NoncashTableColumn {
    .ant-table-column-sorters {
      padding-left: 0 !important;
    }
  }

  .NoncashPageTitleDescription {
    width: 100% !important;
  }

  .NoncashPageHeader {
    width: 100% !important;
  }

  .NoncashPageTitle {
    width: 100% !important;
  }


}

.ant-table-column-sorters {
  padding-left: 0 !important;
}

.NoncashTableColumn {
  padding: 12px !important;
  word-spacing: normal !important
}

.CloseModalIcon {
  margin-left: auto;
}

.NoncashTableDate {
  padding-left: 0;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
}

.NoncashTableTypeValue {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
  padding-left: 8px;
  padding-top: 1px;
}

.NoncashTableTime {
  opacity: 0.5;
  padding-left: 8px;
}


.NoncashPageHeaderDescriptionWrapper {
  width: 100%;
}

.ant-table-thead > tr > th:first-child {
  padding-left: 12px !important;
}

.ant-table-column-sorters {
  justify-content: initial !important;
}

.ant-table-column-title {
  flex: 0 auto !important;
}

.ant-table-column-sorter {
  margin-left: 6px !important;
}

.TransactionEnterPin {
  background: #F1F3F5;
  border-radius: 4px;
  padding: 6px 12px 8px;
}

.TransactionSuccess {
  color: #40C057;
  background: #EBFBEE;
  border-radius: 4px;
  padding: 6px 12px 8px;
}

.TransactionUnsuccessful {
  color: #FA5252;
  background: #FFF5F5;
  border-radius: 4px;
  padding: 6px 12px 8px;
}

.TransactionPending {
  background: #F1F3F5;
  border-radius: 4px;
  padding: 6px 12px 8px;
}

.TransactionContact {
  background: #F1F3F5;
  border-radius: 4px;
  padding: 6px 12px 8px;
}


.NoncashPageHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 32px;
}

.NoncashPageTitle {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #212529;
}

@media screen and (max-width: 1500px) {
  .MainPageContentWrapperUnFilter .MainPageContentRestrictions
  .ReportLegoComponentContentWrapper div
  .NoncashPageHeader div div .NoncashPageTitle {
    max-width: 400px;
  }
}

@media screen and (max-width: 1800px) {
  .MainPageContentWrapperFilter .MainPageContentRestrictions
  .ReportLegoComponentContentWrapper div
  .NoncashPageHeader div div .NoncashPageTitle {
    max-width: 400px;
  }
}

@media screen and (max-width: 920px) {
  .NoncashPageTitleDescription {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 921px) {
  .NoncashPageTitleDescription {
    display: flex;
    flex-direction: row;
  }
}

.NoncashContentPeriodWrapper {
  padding-top: 8px;
}

.NoncashPageTitleInfo {
  margin-left: 10px;
  margin-top: 4px;
}

.NoncashPageTitleInfo:hover {
  cursor: pointer;
  opacity: 0.8;
}

.NoncashPageTitleExel {
  margin-left: 12px;

}

.Noncash{
  .NoncashPageTitleExel {
    display: flex;
    align-items: center;

    .ButtonIconWrapper .ant-btn.ButtonIcon_excel{
      height: 32px;

      svg{
        top: 5px;
      }
    }
  }
}

.NoncashPageTitleQiwiWrapper {
  margin-left: auto;
}

.NoncashPageTitleQiwiWrapper:hover {
  cursor: pointer;
  opacity: 0.8;
}

.NoncashPageTitleQiwiIcon {
  padding-right: 8px;
}

.NoncashPageTitleQiwiTitle {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #212529;
  padding-top: 2px;
  padding-right: 24px;
  width: 128px;
}

.NoncashPageTitleQiwiValue {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #212529;
}

.NoncashContentPeriodWrapper {

  align-items: flex-end;

  .ButtonIconWrapper {

    display: none;

    //TODO поправить иконку exel согласно макета

    button {
      //border: none !important;
      //background-color: transparent !important;
      //background-image: url("/Components/img/excel.svg") !important;
      //background-size: contain !important;
      //padding: 0 !important;
      //width: 32px;
      //height: 32px;
      //border-radius: 0;

      span {
        display: none;
      }

      svg {
        display: none;
      }
    }
  }

  .ButtonIconWrapper .ant-btn.ButtonIcon_excel:not([disabled]):hover, .ButtonIconWrapper .ant-btn.ButtonIcon_excel:not([disabled]):focus, .ButtonIconWrapper .ant-btn.ButtonIcon_excel:not([disabled]):active {
    border: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .ContentPeriodWrapper {
    align-items: center;
  }
}

@media screen and (max-width: 1600px) {
  .MainPageContentWrapperFilter {
    .NoncashPageHeader {
      display: block !important;
    }

    .NoncashPageTitleQiwiWrapper {
      display: inline-flex !important;
      margin-top: 10px
    }
  }

  .ReportLegoComponentContentWrapper {
    margin-left: 5px;
  }

  .NoncashPageTitleExel {
    margin-left: 7px;
  }
}

@media screen and (max-width: 1200px) {

  .NoncashContentPeriodWrapper {

    .ButtonIconWrapper {
      display: block;
    }
  }
}

@media screen and (max-width: 910px) {

  .NoncashComponentContentWrapper {
    padding-left: 10px;
    padding-right: 10px;

    .TableColumnWrapper {
      max-width: 100%;
    }

  }

  .NoncashPageTitle {
    font-size: 24px;
    max-width: 320px !important;
  }

  .ReportModalWrapper .NoncashPageTitle {
    max-width: 400px !important;
    margin-left: 0 !important;
  }

  .NoncashTitleInfo {
    margin-left: 0;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .NoncashPageHeader {
    display: block !important;
  }

  .NoncashTitleQiwiWrapper {
    display: inline-flex !important;
  }

  .NoncashPeriodWrapper {
    margin-top: 10px;
    justify-content: space-between;
  }

  .NoncashContentPeriodWrapper .ContentPeriodWrapper {
    display: block;

    .ContentPeriod,
    .ContentPeriodMain {
      padding-left: 0;
    }
  }

  .ContentPeriodWrapper {
    flex-direction: column !important;

    .ContentPeriodDescription {
      padding-left: 8px;
    }
  }
}
