.ReportHallPDFWrapper {
  height: calc(100vh - 216px);
}

.ReportHallTitleTextDescription {
  margin-left: 7px;
  padding-top: 5.5px;
}

.ReportHallPDFMobile {
  .FileItemWrapper {
    padding-left: 32px;
    width: calc(100vw - 64px) !important;
    background: @gray-100 !important;

    .FileItemText {
      .FileItemTextName {
        max-width: calc(100vw - 180px) !important;
      }
    }
  }
}