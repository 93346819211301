.ProposalMobileCard {
  padding: 16px 14px 19px 14px;
  background-color: #FFFFFF;
  border: 1px solid #E9ECEF;
  border-radius: 12px;
  margin: 8px;
}

.ProposalMobileCard:hover {
  cursor: pointer;
}

.ProposalMobileCardTitleWrapper {
  display: flex;
  justify-content: space-between;
}

.ProposalMobileCardTitle {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #212529;
  display: flex;
}

.ProposalMobileCardText {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.2px;
  padding-top: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 5 !important; /* number of lines to show */
  line-clamp: 5 !important;
  -webkit-box-orient: vertical;
}

.ProposalMobileCardFooter {
  display: flex;
  padding-top: 16px;
}

.ProposalMobileCardElement {
  display: flex;
  padding-left: 14px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  letter-spacing: 0.5px;
  color: #ADB5BD;
}

.ProposalMobileCardElementDetails {
  margin-left: auto;
  margin-right: 0;
}

.ProposalMobileCardElementDetails:hover {
  cursor: pointer;
}

.ProposalMobileCardStatus {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.4px;
}

.ProposalMobileCardCounter {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #ADB5BD;

  padding-left: 4px;
  padding-bottom: 4px;
}

.ProposalMobileCardTitleDate .FlexRow {
  align-items: center;
}

.ProposalMobileCardTitleText {
  margin-right: 6px;
}

.SupportProposalModalMobile .ProposalModalTextTitleText {
  font-size: 14px;
}

.SupportProposalModalMobile .TooltipComponentTargetWrapper {
  margin-left: 2px !important;
  margin-top: 6px !important;
}

.ProposalModalContentMobileView {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: white;
  height: 100vh;
  z-index: 5;
  padding: 0 17px 0 17px;
  border-top: 52px solid #f4f4f4;


  .ButtonIconWrapper {
    font-size: 16px !important;
  }

  .ProposalModalTextTitleText {
    font-size: 24px !important;
  }

  .ProposalModalButtonsGroupTopLine {
    padding-top: 18px !important;
  }

  .ProposalModalContentWrapper:first-child {
    margin-top: 18px;
  }

  .ProposalModalSubTitleWrapper {
    margin-top: 0 !important;
  }

  .ProposalModalButtonsGroup {
    padding-bottom: 19px !important;
  }
}