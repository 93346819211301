.AccessModalContent {
  border-radius: 8px;
  border: 1px solid @gray-200;
  background: @white-100;

  padding: 40px 32px;
}

@media screen and (min-width: 921px){
  .AccessModalContentColumn {
    width: 33%;
  }
}

.AccessDashboardSelectItemWrapper {
  background: @white-100 !important;

  .ant-select-item-option-state {
    display: none;
  }
}

.AccessDashboardSelectItemWrapper .ant-select-item-option-content div {
  .CheckboxWrapper {
    margin-top: 0 !important;

    .CheckboxText {
      padding-left: 0 !important;
    }
  }
  div {
    margin-top: 3px;
  }
}

.AccessDashboardTag {
  padding: 6px 12px;

  border-radius: 8px;
  background: @white-800;
}

.AccessDashboardTag > div {
  height: 20px;
}

.AccessDashboardSelectWrapper .ant-select .ant-select-selector .ant-select-selection-overflow {
  gap: 8px
}

.AccessDashboardSelectWrapper {}

.CrudAccessMenuWrapper {
  border-radius: 8px;
  border: 1px solid @gray-400;

  align-content: flex-start;

  height: 100%;

  .ant-tree-checkbox.ant-tree-checkbox-checked.ant-tree-checkbox-disabled .ant-tree-checkbox-inner{
    background-image: url(/Components/icons/check/checkbox-disabled_20.svg) !important;
  }

  .ant-tree-checkbox.ant-tree-checkbox-disabled .ant-tree-checkbox-inner{
    background-color: #E9ECEF !important;
    border: none !important;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.AccessMenuTagsWrapper {
  border-radius: 8px;
  background: @white-800;
  padding: 6px 12px;

  height: 32px;
}

.CustomTemplateNameRadio {
  display: flex !important;
  flex-direction: row;

  margin-top: -8px !important;
}

.MenuTagsItemLabel {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.AccessMenuMobileWrapper {
  height: 48px !important;

  .AccessMenuMobileSelectWrapper {
    height: 48px !important;

    .ant-select {
      height: 48px !important;

      .ant-select-selector {
        height: 48px !important;
      }
    }
  }
}

.AccessCrudMenuTags {
  height: 100%;
}

.AccessCrudPasswordSend {
  margin-top: -4px;
}

.AccessCrudPasswordSend .ant-form-item .ant-form-item-row .ant-form-item-control-input {
  min-height: 32px !important;
}

.AccessDashboardIcon {
  height: 20px;
  width: 20px;
}

.AccessModalInputGroup{
  margin-bottom: 24px;

  .ant-form-item{
    margin-bottom: 6px;
  }
}

.ant-select-item.ant-select-item-group:has(.AccessDividerWrapper){
  .Gray500Color;
  min-height: 11px;
  padding-top: 0;
  padding-bottom: 0;
}

.AccessDividerWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}

.AccessDividerInner{
  position: relative;
  width: 100%;
  text-align: center;
  line-height: 11px;
  font-size: 11px;

  &:before,
  &:after{
    .Gray400Background;
    content:'';
    position: absolute;
    width: calc(50% - 40px);
    height: 1px;
    top: 5px;
  }

  &:before{
    left: -12px;
  }

  &:after{
    right: -12px;
  }
}

.AccessModalNewPosition{
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 4px;

  svg{
    display: none;
  }
}

.ant-select-dropdown .AccessModalNewPosition svg {
  display: block;
}

.AccessModalFormWrapper{
  padding: 12px 12px 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AccessModalButtonContainer{
  flex: 0 0 90px;

  .ant-btn-primary .ant-btn, .ButtonPrimaryWrapper .ant-btn{
    height: 40px;
    width: 100%;
    padding: 0;
  }
}

.AccessModalInputContainer{
  flex: 0 0 calc(100% - 102px);

  .InputWrapper > .ant-input, .InputWrapper > .ant-input-number, .InputWrapper > .ant-input-affix-wrapper, .InputWrapper .ant-input-number-input{
    height: 40px !important;
  }
}

.AccessModalUnemployedCheckbox.ant-checkbox-wrapper-disabled {
  span .ant-checkbox-inner {
    background-color: @gray-200 !important;
  }
}