// left menu
@import "./LeftMenu/styles";
// halls
@import "../HallPicker/styles";
// messages
@import "./Messages/styles";
// main page
@import "../Main/style";
// cashbox
@import "../Cashbox/styles";
// consolidated
@import "../Consolidated/styles";
// games
@import "../Games/styles";
// Tools
// tools/notifi
@import "../Tools/Notifications/styles";
// Order
// order/changephone
@import "../Order/ChangePhone/styles";
// Promotion
// guest_info
@import "../Promotion/GuestInfo/styles";
// messages
// noncash
@import "../Noncash/BalanceOperation/styles";
@import "../Noncash/Transaction/styles";
@import "../Noncash/ReportLego/styles";
// tools/messages
@import "../Messages/styles";
// mailing
@import "../Mailing/styles";
// guest-history
@import "../GuestsHistory/styles";
// superwin
@import "../Superwin/styles";
// errors
@import "../Errors/styles";

@import "../Support/Proposal/styles";
@import "../Handbooks/styles";
@import "../Support/Penalty/Admin/styles";
@import "../Support/Penalty/User/styles";
@import "../Contacts/styles";
@import "../Visit/styles";
@import "../Guidebook/styles";
@import "../Sms/styles";
@import "../Reports/Cupis/styles";
@import "../Reports/Hall/styles";
@import "../GuestsBalance/styles";
@import "../OfficialSms/styles";
@import "../Wiki/styles";
@import "../SportBoom/styles";
@import "../Insurances/styles";
@import "../Promotions/TopThree/styles";
@import "../Race/styles";
@import "../Nominals/styles";
@import "../Order/Payrolls/styles";
@import "../Order/Qiwi/styles";
@import "../Order/Payment/styles";
@import "../Order/Components/StatusNotifyModal/styles";
@import "../HistoryBets/styles";
@import "../RegistrationCheck/styles";
@import "../Promotion/Bonuses/Lego/styles";
@import "../Promotion/Bonuses/Goldrush/styles";
@import "../Hall/SportsBroadcast/styles";
@import "../Hall/Geoservices/styles";
@import "../Guest/Filters/styles";
@import "../Hall/Access/styles";


.BaseLayoutWrapper {
  background-color: #1C1C1E;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.BaseLayoutWrapperWiki {
  background-color: #4A5057 !important;
}

.BaseLayoutContentWrapper {
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background: #f4f4f4;
}

.BaseLayoutLeftMenu {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.BaseLayoutLeftMenuWiki {
  background-color: #4A5057 !important;
}

.BaseLayoutLeftMenuWikiWrapperText {
  font-family: Gilroy;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.19984513521194458px;
  text-align: left;
  margin-left: 12px;
  color: #FFFFFF
}

.BaseLayoutLeftMenuWikiWrapperRoot {
  display: flex;
  flex-direction: column;
  margin: 31px 32px;
}

.BaseLayoutLeftMenuWikiWrapper {
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
}

.BaseLayoutLeftMenuWikiWrapperBackButton {
  margin-bottom: 30px;
  width: 100%;
  background-color: #4A5057 !important;
  color: #FFFFFF;
   span {
    color: #FFFFFF !important;
  }
}


.BaseLayoutContentFooterMobile, .BaseLayoutContentFooter {
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
  color: #808889;
  padding: 8px 0;
}

.BaseLayoutContentFooterMobile {
  font-size: 15px;
}

.BaseLayoutContentFooter {
  font-size: 15px !important;
  background: #F4F4F4;
}

.BaseLayoutContentFooter {
  width: 100%;
  text-align: center;
}


@media screen and (max-width: 920px){
  .BaseLayoutContentWrapper {
    width: 100vw;
  }

  .BaseLayoutContentWrapper::-webkit-scrollbar {
    display: none !important;
  }

  .BaseLayoutLeftMenu {
    max-height: 100vh;
    width: 100vw !important;
    overflow-y: auto;
  }

  .MobileMenuCloseWrapper {
    margin-top: 48px;
    margin-left: auto;
    margin-right: 32px;
  }


  .BaseLayoutLeftMenu::-webkit-scrollbar {
    width: 8px;
    background-color: #1C1C1E;
    border-radius: 20px;
  }

  .BaseLayoutLeftMenu::-webkit-scrollbar:horizontal {
    height: 8px;
    background-color: #8E8E93;
    border-radius: 20px;
  }

  .BaseLayoutLeftMenu::-webkit-scrollbar-thumb {
    background-color: #8E8E93;
    border-radius: 20px;
    margin-right: 4px;
  }

  .BaseLayoutLeftMenu::-webkit-scrollbar-track {
    background-color: #1C1C1E;
  }

  .BaseLayoutLogOutIcon {
    margin-bottom: 120px !important;
  }
}

@media screen and (min-width: 921px) {
  .MobileMenuCloseWrapper, .BaseLayoutContentFooterMobile {
    display: none !important;
  }
}

@media screen and (min-width: 921px) and (max-width: 1440px) {
  .BaseLayoutLeftMenu {
    width: 256px;
  }

  .BaseLayoutLogoWrapper {
    margin-right: 14px !important;
  }

  .BaseLayoutContentWrapper {
    width: calc(100vw - 256px);
  }

  .BaseLayoutMenuAccordionItem, .BaseLayoutMenuAccordionHeader div {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1441px) {
  .BaseLayoutLeftMenu {
    width: 320px;
  }

  .BaseLayoutContentWrapper {
    width: calc(100vw - 320px);
  }
}

.BaseLayoutLogoWrapper {
  margin: 32px 32px 24px 32px;
  display: flex;
  flex-direction: column;
}

.BaseLayoutClickableLogo {
  cursor: pointer;
}

.BaseLayoutLogoDescription {
  padding-top: 6px;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  opacity: 0.75;
}

.BaseLayoutLogOutIcon:hover {
  cursor: pointer;
  opacity: 1;
}

.BaseLayoutMenuAccordionHeader .ant-collapse-header {
  opacity: 0.8;

  &:hover {
    opacity: 1;

    svg path {
      fill: #ffffff;
    }
  }
}

.BaseLayoutLeftMenuRectangle {
  height: 1px;
  background: #2C2C2E;
  margin: 18px 32px;
}

.BaseLayoutMenuAccordion {
  padding: 0 16px;
}

.BaseLayoutMenuAccordionHeader .ant-collapse-header:hover {
  background-color: rgba(255,255,255, 0.1);
  border-radius: 4px;
}

.BaseLayoutMenuAccordionHeader .ant-collapse-content .ant-collapse-content-box {
  padding: 0 !important;
}

.BaseLayoutMenuAccordionItem, .BaseLayoutMenuAccordionHeader div {
  font-family: Lato, sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 19px !important;
  color: #FFFFFF !important;
}

.BaseLayoutMenuAccordionContent {
  padding-left: 16px;
}

.BaseLayoutLeftMenuItem {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  opacity: 0.75;
  padding: 8px 0;
  height: 56px;

  svg {
    margin-top: 8px;
    margin-left: 32px;
  }

  span {
    padding: 8px 12px;
  }

  .BaseLayoutLeftMenuItemWithoutIcons {
    padding-left: 32px !important;
  }

  svg path {
    fill: #fff;
  }
}

.BaseLayoutLeftMenuItem:hover {
  cursor: pointer;
  color: #F8E800;
  opacity: 1;

  .BaseLayoutLeftMenuItemWithoutIcons {
    cursor: pointer;
    color: #F8E800;
    opacity: 1;
  }

  svg path {
    fill: #F8E800;
  }
}

.BaseLayoutLeftMenuItem:hover > span > a {
  color: #F8E800;
}

.BaseLayoutLeftMenuItemActive {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #F8E800;
  padding: 8px 0;
  height: 56px;

  span a {
    color: #F8E800 !important;
  }

  .BaseLayoutLeftMenuItemWithoutIcons {
    color: #F8E800;
    opacity: 1;
  }

  .BaseLayoutLeftMenuItemActiveMarker {
    width: 4px;
    background-color: #F8E800;
    height: 40px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .BaseLayoutLeftMenuItemActiveMarkerAccordion {
    margin-left: -15px;
  }

  .BaseLayoutLeftMenuItemWithoutIconsAccordion {
    margin-left: 15px;
  }

  span {
    padding: 8px 12px;
  }

  .BaseLayoutLeftMenuItemWithoutIcons {
      padding-left: 28px !important;
  }

  svg {
    margin-top: 8px;
    margin-left: 28px;
  }

  svg path {
    fill: #F8E800;
  }
}

.BaseLayoutLogOutIcon {
  opacity: 0.75;
  margin: 0 32px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;

  svg path {
    fill: #fff;
  }

  span {
    padding-left: 12px;
    vertical-align: center;
    text-align: center;
  }
}

.AccordionItemUp {
  transform: rotate(-180deg); /* Equal to rotateZ(45deg) */
  transition-duration: 0.2s;
}

.AccordionItemDown {
  transform: rotate(0deg); /* Equal to rotateZ(45deg) */
  transition-duration: 0.2s;
}

.FlexRow {
  display: flex;
  flex-direction: row;

  &.JustifyEnd {
    justify-content: flex-end;
  }
}



.FlexColumn {
  display: flex;
  flex-direction: column;
}

.FlexWrap {
  flex-wrap: wrap;
}

.GridCenter {
  display: grid;
  place-items: center;
}

.ErrorTextWrapper {
  max-height: 84px;
  overflow-y: auto;
}

.ErrorText {
  font-family: Lato, sans-serif;
  font-size: 10px;
  color: #e80012;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}