.PenaltyUserCard {
  //width: 289px;
  width: 270px;
  height: 206px;
  background: #FFFFFF;
  margin-right: 20px;
  margin-bottom: 20px;
  border: 1px solid #E9ECEF;
  border-radius: 12px;
  padding: 20px 14px;
  display: flex;
  flex-direction: column;
}

.PenaltyUserCard:hover {
  cursor: pointer;
  opacity: 0.8;
}

.PenaltyUserCardWinReason {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 244px;
  margin-top: 12px;
}


.PenaltyUserCardHeaderWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 11px;
  justify-content: space-between;
}

.PenaltyUserCardHeaderSetDate {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #868E96;
}

.PenaltyUserCardFooter {
  display: flex;
  margin-top: auto;
  //padding-top: 15px;
}

.PenaltyUserCardPenaltyWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.PenaltyUserCardPenalty {
  margin-right: 5px;
}

.PenaltyUserCardPenaltyResolvedFirst {
  text-decoration-line: line-through;
  color: #868E96;
}

.TextDefaultBoldWinResolved {
  text-decoration-line: line-through;
  color: #868E96;
}

.PenaltyCardWinWinResolved {
  display: flex;
  .TextDefaultBoldWinResolved {
    margin-left: 4px;
    margin-right: 4px;
  }
}

.PenaltyCardWinWinResolvedColumn {
  display: flex;
  flex-direction: column;
}
