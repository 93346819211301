.SideFilterStatusesCounter {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
  padding-left: 4px;
  opacity: 0.2;
}

.SideFilterCheckBoxWrapper {
  max-height: calc(100vh - 25%);
  margin-bottom: 24px;
}

.SideFilterCheckBoxGroupWrapper {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #2C2C2E;
  display: flex;
  flex-direction: column;
  max-height: 180px;
  overflow-y: auto;

}

.SideFilterCheckBoxGroupWrapper label {
  border-bottom: none !important;
  padding-bottom: 16px;
}

.SideFilterCheckBoxGroupWrapper label:last-child {
  border-bottom: none !important;
}