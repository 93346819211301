.ant-select-multiple {
  .ant-select-selector {
    max-height: 150px;
    overflow-y: auto;
    height: auto !important;
  }
}

.ant-select-multiple .ant-select-selector .ant-select-selection-overflow
.ant-select-selection-search input {
  height: auto !important;
}

.ant-select-multiple .ant-select-selector .ant-select-selection-placeholder{
  left: 17px;
}

.ant-select-multiple .ant-select-selector .ant-select-selection-overflow
.ant-select-selection-overflow-item .ant-select-selection-search {
  -webkit-margin-start: 5px !important;
  margin-inline-start: 5px !important;
}

.ant-select-multiple .ant-select-selector {
  padding: 4px 12px !important;
  height: auto !important;
  min-height: 48px !important;
}

.ViewSelectWrapper {
  .ant-select-disabled {
    opacity: 1 !important;
    .ant-select-selector {
      background-color: white !important;
      .ant-select-selection-overflow > .ant-select-selection-overflow-item > span {
        color: #1D1F21 !important;
      }
    }
  }
}

.SelectWrapper,
.TreeSelectWrapper {
  .ant-select {
    width: 100%;

    .ant-select-arrow {
      transform: rotate(0deg);
      transition: transform 500ms ease;
    }
  }

  .ant-select-selector {
    display: flex !important;
    align-items: center !important;
    border-radius: 8px !important;
    color: #808889 !important;
    border-color: #D7D7D7 !important;
    box-shadow: none !important;
    min-width: 80px;

    &:not(.ant-select-disabled):hover {
      border-color: #212529 !important;
      //box-shadow: 0 0 0 1px rgba(248, 232, 0, 0.4) !important;

      .ant-select-selection-item {
        color: #545454 !important;
      }
    }

    .ant-select-selection-item {
      color: #545454 !important;
    }
  }

  .ant-select-arrow {
    color: #808889;
  }

  .ant-select-open {
    &:not(.ant-select-disabled) .ant-select-selector {
      &,
      &:hover {
        border-color: #212529 !important;
        box-shadow: none !important;
      }

      .ant-select-selection-item {
        color: #1D1F21 !important;
      }
    }

    .ant-select-arrow {
      transform: rotate(180deg);
      transition: transform 500ms ease;
    }
  }

  .ant-select-selection-placeholder {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #ADB5BD !important;
  }

  &.ant-select-disabled {
    opacity: 0.5;
  }
}

.SelectGreyWrapper {
  .SelectComponent {
    .ant-select-selector {
      background: #F8F9FA !important;
    }
  }
  .ant-select-focused > .ant-select-selector{
    background: #FFF !important;
    border-color: #212529 !important;
  }
}

.SelectGreyWrapperDisabled {
  opacity: 1;
}

.ErrorTreeSelectWrapper,
.ErrorSelectWrapper {
  .ErrorText {
    font-size: 10px;
    color: #e80012;
    margin-top: 2px;
  }

  .ant-select-selector {
    border-color: #e80012 !important;
  }
}

.ant-select-dropdown .ant-select-item-option-selected {
  background-color: @white-800 !important;
  background: @white-800 !important;
}

.ant-select-dropdown {
  border: 1px solid #D7D7D7;
  box-shadow: 0 4px 6px rgba(62, 73, 84, 0.04);
  border-radius: 8px !important;

  padding-top: 8px !important;
  padding-bottom: 8px !important;

  margin-top: -6px !important;

  .ant-select-item-option:hover {
    background-color: @white-800 !important;
  }

  .ant-select-item-option {
    padding: 10px 16px;
    font-weight: 500;

    .ant-select-item-option-content {
      &:extend(.TextOne);
    }

    &:not(.ant-select-item-option-disabled) {
      color: #545454;
    }

    &.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      color: #1D1F21;
    }

    &-selected {
      color: #1D1F21 !important;
      background-color: rgba(248,232,0,.2) !important;
      font-weight: 400 !important;
    }
  }

  .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
    background-color: rgba(248,232,0,.2) !important;
  }
}

.ant-select-selection-overflow-item > .ant-select-selection-item {
  background: #F4F4F4 !important;
  border-radius: 8px !important;
  padding: 0 8px !important;
}

.SelectLargeWrapper > .ant-select-single > .ant-select-selector {
  height: 50px !important;
  padding: 8px !important;
}

.SelectLargeWrapper .ant-select-selection-search-input {
  height: 48px !important;
}

.SelectWrapper {
  height: 48px !important;

  .ant-select {
    height: 48px !important;

    .ant-select-selector {
      height: 48px !important;

      .ant-select-selection-search {
        height: 48px !important;

        input {
          height: 48px !important;
        }
      }
    }
  }
}


.SelectWrapperGreyBackground .ant-select-selector {
  background-color: #f8f9fa !important;
}

.SelectMiddleWrapper > .ant-select-single > .ant-select-selector {
  height: 40px !important;
  padding: 9px 12px !important;
}

.SelectMiddleWrapper > .ant-select-single > .ant-select-arrow {
  position: absolute;
  top: 20px;
  right: 12px;
}

.PaginationSelectSmallWrapper {
  height: 32px !important;
  width: 72px;

  .ant-select {
    height: 32px !important;

    .ant-select-selector {
      height: 32px !important;
      width: 72px;

      padding: 6px 12px !important;

      border: 1px solid #CED4DA !important;
      border-radius: 4px !important;

      .ant-select-selection-search {
        width: auto !important;
        height: 20px !important;
      }
    }

    .ant-select-selection-item {
      padding-right: 0 !important;
    }
    .ant-select-arrow {
      margin-left: auto;
      padding-top: 0 !important;
      padding-right: 0 !important;
    }

    .ant-select-arrow {
      transform: rotate(0deg);
      transition: transform 500ms ease;
    }
  }
}

