@import "./Info/styles";
@import "./Modal/styles";

.SportsBroadcast__TitleButton {
  .ButtonPrimaryWrapper button {
    height: 40px;
  }
}

@media screen and (max-width: 920px) {
  .SportsBroadcast__TitleButton {
    margin-left: -10px;
    padding-top: @pd-12;
  }
}

.SportsBroadcast__Table__Cell__Lock {
  width: 20px;
}