.MailingDeleteContent {
  padding: 8px;
}

.MailingDeleteDescription {
  padding-top: 16px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #212529;
}

.MailingDeleteButtons {
  width: 252px;
  padding-top: 34px;
  justify-content: space-between;
  margin-left: auto;

  .ButtonDefaultWrapper {
    margin-right: 8px;
  }
}