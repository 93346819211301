.HourSelector {
  width: 120px !important;
  margin-right: 8px !important;
  height: 44px !important;
}

.HourSelector .ant-select-selector {
  padding: 0 !important;
  border: 1px solid #E9ECEF;
  border-radius: 8px !important;
  height: 44px !important;
}

.SelectHoursWrapper .ant-select-focused .ant-select-selector,
.HourSelector .ant-select-selector:focus,
.HourSelector .ant-select-selector:active,
.SelectHoursWrapper .ant-select-open .ant-select-selector,
.HourSelector .ant-select-selector:hover {
  box-shadow: none !important;
  border-color: #343A40 !important;
}

.SelectHoursWrapper .ant-select-open,
.SelectHoursWrapper .ant-select-focused,
.HourSelector:hover {
  .ant-select-arrow {
    svg path {
      fill: #343A40;
    }
  }
}

.HourSelector .ant-select-selection-search,
.HourSelector .ant-select-selection-item {
  padding: 6px 32px 10px 16px !important;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px !important;
  align-items: center;
  color: #212529;
}

.HourSelectorDropdownWrapper {
  margin: 8px 0 !important;
  padding: 8px 0 !important;
  background: #FFFFFF !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08) !important;
  border-radius: 8px !important;
}

.HourSelectorDropdownWrapper div
.rc-virtual-list .rc-virtual-list-holder div
.rc-virtual-list-holder-inner > div {

  padding: 10px 16px !important;

  .ant-select-item-option-content {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #212529;
  }
}

.HourSelectorDropdownWrapper div
.rc-virtual-list .rc-virtual-list-holder div
.rc-virtual-list-holder-inner
.ant-select-item-option-selected {
  background-color: #F1F3F5 !important;
}
