.AppealModalButtonsGroupMobile {
  .ButtonDefaultWrapper {
    margin-bottom: 24px;
    width: 100%;

    button {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.ProposalModalContentMobileView {
  .ViewModalButtonsGroupWrapper {
    padding-bottom: 21px;
    margin-top: 24px;
  }
  .ButtonPrimaryWrapper button {
    width: 231px;
  }

  .AppealModalPenaltyTextElement {
    margin-bottom: 24px;
  }

  .AppealModalPenaltyTextElementFirst {
    margin-top: 15px;
  }
}

