.GamesInsideHall {
  padding-top: 24px;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 120%;
  color: #2B2D33;
}

.GamesInsideHallTitle {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #2B2D33;
}

.GamesTableWrapper {
  margin-top: 24px;
}

.GamesDetailWrapper, .SportDetailWrapper, .GamesTableWrapper {
  border: 1px solid #E9EAEA;
  border-radius: 8px;
  margin-top: 16px;
  min-width: 1130px;
  width: 100%;
}

.GamesDetailPlayWrapper, .GamesDetailWinWrapper {
  width: 40%;
}

.GamesDetailPlaySubTitle div:first-child,
.GamesDetailWinSubTitle div:first-child {
  min-width: 264px;
  width: 60%;
}

.GamesDetailPlaySubTitle div:nth-child(2),
.GamesDetailWinSubTitle div:nth-child(2)  {
  min-width: 176px;
  width: 40%;
}

.GamesDetailPlayTitle,
.GamesDetailWinTitle,
.GamesDetailPlayTotalTitle,
.GamesDetailWinTotalTitle,
.GamesDetailWinSubTitle > div,
.GamesDetailPlaySubTitle > div {
  width: 100%;
  text-align: center;
  padding: 12px 0;
}

.GamesDetailPlayTitle,
.GamesDetailWinTitle,
.GamesDetailPlayTotalTitle,
.GamesDetailWinTotalTitle,
.GamesDetailValueBold {
  font-weight: bold !important;
}

.GamesDetailPlayTitle,
.GamesDetailWinTitle,
.GamesDetailPlayTotalTitle,
.GamesDetailWinSubTitle > div,
.GamesDetailPlaySubTitle > div {
  border-right: 1px solid #E9EAEA;
}

.GamesDetailPlayTitle,
.GamesDetailWinTitle,
.GamesDetailPlayTotalTitle,
.GamesDetailWinTotalTitle,
.GamesDetailWinSubTitle > div,
.GamesDetailPlaySubTitle > div {
  border-bottom: 1px solid #E9EAEA;
}

.GamesDetailPlayTotal, .GamesDetailWinTotal {
  width: 10%;
}
.GamesDetailPlayTotalTitle, .GamesDetailWinTotalTitle {
  height: 120px;
}

.GamesDetailPlayTitle,
.GamesDetailPlayTotalTitle,
.GamesDetailPlaySubTitle > div {
  background-color: #FFF4E8;
}

.GamesDetailWinTitle,
.GamesDetailWinTotalTitle,
.GamesDetailWinSubTitle > div {
  background-color: #E7F8F4;
}

.GamesDetailValueTitle {
  height: 40px;
  min-width: 88px;
  width: 20%;
  background-color: #F8F8F8;
  text-align: center;
  border-right: 1px solid #E9EAEA;
  border-bottom: 1px solid #E9EAEA;
}

.GamesDetailValue {
  height: 48px;
  min-width: 88px;
  width: 100%;
  text-align: center;
  border-right: 1px solid #E9EAEA;
}

.GamesDetailValueBold {
  min-width: 88px;
}

.GamesDetailValueWithoutRightBorder {
   border-right: 0 !important;
 }


// Bets table

.GamesTableHeaderItem,
.GamesTableHeaderLastItem,
.GamesTableRowItem,
.GamesTableRowLastItem {
  width: calc(100% / 7);
  min-width: 161.1px;
  padding: 12px 32px;
}

.GamesTableHeaderItem,
.GamesTableRowItem,
.GamesTableRowLastItem {
  border-right: 1px solid #E9EAEA;
}

.GamesTableHeaderItem,
.GamesTableRowItem,
.GamesTableHeaderLastItem {
  border-bottom: 1px solid #E9EAEA;
}

.GamesTableRowItemWithoutRight {
  border-right: 0 !important;
}

.GamesTableHeaderItem, .GamesTableHeaderLastItem {
  background-color: #F0F0FE;
}

.GamesTableBoldItem {
  font-weight: bold !important;
}


// Sport layout

.SportDetailPlayWrapper, .SportDetailWinWrapper {
  width: 40%;
}

.SportDetailPlayTotal, .SportDetailWinTotal {
  width: 10%;
}

.SportDetailPlayTitle, .SportDetailWinTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 120%;
  text-align: center;
  color: #2B2D33;
  min-width: 440px;
  height: 40px;
  padding: 12px 0;
  border-bottom: 1px solid #E9EAEA;
  border-right: 1px solid #E9EAEA;
}

.SportDetailPlayTitle {
  background-color: #FFF4E8;
  border-top-left-radius: 8px;
}

.SportDetailWinTitle {
  background-color: #E7F8F4;
}

.SportDetailPlaySubTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  padding: 12px 24px;
  line-height: 120%;
  color: #2B2D33;
  min-width: 220px;
  width: 50%;
  background: #F8F8F8;
  border-bottom: 1px solid #E9EAEA;
  border-right: 1px solid #E9EAEA;
}

.SportDetailPlayValue, .SportDetailTotalValue {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding: 16px 24px;
  color: #2B2D33;
  min-width: 220px;
  width: 50%;
  border-right: 1px solid #E9EAEA;
}

.SportDetailTotalValue {
  font-weight: bold;
  min-width: 124px !important;
  width: 100%;
  text-align: center;
}

.SportDetailPlayTotalTitle, .SportDetailWinTotalTitle {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 120%;
  text-align: center;
  color: #2B2D33;
  min-width: 124px;
  padding: 12px 0 52px 0;
  border-bottom: 1px solid #E9EAEA;
}

.SportDetailWinTotalTitle {
  border-top-right-radius: 8px;
  background-color: #E7F8F4;
}

.SportDetailPlayTotalTitle {
  border-right: 1px solid #E9EAEA;
  background-color: #FFF4E8;
}
