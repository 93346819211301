.NotifyStatusTableConfirmModalButton {
  height: 48px !important;
}

.NotifyStatusTableConfirmModalButton > span {
  padding-top: 6px;
}

.NotifyStatusTableConfirmModalApprove > button {
  padding: 0 24px !important;
}