@import "./src/styles/colors/styles";
@import "./src/_main";

.Geoservices {
  gap: 16px;
}

.Geoservices_InformationWrapper {
  background-color: @blue-10;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  justify-content: space-between;

  &__Rules {
    display: flex;
    .Text1;
    color: @blue-600;

    span {
      font-weight: 700;
    }
  }

  &__Info {
    color: @blue-600;
    font-weight: 700;
  }

  &__Button {
    .ant-btn.ant-btn-default {
      background: transparent !important;
    }

    .ButtonSecondary
  }
}

.Geoservices_Photos {
  padding: 24px;
  background: @white-100;
  border-radius: 8px;

  &__Info {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;

    &_Icon {
      path {
        fill: #868E96;
      }
    }

    &_Text {
      &:extend(.Text1);
      color: @gray-600;

      &-Valid {
        color: @green-600;
      }
    }
  }

  &__Upload {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }
}

.Interior {
  padding: 24px;
  background: @white-100;
  border-radius: 8px;
}

.UploadComponent {
  &__Uploaded {
    .ant-upload.ant-upload-select-picture-card {
      border: none;
    }
  }

  &__Delete {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    position: absolute;
    top: 4px;
    left: 4px;
    background: rgba(33, 37, 41, 0.75);
    cursor: pointer;
  }

  &__Wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__Image {
    max-width: 180px !important;
    max-height: 120px;
    display: block;
    width: 100% !important;
    object-fit: cover;
    border-radius: 8px;
  }

  .ant-select {
    .ant-select-selector {
      height: 40px;
      border-radius: 8px;
      min-width: 180px;
    }

    .ant-select-single.ant-select-selector {
      align-items: center;
    }
  }

  .ant-upload {
    flex-direction: column;
    min-width: 180px;
    border-radius: 8px;
    min-height: 120px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Gilroy', sans-serif;
    color: #212529;
  }

  .ant-upload.ant-upload-select-picture-card {
    margin: 0 !important;
  }
}

.UploadComponent > .ant-upload-select-picture-card {
  margin: 0 !important;
}

.RulesPopup {
  .ant-modal-content {
    width: 800px;

    .ant-modal-body {
      padding: 40px;
    }

    border-radius: 8px;

    ul {
      margin-bottom: 0;
      padding-left: 15px;
    }
  }
}

@media screen and (max-width: 920px) {
  .RulesPopup {
    &__Wrapper {
      overflow-y: scroll;
      max-height: 78vh;
      padding-left: 2px;
    }
    ::-webkit-scrollbar {
      width: 4px;
      //height: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background: @gray-400;
      border-radius: 8px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    .ant-modal-content {
      max-width: 100%;
      max-height: 716px;

      .ant-modal-body {
        padding: 24px 16px 24px 14px;
      }
    }
  }
  .Geoservices {
    gap: 8px;

    button {
      margin-left: 16px;
    }
  }

  .Geoservices_Photos {
    padding: 16px;

    &__Upload {
      gap: 15px;

      .SelectWrapper {
        margin-bottom: 4px;
      }
    }
  }

  .Geoservices_InformationWrapper {
    flex-direction: column;
    gap: 16px;
  }

  .UploadComponent {
    .ant-upload {
      min-width: 164px;
      min-height: 109px;
    }

    .ant-select {
      .ant-select-selector {
        width: 164px !important;
        min-width: unset
      }
    }
  }
}

.Geoservices_ConfirmPopup {
  &__Button {
    background-color: @gray-100 !important;
    border: none;
    span {
      font-family: 'Gilroy', sans-serif;
      font-size: 16px !important;
    }
  }
  &__Delete {
    background-color: @red-600 !important;
    span {
      color: @white-100 !important;
    }
  }
  &__Footer {
    display: flex;
    justify-content: end;
    gap: 8px;
    margin-top: 8px;
  }
  .ant-modal-content {
    max-width: 480px;

    .ant-modal-body {
      padding: 32px;
    }

    border-radius: 16px;

    ul {
      margin-bottom: 0;
      padding-left: 15px;
    }
  }
}