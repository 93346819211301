.RegistrationCheckMobileTableWrapper {
  border: 1px solid @gray-200;
  border-radius: 16px;
}

.RegistrationCheckMobileTableHeader {
  border-top-right-radius: 16px;
  border-bottom: 1px solid @gray-200;
  border-top-left-radius: 16px;
  &:extend(.Cyan10Background);
  padding: 14px 20px;
}

.RegistrationCheckMobileTableContent {
  padding: 15px 20px 20px 20px;

  div {
    width: 90px;
  }
}