@import "./Table/styles";
@import "./Charts/styles";

.GuestHistoryMetricChartHeader {
  padding-top: 32px;
  padding-bottom: 16px;
}

.GuestHistoryMetricChartTitle {
  padding-right: 16px;
  padding-top: 14px;
}

.GuestHistoryMetricChartSwitcher {
  width: 230px;
}

@media screen and (max-width: 920px) {
  .GuestHistoryMetricChartHeader {
    padding-top: 0 !important;
  }

  .GuestHistoryMetricChartTitle {
    padding-bottom: 16px;
  }
}

.GuestHistoryEmptyDataWrapper {
  padding-top: 72px;
  padding-bottom: 72px;
}