.HallPickerHeader {
  padding-bottom: 10px;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.HallPickerHeaderCounter {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
  padding-left: 4px;
  opacity: 0.2;
}

.HallPickerHeaderReset {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #228BE6;
  margin-left: auto;
}

.HallPickerHeaderReset:hover {
  cursor: pointer;
  opacity: 0.8;
}

.HallPickerModalHeader {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 20px;
  color: #000000;
  padding: 26px 0;
  border-bottom: 1px solid #E5E5EA;
}

@media screen and (min-width: 921px) {
  .HallPickerModalHeader {
    min-width: 544px;
  }
}

.HallPickerModalSearchWrapper {
  padding: 24px 35px 24px 24px;
}

.HallPickerModalSearch {
  padding-bottom: 24px;
}

.HallPickerModalSearch .ant-input-affix-wrapper {
  background-color: #fff !important;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #E5E5EA;
  padding: 12px !important;
}

.HallPickerModalSearch input {
  background-color: #fff;
}

.HallPickerModalSearch > .ant-input-affix-wrapper:hover {
  box-shadow: 0 0 0 1px #f8e800 !important;
  border-color: #f8e800 !important;
}

.HallPickerModalSearch > .ant-input:focus,
.HallPickerModalSearch > .ant-input-focused,
.HallPickerModalSearch > .ant-input-affix-wrapper-focused,
.HallPickerModalSearch > .ant-input:focus,
.HallPickerModalSearch > .ant-input-number-focused,
textarea.ant-input:focus {
  box-shadow: 0 0 0 1px #f8e800 !important;
  border-color: #f8e800 !important;
}

.HallPickerModalSearch #placeholder {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #AAABAD;
  width: 100%;
}

.HallPickerCheckboxWrapper {
  //padding: 0 24px;
  max-height: calc(100vh - 25%);
  margin-bottom: 16px;
}

.HallPickerApplyWrapper {
  padding: 18px 0 20px 0;
  opacity: 0;
  transition: opacity 500ms ease;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.HallPickerApplyWrapperActive {
  background: #F8E800;
  opacity: 1;
}

.HallPickerApplyWrapperActive:hover {
  cursor: pointer;
  opacity: 0.9;
}

.HallPickerFilterCheckboxAllWrapper {
  display: flex;
}

.HallPickerFilterCheckboxGroupWrapper {
  display: flex !important;

  .CheckboxWrapper > .CheckboxText {
      padding-top: 2px;
  }
}


.HallPickerFilterCheckboxAllLabel {
  padding-top: 3px;
}

.HallPickerFilterCheckboxGroupWrapper, .HallPickerFilterCheckboxAllLabel {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #2C2C2E;
}

.HallPickerFilterCheckboxGroupWrapper {
  display: flex;
  flex-direction: column;
  max-height: 165px;
  overflow-y: auto;
}

.HallPickerFilterRadioGroupWrapper {
  padding-left: 2px;
}

.HallPickerFilterCheckboxGroupWrapper label, .HallPickerFilterCheckboxAllWrapper label {
  padding-bottom: 16px;
}

.HallPickerFilterCheckboxGroupWrapper label:last-child {
  border-bottom: none !important;
}

.HallPickerFilterCheckboxGroupShowAll {
  cursor: pointer;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #007AFF;
}

.HallPickerModalContentWrapper {
  height: 400px;
  overflow-y: auto;
  margin-right: 4px;
  margin-bottom: 24px;
  padding: 0 24px;
}

.HallPickerModalList {
  gap: 24px;
}

@media screen and (min-width: 921px) {
  .HallPickerModalItem {
    width: 145px;
  }
}

@media screen and (max-width: 920px) {
  .HallPickerModalItem {
    width: 110px;
  }
}

.HallPickerModalItem {
  padding: 18px 0 18px 24px;
  height: 56px;
  border: 1px solid #D5D5D6;
  border-radius: 8px;
}

.HallPickerModalItemDescription {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #8E8E93;
  padding-top: 2px;
}

.HallPickerModalItemLabel {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #2B2D33;
  padding-left: 6px;
}

.HallPickerModalItem:hover {
  cursor: pointer;
  background-color: #F8F8F8;
}

.HallPickerModalActiveItem {
  border: none !important;
  background-color: #FEE600 !important;
}

.HallPickerModalDisabledItem {
  background-color: #F8F8F8;
}

.HallPickerModalDisabledItem:hover {
  cursor: not-allowed !important;
}

.HallPickerModalActiveItem:hover {
  background-color: #FEE600 !important;
  cursor: not-allowed !important;
}
