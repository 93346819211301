@import "./Table/styles";
@import "./Modal/styles";

.PaymentTitleButtons .ButtonFadedWrapper {
  button {
    padding-top: 12px !important;
    display: flex;
    justify-content: center;

    span {
      font-weight: 600 !important;
      &:extend(.ButtonTextSecond);
    }
  }
}

.PaymentTitleButtons > .ButtonDefaultWrapper > button {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.PaymentTitleButtons > div > .ButtonDefaultWrapper,
.PaymentTitleButtons > div > .ButtonFadedWrapper,
.PaymentTitleButtons > .ButtonPrimaryWrapper {
  button {
    height: 40px !important;
  }
}

@media screen and (min-width: 1280px) {
  .PaymentTitleButtons {
    padding-left: 16px;
  }
}

.PaymentGiftIcon {
  width: 20px;
  height: 20px;
}

.PaymentTriggerButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}