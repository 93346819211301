.anticon-caret-down, .ant-tree-switcher {
  display: none !important;
}

.ant-tree-checkbox {
  .ant-tree-checkbox-inner {
    width: 20px;
    height: 20px;

    border: 2px solid @gray-400 !important;
    border-radius: 4px;
  }
}

.ant-tree-checkbox-checked {
  .ant-tree-checkbox-inner {
    background-color: @lemon-500 !important;
    border-color: @lemon-500 !important;
  }
}

.ant-tree-checkbox .ant-tree-checkbox-inner {
  &:after {
    display: none !important;
  }
}

.ant-tree-treenode-switcher-close .ant-tree-checkbox .ant-tree-checkbox-inner {
  background: transparent url(/Components/Tree/minus_outline_16.svg) no-repeat 70% 50%;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background: transparent url(/Components/Tree/minus_outline_16.svg) no-repeat 70% 50%;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #6DB3F2;
  background-image: url(/Components/CheckBox/check.svg) !important;
}

.ant-tree .ant-tree-node-content-wrapper .ant-tree-node-selected {
  background-color: #fff !important;
}

.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #fff !important;
}

.ant-tree-checkbox-checked.ant-tree-checkbox-disabled {
  .ant-tree-checkbox-inner {
    border: 2px solid @gray-400 !important;
    background-color: @gray-400 !important;
  }
}

.ant-checkbox-inner {
  width: 20px;
  height: 20px;
  background: none;
  border-color: #C7C7CC;
  border-radius: 4px;

  &:after {
    display: none;
    width: 7px;
    height: 11px;
  }
}

.ant-tree-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #F8E800;
}

.ant-tree-checkbox-checked .ant-checkbox-inner {
  background-color: #F8E800;
  border-color: #F8E800;
}

.ant-tree-checkbox-checked::after {
  border-color: #F8E800;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner:after,
.ant-tree-checkbox-checked:hover .ant-tree-checkbox-inner:after {
  border-right-color: #1D1F21;
  border-bottom-color: #1D1F21;
}

.ant-tree-checkbox-wrapper-disabled,
.ant-tree-checkbox-wrapper-disabled + .CheckboxText {
  opacity: 0.5;
}

.ant-tree-title span div {
  padding-top: 3px;
}