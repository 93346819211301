.ViewPenaltyModalHeaderCards {
  display: flex;
  flex-direction: row;
  margin-top: 8px;

  .PenaltyAdminCardFooterCard:not(:first-child) {
    margin-left: 8px;
  }
}

.ViewPenaltyModalHeaderDate {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #868E96;
  margin-top: 8px;
  margin-bottom: 18px;
}

.ViewPenaltyModalSubtitle {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
}

.ViewPenaltyContentWrapper {
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  border-top: 1px solid #E5E5EA;
  border-bottom: 1px solid #E5E5EA;
  //flex-direction: row;
  //flex-flow: row wrap;
}

.ViewPenaltyContentLeft {
  flex: 50%
}

.ViewPenaltyContentRight {
  flex: 50%;
  margin-left: 16px;
}
.ViewPenaltyFilesWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ViewPenaltyModalSubtitle {
  margin-top: 24px;
  margin-bottom: 3px;
}

.ViewPenaltyModalSubtitleFirst {
  margin-top: 0 !important;
}

.ViewModalButtonsGroupWrapper {
  display: flex;
  margin-top: 32px;
}

.ViewModalButtonsGroupLeft {
  flex: 50%;
  display: flex;
  .ButtonIcon_basic {
    height: 48px;
  }
}

.ViewModalButtonsGroupRight {
  display: flex;
  flex: 50%;
  margin-left: 16px;

  .ButtonIcon_basic {
    height: 48px;
  }
}

.ViewModalCancelButton,
.ViewModalRejectButton {
  margin-left: 16px;
}

.ViewModalEditButton,
.ViewModalCancelButton,
.ViewModalResolveButton,
.ViewModalRejectButton {
  height: 48px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ViewModalEditButton {
  //width: 188px;
  svg {
    margin-top: 2px;
  }
}


.ViewModalCancelButton {
  svg {
   margin-top: 5px;
    width: 14px !important;
    height: 14px !important;
    margin-left: 4px;
 }
}

.ViewModalRejectButton {
  svg {
   margin-top: 2px;
 }
}

.ViewPenaltyModalHeaderDateUser {
  margin-top: 2px !important;
}

.ViewModalButtonsGroupWrapperEmpty {
  display: none;
}

.ViewPenaltyContentWrapperEmpty {
  border-bottom: 0 !important;
}

.ViewPenaltyModalComment {
  margin-top: 24px;

  .ViewPenaltyModalSubtitle {
    margin-top: 0px !important;
  }

  background: #F8F9FA;
  border-radius: 12px;
  padding: 16px;
}

.ViewPenaltyModalUserCountBack {
  align-self: center;
  margin-left: 16px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.2px;
  color: #868E96;
}

.ProposalModalTextTitleTextWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}