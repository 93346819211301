.GuestHistoryCashboxChartsWrapper {
  padding-top: 32px;
}

.GuestHistoryCashboxChartsPeriod {
  width: 230px;

  .SelectWrapper .SelectComponent .ant-select-selector .ant-select-selection-item {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
}

.GuestHistoryCashboxChartsTypes {
  display: flex;
  flex-direction: row;
  gap: 8px;

  margin-left: 8px;
}

.ChartTypeComponentWrapper {
  border-radius: 8px;
  padding: 18px 18px 16px 18px;
  width: 164px;
  height: 48px;
}

.ChartTypeComponentWrapper:hover {
  cursor: pointer;
  opacity: 0.8;
}

.ChartTypeComponentDot {
  width: 10px;
  height: 10px;
  border-radius: 50%;

  margin-top: 3px;
  margin-right: 7px;
}

.ChartTypeComponentLabel {
  color: #868E96;
}

.ChartTypeComponentActiveLabel {
  color: #212529 !important;
}

@media screen and (max-width: 920px) {
  .GuestHistoryCashboxChartsTypes {
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;
  }

  .GuestHistoryCashboxChartsHeader {
    flex-direction: column !important;
    gap: 8px;
  }

  .ChartTypeComponentWrapper {
    width: fit-content;
  }
}

.highcharts-tooltip {
  padding: 20px 24px;
}

.highcharts-tooltip span {
  height: auto;
}

.InOutTooltip span {
  width: 376px !important;
  white-space: normal !important;
}

.AttendanceTooltip {
  width: 100px !important;
}

.highcharts-axis-labels > text {
  transform: none !important;
  margin-left: -5px !important;
}

.highcharts-series > rect {
  width: 45px !important;
}

.HideScrollHighcharts div .highcharts-scrolling-parent {
  .highcharts-scrolling::-webkit-scrollbar {
    display: none !important;
  }
}

.GuestHistoryCashboxChartsWrapperAttendance div .highcharts-scrolling-parent {
  .highcharts-scrolling {
    margin-bottom: 16px;
  }
}