.GuestsBalancePage{

  .ComponentDashboardItem{
    margin-top: 0;
  }

  .ModalContent, .ModalFormContent{
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 1px 13px rgba(0, 0, 0, 0.1);
  }

  .ant-table-tbody .ant-table-row > td:first-child{
    .TextDefaultBold;
  }

  .ant-table-tbody .ant-table-row:hover {
    cursor: auto !important;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover
  {
    background: transparent;
    cursor: auto !important;
  }

  .DashboardItemLabel{
    white-space: pre;
  }
}

.GuestBalanceTitle {
  margin-left: 25px;
}

@media screen and (max-width: 1900px){
  .GuestBalance_filterOpen{
    .TableHeaderModal .ModalWrapper{
      left: auto;
      right: 10px;
    }
  }
}

@media screen and (max-width: 1300px){
  .GuestsBalancePage{
    .ant-table table{
      min-width: 1000px !important;
    }
  }
}

@media screen and (min-width: 921px){
  .GuestsBalancePage{

    .ant-table-tbody .ant-table-row > td:first-child,
    .ant-table-container table > thead > tr th:first-child{
      padding-left: 32px !important;
    }

    .ant-table-tbody .ant-table-row > td:last-child,
    .ant-table-container table > thead > tr th:last-child{
      padding-right: 32px !important;
    }
  }

}


@media screen and (max-width: 920px){
  .GuestBalanceTitleWrapper{
    .TitleDescription{
      flex-direction: row;
      align-items: center;
      padding-left: 9px;
      padding-right: 12px;
    }
  }

  .GuestsBalancePage{
    .ComponentDashboardWrapper{
      padding: 0 16px;
    }

    .ant-table table{
      min-width: 100% !important;
    }
  }
}

.guestsBalanceFreeBetCountIcon {
  margin-right: 10px;
  position: relative;
  top: 3px;
}

.guestsBalance_freeBetCountClickable {
  cursor: pointer;
}

.FreeBetsDetailDescription {
  margin-top: 6px;
  .Text1
}
