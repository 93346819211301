.MailingHistoryPeriodWrapper {
  padding-top: 16px;

  span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #495057;
  }

  .MailingHistoryDateDescriptionDef {
    padding-right: 6px;
  }
}

.MailingHistoryDateDescriptionBold {
  font-weight: bold !important;
  padding-right: 6px;
}

.MailingHistoryTable {
  padding-top: 16px;
}

.MailingHistoryTable .TableColumnWrapper .TableComponentWrapper
.ant-spin-nested-loading .ant-spin-container .ant-table {
  min-width: 900px;
}

.MailingHistoryThCell {
  word-spacing: 0 !important;
}

.MailingHistoryTdDate, .MailingHistoryTdDateDescription {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.2px;
  color: #212529;
}

.MailingHistoryTdDate, .MailingHistoryThCellDate {
  padding-left: 20px;
}

.MailingHistoryTdDateDescription {
  padding-left: 6px;
  opacity: 0.6;
}

.MailingHistoryTdType {
  span {
    padding-left: 8px;
    padding-top: 1px;
  }
}