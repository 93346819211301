.SliderComponentWrapper .ant-slider .ant-slider-rail,
.SliderComponentWrapper .ant-slider .ant-slider-track {
  height: 6px !important;
}

.ant-slider {
  margin: 0 !important;
}

.ant-slider-handle {
  top: 0;
  height: 20px !important;
  width: 20px !important;
  background: #FFDD2D !important;
  border: 3px solid #FFFFFF !important;
  box-sizing: border-box !important;
  margin-top: -3px !important;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2) !important;
}

.SliderComponentWrapper .ant-slider-disabled .ant-slider-handle,
.SliderComponentWrapper .ant-slider-disabled .ant-slider-dot {
  border-color: #fff !important;
}

.ant-slider-disabled .ant-slider-handle {
  background: #C7C7CC !important;
  border: 3px solid #FFFFFF !important;
}

.ant-slider-tooltip {
  background-color: #fff !important;
}

.SliderComponentValueWrapper {
  padding-bottom: 4px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2B2D33;
}
