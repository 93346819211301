.TableActionsModalTrigger {
  position: relative;
  margin-left: auto;
  cursor: pointer;
}

.TableActionsModalTriggerActive {
  border-radius: 4px;
  background: @gray-100;
  width: 28px;
  height: 28px;
  padding: @pd-4;

  svg path {
    fill: black;
  }
}

.TableActionsModal {
  position: fixed;
  z-index: 5;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 96px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 14px 18px;
}

.TableActionsModalHidden {
  display: none;
}

.TableActionsModalActive {
  display: block;
}